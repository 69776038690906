import "./ModalPlayer.scss";
import React, { useEffect } from "react";
import VideoPlayer from "./VideoPlayer";

const ModalPlayer = ({ onClick, videoSource, onEnd }) => {
  // console.log(videoSource, "in mediaplayer");
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if the pressed key is the Escape key (key code 27)
      if (event.keyCode === 27) {
        onClick(); // Call the function to close the modal player
      }
    };

    // Add event listener for keydown event
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClick]);
  const handleVideoEnd = () => {
    if (onEnd) {
      // console.log("this vidoe is ended");
      onEnd();
    }
  };
  return (
    <div className="ir-ws-preview-modal-player-container">
      <div className="ir-ws-preview-modal-box">
        <div className="ir-ws-preview-modal-player-close-btn" onClick={onClick}>
          {/* <span>X</span> */}
          <i class="bi bi-x-lg"></i>
        </div>

        <div>
          {videoSource?.endsWith(".m3u8") ? (
            <VideoPlayer hlsLink={videoSource} onEnded={handleVideoEnd} />
          ) : (
            <video className="ir-ws-preview-modal-player" controls>
              <source src={videoSource} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalPlayer;
