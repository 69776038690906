import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./HomeWebinarFeatureContainer.scss";
import { useModalContext } from "../WebinarAdminCreateWebinarTab/ModalContext";
import BookNowModal from "./BookNowModal";
import axios from "axios";
import TermsAndConditionModal from "./TermsAndConditionModal";
import Loader from "../Loader/Loader";
import BookAgainModal from "../WebinarListTab/BookAgainModal";

const HomeWebinarFeatureContainer = () => {
  const {
    webinarData,
    handleRegisterUserMeeting,
    setBookNowModal,
    bookNowModal,
    transacationDetails,
    handleBuyNowClick,
    isbookLoading,
    isLoading,
    handlePaymentCheckout,
  } = useModalContext();
  const reversedWebinarData = Array.isArray(webinarData)
    ? [...webinarData].reverse()
    : [];
  const firstThreeWebinars = reversedWebinarData.slice(0, 3);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [emailVerified, setEmailVerified] = useState(false);
  const [termsAndCondition, setTermsAndCondition] = useState(false);
  const [sendWebinarId, setSendWebinarId] = useState("");
  const [username, setUsername] = useState("");
  const [bookAgainModal, setBookAgainModal] = useState(false);

  const isAdmin = localStorage.getItem("loggedInUsername") === "admin";

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleTermsAndCondition = () => {
    setTermsAndCondition(!termsAndCondition);
  };
  useEffect(() => {
    const userProfileDetails = async () => {
      try {
        const response = await axios.get(
          `https://backend.ir4u.info/api/v1/user/profile`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
            },
          }
        );
        setUsername(response.data?.username);
        if (response.data?.isEmailVerified) {
          setEmailVerified(true);
        }
      } catch (error) {
        // console.log(error);
        return;
      }
    };
    userProfileDetails();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  function convertUTCToLocalAndFormat(utcTimeString) {
    const utcDateTime = new Date(utcTimeString);
    const localTimeZoneOffset = new Date().getTimezoneOffset();
    const localDateTime = new Date(
      utcDateTime.getTime() - localTimeZoneOffset * 60000
    );

    const localTimeFormat = localDateTime.toISOString().slice(0, 16);
    const inputDate = new Date(localTimeFormat);
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return inputDate.toLocaleString("en-US", options);
  }

  const navigate = useNavigate();
  const loggedIn = localStorage.getItem("isLoggedIn") === "true";

  const confirmationFunc = async () => {
    await handlePaymentCheckout(sendWebinarId);
    handleTermsAndCondition();
  };
  const webinarCards = firstThreeWebinars?.map((webinar, index) => {
    const isWebinarIdMatch = transacationDetails?.some(
      (obj) => obj.webinarId === webinar.id
    );

    return (
      <div
        className="ir-ws-hme-prvw-card-container ir-ws-width-33 ir-ws-hme-webinar-division-main-container"
        key={index}
      >
        <div className="ir-bg-white ir-ws-card-main-container">
          <div className="ir-ws-text-center ir-ws-position-relative ir-ws-position-relative ir-ws-card-header">
            <img
              className="ir-ws-width-100 ir-ws-max-width-100 ir-ws-webinar-card-thumbnail-container ir-ws-webinar-thumbnail-tablet"
              src={webinar.thumbnail}
              alt="Courses thumbnail"
            />
            <div className="ir-ws-position-absolute ir-ws-heading-default-color ir-ws-hme-webinar-car-title-tag">
              {convertUTCToLocalAndFormat(webinar.dateAndTime) ===
              "Apr 24, 2025, 11:06 PM"
                ? "Upcoming"
                : webinar.status}
            </div>
          </div>
          <div className="ir-ws-heading-default-color ir-ws-app-bg ir-ws-text-center ir-ws-webinar-status-tag-box">
            {convertUTCToLocalAndFormat(webinar.dateAndTime) ===
            "Apr 24, 2025, 11:06 PM"
              ? "Upcoming"
              : convertUTCToLocalAndFormat(webinar.dateAndTime)}
          </div>
          <div className="ir-ws-card-body">
            <h4
              className="ir-ws-heading-default-color ir-ws-webinar-crd-bdy-title"
              title={webinar.title.length > 16 ? webinar.title : null}
            >
              {webinar.title.length > 16
                ? `${webinar.title.slice(0, 16)}...`
                : webinar.title}
            </h4>
            <p
              className="ir-default-color ir-ws-hme-webinar-crd-para"
              title={
                webinar.description.length > 25 ? webinar.description : null
              }
            >
              {webinar.description.length > 25
                ? `${webinar.description.slice(0, 25)}...`
                : webinar.description}
            </p>
            <div className="ir-ws-flex ir-ws-align-center ir-ws-hme-webinar-seats-container">
              <img
                src={require("../../assets/images/car-seat.png")}
                alt="users"
              />
              <span className="ir-ws-heading-default-color">
                {webinar.noOfSeats} Seats available
              </span>
            </div>
            <div className="ir-ws-flex ir-ws-align-center ir-ws-hme-webinar-price-container">
              <img
                src={require("../../assets/images/shopping.png")}
                alt="users"
              />
              <span className="ir-ws-app-color">
                {webinar.currency === "USD" ? "$" : webinar.currency}
                {webinar?.price || 0}
              </span>
            </div>
            <div className="ir-ws-flex ir-ws-align-center ir-ws-hme-webinar-lang-container">
              <img src={require("../../assets/images/globe.png")} alt="users" />
              <span className="ir-ws-heading-default-color">English</span>
            </div>

            <div className="ir-ws-text-center ir-ws-default-btn-container ir-ws-book-now-container">
              {!isWebinarIdMatch ? (
                <button
                  className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                  style={{
                    visibility:
                      webinar.status === "cancelled" ||
                      webinar.status === "completed" ||
                      convertUTCToLocalAndFormat(webinar.dateAndTime) ===
                        "Apr 24, 2025, 11:06 PM"
                        ? "hidden"
                        : "visible",
                  }}
                  onClick={() => {
                    if (loggedIn) {
                      if (emailVerified) {
                        handleTermsAndCondition();
                        setSendWebinarId(webinar.id);
                      } else {
                        setBookNowModal(true);
                      }
                    } else if (!loggedIn) {
                      setBookNowModal(true);
                    }
                  }}
                  disabled={isAdmin || isbookLoading}
                >
                  <span>Book now</span>
                </button>
              ) : (
                <button
                  className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                  disabled={
                    webinar.status === "cancelled" ||
                    webinar.status === "completed" ||
                    convertUTCToLocalAndFormat(webinar.dateAndTime) ===
                      "Apr 24, 2025, 11:06 PM" ||
                    isAdmin
                  }
                  onClick={() => {
                    toggleBookAgain();
                    setSendWebinarId(webinar.id);
                  }}
                >
                  <span>Book now</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  });
  const toggleBookAgain = () => {
    setBookAgainModal(!bookAgainModal);
  };
  return (
    <div className="ir-ws-hme-webnr-inside-gradient-contianer">
      <div className="ir-ws-padding-tb-100 ir-ws-padding-lr-132 ir-ws-hme-webinar-main-container">
        <h2 className="ir-ws-text-center ir-ws-hme-webinar-title ir-ws-hme-upcoming-text">
          Upcoming Webinars on Interventional Radiology
        </h2>
        <div className="ir-ws-align-center ir-ws-hme-webinar-inner-container">
          <div className="ir-ws-hme-webinar-sessions-container">
            {webinarData && (
              <>
                {isMobile ? (
                  <Slider {...settings}>{webinarCards}</Slider>
                ) : (
                  <div className="ir-ws-flex ir-ws-wrap">{webinarCards}</div>
                )}
              </>
            )}
          </div>
          <div className="ir-ws-hme-webinar-content-container">
            <h3 className="ir-ws-position-relative ir-ws-heading-default-color ir-ws-hme-webinar-content-title">
              Learn from experts through IR4U Webinars
            </h3>
            <p className="ir-default-color ir-ws-hme-webinar-content-para">
              Webinars are conducted regularly to bring the ;latest advancements
              in the IR field, as well the industry practices and expert
              knowledge.
            </p>
            <div className="ir-ws-hme-prvw-signup-container">
              <div className="ir-ws-prvw-signup-btn-container">
                <button
                  className="ir-ws-app-bg ir-ws-text-uppercase ir-color-white ir-ws-no-border ir-ws-webinar-signup-btn"
                  onClick={() => navigate("/webinar")}
                >
                  <span>Explore Webinars now</span>
                </button>
              </div>
              <div className="ir-ws-flex ir-ws-align-center ir-ws-hme-prvw-signup-inner-container">
                <div className="ir-ws-prvw-signup-content-container">
                  <h4 className="ir-ws-heading-default-color ir-ws-prvw-signup-title">
                    <span className="ir-ws-text-uppercase">Sign up</span> to
                    access all these features and more
                  </h4>
                  <div className="ir-ws-flex ir-ws-align-center ir-ws-prvw-signup-dot-main-container">
                    <div className="ir-ws-prvw-signup-dot-small"></div>
                    <div className="ir-ws-prvw-signup-dot-small"></div>
                    <div className="ir-ws-prvw-signup-dot-large"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {bookNowModal && loggedIn && !emailVerified && (
        <BookNowModal
          type="Verify Email"
          emailVerified={!emailVerified}
          loggedIn={loggedIn}
          username={username}
          setBookNowModal={setBookNowModal}
        />
      )}
      {bookNowModal && !loggedIn && (
        <BookNowModal
          type="Sign up"
          loggedIn={!loggedIn}
          setBookNowModal={setBookNowModal}
        />
      )}
      {termsAndCondition && (
        <TermsAndConditionModal
          handleTermsAndCondition={handleTermsAndCondition}
          id={sendWebinarId}
          confirmationFunc={confirmationFunc}
        />
      )}
      {bookAgainModal && (
        <BookAgainModal
          id={sendWebinarId}
          toggleBookAgain={toggleBookAgain}
          confirmationFunc={confirmationFunc}
        />
      )}
      {isbookLoading && <Loader />}
    </div>
  );
};

export default HomeWebinarFeatureContainer;
