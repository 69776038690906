import React from "react";

const SecondAdminTab = () => {
  return (
    <div className="ir-ws-webinar-admin-custom-tab">
      <p className="ir-ws-custom-tab-button w-[150px] lg:w-[100px]  xl:w-[120px] text-center">
        Admin List
      </p>
    </div>
  );
};

export default SecondAdminTab;
