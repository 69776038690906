import React, { useState } from "react";
import ConfirmationModal from "../Courses/AdminCreateCourseTab/ConfirmationModal";
import { useModalContext } from "../../components/WebinarAdminCreateWebinarTab/ModalContext";
import { useNavigate } from "react-router-dom";

const AdminCaseTrashList = () => {
  const navigate = useNavigate();
  const { deletedCases, restoreCaseFunction, secondAdminDeletedCases } =
    useModalContext();

  const userProfile = JSON.parse(localStorage.getItem("userProfile") || "[]");
  const isSecondAdmin = userProfile.includes("publisher");

  const deletedCasesList = isSecondAdmin
    ? secondAdminDeletedCases
    : deletedCases;

  const [caseId, setCaseId] = useState("");
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const toggleConfirmationModal = (id) => {
    setCaseId(id);
    setOpenConfirmationModal(!openConfirmationModal);
  };

  const handleRestoreConfirmation = async () => {
    if (caseId) {
      await restoreCaseFunction(caseId);
      setCaseId("");
      setOpenConfirmationModal(false);
    }
  };

  return (
    // <div>
    //   {deletedCasesList?.map((caseItem, index) => (
    //     <div className="ir-ws-webinar-list-tab" key={index}>
    //       <div className="ir-ws-admin-webinar-content">
    //         <div className="ir-ws-admin-webinar-title-container">
    //           {/* <img
    //               src={course.formData.imagePreview}
    //               className="ir-ws-course-list-image"
    //               alt="course"
    //             /> */}
    //           <p className="ir-ws-webinar-title-text">
    //             {caseItem?.slides?.[0]?.name ?? "No name found"}
    //           </p>
    //         </div>
    //         <div className="ir-ws-admin-list-button-container">
    //           <button
    //             className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
    //             onClick={() => navigate(`/case/${caseItem._id}`)}
    //           >
    //             View Case
    //           </button>
    //           <button
    //             className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
    //             onClick={() => toggleConfirmationModal(caseItem._id)}
    //           >
    //             Restore
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   ))}
    //   {openConfirmationModal && (
    //     <ConfirmationModal
    //       toggleModal={toggleConfirmationModal}
    //       confirmationFunc={handleRestoreConfirmation}
    //       title="restore"
    //     />
    //   )}
    // </div>

    <div>
      {deletedCasesList?.length > 0 ? (
        deletedCasesList.map((caseItem, index) => (
          <div className="ir-ws-webinar-list-tab" key={index}>
            <div className="ir-ws-admin-webinar-content">
              <div className="ir-ws-admin-webinar-title-container">
                {/* <img
                src={course.formData.imagePreview}
                className="ir-ws-course-list-image"
                alt="course"
              /> */}
                <p className="ir-ws-webinar-title-text">
                  {caseItem?.slides?.[0]?.name ?? "No name found"}
                </p>
              </div>
              <div className="ir-ws-admin-list-button-container">
                <button
                  className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
                  onClick={() => navigate(`/case/${caseItem._id}`)}
                >
                  View Case
                </button>
                <button
                  className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
                  onClick={() => toggleConfirmationModal(caseItem._id)}
                >
                  Restore
                </button>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-center mt-[30px] text-[10px] lg:text-[10px] xl:text-[12px] 3xl:text-[14px]">
          <p>No deleted cases found.</p>
        </div>
      )}

      {openConfirmationModal && (
        <ConfirmationModal
          toggleModal={toggleConfirmationModal}
          confirmationFunc={handleRestoreConfirmation}
          title="restore"
        />
      )}
    </div>
  );
};

export default AdminCaseTrashList;
