import { useNavigate } from "react-router-dom";
import { useModalContext } from "../WebinarAdminCreateWebinarTab/ModalContext";

const BookNowModal = ({
  type,
  loggedIn,
  emailVerified,
  setBookNowModal,
  username,
  category,
}) => {
  const { sendVerifyEmailLink } = useModalContext();
  const navigate = useNavigate();
  const handleCloseBookNowModal = () => {
    setBookNowModal(false);
  };
  return (
    <div className="ir-ws-position-fixed ir-ws-sign-popup-container ">
      <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
        <div className="ir-ws-signup-content-container">
          <div
            className="ir-ws-position-absolute ir-ws-signup-close-container"
            onClick={handleCloseBookNowModal}
          >
            <span>X</span>
          </div>
          <div className="ir-ws-signup-content-inner-container ir-ws-webinar-book-verify-container">
            {type === "Verify Email" && loggedIn && emailVerified && (
              <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                Verify your email to book {category}
              </h3>
            )}
            {type === "Sign up" && loggedIn && (
              <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title px-[30px]">
                You have to signIn or signUp
                {/* to book the {category} */}
              </h3>
            )}
            <div>
              {type === "Sign up" && loggedIn ? (
                <div className="ir-ws-deleteModal-button-container !mt-[20px] lg:!mt-[30px]">
                  <button
                    className="ir-ws-deleteModal-button-yes"
                    //   onClick={confirmationFunc}
                    onClick={() => {
                      navigate("/signup");
                      handleCloseBookNowModal();
                    }}
                    style={{ width: "150px" }}
                  >
                    Sign Up
                  </button>
                  <button
                    className="ir-ws-deleteModal-button-no"
                    onClick={() => {
                      navigate("/signin");
                      handleCloseBookNowModal();
                    }}
                    style={{ width: "150px" }}
                  >
                    Sign in
                  </button>
                </div>
              ) : (
                <div className="ir-ws-deleteModal-button-container">
                  <button
                    className="ir-ws-deleteModal-button-yes"
                    onClick={async () => {
                      if (username) {
                        await sendVerifyEmailLink(username);
                        handleCloseBookNowModal();
                      }
                    }}
                    style={{ width: "180px" }}
                  >
                    Verify Email
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookNowModal;
