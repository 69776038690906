import { useEffect, useState } from "react";
import axios from "axios";

const useFetchProducts = () => {
  const userName = localStorage.getItem("loggedInUsername");
  const userProfile = JSON.parse(localStorage.getItem("userProfile") || "[]");
  const isSecondAdmin = userProfile.includes("publisher");

  const [allProducts, setAllProducts] = useState({
    cases: [],
    webinars: [],
    chats: [],
    courses: [],
    deletedCases: [],
    deletedCourses: [],
  });

  const [combinedProducts, setCombinedProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const token = localStorage.getItem("jwtTokenApi1");

  const handleEmptyResponse = (response, defaultData = []) => {
    if (!response) return defaultData;
    if (Array.isArray(response)) return response;
    if (response.data && Array.isArray(response.data)) return response.data;
    if (response.cases) return response.cases;
    if (response.courses) return response.courses;
    if (response.approvedWebinars) return response.approvedWebinars;
    return defaultData;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        if (!isSecondAdmin) {
          // Fetch data for admin
          const [casesRes, webinarsRes, chatsRes, coursesRes] =
            await Promise.all([
              axios
                .get("https://case.ir4u.info/api/v1/admin/case/all", {
                  headers: { Authorization: `Bearer ${token}` },
                })
                .catch(() => ({ data: { data: [] } })),
              axios
                .get(
                  `https://webinar-staging.backend.ir4u.info/api/v1/webinars`,
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  }
                )
                .catch(() => ({ data: [] })),
              axios
                .get("https://chats.ir4u.info/api/v1/chat/group", {
                  headers: { Authorization: `Bearer ${token}` },
                })
                .catch(() => ({ data: { data: [] } })),
              axios
                .get("https://courses.ir4u.info/api/all/courses")
                .catch(() => ({ data: [] })),
            ]);

          // Filter cases and courses for deleted items
          const casesData = handleEmptyResponse(casesRes, []);
          const filteredCases = casesData.filter((c) => !c?.isDeleted);
          const deletedCasesArray = casesData.filter((c) => c?.isDeleted);

          const coursesData = handleEmptyResponse(coursesRes, []);
          const filteredCourses = coursesData.filter(
            (course) => !course?.formData?.isDeleted
          );
          const deletedCoursesArray = coursesData.filter(
            (course) => course?.formData?.isDeleted
          );

          // Format and combine data
          const formattedCases = filteredCases.map((c) => ({
            ...c,
            type: "case",
          }));

          const webinarsData = handleEmptyResponse(webinarsRes, []);
          const formattedWebinars = webinarsData.map((w) => ({
            ...w,
            type: "webinar",
          }));

          const chatsData = handleEmptyResponse(chatsRes?.data, []);
          const formattedChats = chatsData.map((chat) => ({
            ...chat,
            type: "chat",
          }));

          const formattedCourses = filteredCourses.map((course) => ({
            ...course,
            type: "course",
          }));

          // Combine and sort products
          const sortedProducts = [
            ...formattedCases,
            ...formattedWebinars,
            ...formattedChats,
            ...formattedCourses,
          ].sort(
            (a, b) =>
              new Date(b.createdAt || b.formData?.createdAt) -
              new Date(a.createdAt || a.formData?.createdAt)
          );

          // Update state
          setAllProducts({
            cases: filteredCases,
            deletedCases: deletedCasesArray,
            webinars: webinarsData,
            chats: chatsData,
            courses: filteredCourses.reverse(),
            deletedCourses: deletedCoursesArray,
          });

          setCombinedProducts(sortedProducts);
        } else {
          // Fetch data for second admin
          const [casesRes, webinarsRes, coursesRes] = await Promise.all([
            axios
              .get("https://case.ir4u.info/api/v1/publisher/approve-case", {
                headers: { Authorization: `Bearer ${token}` },
              })
              .catch(() => ({ data: { cases: [] } })),
            axios
              .get(
                `https://webinar-staging.backend.ir4u.info/api/v1/sub-admin/approved-webiners`,
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              )
              .catch(() => ({ data: { approvedWebinars: [] } })),
            axios
              .get(
                `https://courses.ir4u.info/api/approved-courses?username=${userName}`,
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              )
              .catch(() => ({ data: { courses: [] } })),
          ]);

          // Filter cases and courses for deleted items
          const casesData = handleEmptyResponse(casesRes?.data, []);
          const filteredCases = casesData.filter((c) => !c?.isDeleted);
          const deletedCasesArray = casesData.filter((c) => c?.isDeleted);

          const coursesData = handleEmptyResponse(coursesRes?.data, []);
          const filteredCourses = coursesData.filter(
            (course) => !course?.formData?.isDeleted
          );
          const deletedCoursesArray = coursesData.filter(
            (course) => course?.formData?.isDeleted
          );

          // Format and combine data
          const formattedCases =
            filteredCases?.map((c) => ({
              ...c,
              type: "case",
            })) || [];

          const webinarsData = handleEmptyResponse(webinarsRes?.data, []);
          const formattedWebinars =
            webinarsData?.map((w) => ({
              ...w,
              type: "webinar",
            })) || [];

          const formattedCourses =
            filteredCourses?.map((course) => ({
              ...course,
              type: "course",
            })) || [];

          // Combine and sort products
          const sortedProducts = [
            ...formattedCases,
            ...formattedWebinars,
            ...formattedCourses,
          ].sort(
            (a, b) =>
              new Date(b.createdAt || b.formData?.createdAt) -
              new Date(a.createdAt || a.formData?.createdAt)
          );

          // Update state
          setAllProducts({
            cases: filteredCases,
            deletedCases: deletedCasesArray,
            webinars: webinarsData,
            chats: [],
            courses: filteredCourses.reverse(),
            deletedCourses: deletedCoursesArray,
          });

          setCombinedProducts(sortedProducts);
        }
      } catch (err) {
        setError(err.message);
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { allProducts, combinedProducts, loading, error };
};

export default useFetchProducts;
