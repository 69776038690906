// import React, { useContext, useEffect, useState } from "react";
// import Swal from "sweetalert2";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import { RootStore } from "../../store/store";

// const UpdatePasswordModal = ({ updatePassModal, setUpdatePassModal }) => {
//   const [newPassword, setNewPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [loading, setLoading] = useState(false);

//   const navigate = useNavigate();
//   const [token, setToken] = useState("");
//   const [profileData, setProfileData] = useState(null);
//   const [userId, setUserId] = useState("");

//   const [store, setStore] = useContext(RootStore);
//   const { userStore } = store;

//   useEffect(() => {
//     const token = localStorage.getItem("jwtTokenApi1");
//     if (!token) {
//       navigate("/signin?redirect=chat-room");
//     } else {
//       setToken(token);
//       // getProducts() commented for the reason because of this line the chat is not loading in the begining of the page need to fix it
//       if (Object.keys(userStore.profile).length <= 0) {
//         getUserProfile();
//       }
//     }
//   }, []);

//   const getUserProfile = async () => {
//     try {
//       const response = await axios.get(
//         `https://backend.ir4u.info/api/v1/user/profile`,
//         {
//           headers: {
//             Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
//           },
//         }
//       );
//       console.log("User profile fetched", response);
//       setUserId(response.data.id);
//       setProfileData(response.data);
//       userStore.profile = response.data;
//       setStore({ ...store, userStore });
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     getUserProfile();
//   }, [userId]);

//   const handleUpdatePassword = async () => {
//     if (newPassword !== confirmPassword) {
//       Swal.fire("Error", "Passwords do not match!", "error");
//       return;
//     }

//     try {
//       setLoading(true);
//       const userId = userStore.profile.id;

//       const updatePasswordData = {
//         password: newPassword,
//       };

//       const response = await axios.put(
//         `https://backend.ir4u.info/api/v1/user/${userId}`,
//         updatePasswordData,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
//           },
//         }
//       );

//       if (response.status === 200) {
//         Swal.fire("Success!", "Your password has been updated.", "success");

//         // Update user profile in the store (if needed)
//         setStore({
//           ...store,
//           userStore: {
//             ...userStore,
//             profile: {
//               ...userStore.profile,
//               password: newPassword, // Just for local state, backend handles security
//             },
//           },
//         });

//         // Close modal and reset fields
//         setNewPassword("");
//         setConfirmPassword("");
//         setUpdatePassModal(false);
//       }
//     } catch (error) {
//       console.error("Error updating password:", error);
//       Swal.fire("Error", "Something went wrong. Please try again.", "error");
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     setProfileData(userStore.profile);
//   }, [userStore.profile]);

//   return (
//     <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
//       <div className="bg-white rounded-lg shadow-lg w-[400px] p-6 relative">
//         <h2 className="text-xl font-semibold mb-4">Update Password</h2>

//         <div className="mb-4">
//           <label className="block text-sm font-medium mb-1">New Password</label>
//           <input
//             type="password"
//             className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//             value={newPassword}
//             onChange={(e) => setNewPassword(e.target.value)}
//           />
//         </div>

//         <div className="mb-4">
//           <label className="block text-sm font-medium mb-1">
//             Confirm Password
//           </label>
//           <input
//             type="password"
//             className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//             value={confirmPassword}
//             onChange={(e) => setConfirmPassword(e.target.value)}
//           />
//         </div>

//         <div className="flex justify-end gap-2">
//           <button
//             className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400"
//             onClick={() => setUpdatePassModal(false)}
//             disabled={loading}
//           >
//             Cancel
//           </button>
//           <button
//             className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
//             onClick={handleUpdatePassword}
//             disabled={loading}
//           >
//             {loading ? "Updating..." : "Update"}
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default UpdatePasswordModal;

import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { RootStore } from "../../store/store";

const UpdatePasswordModal = ({ updatePassModal, setUpdatePassModal }) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [profileData, setProfileData] = useState(null);
  const [userId, setUserId] = useState("");

  const [store, setStore] = useContext(RootStore);
  const { userStore } = store;

  useEffect(() => {
    const token = localStorage.getItem("jwtTokenApi1");
    if (!token) {
      navigate("/signin?redirect=chat-room");
    } else {
      setToken(token);
      if (Object.keys(userStore.profile).length <= 0) {
        getUserProfile();
      }
    }
  }, []);

  const getUserProfile = async () => {
    try {
      const response = await axios.get(
        `https://backend.ir4u.info/api/v1/user/profile`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
          },
        }
      );
      setUserId(response.data.id);
      setProfileData(response.data);
      userStore.profile = response.data;
      setStore({ ...store, userStore });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUserProfile();
  }, [userId]);

  const handleUpdatePassword = async () => {
    if (newPassword !== confirmPassword) {
      Swal.fire("Error", "Passwords do not match!", "error");
      return;
    }

    try {
      setLoading(true);
      const userId = userStore.profile.id;

      const updatePasswordData = {
        password: newPassword,
      };

      const response = await axios.put(
        `https://backend.ir4u.info/api/v1/user/${userId}`,
        updatePasswordData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
          },
        }
      );

      if (response.status === 200) {
        Swal.fire("Success!", "Your password has been updated.", "success");

        setStore({
          ...store,
          userStore: {
            ...userStore,
            profile: {
              ...userStore.profile,
              password: newPassword,
            },
          },
        });

        setNewPassword("");
        setConfirmPassword("");
        setUpdatePassModal(false);
      }
    } catch (error) {
      console.error("Error updating password:", error);
      Swal.fire("Error", "Something went wrong. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setProfileData(userStore.profile);
  }, [userStore.profile]);

  return (
    <div className="ir-ws-position-fixed ir-ws-sign-popup-container">
      <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
        <div className="ir-ws-signup-content-container">
          <div
            className="ir-ws-position-absolute ir-ws-signup-close-container"
            onClick={() => setUpdatePassModal(false)}
          >
            <span>X</span>
          </div>
          <div className="ir-ws-signup-content-inner-container">
            <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
              Update Password
            </h3>
          </div>
          <form
            className="ir-ws-signup-form-container ir-ws-my-profile-padding"
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdatePassword();
            }}
          >
            <div className="ir-ws-signup-content-group">
              <input
                className="ir-ws-signup-input-field"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <span className="ir-ws-signup-highlight"></span>
              <span className="ir-ws-signup-bar"></span>
              <label className="ir-ws-signup-label">New Password</label>
            </div>

            <div className="ir-ws-signup-content-group">
              <input
                className="ir-ws-signup-input-field"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <span className="ir-ws-signup-highlight"></span>
              <span className="ir-ws-signup-bar"></span>
              <label className="ir-ws-signup-label">Confirm Password</label>
            </div>

            <div className="ir-ws-text-center ir-ws-default-btn-container">
              <button
                className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                type="submit"
                disabled={loading}
              >
                <span>{loading ? "Updating..." : "Update Password"}</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdatePasswordModal;
