import "./WebinarAdminCreateWebinarTab.scss";
import WebinarAdminWebinarListTab from "../WebinarAdminWebinarListTab/WebinarAdminWebinarListTab";
import CreateWebinarModal from "./CreateWebinarModal";
import { useModalContext } from "./ModalContext";
import SuccessfulWebinarModal from "./SuccessfulModal";

const WebinarAdminCreateWebinarTab = ({ toggle, setToggle }) => {
  const {
    modalOpen,
    setModalOpen,
    successfulWebinarModal,
    handleWebinarCreateModalOpen,
  } = useModalContext();
  const { webinarData, secondAdminWebinars } = useModalContext();

  const userProfile = JSON.parse(localStorage.getItem("userProfile") || "[]");
  const isSecondAdmin = userProfile.includes("publisher");

  // const handleWebinarCreateModalOpen = () => {
  //   setModalOpen(true);
  // };
  // const reversedWebinarData = Array.isArray(webinarData)
  //   ? [...webinarData].reverse()
  //   : [];

  const webinarList = isSecondAdmin ? secondAdminWebinars : webinarData;
  const reversedWebinarList = Array.isArray(webinarList)
    ? [...webinarList].reverse()
    : [];

  return (
    <div className="ir-ws-admin-create-webinar-tab ir-ws-webinar-tb-main-container">
      {toggle ? null : (
        <div className="ir-ws-create-btn-container ir-ws-create-webinar-button-container">
          <button
            className="ir-ws-app-bg ir-color-white ir-ws-no-border ir-ws-banner-btn"
            onClick={handleWebinarCreateModalOpen}
          >
            <span>Create Webinar</span>
          </button>
        </div>
      )}
      {modalOpen && <CreateWebinarModal type={"Create"} />}
      {successfulWebinarModal && <SuccessfulWebinarModal />}
      {}
      {toggle && (
        // <div className="ir-ws-admin-webiner-list-container">
        //   {reversedWebinarList?.map((webinar, index) => (
        //     <WebinarAdminWebinarListTab
        //       key={index}
        //       id={webinar.id || webinar._id}
        //       title={webinar.title}
        //       toggle={toggle}
        //       setToggle={setToggle}
        //       meetingId={webinar.meetingId}
        //       presenter1={webinar.presenters?.presenter1.joinUrl}
        //       passCode1={webinar.presenters?.presenter1.passCode}
        //       presenter2={webinar.presenters?.presenter2.joinUrl}
        //       passCode2={webinar.presenters?.presenter2.passCode}
        //       status={webinar.status}
        //       dateAndTime={webinar.dateAndTime}
        //     />
        //   ))}
        // </div>

        <div className="ir-ws-admin-webiner-list-container">
          {reversedWebinarList?.length > 0 ? (
            reversedWebinarList.map((webinar, index) => (
              <WebinarAdminWebinarListTab
                key={index}
                id={webinar.id || webinar._id}
                title={webinar.title}
                toggle={toggle}
                setToggle={setToggle}
                meetingId={webinar.meetingId}
                presenter1={webinar.presenters?.presenter1.joinUrl}
                passCode1={webinar.presenters?.presenter1.passCode}
                presenter2={webinar.presenters?.presenter2.joinUrl}
                passCode2={webinar.presenters?.presenter2.passCode}
                status={webinar.status}
                dateAndTime={webinar.dateAndTime}
              />
            ))
          ) : (
            <p className="text-center mt-[30px] text-[10px] lg:text-[10px] xl:text-[12px] 3xl:text-[14px]">
              No webinars found
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default WebinarAdminCreateWebinarTab;
