import React from "react";
import SecondAdminTab from "../SecondAdmin/SecondAdminTab";
import SecondAdminList from "../SecondAdmin/SecondAdminList";

const SecondAdminPanel = () => {
  return (
    <div className="h-[100%]">
      <SecondAdminTab />
      <SecondAdminList />
    </div>
  );
};

export default SecondAdminPanel;
