import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useModalContext } from "../WebinarAdminCreateWebinarTab/ModalContext";
import Header from "../Header/Header";

const SinglWebinarData = () => {
  const { webinarData, transacationDetails } = useModalContext();
  const { id } = useParams();
  const webinar = webinarData?.find((item) => item.id === id);

  useEffect(() => {
    console.log("Webinar ID from URL:", id); // Log to check if ID is received
  }, [id]);
  const isWebinarIdMatch = transacationDetails?.some(
    (obj) => obj.webinarId === webinar?.id
  );
  function convertUTCToLocalAndFormat(utcTimeString) {
    const utcDateTime = new Date(utcTimeString);
    const localTimeZoneOffset = new Date().getTimezoneOffset();
    const localDateTime = new Date(
      utcDateTime.getTime() - localTimeZoneOffset * 60000
    );

    const localTimeFormat = localDateTime.toISOString().slice(0, 16);
    const inputDate = new Date(localTimeFormat);
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return inputDate.toLocaleString("en-US", options);
  }
  const isAdmin = localStorage.getItem("loggedInUsername") === "admin";
  return (
    <div className="h-[100dvh] flex items-center justify-center">
      {/* <Header /> */}
      <div className="  flex items-center justify-center ">
        <div className="ir-ws-align-center ir-ws-hme-webinar-inner-container ">
          <div className="ir-ws-hme-webinar-sessions-container flex items-center !w-[100%] justify-center">
            {webinar ? (
              <div
                className="ir-ws-hme-prvw-card-container w-[30%] xl:w-[22%] 3xl:w-[30%] md: !pr-[0px] ir-ws-hme-webinar-division-main-container"
                //   key={index}
              >
                <div className="ir-bg-white ir-ws-card-main-container">
                  <div className="ir-ws-text-center ir-ws-position-relative ir-ws-position-relative ir-ws-card-header">
                    <img
                      className="ir-ws-width-100 ir-ws-max-width-100 ir-ws-webinar-card-thumbnail-container"
                      src={webinar.thumbnail}
                      alt="Courses thumbnail"
                    />
                    <div className="ir-ws-position-absolute ir-ws-heading-default-color ir-ws-hme-webinar-car-title-tag">
                      {convertUTCToLocalAndFormat(webinar.dateAndTime) ===
                      "Apr 24, 2025, 11:06 PM"
                        ? "Upcoming"
                        : webinar.status}
                    </div>
                  </div>
                  <div className="ir-ws-heading-default-color ir-ws-app-bg ir-ws-text-center ir-ws-webinar-status-tag-box">
                    {convertUTCToLocalAndFormat(webinar.dateAndTime) ===
                    "Apr 24, 2025, 11:06 PM"
                      ? "Upcoming"
                      : convertUTCToLocalAndFormat(webinar.dateAndTime)}
                  </div>
                  <div className="ir-ws-card-body">
                    <h4
                      className="ir-ws-heading-default-color ir-ws-webinar-crd-bdy-title"
                      title={webinar.title.length > 16 ? webinar.title : null}
                    >
                      {webinar.title.length > 16
                        ? `${webinar.title.slice(0, 16)}...`
                        : webinar.title}
                    </h4>
                    <p
                      className="ir-default-color ir-ws-hme-webinar-crd-para"
                      title={
                        webinar.description.length > 25
                          ? webinar.description
                          : null
                      }
                    >
                      {webinar.description.length > 25
                        ? `${webinar.description.slice(0, 25)}...`
                        : webinar.description}
                    </p>
                    <div className="ir-ws-flex ir-ws-align-center ir-ws-hme-webinar-seats-container">
                      <img
                        src={require("../../assets/images/car-seat.png")}
                        alt="users"
                      />
                      <span className="ir-ws-heading-default-color">
                        {webinar.noOfSeats} Seats available
                      </span>
                    </div>
                    <div className="ir-ws-flex ir-ws-align-center ir-ws-hme-webinar-price-container">
                      <img
                        src={require("../../assets/images/shopping.png")}
                        alt="users"
                      />
                      <span className="ir-ws-app-color">
                        {webinar.currency === "USD" ? "$" : webinar.currency}
                        {webinar?.price || 0}
                      </span>
                    </div>
                    <div className="ir-ws-flex ir-ws-align-center ir-ws-hme-webinar-lang-container">
                      <img
                        src={require("../../assets/images/globe.png")}
                        alt="users"
                      />
                      <span className="ir-ws-heading-default-color">
                        English
                      </span>
                    </div>

                    <div className="ir-ws-text-center ir-ws-default-btn-container ir-ws-book-now-container">
                      {!isWebinarIdMatch ? (
                        <button
                          className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                          style={{
                            visibility:
                              webinar.status === "cancelled" ||
                              webinar.status === "completed" ||
                              convertUTCToLocalAndFormat(
                                webinar.dateAndTime
                              ) === "Apr 24, 2025, 11:06 PM"
                                ? "hidden"
                                : "visible",
                          }}
                          // onClick={() => {
                          //   if (loggedIn) {
                          //     if (emailVerified) {
                          //       handleTermsAndCondition();
                          //       setSendWebinarId(webinar.id);
                          //     } else {
                          //       setBookNowModal(true);
                          //     }
                          //   } else if (!loggedIn) {
                          //     setBookNowModal(true);
                          //   }
                          // }}
                          // disabled={isAdmin || isbookLoading}
                        >
                          <span>Book now</span>
                        </button>
                      ) : (
                        <button
                          className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                          disabled={
                            webinar.status === "cancelled" ||
                            webinar.status === "completed" ||
                            convertUTCToLocalAndFormat(webinar.dateAndTime) ===
                              "Apr 24, 2025, 11:06 PM" ||
                            isAdmin
                          }
                          // onClick={() => {
                          //   toggleBookAgain();
                          //   setSendWebinarId(webinar.id);
                          // }}
                        >
                          <span>Book now</span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <p>Webinar Data loading...</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SinglWebinarData;
