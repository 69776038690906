import { useEffect, useLayoutEffect, useRef, useState } from "react";
import rough from "roughjs/bundled/rough.esm";
import { getStroke } from "perfect-freehand";

const WhiteBoard = (props) => {
  const [action, setAction] = useState("none");

  const [selectedElement, setSelectedElement] = useState(null);
  const textAreaRef = useRef();
  const canvasRef = useRef(null);

  const generator = rough.generator();

  const elementType = {
    // (tool)
    selection: "selection",
    line: "line",
    rectangle: "rectangle",
    pencil: "pencil",
    text: "text",
  };

  const [tool, setTool] = useState(elementType.pencil);

  const positionType = {
    inside: "inside",
    tl: "tl",
    tr: "tr",
    bl: "bl",
    br: "br",
    start: "start",
    end: "end",
  };

  const actionType = {
    writing: "writing",
    drawing: "drawing",
    moving: "moving",
    resizing: "resizing",
    deleteIt: "deleteIt",
  };

  // Creates and returns element. Note: It does not place it on canvas
  const createElement = (id, x1, y1, x2, y2, type) => {
    switch (type) {
      case elementType.line:
        const roughElementForLine = generator.line(x1, y1, x2, y2);
        return { id, x1, y1, x2, y2, roughElement: roughElementForLine, type };
      case elementType.rectangle:
        const roughElementForRectangle = generator.rectangle(
          x1,
          y1,
          x2 - x1,
          y2 - y1
        );
        return {
          id,
          x1,
          y1,
          x2,
          y2,
          roughElement: roughElementForRectangle,
          type,
        };
      case elementType.pencil:
        return { id, type, points: [{ x: x1, y: y1 }] };
      case elementType.text:
        return { id, type, x1, y1, x2, y2, text: "" };
      default:
        throw new Error(`Type not recognised : ${type}`);
    }
  };

  const nearPoint = (x, y, x1, y1, name) => {
    const offset = 10;
    return Math.abs(x - x1) < offset && Math.abs(y - y1) < offset ? name : null;
  };

  // If b is a point lying on line ab then
  // distance(a,c) = distance(a,b) + distance(b,c)
  // Now lets say b is our mouse pointer and we want to return that b is onLine even when its a little far away from line then
  // distance(a,c) < distance(a,b) + distance(b,c)
  // distance(a,c) - (distance(a,b) + distance(b,c)) = offset
  const onLine = (x1, y1, x2, y2, x, y, distanceOffset = 1) => {
    const a = { x: x1, y: y1 };
    const b = { x: x2, y: y2 };
    const c = { x: x, y: y };
    const offset = distance(a, b) - (distance(a, c) + distance(b, c));
    const insideLine =
      Math.abs(offset) < distanceOffset ? positionType.inside : null;
    return insideLine;
  };

  // Checks the location of given point (x,y) with respect to element
  const positionWithinElement = (x, y, element) => {
    const { x1, x2, y1, y2, type } = element;

    switch (type) {
      case elementType.rectangle:
        const minX = Math.min(x1, x2);
        const maxX = Math.max(x1, x2);
        const minY = Math.min(y1, y2);
        const maxY = Math.max(y1, y2);

        const topLeft = nearPoint(x, y, minX, minY, positionType.tl);
        const topRight = nearPoint(x, y, maxX, minY, positionType.tr);
        const bottomLeft = nearPoint(x, y, minX, maxY, positionType.bl);
        const bottomRight = nearPoint(x, y, maxX, maxY, positionType.br);
        const insideRect =
          x >= minX && x <= maxX && y >= minY && y <= maxY
            ? positionType.inside
            : null;

        return topLeft || topRight || bottomLeft || bottomRight || insideRect;
      case elementType.line:
        const insideLine = onLine(x1, y1, x2, y2, x, y, 5);
        const start = nearPoint(x, y, x1, y1, positionType.start);
        const end = nearPoint(x, y, x2, y2, positionType.end);
        return start || end || insideLine;
      case elementType.pencil:
        // Pencil drawing is basically group of points. Now there is a straight line between each of these points.
        // We check here if the x,y lies near any of these line. If yes, then the mouse pointer/(x,y) lies on the pencil drawing
        const betweenAnyPoint = element.points.some((point, index) => {
          const nextPoint = element.points[index + 1];
          if (!nextPoint) return false;
          return (
            onLine(point.x, point.y, nextPoint.x, nextPoint.y, x, y) != null
          );
        });
        const onPath = betweenAnyPoint ? positionType.inside : null;

        return onPath;
      case elementType.text:
        return x >= x1 && x <= x2 && y >= y1 && y <= y2
          ? positionType.inside
          : null;
      default:
        throw new Error("Error");
    }
  };

  // Basic math distance formula
  const distance = (a, b) => {
    return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
  };

  // Its goes through elements and return 1 element on which we have out mouse pointer/(x,y)
  const getElementAtPosition = (x, y, elements) => {
    return elements
      .map((element) => ({
        ...element,
        position: positionWithinElement(x, y, element),
      }))
      .find((element) => element.position !== null);
  };

  const adjustElementCoordinates = (element) => {
    const { type, x1, y1, x2, y2 } = element;
    switch (type) {
      case elementType.rectangle:
        const minX = Math.min(x1, x2);
        const maxX = Math.max(x1, x2);
        const minY = Math.min(y1, y2);
        const maxY = Math.max(y1, y2);
        return { x1: minX, y1: minY, x2: maxX, y2: maxY };
      case elementType.line:
        if ((x1 < x2 || x1 === x2) && y1 < y2) {
          return { x1, y1, x2, y2 };
        }
        return { x1: x2, y1: y2, x2: x1, y2: y1 };
      default:
        throw new Error(`Type not recognised : ${element.type}`);
    }
  };

  function cursorForPosition(position) {
    switch (position) {
      case positionType.tl:
      case positionType.br:
      case positionType.start:
      case positionType.end:
        return "nwse-resize";
      case positionType.tr:
      case positionType.bl:
        return "nesw-resize";
      default:
        return "move";
    }
  }

  const resizedCoordinates = (clientX, clientY, position, coordinates) => {
    const { x1, y1, x2, y2 } = coordinates;
    switch (position) {
      case positionType.tl:
      case positionType.start:
        return { x1: clientX, y1: clientY, x2, y2 };
      case positionType.tr:
        return { x1, y1: clientY, x2: clientX, y2 };
      case positionType.bl:
        return { x1: clientX, y1, x2, y2: clientY };
      case positionType.br:
      case positionType.end:
        return { x1, y1, x2: clientX, y2: clientY };
      default:
        return null;
    }
  };

  // Custom hook that manages History => undo, redo, etc
  const useHistory = (initialState) => {
    const [index, setIndex] = useState(0);
    const [history, setHistory] = useState([initialState]);

    const setState = (action, override = false) => {
      // action could be a 1)current state or 2)function
      // 1) setState(items) 2) setState(prevState => prevState)
      const newState =
        typeof action === "function" ? action(history[index]) : action;
      if (override) {
        const historyCopy = [...history];
        historyCopy[index] = newState;
        setHistory(historyCopy);
      } else {
        const updatedState = [...history].splice(0, index + 1);
        setHistory([...updatedState, newState]);
        setIndex((prevState) => prevState + 1);
      }
    };

    const undo = () => index > 0 && setIndex((prevState) => prevState - 1);
    const redo = () =>
      index < history.length - 1 && setIndex((prevState) => prevState + 1);

    return [history[index], setState, undo, redo];
  };
  const [elements, setElements, undo, redo] = useHistory([]);

  // Function from the library
  const getSvgPathFromStroke = (stroke) => {
    if (!stroke.length) return "";

    const d = stroke.reduce(
      (acc, [x0, y0], i, arr) => {
        const [x1, y1] = arr[(i + 1) % arr.length];
        acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
        return acc;
      },
      ["M", ...stroke[0], "Q"]
    );

    d.push("Z");
    return d.join(" ");
  };

  const drawElement = (roughCanvas, context, element) => {
    switch (element.type) {
      case elementType.line:
      case elementType.rectangle:
        roughCanvas.draw(element.roughElement);
        break;
      case elementType.pencil:
        const stroke = getStroke(element.points, {
          size: 10,
        });
        const pathData = getSvgPathFromStroke(stroke);
        const myPath = new Path2D(pathData);
        context.fill(myPath);
        break;
      case elementType.text:
        context.textBaseline = "top";
        context.font = "24px sans-serif";
        context.fillText(element.text, element.x1, element.y1);
        break;
      default:
        throw new Error(`Type not recognised : ${element.type}`);
    }
  };

  const adjustmentRequired = (type) => {
    if (type in [elementType.line, elementType.rectangle]) {
      return true;
    }
    return false;
  };

  useLayoutEffect(() => {
    const canvas = document.getElementById("canvas");
    const container = document.getElementById("canvas-container-wb"); // Assuming your canvas is inside this div

    // Get the width and height of the container
    const containerWidth = container.offsetWidth;
    const containerHeight = container.offsetHeight;

    // Set canvas width and height to match the container
    canvas.width = containerWidth;
    canvas.height = containerHeight;

    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);

    const roughCanvas = rough.canvas(canvas);
    elements.forEach((element) => {
      if (action === actionType.writing && selectedElement.id === element.id)
        return;
      drawElement(roughCanvas, context, element);
    });
  }, [elements, action, selectedElement]);

  useEffect(() => {
    const undoRedoFunction = (event) => {
      if (event.ctrlKey && event.key === "z") {
        undo();
      }
      if (event.ctrlKey && event.key === "y") {
        redo();
      }
    };

    document.addEventListener("keydown", undoRedoFunction);

    return () => {
      document.removeEventListener("keydown", undoRedoFunction);
    };
  }, [undo, redo]);

  useEffect(() => {
    const textArea = textAreaRef.current;

    if (action === actionType.writing) {
      textArea.focus();
      textArea.value = selectedElement.text;
    }
    // console.log(action,actionType.writing)
  }, [action, selectedElement]);

  const clearScreen = () => {
    props.setAnswerPrivacy(false);
    setElements([]);
  };

  const handleMouseDown = (event) => {
    if (action === actionType.writing) return;

    const canvas = document.getElementById("canvas");
    const { left, top } = canvas.getBoundingClientRect(); // Get canvas position

    // Adjust clientX and clientY to be relative to the canvas
    const clientX = event.clientX - left;
    const clientY = event.clientY - top;

    // Rest of your code remains the same
    if (tool === elementType.selection) {
      const element = getElementAtPosition(clientX, clientY, elements);

      if (element) {
        if (element.type === elementType.pencil) {
          const xoffsets = element.points.map((point) => clientX - point.x);
          const yoffsets = element.points.map((point) => clientY - point.y);
          setSelectedElement({ ...element, xoffsets, yoffsets });
        } else {
          const offsetX = clientX - element.x1;
          const offsetY = clientY - element.y1;
          setSelectedElement({ ...element, offsetX, offsetY });
        }

        setElements((prevState) => prevState);

        if (element.position === positionType.inside) {
          setAction(actionType.moving);
        } else {
          setAction(actionType.resizing);
        }
      }
    } else if (
      tool === elementType.rectangle ||
      tool === elementType.line ||
      tool === elementType.pencil ||
      tool === elementType.text
    ) {
      const id = elements.length;
      const element = createElement(
        id,
        clientX,
        clientY,
        clientX,
        clientY,
        tool
      );
      setElements((prevState) => [...prevState, element]);
      setAction(
        tool === elementType.text ? actionType.writing : actionType.drawing
      );
      setSelectedElement(element);
    } else if (tool === actionType.deleteIt) {
      const element = getElementAtPosition(clientX, clientY, elements);
      if (element) {
        setElements((prevState) =>
          prevState.filter((ele) => element.id !== ele.id)
        );
      }
    }
  };

  const updateElement = (id, x1, y1, x2, y2, type, options) => {
    const elementsCopy = [...elements];

    switch (type) {
      case elementType.line:
      case elementType.rectangle:
        elementsCopy[id] = createElement(id, x1, y1, x2, y2, type);
        break;
      case elementType.pencil:
        elementsCopy[id].points = [
          ...elementsCopy[id].points,
          { x: x2, y: y2 },
        ];
        break;
      case elementType.text:
        const textWidth = document
          .getElementById("canvas")
          .getContext("2d")
          .measureText(options.text).width;
        const textHeight = 24;
        elementsCopy[id] = {
          ...createElement(id, x1, y1, x1 + textWidth, y1 + textHeight, type),
          text: options.text,
        };
        break;
      default:
        throw new Error(`Type not recognized ${type}`);
    }

    setElements(elementsCopy, true);
  };

  const handleMouseMove = (event) => {
    const canvas = document.getElementById("canvas");
    const { left, top } = canvas.getBoundingClientRect(); // Get canvas position

    // Adjust clientX and clientY to be relative to the canvas
    const clientX = event.clientX - left;
    const clientY = event.clientY - top;

    // Rest of your code remains the same
    if (tool === elementType.selection) {
      const element = getElementAtPosition(clientX, clientY, elements);
      event.target.style.cursor = element
        ? cursorForPosition(element.position)
        : "default";
    } else if (tool === actionType.deleteIt) {
      const element = getElementAtPosition(clientX, clientY, elements);
      event.target.style.cursor = element ? "not-allowed" : "default";
    }

    if (action === actionType.drawing) {
      const index = elements.length - 1;
      const { x1, y1, type } = elements[index];
      updateElement(index, x1, y1, clientX, clientY, type);
    } else if (action === actionType.moving) {
      if (selectedElement.type === elementType.pencil) {
        const newPoints = selectedElement.points.map((_, index) => {
          return {
            x: clientX - selectedElement.xoffsets[index],
            y: clientY - selectedElement.yoffsets[index],
          };
        });
        const elementsCopy = [...elements];
        elementsCopy[selectedElement.id] = {
          ...elementsCopy[selectedElement.id],
          points: newPoints,
        };
        setElements(elementsCopy, true);
      } else {
        const { id, x1, y1, x2, y2, type, offsetX, offsetY } = selectedElement;
        const width = x2 - x1;
        const height = y2 - y1;
        const newX1 = clientX - offsetX;
        const newY1 = clientY - offsetY;
        const options =
          type === elementType.text ? { text: selectedElement.text } : {};
        updateElement(
          id,
          newX1,
          newY1,
          newX1 + width,
          newY1 + height,
          type,
          options
        );
      }
    } else if (action === actionType.resizing) {
      const { id, type, position, ...coordinates } = selectedElement;
      const { x1, y1, x2, y2 } = resizedCoordinates(
        clientX,
        clientY,
        position,
        coordinates
      );
      updateElement(id, x1, y1, x2, y2, type);
    }
  };

  const handleMouseUp = (event) => {
    const canvas = document.getElementById("canvas");
    const { left, top } = canvas.getBoundingClientRect(); // Get canvas position

    // Adjust clientX and clientY to be relative to the canvas
    const clientX = event.clientX - left;
    const clientY = event.clientY - top;

    if (selectedElement) {
      if (
        selectedElement.type === elementType.text &&
        clientX - selectedElement.offsetX === selectedElement.x1 &&
        clientY - selectedElement.offsetY === selectedElement.y1
      ) {
        setAction(actionType.writing);
        return;
      }

      const index = selectedElement.id;
      const { id, type } = elements[index];

      if (
        (action === actionType.drawing || action === actionType.resizing) &&
        adjustmentRequired(type)
      ) {
        const { x1, y1, x2, y2 } = adjustElementCoordinates(elements[index]);
        updateElement(id, x1, y1, x2, y2, type);
      }
    }

    if (action === actionType.writing) return;

    setAction("none");
    setSelectedElement(null);
  };

  const handleBlur = (event) => {
    const { id, x1, y1, type } = selectedElement;
    setAction("none");
    setSelectedElement(null);
    updateElement(id, x1, y1, null, null, type, { text: event.target.value });
  };

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const canvasToImage = async () => {
    let img = canvasRef.current.toDataURL("image/jpg");
    // console.log("img", img);

    let imageFile = await dataURLtoFile(img, "img.jpg");
    // console.log(imageFile)
    await props.uploadFile(imageFile);
    props.updateIsUpload(false);
    await props.submitImage();
    props.closeModel(false);
    clearScreen();
  };

  useEffect(() => {
    if (props.isUpload) {
      canvasToImage();
    }
  }, [props.isUpload]);

  return (
    <div className="ir-wb-main-cont" id="canvas-container-wb">
      <div className="ir-wbd-list">
        <div className="ir-wbd-list-item">
          <label htmlFor="selection">
            <input
              type="radio"
              id="selection"
              checked={tool === "selection"}
              onChange={() => setTool("selection")}
            />
            <span className="bi bi-cursor-fill"></span>
          </label>
        </div>
        <div className="ir-wbd-list-item">
          <label htmlFor="line">
            <input
              type="radio"
              id="line"
              checked={tool === "line"}
              onChange={() => setTool("line")}
            />
            <span className="material-symbols-outlined">pen_size_2</span>
          </label>
        </div>
        <div className="ir-wbd-list-item">
          <label htmlFor="rectangle">
            <input
              type="radio"
              id="rectangle"
              checked={tool === "rectangle"}
              onChange={() => setTool("rectangle")}
            />
            <span className="material-symbols-outlined">rectangle</span>
          </label>
        </div>
        <div className="ir-wbd-list-item">
          <label htmlFor="pencil">
            <input
              type="radio"
              id="pencil"
              checked={tool === "pencil"}
              onChange={() => setTool("pencil")}
            />
            <span className="material-symbols-outlined">edit</span>
          </label>
        </div>
        <div className="ir-wbd-list-item">
          <label htmlFor="text">
            <input
              type="radio"
              id="text"
              checked={tool === "text"}
              onChange={() => setTool("text")}
            />
            <span className="material-symbols-outlined">title</span>
          </label>
        </div>
        <div className="ir-wbd-list-item">
          <label htmlFor="deleteIt">
            <input
              type="radio"
              id="deleteIt"
              checked={tool === "deleteIt"}
              onChange={() => setTool("deleteIt")}
            />
            <span className="material-symbols-outlined">delete</span>
          </label>
        </div>
      </div>

      <div className="ir-wbd-btn-main-container">
        <div className="ir-wbd-btn-container">
          <div onClick={() => undo()} className="ir-wbd-undo-redo-btn">
            <span className="material-symbols-outlined">undo</span>
          </div>
          <div className="ir-wbd-redo-undo-line"></div>
          <div onClick={() => redo()} className="ir-wbd-undo-redo-btn">
            <span className="material-symbols-outlined">redo</span>
          </div>
        </div>
        <button className="ir-clear-screen" onClick={clearScreen}>
          Clear Screen
        </button>
      </div>

      {action === actionType.writing ? (
        <textarea
          ref={textAreaRef}
          onBlur={handleBlur}
          style={{
            position: "fixed",
            top: selectedElement.y1,
            left: selectedElement.x1,
            font: "24px sans-serif",
            margin: 0,
            padding: 0,
            border: 0,
            outline: 0,
            resize: "auto",
            overflow: "hidden",
            whitespace: "pre",
            background: "red",
          }}
        />
      ) : null}

      <canvas
        style={{ maxWidth: "100%" }}
        id="canvas"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        ref={canvasRef}
      >
        Canvas
      </canvas>
    </div>
  );
};

export default WhiteBoard;
