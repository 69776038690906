import React from "react";
import { useModalContext } from "../WebinarAdminCreateWebinarTab/ModalContext";
import DashboardPanel from "./AdminPanelType/DashboardPanel";
import UsersPanel from "./AdminPanelType/UsersPanel";
import ChatPanel from "./AdminPanelType/ChatPanel";
import CoursesPanel from "./AdminPanelType/CoursesPanel";
import CasesPanel from "./AdminPanelType/CasesPanel";
import WebinarPanel from "./AdminPanelType/WebinarPanel";
import SecondAdminPanel from "./AdminPanelType/SecondAdminPanel";
import NotificationsPanel from "./AdminPanelType/NotificationsPanel";
import ProductPanel from "./AdminPanelType/ProductPanel";

export const AdminPanelBar = () => {
  const { adminPanelType, setAdminPanelType } = useModalContext();
  const isAdmin = localStorage.getItem("loggedInUsername") === "admin";
  return (
    <div className="w-[100%] flex flex-col  h-[100%] ">
      <div className="flex items-center justify-between ">
        <div>
          <p className="lg:text-[18px] xl:text-[24px] 3xl:text-[30px] text-[#1C1D26] font-[600] ">
            {adminPanelType}
          </p>
        </div>
        {isAdmin && (
          <div>
            <div className="flex items-center justify-center gap-[40px] text-[#6D6C6B]">
              <div
                className="cursor-pointer"
                onClick={() => setAdminPanelType("Notifications")}
              >
                <i className="bi bi-bell xl:text-[14px] 3xl:text-[20px]"></i>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="w-[100%] ir-ad-pn-height">
        {adminPanelType === "Dashboard" && <DashboardPanel />}

        {adminPanelType === "Chat" && <ChatPanel />}
        {adminPanelType === "Courses" && <CoursesPanel />}
        {adminPanelType === "Cases" && <CasesPanel />}
        {adminPanelType === "Webinar" && <WebinarPanel />}
        {adminPanelType === "Users" && <UsersPanel />}
        {adminPanelType === "Second Admin" && <SecondAdminPanel />}
        {adminPanelType === "Notifications" && <NotificationsPanel />}
        {adminPanelType === "Products Status" && <ProductPanel />}
      </div>
    </div>
  );
};
