import React, { useCallback, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import ConfirmationModal from "../../Courses/AdminCreateCourseTab/ConfirmationModal";

const JumblingQuestion = ({
  onDelete,
  id,
  onQuestionDataChange,
  jumblinQuestion,
  jumblinQuestionSets,
}) => {
  const [questionSets, setQuestionSets] = useState([{ id: 1 }]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [jumblingQuestionText, setJumblingQuestionText] = useState("");
  const handleDeleteClick = () => {
    onDelete(id);
  };
  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };
  const modules = {
    toolbar: [["bold", "italic"]],
  };
  useEffect(() => {
    setQuestionSets(jumblinQuestionSets || []);
    setJumblingQuestionText(jumblinQuestion || "");
  }, []);

  useEffect(() => {
    const updatedQuestionSets = questionSets.map((set) => ({
      ...set,
      question: jumblingQuestionText,
    }));
    onQuestionDataChange(updatedQuestionSets);
  }, [jumblingQuestionText]);

  // const handleAddQuestion = () => {
  //   setQuestionSets([...questionSets, { id: questionSets.length + 1 }]);
  // };

  // const handleDeletePair = (setId) => {
  //   setQuestionSets(questionSets.filter((set) => set.id !== setId));
  // };

  // const handleInputChange = (event, setId, inputType) => {
  //   console.log(setId);
  //   const updatedQuestionSets = questionSets.map((set) => {
  //     if (set.id === setId) {
  //       return {
  //         ...set,
  //         [inputType]: event.target.value,
  //         question: jumblingQuestionText,
  //       };
  //     }
  //     return set;
  //   });
  //   setQuestionSets(updatedQuestionSets);
  //   onQuestionDataChange(updatedQuestionSets);
  // };

  const handleAddQuestion = () => {
    const newQuestionSets = [...questionSets, { id: questionSets.length + 1 }];
    setQuestionSets(newQuestionSets);
    onQuestionDataChange(newQuestionSets);
  };

  const handleDeletePair = (setId) => {
    const updatedQuestionSets = questionSets.filter((set) => set.id !== setId);
    setQuestionSets(updatedQuestionSets);
    onQuestionDataChange(updatedQuestionSets);
  };

  const handleInputChange = useCallback(
    (event, setId, inputType) => {
      const updatedQuestionSets = questionSets.map((set) => {
        if (set.id === setId) {
          return {
            ...set,
            [inputType]: event.target.value,
            question: jumblingQuestionText,
          };
        }
        return set;
      });
      setQuestionSets(updatedQuestionSets);
      onQuestionDataChange(updatedQuestionSets);
    },
    [questionSets, jumblingQuestionText]
  );

  return (
    <div className="ir-ws-multiple-choice-question-container">
      <div className="ir-ws-multiple-choice-question-box">
        <ReactQuill
          placeholder="Type Jumbling Questions"
          theme="snow"
          modules={modules}
          value={jumblingQuestionText}
          // onChange={(content, delta, source, editor) =>
          //   setJumblingQuestionText(editor.getText())
          // }
          onChange={setJumblingQuestionText}
        />

        <svg
          height="30"
          viewBox="0 0 48 48"
          width="30"
          xmlns="http://www.w3.org/2000/svg"
          className="ir-ws-creat-case-icon ir-ws-creat-case-icon-margin"
          onClick={toggleDeleteModal}
        >
          <path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z" />
          <path d="M0 0h48v48H0z" fill="none" />
        </svg>
      </div>
      {questionSets.map((questionSet) => (
        <div
          className="ir-ws-create-course-mcq-option-container"
          key={questionSet.id}
        >
          <input
            placeholder="Option"
            className="ir-ws-create-course-input-tag"
            value={questionSet.option || ""}
            onChange={(e) => handleInputChange(e, questionSet.id, "option")}
          />
          <input
            placeholder="Paired option"
            className="ir-ws-create-course-input-tag"
            value={questionSet.pairedOption || ""}
            onChange={(e) =>
              handleInputChange(e, questionSet.id, "pairedOption")
            }
          />
          <div className="ir-ws-rapid-question-pair-container">
            <svg
              height="30"
              viewBox="0 0 48 48"
              width="30"
              xmlns="http://www.w3.org/2000/svg"
              className="ir-ws-creat-case-icon"
              onClick={() => handleDeletePair(questionSet.id)}
            >
              <path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z" />
              <path d="M0 0h48v48H0z" fill="none" />
            </svg>
          </div>
        </div>
      ))}
      <div className="ir-ws-create-course-mcq-option-container ir-ws-rapid-question-time-add-btn-container ">
        {/* <input
          placeholder="Option"
          className="ir-ws-create-course-input-tag"
        />
        <input
          placeholder="Paired Option"
          className="ir-ws-create-course-input-tag"
        /> */}
        <div
          className="ir-ws-text-center ir-ws-default-btn-container ir-ws-create-case-add-option-container"
          onClick={handleAddQuestion}
        >
          <button className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button ir-ws-create-case-add-option-btn">
            <span>+ Add Options</span>
          </button>
        </div>
      </div>
      {showDeleteModal && (
        <ConfirmationModal
          toggleModal={toggleDeleteModal}
          confirmationFunc={handleDeleteClick}
          title="delete"
        />
      )}
    </div>
  );
};

export default JumblingQuestion;
