import React, { useEffect, useState } from "react";
import "./PurchaseHistory.scss";
import { useModalContext } from "../../components/WebinarAdminCreateWebinarTab/ModalContext";

const PurchaseHistory = () => {
  const { courseTransactionDetails, userId, transacationDetails, isLoading } =
    useModalContext();
  const [copied, setCopied] = useState(false);
  const [accordianOpen, setAccordianOpen] = useState({});
  // const reversedTransactionDetails = [...transacationDetails]?.reverse();
  const reversedTransactionDetails = Array.isArray(transacationDetails)
    ? [...transacationDetails]?.reverse()
    : [];
  const [latestCourseTransaction, setlatestCourseTransaction] = useState([]);
  const [allLatestTransaction, setAllLatestTransaction] = useState([]);
  const [latestCaseTransaction, setLatestCaseTransaction] = useState([]);

  const handleAccordianToggle = (itemId) => {
    setAccordianOpen((prevOpen) => ({
      ...prevOpen,
      [itemId]: !prevOpen[itemId] || false,
    }));
  };

  const handleCopyPasscode = (passcode) => {
    const link = passcode;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 3000);
      })
      .catch((err) => {
        return;
      });
  };
  function convertUTCToLocalAndFormat(utcTimeString) {
    if (utcTimeString) {
      const utcDateTime = new Date(utcTimeString);

      const localTimeZoneOffset = new Date().getTimezoneOffset();

      const localDateTime = new Date(
        utcDateTime.getTime() - localTimeZoneOffset * 60000
      );

      const localTimeFormat = localDateTime.toISOString().slice(0, 16);
      const inputDate = new Date(localTimeFormat);
      const options = {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      const outputString = inputDate.toLocaleString("en-US", options);

      return outputString;
    } else {
      return;
    }
  }

  useEffect(() => {
    const fetchTransactionDetails = async () => {
      if (userId) {
        try {
          const courseTransactionData = await courseTransactionDetails(userId);

          setlatestCourseTransaction(courseTransactionData);
        } catch (error) {
          return;
        }
      }
    };

    const fetchCaseTransactionDetails = async () => {
      if (userId) {
        try {
          const token = localStorage.getItem("jwtTokenApi1");
          const response = await fetch(
            `https://case.ir4u.info/api/v1/user/case/transaction/${userId}`,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          const caseTransactionData = await response.json();
          setLatestCaseTransaction(caseTransactionData);
        } catch (error) {
          return;
        }
      }
    };

    fetchTransactionDetails();
    fetchCaseTransactionDetails();
  }, [userId]);

  useEffect(() => {
    if (latestCourseTransaction.length > 0) {
      const allTransactions = [
        ...latestCourseTransaction,
        ...latestCaseTransaction,
        ...reversedTransactionDetails,
      ];
      const allTransactionData = allTransactions.sort(
        (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
      );
      // const latestTenTransactions = allTransactions.slice(0, 10);
      setAllLatestTransaction(allTransactionData);
    } else {
      setAllLatestTransaction(reversedTransactionDetails);
    }
  }, [latestCourseTransaction, latestCaseTransaction]);

  return (
    <div className="ir-ws-purchase-history-conatiner">
      <p className="ir-ws-purchase-history-title-text">Purchasing History</p>
      {!isLoading && (
        <div className="ir-ws-purchase-history-box">
          <ul className="ir-ws-purchase-history-title-container ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center">
            <li>Date</li>
            <li>Product Name</li>
            <li>More Details</li>
          </ul>
          <div className="ir-ws-purchase-history-details-container text-[10px] lg:text-[10px] xl:text-[12px] 3xl:text-[14px]">
            {allLatestTransaction?.filter(
              (p) =>
                p?.status === "SUCCESS" ||
                p?.status === "success" ||
                p?.userJoinDetails?.passCodeStatus === "active"
            ).length === 0 ? (
              <div className="ir-ws-no-transactions-message ">
                You don't have any transactions.
              </div>
            ) : (
              allLatestTransaction
                ?.filter(
                  (p) =>
                    p?.status === "SUCCESS" ||
                    p?.status === "success" ||
                    p?.userJoinDetails?.passCodeStatus === "active"
                )
                .map((item) => (
                  <div
                    className="ir-ws-purchase-history-details-box"
                    key={item?.transactionId || item?.transactionID}
                  >
                    <ul className="ir-ws-purchase-history-title-details-container ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center">
                      <li>{convertUTCToLocalAndFormat(item?.createdOn)}</li>
                      <li>{item.webinarTitle || item.productName}</li>
                      <li
                        onClick={() =>
                          handleAccordianToggle(
                            item?.transactionId || item?.transactionID
                          )
                        }
                      >
                        <span
                          className={
                            !accordianOpen[
                              item?.transactionId || item?.transactionID
                            ]
                              ? "ir-ws-purchase-accordian-dropdown-inactive"
                              : "ir-ws-purchase-accordian-dropdown-active"
                          }
                        >
                          {!accordianOpen[
                            item?.transactionId || item?.transactionID
                          ] ? (
                            <i className="bi bi-chevron-down "></i>
                          ) : (
                            <i className="bi bi-chevron-up "></i>
                          )}
                        </span>
                      </li>
                    </ul>
                    {accordianOpen[
                      item?.transactionId || item?.transactionID
                    ] && (
                      <>
                        <ul className="ir-ws-purchase-history-accordian-title ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center">
                          <li>Product Type</li>
                          <li>Status</li>
                          <li>Product Link</li>
                          {item?.productType === "course" ||
                          item?.productType === "case" ? null : (
                            <li>Passcode</li>
                          )}
                        </ul>
                        <ul className="ir-ws-purchase-history-accordian-details ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center">
                          <li>
                            {item?.productType === "course"
                              ? "Course"
                              : item?.productType === "case"
                              ? "Case"
                              : "Webinar"}
                          </li>
                          <li
                            className={
                              item?.userJoinDetails?.passCodeStatus ===
                                "active" ||
                              item?.status === "success" ||
                              item?.status === "SUCCESS"
                                ? "ir-ws-purchase-status-active"
                                : item?.userJoinDetails?.passCodeStatus ===
                                  "cancelled"
                                ? "ir-ws-purchase-status-cancelled"
                                : item?.userJoinDetails?.passCodeStatus ===
                                  "completed"
                                ? "ir-ws-purchase-status-completed"
                                : ""
                            }
                          >
                            {item?.userJoinDetails?.passCodeStatus ||
                              item?.status}
                          </li>
                          <li>
                            <a
                              href={
                                item?.productType === "course"
                                  ? `/course/${item?.productID}`
                                  : item?.productType === "case"
                                  ? `/case/${item?.productId}`
                                  : item?.userJoinDetails?.joinUrl
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Link
                            </a>
                          </li>
                          {item?.productType === "course" ||
                          item?.productType === "case" ? null : (
                            <li className="ir-ws-purchase-history-copy-passcode-conatiner ir-ws-flex ir-ws-justify-center ir-ws-align-center">
                              {item?.userJoinDetails?.passCode &&
                                (!copied ? (
                                  <i
                                    className="bi bi-clipboard text-[12px] xl:text-[14px] 3xl:text-[16px] cursor-pointer"
                                    title="Copy"
                                    onClick={() =>
                                      handleCopyPasscode(
                                        item?.userJoinDetails?.passCode
                                      )
                                    }
                                  ></i>
                                ) : (
                                  <i
                                    className="bi bi-clipboard-check text-[12px] xl:text-[14px] 3xl:text-[16px] cursor-pointer"
                                    title="Copied"
                                  ></i>
                                ))}
                            </li>
                          )}
                        </ul>
                      </>
                    )}
                  </div>
                ))
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PurchaseHistory;
