// import Cropper from "react-easy-crop";
// import { useModalContext } from "./ModalContext";
// import { useRef, useState } from "react";
// import "./FileEditor.scss";

// const FileEditor = () => {
//   const {
//     setFile,
//     handleCancel,
//     selectedImage,
//     setIsEditorOpen,
//     file,
//     setThumbnail,
//   } = useModalContext();
//   const [croppedImageUrl, setCroppedImageUrl] = useState(null);
//   const [crop, setCrop] = useState({ x: 0, y: 0 });
//   const [zoom, setZoom] = useState(1);

//   const onCropComplete = (croppedArea, croppedAreaPixels) => {
//     try {
//       const image = new Image();
//       image.src = selectedImage;
//       image.onload = () => {
//         const canvas = document.createElement("canvas");
//         const scaleX = image.naturalWidth / image.width;
//         const scaleY = image.naturalHeight / image.height;

//         canvas.width = croppedAreaPixels.width;
//         canvas.height = croppedAreaPixels.height;

//         const ctx = canvas.getContext("2d");

//         // Use the original image's data to draw the cropped portion
//         ctx.drawImage(
//           image,
//           croppedAreaPixels.x * scaleX,
//           croppedAreaPixels.y * scaleY,
//           croppedAreaPixels.width * scaleX,
//           croppedAreaPixels.height * scaleY,
//           0,
//           0,
//           croppedAreaPixels.width,
//           croppedAreaPixels.height
//         );

//         const base64Image = canvas.toDataURL("image/webp");
//         console.log(base64Image);
//         setThumbnail(base64Image);
//         setCroppedImageUrl(base64Image);
//       };
//     } catch (error) {
//       console.error("Error cropping image:", error);
//     }
//   };

//   const handleSave = (e) => {
//     e.preventDefault();
//     const fileName = selectedImage.split("/").pop();
//     if (croppedImageUrl) {
//       const fileObject = {
//         fullName: fileName,
//         height: crop.height,
//         imageBase64: croppedImageUrl,
//         mimeType: "image/webp",
//         width: crop.width,
//       };

//       setFile(fileObject);

//       setIsEditorOpen(false);
//     }
//   };
//   return (
//     <div>
//       <div className="ir-ws-webinar-image-crop-container">
//         <Cropper
//           image={selectedImage}
//           crop={crop}
//           zoom={zoom}
//           aspect={238 / 300}
//           cropSize={{ width: 238, height: 300 }}
//           onCropChange={setCrop}
//           onCropComplete={onCropComplete}
//           onZoomChange={setZoom}
//           style={{ background: "red" }}
//         />
//       </div>

//       <div className="ir-ws-webinar-image-editor-btn-container ">
//         <div className="ir-ws-webinar-image-controls">
//           <div
//             className="ir-ws-zoom-button"
//             onClick={(e) => {
//               e.preventDefault();
//               setZoom(zoom + 0.03);
//             }}
//           >

//             <img
//               src={require("../../assets/images/chat-images/magnifying-glass.png")}
//               className="ir-ws-zoom-button-images"
//             />
//           </div>
//           <div
//             className="ir-ws-zoom-button"
//             onClick={(e) => {
//               e.preventDefault();
//               setZoom(zoom - 0.03);
//             }}
//           >

//             <img
//               src={require("../../assets/images/chat-images/magnifying-glass (1).png")}
//               className="ir-ws-zoom-button-images"
//             />
//           </div>
//         </div>

//         <div className="ir-ws-text-center ir-ws-default-btn-container">
//           <button
//             className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button !w-[120px] !p-[0px] lg:!h-[36px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px] xl:!h-[40px] 3xl:!h-[50px]"
//             onClick={handleSave}
//           >
//             <span>Save</span>
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default FileEditor;

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useModalContext } from "./ModalContext";
import { useEffect, useRef, useState } from "react";
import "./FileEditor.scss";

const FileEditor = ({ type, handleCloseUpdateModal, closeModal }) => {
  const {
    setFile,
    handleCancel,
    selectedImage,
    setIsEditorOpen,
    file,
    setThumbnail,
  } = useModalContext();

  const cropperRef = useRef(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);

  const handleCrop = () => {
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper;
      const croppedCanvas = cropper.getCroppedCanvas();

      if (croppedCanvas) {
        const base64Image = croppedCanvas.toDataURL("image/webp");
        // console.log(base64Image);
        setCroppedImageUrl(base64Image);
        setThumbnail(base64Image);
      }
    }
  };

  const handleSave = (e) => {
    // console.log("Clicked");
    e.preventDefault();
    const fileName = selectedImage.split("/").pop();
    if (croppedImageUrl) {
      const fileObject = {
        fullName: fileName,
        imageBase64: croppedImageUrl,
        mimeType: "image/webp",
      };

      setFile(fileObject);
      setIsEditorOpen(false);
    }
  };

  return (
    <div className="ir-ws-position-fixed ir-ws-sign-popup-container ">
      <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container !p-[15px]">
        <div className="ir-ws-signup-content-container">
          <div
            className="ir-ws-position-absolute ir-ws-signup-close-container"
            onClick={type === "Update" ? handleCloseUpdateModal : closeModal}
          >
            <i class="bi bi-x"></i>
          </div>
          {/* <div className="ir-ws-signup-content-inner-container">
            <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title ">
              {type}
            </h3>
          </div> */}
          <form className="ir-ws-signup-form-container !mt-[0px]">
            <div>
              <div className="ir-ws-webinar-image-crop-container">
                <Cropper
                  src={selectedImage}
                  style={{ height: "100%", width: "100%" }}
                  aspectRatio={238 / 300}
                  guides={false}
                  viewMode={1}
                  dragMode="move"
                  scalable={false}
                  zoomable={true}
                  cropBoxResizable={false}
                  cropend={handleCrop}
                  ready={handleCrop}
                  ref={cropperRef}
                />
              </div>
              <div className="ir-ws-text-center ir-ws-default-btn-container">
                <button
                  className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button !w-[120px] !p-[0px] lg:!h-[36px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px] xl:!h-[40px] 3xl:!h-[50px]"
                  // style={{ width: "200px", height: "50px" }}
                  onClick={handleSave}
                >
                  <span>Save</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FileEditor;
