import React, { useCallback, useEffect, useRef, useState } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./SingleCase.scss";
import Header from "../../../components/Header/Header";

import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import ImageSequence from "./ImageSequence";
import Loader from "../../../components/Loader/Loader";
import Swal from "sweetalert2";
import SingleCaseComponent from "./SingleCaseComponent";
import { height } from "styled-system";
import DisclaimerInfo from "./DisclaimerInfo";

const SingleCase = () => {
  const { caseId } = useParams();

  const [caseDetails, setCaseDetails] = useState(null);
  const [pendingCaseDetails, setPendingCaseDetails] = useState(null);
  const [isPendingCaseReview, setIsPendingCaseReview] = useState(false);

  const [isFavorite, setIsFavorite] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [tooltipText, setTooltipText] = useState(false);

  const fullcontainerRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [answerTypeModal, setanswerTypeModal] = useState(false);
  const [answerType, setAnswerType] = useState("single");
  const isAdmin = localStorage.getItem("loggedInUsername") === "admin";

  const [options, setOptions] = useState([]);

  const [isPaid, setIsPaid] = useState(false);
  const [caseLoading, setCaseLoading] = useState(null);
  // const [currentIndex, setCurrentIndex] = useState(0);
  const [imagesData, setImagesData] = useState([]);
  // const loggedIn = localStorage.getItem("isLoggedIn") === "true";
  const [emailVerified, setEmailVerified] = useState(false);
  const userId = localStorage.getItem("userId");
  const loggedIn = localStorage.getItem("isLoggedIn") === "true";
  const [activeOption, setActiveOption] = useState(1);

  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isDisclaimerAcknowledged, setIsDisclaimerAcknowledged] =
    useState(false);
  const [disclaimerInfo, setIsdisclaimerInfo] = useState(true);
  const userProfile = JSON.parse(localStorage.getItem("userProfile") || "[]");
  const isSecondAdmin = userProfile.includes("publisher");
  const handleDarkModeToggle = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  const categoryImageMap = {
    Chest: require("../../../assets/images/chest-icon.png"),
    "⁠Gastrointestinal": require("../../../assets/images/gastrointestinal-icon.png"),
    Liver: require("../../../assets/images/liver.png"),
    "⁠Genitourinary": require("../../../assets/images/g1.png"),
    Vascular: require("../../../assets/images/vascular-icon.png"),
    Lymphatics: require("../../../assets/images/lymphatic-icon.png"),
    "T⁠rauma": require("../../../assets/images/trauma-icon.png"),
    Venous: require("../../../assets/images/venous-icon.png"),
    "⁠Bleed": require("../../../assets/images/bleeding-icon.png"),
    "⁠Women’s health": require("../../../assets/images/womens-health-icon.png"),
    "⁠Men’s health": require("../../../assets/images/mens-health-icon.png"),
    Bundle: require("../../../assets/images/bundle.png"),
  };

  useEffect(() => {
    const userProfileDetails = async () => {
      try {
        const response = await axios.get(
          `https://backend.ir4u.info/api/v1/user/profile`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
            },
          }
        );

        if (response.data?.isEmailVerified) {
          setEmailVerified(true);
        }
      } catch (error) {
        // console.log(error);
        return;
      }
    };
    userProfileDetails();
  }, []);

  const [activeSlide, setActiveSlide] = useState(1);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [slidesPerPage, setSlidesPerPage] = useState(10);

  const updateSlidesPerPage = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 720) {
      setSlidesPerPage(5);
    } else if (screenWidth < 1300) {
      setSlidesPerPage(6);
    } else {
      setSlidesPerPage(10);
    }
  };

  useEffect(() => {
    updateSlidesPerPage();
    window.addEventListener("resize", updateSlidesPerPage);

    return () => {
      window.removeEventListener("resize", updateSlidesPerPage);
    };
  }, []);

  const chooseSlide = (value) => {
    setActiveSlide(value);

    let start = Math.max(0, value - Math.floor(slidesPerPage / 2));
    if (value + Math.floor(slidesPerPage / 2) >= options.length) {
      start = Math.max(0, options.length - slidesPerPage);
    }
    setCurrentSlideIndex(start);
  };

  const visibleSlides = options.slice(
    currentSlideIndex,
    currentSlideIndex + slidesPerPage
  );

  const hasLeftEllipsis = currentSlideIndex > 0;
  const hasRightEllipsis = currentSlideIndex + slidesPerPage < options.length;

  const navigate = useNavigate();

  const [startedAnswering, setStartedAnswering] = useState(false);

  ///Accoridan State/////////
  const [activeAccordions, setActiveAccordions] = useState([]);

  const accordionRefs = useRef([]);
  //// img multiple pan and zoom states////
  const canvasRefMultiple = useRef(null);
  const containerRefMultiple = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [scale, setScale] = useState(1);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [start, setStart] = useState({ x: 0, y: 0 });
  const [panZoomActive, setPanZoomActive] = useState(false);

  ////Image Sequence New states start////////
  const canvasRefSequence = useRef(null);
  const containerRefSequence = useRef(null);
  const [resetSequence, setResetSequence] = useState(false);
  const [scaleSequence, setScaleSequence] = useState(1);
  const [offsetSequence, setOffsetSequence] = useState({ x: 0, y: 0 });
  const [scrollActive, setScrollActive] = useState(true);
  const [panZoomActiveSequence, setPanZoomActiveSequence] = useState(false);
  ////Image Sequence New states end////////
  ///// function Image Multiple pan and zoom starts////////
  useEffect(() => {
    if (canvasRefMultiple.current) {
      updateCanvasSize();
      drawImage();
    }
  }, [currentIndex, scale, offset]);

  const updateCanvasSize = useCallback(() => {
    if (!canvasRefMultiple.current || !containerRefMultiple.current) return;
    const canvas = canvasRefMultiple.current;
    const container = containerRefMultiple.current;

    canvas.width = container.clientWidth;
    canvas.height = container.clientHeight;
  }, []);

  useEffect(() => {
    const container = containerRefMultiple.current;

    const handleWheel = (e) => {
      if (!panZoomActive) return;

      const scaleFactor = 1.1;
      const minScale = 0.15; // Minimum zoom level (50% of the original size)
      const maxScale = 10; // Maximum zoom level (300% of the original size)

      let newScale = e.deltaY > 0 ? scale / scaleFactor : scale * scaleFactor;

      // Restrict the scale to the specified min and max levels
      newScale = Math.max(minScale, Math.min(newScale, maxScale));

      const img = new Image();
      img.src = imagesData[currentIndex]?.url;

      img.onload = () => {
        const canvas = canvasRefMultiple.current;
        const scaledWidth = img.width * newScale;
        const scaledHeight = img.height * newScale;

        let newOffsetX = offset.x;
        let newOffsetY = offset.y;

        newOffsetX = Math.min(
          0,
          Math.max(newOffsetX, canvas?.width - scaledWidth)
        );
        newOffsetY = Math.min(
          0,
          Math.max(newOffsetY, canvas?.height - scaledHeight)
        );

        setScale(newScale);
        setOffset({ x: newOffsetX, y: newOffsetY });
      };

      e.preventDefault();
      e.stopPropagation();
    };

    if (container) {
      container.addEventListener("wheel", handleWheel, { passive: false });

      return () => {
        container.removeEventListener("wheel", handleWheel);
      };
    }
  }, [panZoomActive, scale, offset, currentIndex, imagesData]);

  const drawImage = () => {
    const canvas = canvasRefMultiple?.current;
    if (!canvas) return;

    const ctx = canvas?.getContext("2d");
    if (!ctx) return;

    const img = new Image();
    img.src = imagesData[currentIndex]?.url;

    img.onload = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      const { width, height } = img;
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      // Calculate aspect ratios
      const imgAspectRatio = width / height;
      const canvasAspectRatio = canvasWidth / canvasHeight;

      let drawWidth, drawHeight;

      if (imgAspectRatio > canvasAspectRatio) {
        // Image is wider than canvas, fit to height
        drawHeight = canvasHeight * scale;
        drawWidth = drawHeight * imgAspectRatio;
      } else {
        // Image is taller or equal aspect ratio to canvas, fit to height
        drawHeight = canvasHeight * scale;
        drawWidth = drawHeight * imgAspectRatio;
      }

      // Calculate the position to center the image with offset
      const xPos = offset.x + (canvasWidth - drawWidth) / 2;
      const yPos = offset.y + (canvasHeight - drawHeight) / 2;

      // Draw the image on the canvas with scaling and panning
      ctx.drawImage(img, xPos, yPos, drawWidth, drawHeight);
    };
  };

  const handleMouseDown = (e) => {
    if (!panZoomActive) return;
    if (e.button !== 0) return; // Check if left mouse button is clicked
    setIsDragging(true);
    setStart({ x: e.clientX - offset.x, y: e.clientY - offset.y });
  };

  const handleMouseMove = (e) => {
    if (!isDragging || !panZoomActive) return;
    const newOffset = {
      x: e.clientX - start.x,
      y: e.clientY - start.y,
    };

    setOffset(newOffset);
  };

  const handleMouseUp = () => {
    if (!panZoomActive) return;
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    if (!panZoomActive) return;
    setIsDragging(false);
  };

  const handlePanZoomButtonMultiple = () => {
    setPanZoomActive((prev) => !prev);
  };

  const handleResetButtonMultiple = () => {
    setScale(1);
    setOffset({ x: 0, y: 0 });
    drawImage(); // Redraw the image
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(document.fullscreenElement === fullcontainerRef.current);
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullScreenChange);
    document.addEventListener("mozfullscreenchange", handleFullScreenChange);
    document.addEventListener("MSFullscreenChange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullScreenChange
      );
    };
  }, []);

  const handleFullScreenToggle = () => {
    if (!document.fullscreenElement) {
      if (fullcontainerRef.current.requestFullscreen) {
        fullcontainerRef.current.requestFullscreen();
      } else if (fullcontainerRef.current.mozRequestFullScreen) {
        // Firefox
        fullcontainerRef.current.mozRequestFullScreen();
      } else if (fullcontainerRef.current.webkitRequestFullscreen) {
        // Chrome, Safari, Opera
        fullcontainerRef.current.webkitRequestFullscreen();
      } else if (fullcontainerRef.current.msRequestFullscreen) {
        // IE/Edge
        fullcontainerRef.current.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        // Chrome, Safari, Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        // IE/Edge
        document.msExitFullscreen();
      }
    }
  };
  ///// function Image Multiple pan and zoom end////////

  ///// function Image Sequence new Function start////////
  const handleScrollButtonClick = () => {
    setScrollActive((prev) => !prev);
    setPanZoomActiveSequence(false);
  };
  const handlePanZoomButtonClick = () => {
    setPanZoomActiveSequence((prev) => !prev);
    setScrollActive(false);
  };

  // const handleResetButtonClick = () => {
  //   setScaleSequence(1);
  //   setOffsetSequence({ x: 0, y: 0 });
  //   drawImage(); // Redraw the image
  // };
  const handleResetButtonClick = () => {
    setResetSequence(true);
    setTimeout(() => {
      setResetSequence(false); // Reset the flag after handling the reset
    }, 0);
  };
  ///// function Image Sequence new Function end////////

  const updateAccordionHeight = (index) => {
    const accordionRef = accordionRefs.current[index];
    if (accordionRef) {
      const height = activeAccordions[index] ? accordionRef.scrollHeight : 0;
      accordionRef.style.maxHeight = `${height}px`; // Set the height dynamically
    }
  };

  const singleAnswer = () => {
    if (!startedAnswering) {
      setAnswerType("single");
      setanswerTypeModal(false);

      updateAccordionHeight(0);
      updateAccordionHeight(1);
      updateAccordionHeight(2);
      updateAccordionHeight(3);
      updateAccordionHeight(4);
    }
  };
  const groupAnswer = () => {
    if (!startedAnswering) {
      setAnswerType("group");
      setanswerTypeModal(false);
      updateAccordionHeight(0);
      updateAccordionHeight(1);
      updateAccordionHeight(2);
      updateAccordionHeight(3);
      updateAccordionHeight(4);
    }
  };

  useEffect(() => {
    const casePurchased = async () => {
      const data = {
        userID: userId,
        caseID: caseId,
      };
      try {
        setCaseLoading(true);
        const response = await axios.post(
          `https://case.ir4u.info/api/v1/user/case/check-user-case`,
          data,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
            },
          }
        );

        const isPaidBool = response.data.isPaid === "true";
        setIsPaid(isPaidBool);
        setCaseLoading(false);
      } catch (error) {
        setCaseLoading(false);
        setIsPaid(false);
      }
    };
    casePurchased();
  }, []);

  useEffect(() => {
    const fetchCaseDetails = async () => {
      try {
        const response = await axios.get(
          `https://case.ir4u.info/api/v1/user/case/${caseId}`
        );

        setCaseDetails(response?.data.data);
        const slideNumbers = response.data.data.slides.map(
          (slide) => slide.slideNumber
        );
        setOptions(slideNumbers);
      } catch (err) {
        setError(err.response?.data?.message || "Error fetching case details");
      } finally {
        setLoading(false);
      }
    };

    fetchCaseDetails();
  }, [caseId]);

  // https://case.ir4u.info/api/v1/admin/case/get-pending-case/67dc206659c06aef92e526bd

  useEffect(() => {
    const fetchPendingCase = async () => {
      try {
        const response = await axios.get(
          `https://case.ir4u.info/api/v1/admin/case/get-pending-case/${caseId}`
        );

        console.log("pending single case details", response.data.data);
        setPendingCaseDetails(response.data.data);
        setIsPendingCaseReview(true);
        const slideNumbers = response.data.data.slides.map(
          (slide) => slide.slideNumber
        );
        setOptions(slideNumbers);
      } catch (err) {
        setError(err.response?.data?.message || "Error fetching case details");
      } finally {
        setLoading(false);
      }
    };

    fetchPendingCase();
  }, [caseId]);

  // useEffect(() => {
  //   const fetchPendingCase = async () => {
  //     const token = localStorage.getItem("jwtTokenApi1");

  //     try {
  //       const response = await axios.get(
  //         `https://case.ir4u.info/api/v1/admin/case/get-pending-case/${caseId}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );
  //       // console.log(`pending course ${id}`, response.data.course);
  //       if (response.status === 200) {
  //         setPendingCaseDetails(response.data.data);
  //         setIsPendingCaseReview(true);
  //       } else {
  //         throw new Error("Failed to fetch pending case");
  //       }
  //     } catch (error) {
  //       return;
  //     }
  //   };
  //   fetchPendingCase();
  // }, [caseId]);

  // const activeSlideObject = caseDetails?.slides?.find(
  //   (slide) => slide?.slideNumber === activeSlide
  // );
  const activeSlideObject = isPendingCaseReview
    ? pendingCaseDetails?.slides?.find(
        (slide) => slide?.slideNumber === activeSlide
      )
    : caseDetails?.slides?.find((slide) => slide?.slideNumber === activeSlide);

  // const activeSlideObject = (pendingCaseDetails ?? caseDetails)?.slides?.find(
  //   (slide) => slide?.slideNumber === activeSlide
  // );

  useEffect(() => {
    if (activeSlideObject) {
      setImagesData(activeSlideObject?.caseImages);
    }
    handleResetButtonMultiple();
  }, [activeSlideObject]);

  const parseTimeString = (timeString) => {
    const [minutes, seconds] = timeString.split(":").map(Number);
    return minutes * 60 + seconds;
  };

  //////Slide Question verifying answer function end//////////////////

  const shareUrl = window.location.href;

  const showShareModal = () => {
    setShareModal(true);
  };
  const shareCloseModalHandle = () => {
    setShareModal(false);
  };
  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(shareUrl);
    setTooltipText(true);
    setTimeout(() => setTooltipText(false), 2000);
  };

  useEffect(() => {
    if (!isAdmin) {
      const fetchFavoriteStatus = async () => {
        if (userId && caseId) {
          const token = localStorage.getItem("jwtTokenApi1");
          const url = `https://case.ir4u.info/api/v1/user/case/check-favorite`;

          try {
            const response = await axios.get(url, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              params: {
                userID: userId,
                caseID: caseId,
              },
            });

            if (response?.status === 200) {
              setIsFavorite(response.data.isFavorite);
            } else {
              throw new Error(
                response.data.message || "Failed to fetch favorite status"
              );
            }
          } catch (error) {
            // console.error("Error fetching favorite status:", error);
            return;
          }
        }
      };

      fetchFavoriteStatus();
    }
  }, [userId, caseId, isAdmin]);

  const toggleFavoriteStatus = async () => {
    const url = `https://case.ir4u.info/api/v1/user/case/add-fevorite`;
    const token = localStorage.getItem("jwtTokenApi1");

    try {
      const response = await axios.post(
        url,
        {
          userID: userId,
          caseID: caseId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const newIsFavorite = !isFavorite;
        setIsFavorite(newIsFavorite);

        const message = newIsFavorite
          ? "Added to favorites successfully!"
          : "Removed from favorites successfully!";

        Swal.fire("Success", message, "success");
      } else {
        Swal.fire(
          "Error",
          response.data.message || "Failed to toggle favorite status",
          "error"
        );
      }
    } catch (error) {
      Swal.fire(
        "Error",
        "An error occurred while toggling favorite status",
        "error"
      );
    }
  };

  const handleFavouriteClick = async () => {
    if (loggedIn) {
      if (emailVerified) {
        await toggleFavoriteStatus();
      } else {
        Swal.fire(
          "Warning!",
          "Please verify your email to favorite a case.",
          "warning"
        );
      }
    } else {
      Swal.fire("Warning!", "Please log in to favorite a case.", "warning");
    }
  };

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? imagesData.length - 1 : prevIndex - 1
    );
    handleResetButtonMultiple();
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === imagesData.length - 1 ? 0 : prevIndex + 1
    );
    handleResetButtonMultiple();
  };

  if (loading || caseLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  // if (error) {
  //   return <div>{error}</div>;
  // }
  function stripHtmlTagsDescription(str) {
    if (!str) return "";
    return str.replace(/<\/?[^>]+(>|$)/g, "");
  }
  const strippedDescription = stripHtmlTagsDescription(
    activeSlideObject?.description
  );

  // const categoryImage = categoryImageMap[caseDetails?.categorie];

  const categoryImage =
    categoryImageMap[(pendingCaseDetails ?? caseDetails)?.categorie];

  return (
    <>
      {!loggedIn ? (
        <div className="ir-ws-case-non-paid-cont">
          <div className="ir-ws-flex ir-ws-justify-center ir-ws-align-center ir-ws-case-non-paid-content-box">
            <h1>Please Sign Up or Sign In to view this case</h1>
            <div className="ir-ws-case-page-sign-cont">
              <div className="ir-ws-default-btn-container ir-profile-info-btn-container">
                <button
                  className="ir-ws-default-btn ir-ws-app-bg"
                  onClick={() => navigate("/signup")}
                >
                  <span>Sign Up</span>
                </button>
              </div>
              <div className="ir-ws-default-btn-container ir-profile-info-btn-container">
                <button
                  className="ir-ws-default-btn ir-ws-app-bg"
                  onClick={() => navigate("/signin")}
                >
                  <span>Sign In</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : !isPaid && !isAdmin && !isSecondAdmin ? (
        <div className="ir-ws-case-non-paid-cont">
          <div className="ir-ws-flex ir-ws-justify-center ir-ws-align-center ir-ws-case-non-paid-content-box">
            <h1>Please Purchase this case to view</h1>
            <div className="ir-ws-default-btn-container ir-profile-info-btn-container">
              <button
                className="ir-ws-default-btn ir-ws-app-bg"
                onClick={() => navigate("/cases")}
              >
                <span>Go to Cases</span>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`ir-cs-cases-detail-page-main-container ${
            isDarkMode ? "dark-mode" : ""
          }`}
          ref={fullcontainerRef}
          style={{
            // backgroundColor: isFullScreen ? "#ffff" : "initial",
            backgroundColor: isFullScreen
              ? isDarkMode
                ? "#262626"
                : "#ffffff"
              : "initial",
          }}
        >
          <Header isDarkMode={isDarkMode} />
          {/* <div
          // className={`single-case-conatiner-sub ${
          //   isDarkMode ? "dark-mode" : ""
          // }`}
          > */}
          <div
            className="ir-ws-banner-padding-tb ir-ws-padding-lr-132  ir-cs-cases-detail-page-content"
            // ref={fullcontainerRef}
            // style={{
            //   // backgroundColor: isFullScreen ? "#ffff" : "initial",
            //   backgroundColor: isFullScreen
            //     ? isDarkMode
            //       ? "#262626"
            //       : "#ffffff"
            //     : "initial",
            // }}
          >
            {/* <div className="ir-ws-padding-lr-132"> */}
            <div className="ir-ws-case-title-detail-cont">
              <div className="ir-ws-case-head-deatil-cont">
                <div className="ir-ws-case-category-icon-cont">
                  <img
                    className="ir-ws-case-category-icon"
                    src={categoryImage}
                    alt="case"
                  />
                </div>
                <div className="ir-ws-case-category-title-cont">
                  <h1 className="ir-ws-font-size-xl ir-ws-case-title-text">
                    {activeSlideObject?.name}
                  </h1>
                </div>
                <div className="separation-line"></div>

                {strippedDescription !== "" ? (
                  <div className="ir-ws-case-category-description-cont">
                    <p className="ir-ws-case-description-text">
                      {strippedDescription.length > 300
                        ? `${strippedDescription.slice(0, 300)}...`
                        : strippedDescription}
                    </p>
                  </div>
                ) : (
                  <div
                    className="ir-ws-case-category-description-cont"
                    style={{ visibility: "hidden" }}
                  >
                    <p className="ir-ws-case-description-text">
                      {strippedDescription.length > 300
                        ? `${strippedDescription.slice(0, 300)}...`
                        : strippedDescription}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="ir-cs-content-main-container ">
              <div className="ir-cs-content-left-container ir-cs-case-sections ">
                <div
                  className="ir-ws-case-slide-cont"
                  // style={{ height: "100%" }}
                >
                  <div className="ir-ws-flex ir-ws-align-center ir-ws-case-slide-dropdown-main-cont">
                    <div className="ir-ws-case-slide-text">
                      {/* Slide #{activeSlide} */}
                      Image
                    </div>
                    {/* {options.length >= 1 && (
                      <div>
                        <ul
                          className={`ir-ws-case-dropdown-active-cont ${"ir-ws-case-dropdown-slide-active"}`}
                        >
                          {options.map((option, index) => (
                            <li
                              key={index}
                              className={
                                option === activeOption ? "active" : ""
                              }
                              onClick={() => chooseSlide(option)}
                            >
                              {option}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )} */}

                    {/* {options.length >= 1 && (
                      <div className="ir-ws-case-dropdown-wrapper">
                        <ul className="ir-ws-case-dropdown-active-cont ir-ws-case-dropdown-slide-active">
                          {visibleSlides.map((option, index) => (
                            <li
                              key={index}
                              className={option === activeSlide ? "active" : ""}
                              onClick={() => chooseSlide(option)}
                            >
                              {option}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )} */}

                    {options.length >= 1 && (
                      <div className="ir-ws-case-dropdown-wrapper">
                        <ul className="ir-ws-case-dropdown-active-cont ir-ws-case-dropdown-slide-active">
                          {hasLeftEllipsis && <li className="ellipsis">...</li>}
                          {visibleSlides.map((option, index) => (
                            <li
                              key={index}
                              className={option === activeSlide ? "active" : ""}
                              onClick={() => chooseSlide(option)}
                            >
                              {option}
                            </li>
                          ))}
                          {hasRightEllipsis && (
                            <li className="ellipsis">...</li>
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="ir-ws-flex ir-ws-case-single-answery-type-dark-cont">
                    <div className="ir-ws-flex"></div>

                    <div
                      className="ir-ws-case-dark-mode-cont"
                      onClick={handleDarkModeToggle}
                    >
                      <img
                        src={require("../../../assets/images/light-theme.png")}
                        alt={isDarkMode ? "light" : "dark"}
                        style={{ width: "30px", height: "30px" }}
                      />
                    </div>
                  </div>
                </div>

                {/* {caseDetails?.slides?.map((slide, index) => (
                  <div
                    key={slide.slideNumber}
                    style={{
                      display:
                        slide.slideNumber === activeSlide ? "block" : "none",
                      // height: "100%",
                    }}
                    className="case-question-main-container"
                  >
                    <SingleCaseComponent
                      slideNumber={slide.slideNumber}
                      answerType={answerType}
                      setStartedAnswering={setStartedAnswering}
                      activeSlide={activeSlide}
                      caseDetails={caseDetails}
                    />
                  </div>
                ))} */}

                {(pendingCaseDetails ?? caseDetails)?.slides?.map(
                  (slide, index) => (
                    <div
                      key={slide.slideNumber}
                      style={{
                        display:
                          slide.slideNumber === activeSlide ? "block" : "none",
                      }}
                      className="case-question-main-container"
                    >
                      <SingleCaseComponent
                        slideNumber={slide.slideNumber}
                        answerType={answerType}
                        setStartedAnswering={setStartedAnswering}
                        activeSlide={activeSlide}
                        caseDetails={pendingCaseDetails ?? caseDetails}
                      />
                    </div>
                  )
                )}
              </div>
              <div className="ir-cs-content-right-container ">
                {activeSlideObject?.caseImages?.length === 0 &&
                  activeSlideObject?.sequenceImage?.length === 0 &&
                  !activeSlideObject.caseVideo && (
                    <div className="ir-cs-disuccion-preview-container ir-ws-position-relative ir-ws-flex ir-ws-justify-center ir-ws-align-center">
                      <img
                        src={require("../../../assets/images/case-static-image.png")}
                        alt="Static"
                      />
                    </div>
                  )}
                {activeSlideObject?.caseImages?.length !== 0 && (
                  <div
                    ref={containerRefMultiple}
                    className="ir-cs-disuccion-preview-container ir-ws-position-relative ir-ws-flex ir-ws-justify-center ir-ws-align-center"
                  >
                    <canvas
                      ref={canvasRefMultiple}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center",
                        // borderRadius: "15px",
                        // border: "2px solid red",
                      }}
                      onMouseDown={handleMouseDown}
                      onMouseMove={handleMouseMove}
                      onMouseUp={handleMouseUp}
                      onMouseLeave={handleMouseLeave}
                    />
                    {imagesData?.length > 1 && (
                      <div
                        className="ir-ws-case-carousel-btn ir-ws-carousel-left-btn"
                        onClick={goToPrevious}
                      >
                        <i className="bi bi-chevron-left"></i>
                      </div>
                    )}
                    {imagesData?.length > 1 && (
                      <div
                        className="ir-ws-case-carousel-btn ir-ws-carousel-right-btn"
                        onClick={goToNext}
                      >
                        <i className="bi bi-chevron-right"></i>
                      </div>
                    )}
                  </div>
                )}
                {activeSlideObject?.caseImages?.length !== 0 && (
                  <div className="ir-ws-case-imgae-btn-cont">
                    <div className="ir-ws-case-image-seq-func-cont">
                      <div
                        className={`ir-ws-case-image-btn ${
                          panZoomActive ? "ir-ws-case-image-btn-active" : ""
                        }`}
                        title="Pan and Zoom"
                        onClick={handlePanZoomButtonMultiple}
                      >
                        <img
                          src={require("../../../assets/images/pan-zoom.png")}
                          alt="pan and zoom"
                        />
                      </div>
                      <div
                        className="ir-ws-case-image-btn"
                        title="Reset"
                        onClick={handleResetButtonMultiple}
                      >
                        <img
                          src={require("../../../assets/images/anticlock.png")}
                          alt="reset"
                        />
                      </div>
                      <div
                        className="ir-ws-case-image-btn"
                        title="Fullscreen"
                        // onClick={handleFullscreenButtonMultiple}
                        onClick={handleFullScreenToggle}
                      >
                        <img
                          src={require("../../../assets/images/fullScreen.png")}
                          alt="fullscreen"
                        />
                      </div>
                    </div>
                  </div>
                )}
                {activeSlideObject?.caseImages?.length !== 0 &&
                  imagesData?.length > 1 && (
                    <div className="ir-ws-case-carosuel-dot-cont">
                      {imagesData?.map((_, index) => (
                        <span
                          key={index}
                          className={`ir-ws-case-carousel-dot ${
                            index === currentIndex
                              ? "ir-ws-case-carousel-dot-active"
                              : ""
                          }`}
                          onClick={() => setCurrentIndex(index)}
                        ></span>
                      ))}
                    </div>
                  )}

                {activeSlideObject?.caseVideo &&
                  activeSlideObject.caseVideo !== "" && (
                    <div className="ir-cs-disuccion-preview-container ir-ws-flex ir-ws-justify-center ir-ws-align-center">
                      <video controls className="ir-ws-width-100">
                        <source
                          src={activeSlideObject?.caseVideo}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  )}
                {activeSlideObject?.sequenceImage?.length !== 0 && (
                  <div
                    className="ir-cs-disuccion-preview-container"
                    ref={containerRefSequence}
                  >
                    <ImageSequence
                      canvasRefSequence={canvasRefSequence}
                      containerRefSequence={containerRefSequence}
                      panZoomActiveSequence={panZoomActiveSequence}
                      setScaleSequence={setScaleSequence}
                      scaleSequence={scaleSequence}
                      offsetSequence={offsetSequence}
                      setOffsetSequence={setOffsetSequence}
                      slideSequenceImage={activeSlideObject?.sequenceImage}
                      scrollActive={scrollActive}
                      resetSequence={resetSequence}
                      setScrollActive={setScrollActive}
                      setPanZoomActiveSequence={setPanZoomActiveSequence}
                      activeSlideObject={activeSlideObject}
                    />
                  </div>
                )}
                {activeSlideObject?.sequenceImage?.length !== 0 && (
                  <div className="ir-ws-case-imgae-btn-cont">
                    <div className="ir-ws-case-image-seq-func-cont">
                      <div
                        className={`ir-ws-case-image-btn ${
                          scrollActive ? "ir-ws-case-image-btn-active" : ""
                        }`}
                        // className={`ir-ws-case-image-btn
                        // `}
                        title="scroll"
                        onClick={handleScrollButtonClick}
                      >
                        <img
                          src={require("../../../assets/images/mouse-1.png")}
                          alt="scroll"
                        />
                      </div>
                      <div
                        className={`ir-ws-case-image-btn ${
                          panZoomActiveSequence
                            ? "ir-ws-case-image-btn-active"
                            : ""
                        }`}
                        title="Pan and Zoom"
                        onClick={handlePanZoomButtonClick}
                      >
                        <img
                          src={require("../../../assets/images/pan-zoom.png")}
                          alt="pan and zoom"
                        />
                      </div>
                      <div
                        className="ir-ws-case-image-btn"
                        title="Reset"
                        onClick={handleResetButtonClick}
                      >
                        <img
                          src={require("../../../assets/images/anticlock.png")}
                          alt="reset"
                        />
                      </div>
                      <div
                        className="ir-ws-case-image-btn"
                        title="Fullscreen"
                        // onClick={handleFullscreenButtonClick}
                        onClick={handleFullScreenToggle}
                      >
                        <img
                          src={require("../../../assets/images/fullScreen.png")}
                          alt="fullscreen"
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="ir-cs-case-actions">
                  <button
                    className={`ir-cs-favorite-button ${
                      isFavorite ? "active" : ""
                    }`}
                    // onClick={toggleFavorite}
                    onClick={handleFavouriteClick}
                    disabled={isAdmin}
                    style={{
                      ...(isAdmin
                        ? { pointerEvents: "none", cursor: "default" }
                        : {}),
                    }}
                  >
                    <i
                      className={`bi ${
                        isFavorite ? "bi-heart-fill" : "bi-heart"
                      }`}
                    ></i>
                  </button>
                  <button
                    className="ir-cs-share-button"
                    onClick={showShareModal}
                    disabled={isAdmin}
                    style={{
                      ...(isAdmin
                        ? { pointerEvents: "none", cursor: "default" }
                        : {}),
                    }}
                  >
                    <i className="bi bi-share"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
          {isDisclaimerAcknowledged && answerTypeModal && (
            <div className="ir-ws-position-fixed ir-ws-sign-popup-container ">
              <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
                <div className="ir-ws-signup-content-container">
                  <div className="ir-ws-signup-content-inner-container">
                    <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                      How do you want to answer the questions?
                    </h3>
                  </div>
                  <div className="ir-ws-deleteModal-button-container">
                    <button
                      className="ir-ws-deleteModal-button-yes"
                      onClick={singleAnswer}
                    >
                      Tutor
                    </button>
                    <button
                      className="ir-ws-deleteModal-button-no"
                      onClick={groupAnswer}
                    >
                      Test
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {disclaimerInfo && (
            <DisclaimerInfo
              disclaimerInfo={disclaimerInfo}
              setIsdisclaimerInfo={setIsdisclaimerInfo}
            />
          )}
          {shareModal && (
            <div className="ir-ws-position-fixed ir-ws-sign-popup-container ir-ws-share-main-container">
              <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
                <div className="iir-ws-signup-content-container">
                  <div
                    className="ir-ws-position-absolute ir-ws-signup-close-container"
                    onClick={shareCloseModalHandle}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>
                  <div className="ir-ws-signup-content-inner-container">
                    <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                      Share Case
                    </h3>
                  </div>
                  <div className="ir-share-modal-inside-content">
                    <p>Invite your friends through social network</p>
                    <div className="ir-share-container">
                      <div className="ir-share-icon-container ir-share-icon-container-hover">
                        <FacebookShareButton
                          url={shareUrl}
                          quote={"Dummy text!"}
                          hashtag="#ir4u"
                        >
                          <FacebookIcon size={50} round />
                        </FacebookShareButton>
                      </div>
                      <div className="ir-share-icon-container ir-share-icon-container-hover">
                        <WhatsappShareButton
                          url={shareUrl}
                          quote={"Dummy text!"}
                        >
                          <WhatsappIcon size={50} round />
                        </WhatsappShareButton>
                      </div>
                      <div className="ir-share-icon-container ir-share-icon-container-hover">
                        {!tooltipText ? (
                          <div
                            className="ir-share-icon-copy-link ir-share-icon-link"
                            onClick={handleCopyLink}
                            title="Copy Link"
                          >
                            <i className="bi bi-link-45deg"></i>
                          </div>
                        ) : (
                          <div
                            className="ir-share-icon-copy-link ir-share-icon-link"
                            title="Link Copied"
                          >
                            <i class="bi bi-check-lg"></i>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* </div> */}
        </div>
      )}
    </>
  );
};

export default SingleCase;
