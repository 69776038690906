import React from "react";

const UpdateBillingDetails = ({ updateAddress, setUpdateAddress }) => {
  return (
    <div className="ir-ws-position-fixed ir-ws-sign-popup-container">
      <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container !py-[10px] md:!py-[15px] lg:!py-[20px] xl:!py-[30px] !max-w-[400px] md:!max-w-[400px] xl:!max-w-[500px] 3xl:!max-w-[600px]">
        <div className="ir-ws-signup-content-container ">
          <div
            className="ir-ws-position-absolute ir-ws-signup-close-container"
            onClick={() => setUpdateAddress(false)}
          >
            <i className="bi bi-x-lg"></i>
          </div>
          <div className="ir-ws-signup-content-inner-container">
            <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title !text-[20px]  lg:!text-[24px] xl:!text-[26px] 3xl:!text-[30px]">
              Update Address
            </h3>
          </div>

          <form
            className={`ir-ws-signup-form-container !mt-[10px] lg:!mt-[20px] xl:!mt-[40px] !px-[15px] md:!px-[20px] lg:!px-[30px] xl:!px-[40px] 3xl:!px-[50px]`}
          >
            <div className="ir-ws-signup-content-group !my-[15px] lg:!my-[25px] xl:!my-[30px] 3xl:!my-[35px]">
              <input
                className="ir-ws-signup-input-field !text-[12px] lg:!text-[12px] xl:!text-[14px] 3xl:!text-[16px]"
                type="text"
                name="Address"
                required
              />
              <span className="ir-ws-signup-highlight"></span>
              <span className="ir-ws-signup-bar"></span>
              <label className="ir-ws-signup-label !text-[10px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px]">
                Address
              </label>
            </div>
            <div className="ir-ws-signup-content-group !my-[15px] lg:!my-[25px] xl:!my-[30px] 3xl:!my-[35px]">
              <input
                className="ir-ws-signup-input-field !text-[12px] lg:!text-[12px] xl:!text-[14px] 3xl:!text-[16px]"
                type="text"
                name="state"
                required
              />
              <span className="ir-ws-signup-highlight"></span>
              <span className="ir-ws-signup-bar"></span>
              <label className="ir-ws-signup-label !text-[10px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px]">
                State
              </label>
            </div>
            <div className="ir-ws-signup-content-group !my-[15px] lg:!my-[25px] xl:!my-[30px] 3xl:!my-[35px]">
              <input
                className="ir-ws-signup-input-field !text-[12px] lg:!text-[12px] xl:!text-[14px] 3xl:!text-[16px]"
                type="text"
                name="postalCode"
                required
              />
              <span className="ir-ws-signup-highlight"></span>
              <span className="ir-ws-signup-bar"></span>
              <label className="ir-ws-signup-label !text-[10px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px]">
                Postal Code
              </label>
            </div>
            <div className="ir-ws-signup-content-group !my-[15px] lg:!my-[25px] xl:!my-[30px] 3xl:!my-[35px]">
              <input
                className="ir-ws-signup-input-field !text-[12px] lg:!text-[12px] xl:!text-[14px] 3xl:!text-[16px]"
                type="text"
                name="country"
                required
              />
              <span className="ir-ws-signup-highlight"></span>
              <span className="ir-ws-signup-bar"></span>
              <label className="ir-ws-signup-label !text-[10px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px]">
                Country
              </label>
            </div>

            <div className="ir-ws-text-center ir-ws-default-btn-container">
              <button
                className="!w-[200px] !p-[0px] !h-[36px] !text-[10px] lg:!h-[36px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px] xl:!h-[40px] 3xl:!h-[50px] ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                type="submit"
              >
                <span>Update Address</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateBillingDetails;
