import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// export const signInApi1 = createAsyncThunk('auth/signInApi1', async (formData) => {
//   try {
//     const response = await axios.post('https://backend.ir4u.info/api/v1/login', formData, {
//         headers: { 'Content-Type': 'multipart/form-data' }
//     });
//     return response;
//   } catch (error) {
//     throw error.response;
//   }
// });

export const signInApi1 = createAsyncThunk(
  "auth/signInApi1",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "https://backend.ir4u.info/api/v1/login",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      return response;
    } catch (error) {
      return rejectWithValue({
        status: error.response?.status, // ✅ Status code store karo
        message: error.response?.data?.error || "Something went wrong", // ✅ Backend ka error message store karo
      });
    }
  }
);

// export const signInApi2 = createAsyncThunk('auth/signInApi2', async (data2) => {
//   try {
//     const response = await axios.post('https://chat.ir4u.info/user/login', data2,
//     {
//         withCredentials: false,
//         headers: { 'Content-Type': 'application/json' }
//     });
//     return response;
//   } catch (error) {
//     throw error.response;
//   }
// });

// const authSlice = createSlice({
//     name: 'auth',
//     initialState: {
//       user: null, // User data (e.g., username)
//       token: null, // JWT token
//       isLoading: false, // Flag to track loading state
//       error: null, // Error message if authentication fails
//     },
//     extraReducers: (builder) => {
//         builder
//           .addCase(signInApi1.fulfilled, (state, action) => {
//             // Handle response for API 1
//             // state.user = action.data.user; // Update user data
//             state.token = action.payload.headers.authorization.split("Bearer ")[1]; // Update JWT token
//             state.isLoading = false;
//             state.error = null;
//           })
//           .addCase(signInApi2.fulfilled, (state, action) => {
//             // Handle response for API 2
//             // You can update state properties based on API 2 response if needed\
//             state.token = action.payload.data.data.token; // Update JWT token
//             state.isLoading = false;
//             state.error = null;
//           })
//           .addMatcher(
//             (action) => [signInApi1.pending, signInApi2.pending].includes(action.type),
//             (state) => {
//               state.isLoading = true;
//               state.error = null;
//             }
//           )
//           .addMatcher(
//             (action) => [signInApi1.rejected, signInApi2.rejected].includes(action.type),
//             (state, action) => {
//               state.isLoading = false;
//               state.error = action.error.message;
//             }
//           );
//       },
//     });

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: null,
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInApi1.fulfilled, (state, action) => {
        state.token = action.payload.headers.authorization.split("Bearer ")[1];
        state.isLoading = false;
        state.error = null;
      })
      .addMatcher(
        (action) => action.type === signInApi1.pending.type,
        (state) => {
          state.isLoading = true;
          state.error = null;
        }
      )
      .addMatcher(
        (action) => action.type === signInApi1.rejected.type,
        (state, action) => {
          state.isLoading = false;
          state.error = action.payload;
        }
      );
  },
});

export default authSlice.reducer;
