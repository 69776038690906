import React, { useEffect, useState } from "react";
import ModalPlayer from "../../pages/Courses/CoursePreview/ModalPlayer";
import HomeJoinContainer from "../HomeJoinContainer/HomeJoinContainer";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { useNavigate, useParams } from "react-router-dom";
import { useModalContext } from "../WebinarAdminCreateWebinarTab/ModalContext";

const CategoryCourses = () => {
  const { category } = useParams();
  const { courses } = useModalContext();
  const [videoModalOpen, setVideoModalOpen] = useState(false);

  const [videoSource, setVideoSource] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const filtered = courses?.filter((course) =>
    course.formData.basicInfo.category
      .toLowerCase()
      .includes(category.toLowerCase())
  );

  const handleToggleVideo = (source) => {
    setVideoSource(source);
    setVideoModalOpen(!videoModalOpen);
    document.body.classList.toggle("modal-open");
  };
  function stripHtmlTags(html, maxLength) {
    let doc = new DOMParser().parseFromString(html, "text/html");
    let plainText = doc.body.innerText;
    return plainText.length > maxLength
      ? plainText.slice(0, maxLength) + "..."
      : plainText;
  }
  const navigate = useNavigate();
  return (
    <>
      <Header />

      <div className="ir-ws-searched-course-main-container">
        <div className="ir-cours-list-main-container">
          <div className="ir-ws-banner-padding-tb ir-ws-padding-lr-132 ir-cours-inner-list-container ir-ws-searched-courses-box">
            {filtered && filtered.length > 0 ? (
              <div className="ir-ws-course-search-query-text-box">
                <p className="ir-ws-course-search-query-text">
                  Showing courses for category of {category}
                </p>
              </div>
            ) : (
              <div className="ir-ws-course-search-query-text-box">
                <p className="ir-ws-course-search-query-text">
                  Category "{category}"
                </p>
                <p className="ir-ws-course-search-query-text">
                  No courses for category of {category}
                </p>
                <p className="ir-ws-go-back-btn" onClick={() => navigate(-1)}>
                  Go Back
                </p>
              </div>
            )}
            <div className="ir-cours-list-grid-container relative">
              {filtered && filtered.length > 0 && (
                <p
                  className="ir-ws-go-back-btn absolute left-[0px] top-[-70px]"
                  onClick={() => navigate(-1)}
                >
                  <i class="bi bi-arrow-left"></i> Go Back
                </p>
              )}
              {filtered?.map((course, index) => (
                <div className="ir-cours-list-grind-ind-container" key={index}>
                  <div className="ir-cours-list-grid-ind-inner">
                    <div className="ir-ws-flex ir-ws-course-list-main-box">
                      <div
                        className="ir-cours-list-grid-img-container"
                        onClick={() => {
                          if (course.formData.videoPreview !== "") {
                            handleToggleVideo(course.formData.videoPreview);
                          } else {
                            return;
                          }
                        }}
                      >
                        <img
                          className="ir-cours-list-grid-img"
                          src={course.formData.imagePreview}
                          alt="play"
                        />
                        {/* <img className="ir-cours-list-grid-play-btn" src={require('../../assets/images/play-icon.png')} /> */}
                        <i className="ir-cours-list-grid-play-btn bi bi-play-circle-fill"></i>
                      </div>
                      <div className="ir-cours-list-grid-content-container">
                        <div className="ir-cours-list-grid-content">
                          <h3>{course.formData.title}</h3>
                          <p title={stripHtmlTags(course.formData.description)}>
                            {stripHtmlTags(course.formData.description, 130)}
                          </p>
                        </div>
                        <div className="ir-ws-flex ir-cours-list-grid-price-read-container">
                          <div className="ir-cours-list-grid-price-container">
                            ${course.formData.coursePrice}
                          </div>
                          <div
                            className="ir-cours-list-grid-readmore-container"
                            onClick={() => navigate(`/course/${course._id}`)}
                          >
                            Read more
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {videoModalOpen && (
            <ModalPlayer
              videoSource={videoSource}
              onClick={handleToggleVideo}
            />
          )}
        </div>
      </div>
      <HomeJoinContainer />
      <Footer />
    </>
  );
};

export default CategoryCourses;
