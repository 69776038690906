import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../api/axios";
import Swal from "sweetalert2";
import "./SignIn.scss";

export const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [passwordVerification, setPasswordVerification] = useState({
    isLengthValid: true,
    hasSpecialCharacter: true,
    hasNumber: true,
    hasUppercase: true,
  });
  const [trueConditionCount, setTrueConditionCount] = useState(0);
  const { resetLink } = useParams();
  const navigate = useNavigate();
  const [isSubmit, setIssubmit] = useState(false);
  const handlePasswordChange = (e) => {
    const password = e.target.value;
    if (password === "") {
      setIssubmit(false);
      setPasswordVerification({
        isLengthValid: true,
        hasSpecialCharacter: true,
        hasNumber: true,
        hasUppercase: true,
      });
      setTrueConditionCount(0);
      return;
    }

    const lengthRegex = /.{6,}/;
    const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numberRegex = /\d/;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;

    // Update password verification status

    const updatedVerification = {
      isLengthValid: lengthRegex.test(password),
      hasSpecialCharacter: specialCharacterRegex.test(password),
      hasNumber: numberRegex.test(password),
      hasUppercase: uppercaseRegex.test(password),
      hasLowercase: lowercaseRegex.test(password),
    };
    setPasswordVerification(updatedVerification);
    const trueConditionsCount =
      Object.values(updatedVerification).filter(Boolean).length;
    setTrueConditionCount(trueConditionsCount);
  };

  const handleChangePassword = async () => {
    if (
      !passwordVerification.isLengthValid ||
      !passwordVerification.hasSpecialCharacter ||
      !passwordVerification.hasNumber ||
      !passwordVerification.hasUppercase
    ) {
      setIssubmit(true);
      return;
    }
    try {
      const response = await axios.post(
        `https://backend.ir4u.info/api/v1/reset/password/${resetLink}`,
        {
          password: confirmPassword,
        }
      );

      if (response.status === 200) {
        Swal.fire(
          "Success",
          "Password has been successfully reset.",
          "success"
        );
        navigate("/signin");
      }
    } catch (error) {
      return;
    }
  };
  return (
    <div className="ir-ws-sign-in-main-container">
      <div className="ir-ws-signin-inner-container ir-ws-sign-inner-container md:!max-w-[350px] lg:!max-w-[400px]  xl:!max-w-[500px] 3xl:!max-w-[600px]">
        <div className="ir-ws-sign-in-content-container">
          <div className="ir-ws-signin-logo-container">
            <div className="ir-ws-text-center ir-ws-signin-logo-inner-container flex items-center justify-center !m-[0px]">
              <a href="/">
                <img
                  className="ir-ws-signin-logo-img"
                  src={require("../../assets/images/ir4u2.png")}
                  alt="logo"
                />
              </a>
            </div>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (confirmPassword === newPassword) {
                handleChangePassword();
              } else {
                setError("Passwords do not match");
              }
            }}
          >
            <div className="ir-ws-signup-content-inner-container">
              <h3 className="!text-[16px] !leading-normal  lg:!text-[16px] xl:!text-[18px] 3xl:!text-[20px] !my-[15px] lg:!my-[15px] xl:!my-[25px] 3xl:!my-[35px] ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                Reset Password
              </h3>
            </div>
            <div className="ir-ws-signup-form-container !mt-[25px]  xl:!mt-[25px] 3xl:!mt-[30px]">
              <div className="ir-ws-signup-content-group !my-[15px] lg:!my-[15px] xl:!my-[25px] 3xl:!my-[35px]">
                <input
                  className="ir-ws-signup-input-field !text-[12px] lg:!text-[12px] xl:!text-[14px] 3xl:!text-[16px]"
                  type="password"
                  required="required"
                  autoComplete="off"
                  value={newPassword}
                  onBlur={handlePasswordChange}
                  name="New Password"
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    handlePasswordChange(e);
                  }}
                />
                <label className="ir-ws-signup-label !text-[10px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px]">
                  New Password
                </label>
                <span className="ir-ws-signup-highlight"></span>
                <span className="ir-ws-signup-bar"></span>
              </div>
              <div className="ir-ws-signup-content-group !my-[15px] lg:!my-[15px] xl:!my-[25px] 3xl:!my-[35px]">
                <input
                  className="ir-ws-signup-input-field !text-[12px] lg:!text-[12px] xl:!text-[14px] 3xl:!text-[16px]"
                  type="password"
                  required="required"
                  autoComplete="off"
                  value={confirmPassword}
                  name="Confirm Password"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setError("");
                  }}
                />
                <label className="ir-ws-signup-label !text-[10px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px]">
                  Confirm Password
                </label>
                <span className="ir-ws-signup-highlight"></span>
                <span className="ir-ws-signup-bar"></span>
                {error !== "" && (
                  <p className="!text-[10px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px] text-[#fa5252]">
                    {error}
                  </p>
                )}
              </div>
              <div className="password-verification">
                <div>
                  {!isSubmit && trueConditionCount == 0 && (
                    <div>
                      <div className="ir-ws-password-velbox">
                        <div style={{ backgroundColor: "#D6D6D6" }}></div>
                        <div style={{ backgroundColor: "#D6D6D6" }}></div>
                        <div style={{ backgroundColor: "#D6D6D6" }}></div>
                        <div style={{ backgroundColor: "#D6D6D6" }}></div>
                        <div style={{ backgroundColor: "#D6D6D6" }}></div>
                      </div>
                    </div>
                  )}
                  {!isSubmit && trueConditionCount > 0 && (
                    <div>
                      <div className="ir-ws-password-velbox">
                        {[...Array(5)].map((_, index) => (
                          <div
                            key={index}
                            style={{
                              backgroundColor:
                                index < trueConditionCount
                                  ? "#00ff0a"
                                  : "#D6D6D6",
                            }}
                          ></div>
                        ))}
                      </div>
                    </div>
                  )}
                  {isSubmit && (
                    <div>
                      <div className="ir-ws-password-velbox">
                        {[...Array(5)].map((_, index) => (
                          <div
                            key={index}
                            style={{
                              backgroundColor:
                                index < trueConditionCount
                                  ? "#00ff0a"
                                  : "#ff0000",
                            }}
                          ></div>
                        ))}
                      </div>
                      <div className="ir-ws-password-indicator !text-[10px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px] !my-[15px] lg:!my-[15px] xl:!my-[25px] 3xl:!my-[35px] text-center">
                        <span>Password should have:</span>{" "}
                        {[
                          !passwordVerification.isLengthValid && "6 characters",
                          !passwordVerification.hasSpecialCharacter &&
                            "a special character",
                          !passwordVerification.hasNumber && "a number",
                          !passwordVerification.hasUppercase &&
                            "an uppercase letter",
                          !passwordVerification.hasLowercase &&
                            "contains an lowercase letter",
                        ]
                          .filter(Boolean)
                          .join(", ")}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="ir-ws-text-center ir-ws-signup-form-submit-container !mt-[15px] lg:!mt-[15px] xl:!mt-[25px] 3xl:!mt-[35px]">
                <button
                  className="!w-[170px] !p-[0px] !h-[36px] !text-[10px] lg:!h-[36px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px] xl:!h-[40px] 3xl:!h-[50px]  ir-ws-app-bg ir-ws-no-border ir-color-white ir-ws-sign-submit-btn ir-ws-change-password-btn "
                  disabled={newPassword === "" || confirmPassword === ""}
                >
                  Change Password
                </button>
              </div>
            </div>
          </form>

          {/* <div className="ir-ws-signup-form-footer-container">
            <div className="ir-ws-login-social-container">
 
            </div>
            <div className="ir-ws-sign-footer-lin-container">
              <p className="ir-default-color">
                Not having an account? &nbsp;
                <b>
                  <a href="/signup" className="ir-ws-app-color">
                    SignUp
                  </a>
                </b>
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
