import React from "react";

const ConfirmationModal = ({ toggleModal, confirmationFunc, title }) => {
  return (
    <div className="ir-ws-position-fixed ir-ws-sign-popup-container ">
      <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container lg:!max-w-[400px] xl:!max-w-[500px] 3xl:!max-w-[600px]">
        <div className="ir-ws-signup-content-container">
          {/* <div
            className="ir-ws-position-absolute ir-ws-signup-close-container"
            onClick={toggleModal}
          >
            <span>X</span>
          </div> */}
          <div className="ir-ws-signup-content-inner-container">
            <h3
              className="ir-ws-text-center ir-ws-heading-default-color px-[15px] !leading-[30px] lg:px-[15px] xl:px-[25px] 3xl:px-[30px] ir-ws-signup-content-title lg:!text-[16px] xl:!text-[18px] 3xl:!text-[20px]"
              // style={{ fontSize: "24px" }}
            >
              Are you sure you want to {title}?
            </h3>
          </div>
          <div className="ir-ws-deleteModal-button-container lg:!mt-[30px] xl:!mt-[40px] 3xl:!mt-[50px] lg:!gap-[30px] xl:!gap-[40px] 3xl:!gap-[50px]">
            <button
              className="ir-ws-deleteModal-button-yes lg:!h-[40px] lg:!text-[10px] xl:!h-[50px] xl:!text-[16px]"
              onClick={confirmationFunc}
            >
              Yes
            </button>
            <button
              className="ir-ws-deleteModal-button-no lg:!h-[40px] lg:!text-[10px] xl:!h-[50px] xl:!text-[16px]"
              onClick={toggleModal}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
