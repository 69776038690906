import React, { useEffect, useState } from "react";
import ConfirmationModal from "../Courses/AdminCreateCourseTab/ConfirmationModal";
import { useModalContext } from "../../components/WebinarAdminCreateWebinarTab/ModalContext";
import axios from "../../api/axios";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { setCaseData } from "./CaseSlice/Caseslice";

const AdminCaseDraft = () => {
  const { deletedCourses, restoreCourseFunction } = useModalContext();
  // const [allDraftCases, setAlldraftCases] = useState([])
  const dispatch = useDispatch();
  const [caseId, setCaseId] = useState("");
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const toggleConfirmationModal = (id) => {
    setCaseId(id);
    setOpenConfirmationModal(!openConfirmationModal);
  };
  const [draftCaseId, setDraftCaseId] = useState();
  const {
    setActiveTabCase,
    handleCreateCase,
    isUpdateCase,
    isUpdateDraftCaseId,
    allDraftCases,
    handleDeleteDraftCase,
    fetchDraftData,
  } = useModalContext();

  useEffect(() => {
    fetchDraftData();
  }, []);

  const handleEditDraftCase = async (id) => {
    try {
      const response = await axios.get(
        `https://case.ir4u.info/api/v1/admin/case/draft/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwtTokenApi1")}`,
          },
        }
      );
      const caseData = response.data.draftCase;
      if (!caseData.slides || caseData.slides.length === 0) {
        Swal.fire("", "No slides available for this case.", "error");
        return;
      }

      dispatch(setCaseData(caseData));
      setActiveTabCase(1);
      handleCreateCase(id);
      // isUpdateCase(id)
      isUpdateDraftCaseId(id);
    } catch (error) {
      Swal.fire(
        "",
        `Error fetching case data: ${error?.message || error}`,
        "error"
      );
    }
  };

  // const handleDeleteDraftCase = async (caseId) => {
  //   try {
  //     const response = await axios.delete(`https://case.ir4u.info/api/v1/admin/case/delete/draft/${caseId}`,{
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("jwtTokenApi1")}`,
  //       },
  //     })
  //     if(response.status === 200){
  //       Swal.fire("Success", "Case has been deleted", "success");
  //       // setAlldraftCases(prevCases => prevCases.filter(caseObj => caseObj.id !== caseId));
  //     }
  //   } catch (error) {
  //     console.log('error',error);

  //   }
  // }

  const handletDeleteConfirmation = async () => {
    if (caseId) {
      await handleDeleteDraftCase(caseId);
      setCaseId("");
      setOpenConfirmationModal(false);
    }
  };

  return (
    // <div>
    //   {allDraftCases?.map((caseItem, index) => (
    //     <div className="ir-ws-webinar-list-tab" key={index}>
    //       <div className="ir-ws-admin-webinar-content">
    //         <div className="ir-ws-admin-webinar-title-container">
    //           {/* <img
    //               src={course.formData.imagePreview}
    //               className="ir-ws-course-list-image"
    //               alt="course"
    //             /> */}
    //           <p className="ir-ws-webinar-title-text">{caseItem?.title}</p>
    //         </div>
    //         <div className="ir-ws-admin-list-button-container">
    //           <button
    //             className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
    //             //   onClick={() => navigate(`/course/${course._id}`)}
    //             onClick={() => handleEditDraftCase(caseItem.id)}
    //           >
    //             Edit
    //           </button>
    //           <button
    //             className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
    //             onClick={() => toggleConfirmationModal(caseItem.id)}
    //           >
    //             Delete
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   ))}
    //   {openConfirmationModal && (
    //     <ConfirmationModal
    //       toggleModal={toggleConfirmationModal}
    //       confirmationFunc={handletDeleteConfirmation}
    //       title="delete"
    //     />
    //   )}
    // </div>
    <div>
      {allDraftCases?.length > 0 ? (
        allDraftCases.map((caseItem, index) => (
          <div className="ir-ws-webinar-list-tab" key={index}>
            <div className="ir-ws-admin-webinar-content">
              <div className="ir-ws-admin-webinar-title-container">
                <p className="ir-ws-webinar-title-text">{caseItem?.title}</p>
              </div>
              <div className="ir-ws-admin-list-button-container">
                <button
                  className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
                  onClick={() => handleEditDraftCase(caseItem.id)}
                >
                  Edit
                </button>
                <button
                  className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
                  onClick={() => toggleConfirmationModal(caseItem.id)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-center mt-[30px] text-[10px] lg:text-[10px] xl:text-[12px] 3xl:text-[14px]">
          No draft cases found
        </div>
      )}
      {openConfirmationModal && (
        <ConfirmationModal
          toggleModal={toggleConfirmationModal}
          confirmationFunc={handletDeleteConfirmation}
          title="delete"
        />
      )}
    </div>
  );
};
export default AdminCaseDraft;
