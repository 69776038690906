import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useModalContext } from "../WebinarAdminCreateWebinarTab/ModalContext";
import "./WebinarListTab.scss";
import BookNowModal from "../HomeWebinarFeatureContainer/BookNowModal";
import axios from "axios";
import TermsAndConditionModal from "../HomeWebinarFeatureContainer/TermsAndConditionModal";
import BookAgainModal from "./BookAgainModal";

const WebinarListTab = () => {
  const {
    webinarData,
    handleRegisterUserMeeting,
    transacationDetails,
    setBookNowModal,
    bookNowModal,
    handleBuyNowClick,
    handlePaymentCheckout,
  } = useModalContext();
  const navigate = useNavigate();
  const [emailVerified, setEmailVerified] = useState(false);
  const [termsAndCondition, setTermsAndCondition] = useState(false);
  const [sendWebinarId, setSendWebinarId] = useState("");
  const [username, setUsername] = useState("");
  const [bookAgainModal, setBookAgainModal] = useState(false);
  const handleTermsAndCondition = () => {
    setTermsAndCondition(!termsAndCondition);
  };
  useEffect(() => {
    const userProfileDetails = async () => {
      try {
        const response = await axios.get(
          `https://backend.ir4u.info/api/v1/user/profile`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
            },
          }
        );

        setUsername(response.data?.username);
        if (response.data?.isEmailVerified) {
          setEmailVerified(true);
        }
      } catch (error) {
        return;
      }
    };
    userProfileDetails();
  }, []);

  const loggedIn = localStorage.getItem("isLoggedIn") === "true";
  const isAdmin = localStorage.getItem("loggedInUsername") === "admin";
  const reversedWebinarData = Array.isArray(webinarData)
    ? [...webinarData].reverse()
    : [];
  function convertUTCToLocalAndFormat(utcTimeString) {
    // Create a JavaScript Date object from the UTC time string
    const utcDateTime = new Date(utcTimeString);

    // Get the local time zone offset in minutes
    const localTimeZoneOffset = new Date().getTimezoneOffset();

    // Convert UTC time to local time
    const localDateTime = new Date(
      utcDateTime.getTime() - localTimeZoneOffset * 60000
    );

    // Format local time as "2025-01-09T13:00"
    const localTimeFormat = localDateTime.toISOString().slice(0, 16);
    const inputDate = new Date(localTimeFormat);
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const outputString = inputDate.toLocaleString("en-US", options);

    return outputString;
  }

  const upcomingWebinars = reversedWebinarData.filter((item) => {
    const webinarDateTime = new Date(item.dateAndTime);
    return webinarDateTime > new Date();
  });

  const activeWebinars = reversedWebinarData.filter((item) => {
    const webinarDateTime = new Date(item.dateAndTime);
    return webinarDateTime <= new Date() && item.status !== "completed";
  });
  const [showAllUpcoming, setShowAllUpcoming] = useState(false);

  const handleSeeAllClickUpcoming = () => {
    setShowAllUpcoming(true);
  };

  const handleSeeLessClickUpcoming = () => {
    setShowAllUpcoming(false);
  };
  const [showAllActive, setShowAllActive] = useState(false);

  const handleSeeAllClickActive = () => {
    setShowAllActive(true);
  };

  const handleSeeLessClickActive = () => {
    setShowAllActive(false);
  };

  const [showAllCompleted, setShowAllCompleted] = useState(false);

  const handleSeeAllClickCompleted = () => {
    setShowAllCompleted(true);
  };

  const handleSeeLessClickCompleted = () => {
    setShowAllCompleted(false);
  };
  const confirmationFunc = async () => {
    await handlePaymentCheckout(sendWebinarId);
    handleTermsAndCondition();
  };
  const toggleBookAgain = () => {
    setBookAgainModal(!bookAgainModal);
  };
  return (
    <div className="ir-ws-webinar-page-webinar-list-main-container">
      {activeWebinars.length > 0 && (
        <div className="ir-ws-banner-padding-tb ir-ws-webinar-page-webinar-list-inner-container ir-ws-weinar-active-container">
          <h3 className=" ir-ws-hme-webinar-title ir-ws-heading-default-color ir-ws-webinar-page-webinar-list-title">
            Active Webinars
          </h3>
          <div className="ir-ws-padding-lr-132">
            <div className="ir-ws-webinar-page-ul-main-container">
              <div className="ir-ws-align-center ir-ws-hme-webinar-inner-container">
                <div className="ir-ws-hme-webinar-sessions-container">
                  {activeWebinars
                    .slice(0, showAllActive ? undefined : 5)
                    .map((item, index) => {
                      const isWebinarIdMatch = transacationDetails?.some(
                        (obj) => obj.webinarId === item.id
                      );
                      return (
                        <div
                          className="ir-ws-hme-prvw-card-container ir-ws-width-25 ir-ws-hme-webinar-division-main-container"
                          key={index}
                        >
                          <div className="ir-bg-white ir-ws-card-main-container">
                            <div className="ir-ws-text-center ir-ws-position-relative ir-ws-position-relative ir-ws-card-header">
                              <img
                                className="ir-ws-width-100 ir-ws-max-width-100 ir-ws-webinar-card-thumbnail-container"
                                src={item.thumbnail}
                                alt="Courses thumbnail"
                              />
                              <div className="ir-ws-position-absolute ir-ws-heading-default-color ir-ws-hme-webinar-car-title-tag">
                                {convertUTCToLocalAndFormat(
                                  item.dateAndTime
                                ) === "Apr 24, 2025, 11:06 PM"
                                  ? "Upcoming"
                                  : item.status}
                              </div>
                            </div>
                            <div className="ir-ws-heading-default-color ir-ws-app-bg ir-ws-text-center ir-ws-hme-webinar-tag-container">
                              {convertUTCToLocalAndFormat(item.dateAndTime) ===
                              "Apr 24, 2025, 11:06 PM"
                                ? "Upcoming"
                                : convertUTCToLocalAndFormat(item.dateAndTime)}
                            </div>
                            <div className="ir-ws-card-body ">
                              <h4
                                className="ir-ws-heading-default-color ir-ws-webinar-crd-bdy-title"
                                title={
                                  item.title.length > 20 ? item.title : null
                                }
                              >
                                {item.title.length > 20
                                  ? `${item.title.slice(0, 20)}...`
                                  : item.title}
                              </h4>
                              <p
                                className="ir-default-color ir-ws-hme-webinar-crd-para"
                                title={
                                  item.description.length > 40
                                    ? item.description
                                    : null
                                }
                              >
                                {item.description.length > 40
                                  ? `${item.description.slice(0, 40)}...`
                                  : item.description}
                              </p>
                              <div className="ir-ws-flex ir-ws-align-center ir-ws-hme-webinar-seats-container">
                                <img
                                  src={require("../../assets/images/car-seat.png")}
                                  alt="users"
                                />
                                <span className="ir-ws-heading-default-color">
                                  {item.noOfSeats} Seats available
                                </span>
                              </div>
                              <div className="ir-ws-flex ir-ws-align-center ir-ws-hme-webinar-price-container">
                                {/* {item.currency}
														{item.price}{" "} */}
                                <img
                                  src={require("../../assets/images/shopping.png")}
                                  alt="users"
                                />
                                <span className="ir-ws-app-color">
                                  {item.currency === "USD"
                                    ? "$"
                                    : item.currency}
                                  {item.price}
                                </span>
                              </div>
                              <div className="ir-ws-flex ir-ws-align-center ir-ws-hme-webinar-lang-container">
                                <img
                                  src={require("../../assets/images/globe.png")}
                                  alt="users"
                                />
                                <span className="ir-ws-heading-default-color">
                                  English
                                </span>
                              </div>
                              {/* <div className="ir-ws-flex ir-ws-align-center ir-ws-justify-space-btw ir-ws-hme-webinar-pro-container">
													<div className="ir-ws-flex ir-ws-align-center ir-ws-hme-webinar-pro-content">
														<img src={require('../../assets/images/hme-webinar-pro.png')} className="ir-ws-hme-webinar-pro-icon" alt="pro" />
														<span className="ir-default-color ir-ws-hme-webinar-pro-txt">Pro/Beginner</span>
													</div>
													<div className="ir-ws-flex ir-ws-align-center ir-ws-hme-webinar-cloc-container">
														<img src={require('../../assets/images/hme-webinar-clock.png')} className="ir-ws-hme-webinar-cloc-icon" alt="clock"/>
														<span className="ir-default-color ir-ws-hme-webinar-clock-txt">22hr..</span>
													</div>
												</div> */}
                              <div className="ir-ws-text-center ir-ws-default-btn-container ir-ws-book-now-container">
                                {!(
                                  isWebinarIdMatch ||
                                  item?.createdBy === username
                                ) ? (
                                  <button
                                    className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                                    disabled={
                                      // Add disabled attribute to disable the button
                                      item.status === "cancelled" ||
                                      item.status === "completed" ||
                                      convertUTCToLocalAndFormat(
                                        item.dateAndTime
                                      ) === "Apr 24, 2025, 11:06 PM" ||
                                      isAdmin
                                    }
                                    onClick={() => {
                                      if (loggedIn) {
                                        if (emailVerified) {
                                          // handleRegisterUserMeeting(item.id);
                                          // handleBuyNowClick(item);
                                          handleTermsAndCondition();
                                          setSendWebinarId(item.id);
                                        } else {
                                          setBookNowModal(true);
                                        }
                                      } else if (!loggedIn) {
                                        setBookNowModal(true);
                                      }
                                    }}
                                  >
                                    <span>Book now</span>
                                  </button>
                                ) : (
                                  <button
                                    className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                                    disabled={
                                      // Add disabled attribute to disable the button
                                      item.status === "cancelled" ||
                                      item.status === "completed" ||
                                      convertUTCToLocalAndFormat(
                                        item.dateAndTime
                                      ) === "Apr 24, 2025, 11:06 PM" ||
                                      isAdmin
                                    }
                                    onClick={() => {
                                      toggleBookAgain();
                                      setSendWebinarId(item.id);
                                    }}
                                  >
                                    <span>Book now</span>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                {activeWebinars.length > 5 && (
                  <div className="ir-ws-webinar-homepage-show-more-btn">
                    <p
                      onClick={
                        showAllActive
                          ? handleSeeLessClickActive
                          : handleSeeAllClickActive
                      }
                    >
                      {showAllActive ? "Show less" : "Show more"}
                    </p>
                  </div>
                )}
                <div className="ir-ws-webinar-list-page-dotted-border"></div>
              </div>
            </div>
          </div>
        </div>
      )}
      {upcomingWebinars.length > 0 && (
        <div className="ir-ws-banner-padding-tb ir-ws-webinar-page-webinar-list-inner-container ir-ws-weinar-upcoming-container">
          <h3 className=" ir-ws-hme-webinar-title ir-ws-heading-default-color ir-ws-webinar-page-webinar-list-title">
            Upcoming Webinars
          </h3>
          <div className="ir-ws-padding-lr-132">
            <div className="ir-ws-webinar-page-ul-main-container">
              <div className="ir-ws-align-center ir-ws-hme-webinar-inner-container">
                <div className="ir-ws-hme-webinar-sessions-container">
                  {upcomingWebinars
                    .slice(0, showAllUpcoming ? undefined : 5)
                    .map((item, index) => {
                      const isWebinarIdMatch = transacationDetails?.some(
                        (obj) => obj.webinarId === item.id
                      );
                      return (
                        <div
                          className="ir-ws-hme-prvw-card-container ir-ws-width-25 ir-ws-hme-webinar-division-main-container"
                          key={index}
                        >
                          <div className="ir-bg-white ir-ws-card-main-container">
                            <div className="ir-ws-text-center ir-ws-position-relative ir-ws-position-relative ir-ws-card-header">
                              <img
                                className="ir-ws-width-100 ir-ws-max-width-100 ir-ws-webinar-card-thumbnail-container"
                                src={item.thumbnail}
                                alt="Courses thumbnail"
                              />
                              <div className="ir-ws-position-absolute ir-ws-heading-default-color ir-ws-hme-webinar-car-title-tag">
                                {convertUTCToLocalAndFormat(
                                  item.dateAndTime
                                ) === "Apr 24, 2025, 11:06 PM"
                                  ? "Upcoming"
                                  : item.status}
                              </div>
                            </div>
                            <div className="ir-ws-heading-default-color ir-ws-app-bg ir-ws-text-center ir-ws-hme-webinar-tag-container">
                              {convertUTCToLocalAndFormat(item.dateAndTime) ===
                              "Apr 24, 2025, 11:06 PM"
                                ? "Upcoming"
                                : convertUTCToLocalAndFormat(item.dateAndTime)}
                            </div>
                            <div className="ir-ws-card-body ">
                              <h4
                                className="ir-ws-heading-default-color ir-ws-webinar-crd-bdy-title"
                                title={
                                  item.title.length > 20 ? item.title : null
                                }
                              >
                                {item.title.length > 20
                                  ? `${item.title.slice(0, 20)}...`
                                  : item.title}
                              </h4>
                              <p
                                className="ir-default-color ir-ws-hme-webinar-crd-para"
                                title={
                                  item.description.length > 40
                                    ? item.description
                                    : null
                                }
                              >
                                {item.description.length > 40
                                  ? `${item.description.slice(0, 40)}...`
                                  : item.description}
                              </p>
                              <div className="ir-ws-flex ir-ws-align-center ir-ws-hme-webinar-seats-container">
                                <img
                                  src={require("../../assets/images/car-seat.png")}
                                  alt="users"
                                />
                                <span className="ir-ws-heading-default-color">
                                  {item.noOfSeats} Seats available
                                </span>
                              </div>
                              <div className="ir-ws-flex ir-ws-align-center ir-ws-hme-webinar-price-container">
                                {/* {item.currency}
														{item.price}{" "} */}
                                <img
                                  src={require("../../assets/images/shopping.png")}
                                  alt="users"
                                />
                                <span className="ir-ws-app-color">
                                  {item.currency === "USD"
                                    ? "$"
                                    : item.currency}
                                  {item?.price || 0}
                                </span>
                              </div>
                              <div className="ir-ws-flex ir-ws-align-center ir-ws-hme-webinar-lang-container">
                                <img
                                  src={require("../../assets/images/globe.png")}
                                  alt="users"
                                />
                                <span className="ir-ws-heading-default-color">
                                  English
                                </span>
                              </div>
                              {/* <div className="ir-ws-flex ir-ws-align-center ir-ws-justify-space-btw ir-ws-hme-webinar-pro-container">
													<div className="ir-ws-flex ir-ws-align-center ir-ws-hme-webinar-pro-content">
														<img src={require('../../assets/images/hme-webinar-pro.png')} className="ir-ws-hme-webinar-pro-icon" alt="pro" />
														<span className="ir-default-color ir-ws-hme-webinar-pro-txt">Pro/Beginner</span>
													</div>
													<div className="ir-ws-flex ir-ws-align-center ir-ws-hme-webinar-cloc-container">
														<img src={require('../../assets/images/hme-webinar-clock.png')} className="ir-ws-hme-webinar-cloc-icon" alt="clock"/>
														<span className="ir-default-color ir-ws-hme-webinar-clock-txt">22hr..</span>
													</div>
												</div> */}
                              <div className="ir-ws-text-center ir-ws-default-btn-container ir-ws-book-now-container">
                                {!(
                                  isWebinarIdMatch ||
                                  item?.createdBy === username
                                ) ? (
                                  <button
                                    className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                                    disabled={
                                      // Add disabled attribute to disable the button
                                      item.status === "cancelled" ||
                                      item.status === "completed" ||
                                      convertUTCToLocalAndFormat(
                                        item.dateAndTime
                                      ) === "Apr 24, 2025, 11:06 PM" ||
                                      isAdmin
                                    }
                                    onClick={() => {
                                      if (loggedIn) {
                                        if (emailVerified) {
                                          // handleRegisterUserMeeting(item.id);
                                          // handleBuyNowClick(item);
                                          handleTermsAndCondition();
                                          setSendWebinarId(item.id);
                                        } else {
                                          setBookNowModal(true);
                                        }
                                      } else if (!loggedIn) {
                                        setBookNowModal(true);
                                      }
                                    }}
                                  >
                                    <span>Book now</span>
                                  </button>
                                ) : (
                                  <button
                                    className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                                    disabled={
                                      // Add disabled attribute to disable the button
                                      item.status === "cancelled" ||
                                      item.status === "completed" ||
                                      convertUTCToLocalAndFormat(
                                        item.dateAndTime
                                      ) === "Apr 24, 2025, 11:06 PM" ||
                                      isAdmin
                                    }
                                    onClick={() => {
                                      toggleBookAgain();
                                      setSendWebinarId(item.id);
                                    }}
                                  >
                                    <span>Book now</span>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>

                {upcomingWebinars.length > 5 && (
                  <div className="ir-ws-webinar-homepage-show-more-btn">
                    <p
                      onClick={
                        showAllUpcoming
                          ? handleSeeLessClickUpcoming
                          : handleSeeAllClickUpcoming
                      }
                    >
                      {showAllUpcoming ? "Show Less" : "Show more"}
                    </p>
                  </div>
                )}
                <div className="ir-ws-webinar-list-page-dotted-border"></div>
              </div>
            </div>
          </div>
        </div>
      )}
      {reversedWebinarData.filter((item) => item.status === "completed")
        .length > 0 && (
        <div className="ir-ws-banner-padding-tb ir-ws-webinar-page-webinar-list-inner-container ir-ws-weinar-completed-container">
          <h3 className=" ir-ws-hme-webinar-title ir-ws-heading-default-color ir-ws-webinar-page-webinar-list-title">
            Completed Webinars
          </h3>
          <div className="ir-ws-padding-lr-132">
            <div className="ir-ws-webinar-page-ul-main-container">
              <div className="ir-ws-align-center ir-ws-hme-webinar-inner-container">
                <div className="ir-ws-hme-webinar-sessions-container">
                  {reversedWebinarData
                    .filter((item) => item.status === "completed")
                    .slice(0, showAllCompleted ? undefined : 5)
                    .map((item, index) => {
                      const isWebinarIdMatch = transacationDetails?.some(
                        (obj) => obj.webinarId === item.id
                      );
                      return (
                        <div
                          className="ir-ws-hme-prvw-card-container ir-ws-width-25 ir-ws-hme-webinar-division-main-container"
                          key={index}
                        >
                          <div className="ir-bg-white ir-ws-card-main-container">
                            <div className="ir-ws-text-center ir-ws-position-relative ir-ws-position-relative ir-ws-card-header">
                              <img
                                className="ir-ws-width-100 ir-ws-max-width-100 ir-ws-webinar-card-thumbnail-container"
                                src={item.thumbnail}
                                alt="Courses thumbnail"
                              />
                              <div className="ir-ws-position-absolute ir-ws-heading-default-color ir-ws-hme-webinar-car-title-tag">
                                {convertUTCToLocalAndFormat(
                                  item.dateAndTime
                                ) === "Apr 24, 2025, 11:06 PM"
                                  ? "Upcoming"
                                  : item.status}
                              </div>
                            </div>
                            <div className="ir-ws-heading-default-color ir-ws-app-bg ir-ws-text-center ir-ws-hme-webinar-tag-container">
                              {convertUTCToLocalAndFormat(item.dateAndTime) ===
                              "Apr 24, 2025, 11:06 PM"
                                ? "Upcoming"
                                : convertUTCToLocalAndFormat(item.dateAndTime)}
                            </div>
                            <div className="ir-ws-card-body ">
                              <h4
                                className="ir-ws-heading-default-color ir-ws-webinar-crd-bdy-title"
                                title={
                                  item.title.length > 20 ? item.title : null
                                }
                              >
                                {item.title.length > 20
                                  ? `${item.title.slice(0, 20)}...`
                                  : item.title}
                              </h4>
                              <p
                                className="ir-default-color ir-ws-hme-webinar-crd-para"
                                title={
                                  item.description.length > 40
                                    ? item.description
                                    : null
                                }
                              >
                                {item.description.length > 40
                                  ? `${item.description.slice(0, 40)}...`
                                  : item.description}
                              </p>
                              <div className="ir-ws-flex ir-ws-align-center ir-ws-hme-webinar-seats-container">
                                <img
                                  src={require("../../assets/images/car-seat.png")}
                                  alt="users"
                                />
                                <span className="ir-ws-heading-default-color">
                                  {item.noOfSeats} Seats available
                                </span>
                              </div>
                              <div className="ir-ws-flex ir-ws-align-center ir-ws-hme-webinar-price-container">
                                {/* {item.currency}
														{item.price}{" "} */}
                                <img
                                  src={require("../../assets/images/shopping.png")}
                                  alt="users"
                                />
                                <span className="ir-ws-app-color">
                                  {item.currency === "USD"
                                    ? "$"
                                    : item.currency}
                                  {item?.price || 0}
                                </span>
                              </div>
                              <div className="ir-ws-flex ir-ws-align-center ir-ws-hme-webinar-lang-container">
                                <img
                                  src={require("../../assets/images/globe.png")}
                                  alt="users"
                                />
                                <span className="ir-ws-heading-default-color">
                                  English
                                </span>
                              </div>
                              {/* <div className="ir-ws-flex ir-ws-align-center ir-ws-justify-space-btw ir-ws-hme-webinar-pro-container">
													<div className="ir-ws-flex ir-ws-align-center ir-ws-hme-webinar-pro-content">
														<img src={require('../../assets/images/hme-webinar-pro.png')} className="ir-ws-hme-webinar-pro-icon" alt="pro" />
														<span className="ir-default-color ir-ws-hme-webinar-pro-txt">Pro/Beginner</span>
													</div>
													<div className="ir-ws-flex ir-ws-align-center ir-ws-hme-webinar-cloc-container">
														<img src={require('../../assets/images/hme-webinar-clock.png')} className="ir-ws-hme-webinar-cloc-icon" alt="clock"/>
														<span className="ir-default-color ir-ws-hme-webinar-clock-txt">22hr..</span>
													</div>
												</div> */}
                              <div className="ir-ws-text-center ir-ws-default-btn-container ir-ws-book-now-container">
                                {/* {!isWebinarIdMatch ? ( */}
                                <button
                                  className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                                  disabled={
                                    // Add disabled attribute to disable the button
                                    item.status === "cancelled" ||
                                    item.status === "completed" ||
                                    convertUTCToLocalAndFormat(
                                      item.dateAndTime
                                    ) === "Apr 24, 2025, 11:06 PM" ||
                                    isAdmin
                                  }
                                  onClick={() => {
                                    if (loggedIn) {
                                      if (emailVerified) {
                                        // handleRegisterUserMeeting(item.id);
                                        // handleBuyNowClick(item);
                                        handleTermsAndCondition();
                                        setSendWebinarId(item.id);
                                      } else {
                                        setBookNowModal(true);
                                      }
                                    } else if (!loggedIn) {
                                      setBookNowModal(true);
                                    }
                                  }}
                                >
                                  <span>Book now</span>
                                </button>
                                {/* ) : (
                                  <div
                                    className="ir-ws-no-bg ir-ws-app-color ir-ws-book-now-text "
                                    disabled={isWebinarIdMatch}
                                    style={{
                                      visibility:
                                        item.status === "cancelled" ||
                                        item.status === "completed"
                                          ? "hidden"
                                          : "visible",
                                    }}
                                  >
                                    Booked
                                  </div>
                                )} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                {reversedWebinarData.filter(
                  (item) => item.status === "completed"
                ).length > 5 && (
                  <>
                    <div className="ir-ws-webinar-homepage-show-more-btn">
                      <p
                        onClick={
                          showAllCompleted
                            ? handleSeeLessClickCompleted
                            : handleSeeAllClickCompleted
                        }
                      >
                        {showAllCompleted ? "See Less" : "See All"}
                      </p>
                    </div>
                    <div className="ir-ws-webinar-list-page-dotted-border"></div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {bookNowModal && loggedIn && !emailVerified && (
        <BookNowModal
          type="Verify Email"
          emailVerified={!emailVerified}
          loggedIn={loggedIn}
          setBookNowModal={setBookNowModal}
          username={username}
          category={"webinar"}
        />
      )}
      {bookNowModal && !loggedIn && (
        <BookNowModal
          type="Sign up"
          loggedIn={!loggedIn}
          setBookNowModal={setBookNowModal}
          category={"webinar"}
        />
      )}
      {termsAndCondition && (
        <TermsAndConditionModal
          handleTermsAndCondition={handleTermsAndCondition}
          id={sendWebinarId}
          confirmationFunc={confirmationFunc}
        />
      )}
      {bookAgainModal && (
        <BookAgainModal
          id={sendWebinarId}
          toggleBookAgain={toggleBookAgain}
          confirmationFunc={confirmationFunc}
        />
      )}
    </div>
  );
};

export default WebinarListTab;
