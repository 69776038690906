import React, { useState } from "react";
import { STATUS } from "../../../../config";
import { ConfirmationTypes } from "../../../../constants";
import "./UserAdminTab.scss";

const ActiveUserAdminTab = ({ data, onAction, inactiveUser, deleteUser }) => {
  const [expandedRows, setExpandedRows] = useState(null);
  const expandUser = (id) => {
    // console.log("expandUser", id);
    let currentExpandedRows = null;
    const isRowExpanded = currentExpandedRows === id ? id : null;
    const newExpandedRows = isRowExpanded ? null : (currentExpandedRows = id);
    if (expandedRows !== id) {
      setExpandedRows(newExpandedRows);
    } else {
      setExpandedRows(null);
    }
  };

  return (
    <div className="ir-chat-rooms-tab-container ir-active-users-tab-main-container">
      {/* {JSON.stringify(data)} */}
      <div className="ir-chat-admin-inside-tab-container">
        {data.length > 0 &&
          data?.filter((i) => i.status === "active")?.length === 0 && (
            <p className="ir-found-no-results-text">
              There are No Inactive Users
            </p>
          )}
        <ul className="ir-chat-admin-tab-in-list-container">
          {data.length > 0 &&
            data
              ?.filter((i) => i.status === "active")
              .map((profilesInfo, index) => {
                return (
                  <li
                    className="ir-chat-admin-tab-in-list-item-container"
                    key={index}
                  >
                    <div className="ir-chat-admin-tab-in-list-item">
                      <div className="ir-chat-admin-tab-in-list-item-content-container ir-chat-admin-tab-in-list-item-check-container">
                        <div className="ir-checkbox">
                          <input id={index} type="checkbox" />
                          <label htmlFor={index}></label>
                        </div>
                      </div>
                      <div className="ir-chat-admin-tab-in-list-item-content-container ir-chat-admin-tab-in-list-item-img-container">
                        {profilesInfo.image === "" ? (
                          <p>
                            {profilesInfo?.firstname[0] +
                              profilesInfo?.lastname[0]}
                          </p>
                        ) : (
                          <img
                            src={profilesInfo.image}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = "";
                            }}
                            className="profile-icon"
                            alt="profile_pic"
                          />
                        )}
                      </div>
                      <div className="ir-chat-admin-tab-in-list-item-content-container ir-chat-admin-tab-in-list-item-more-width-container">
                        <span>
                          {profilesInfo?.firstname} {profilesInfo?.lastname}
                        </span>
                      </div>
                      <div className="ir-chat-admin-tab-in-list-actions-container">
                        <div className="ir-chat-admin-tab-in-list-item-content-container">
                          {/* { profilesInfo.status ==="active" ? (   
                                                    <button className="ir-admin-tb-warn-btn" onClick={() => inactiveUser(profilesInfo.id, STATUS.inactive)}>Inactive</button>
                                                ) : (
                                                    <button className="ir-admin-tb-warn-btn" onClick={() => activeUser(profilesInfo.id, STATUS.active)}>Active</button>
                                                )
                                            } */}
                          <button
                            className="ir-admin-tb-warn-btn"
                            onClick={() =>
                              inactiveUser(profilesInfo.id, STATUS.inactive)
                            }
                          >
                            Inactive
                          </button>
                        </div>
                        <div className="ir-chat-admin-tab-in-list-item-content-container">
                          <button
                            className="ir-admin-tb-delete-btn btn-danger"
                            onClick={() => {
                              onAction(
                                profilesInfo,
                                ConfirmationTypes.DELETEUSER
                              );
                            }}
                          >
                            Delete
                          </button>
                        </div>
                        <div className="ir-chat-admin-tab-in-list-item-content-container">
                          <button onClick={() => expandUser(index)}>
                            View More
                          </button>
                        </div>
                      </div>
                    </div>
                    {expandedRows === index ? (
                      <div className="expandable">
                        <div className="ir-ad-tr-user-profile-container">
                          {profilesInfo.image === "" ? (
                            <p>
                              {profilesInfo?.firstname[0] +
                                profilesInfo?.lastname[0]}
                            </p>
                          ) : (
                            <img
                              src={profilesInfo.image}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "";
                              }}
                              className="profile-icon"
                              alt="profile_pic"
                            />
                          )}
                        </div>
                        <div className="ir-ad-tr-user-details-main-container">
                          <div className="ir-ad-user-details-container">
                            <div className="ir-ad-user-details-title">
                              Name : &nbsp;
                            </div>
                            <div className="ir-ad-user-details-content">
                              {profilesInfo?.firstname} {profilesInfo?.lastname}
                            </div>
                          </div>
                          <div className="ir-ad-user-details-container">
                            <div className="ir-ad-user-details-title">
                              Username : &nbsp;
                            </div>
                            <div className="ir-ad-user-details-content">
                              {profilesInfo?.username}
                            </div>
                          </div>
                          <div className="ir-ad-user-details-container">
                            <div className="ir-ad-user-details-title">
                              Email : &nbsp;
                            </div>
                            <div className="ir-ad-user-details-content">
                              {profilesInfo?.email}
                            </div>
                          </div>
                          {profilesInfo?.phonenumber === "" ? (
                            ""
                          ) : (
                            <div className="ir-ad-user-details-container">
                              <div className="ir-ad-user-details-title">
                                Phone : &nbsp;
                              </div>
                              <div className="ir-ad-user-details-content">
                                {profilesInfo?.phonenumber}
                              </div>
                            </div>
                          )}
                          {profilesInfo?.gender === "" ? (
                            ""
                          ) : (
                            <div className="ir-ad-user-details-container">
                              <div className="ir-ad-user-details-title">
                                Gender : &nbsp;
                              </div>
                              <div className="ir-ad-user-details-content">
                                {profilesInfo?.gender}
                              </div>
                            </div>
                          )}
                          {profilesInfo?.groups?.length > 0 && (
                            <div className="ir-ad-user-details-container">
                              <div className="ir-ad-user-details-title">
                                Groups : &nbsp;
                              </div>
                              <div className="ir-ad-user-details-content">
                                {profilesInfo?.groups.join(", ")}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </li>
                );
              })}
        </ul>
      </div>
    </div>
  );
};

export default ActiveUserAdminTab;
