import React from "react";
import { useLocation } from "react-router-dom";
import { useModalContext } from "../../components/WebinarAdminCreateWebinarTab/ModalContext";

const EmailVerification = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const { handleVerifyEmail } = useModalContext();
  return (
    <div className="ir-ws-sign-in-main-container">
      <div className="ir-ws-signin-inner-container ir-ws-sign-inner-container md:!max-w-[350px]   xl:!max-w-[400px] 3xl:!max-w-[500px]">
        <div className="ir-ws-sign-in-content-container">
          <div className="ir-ws-signin-logo-container">
            <div className="ir-ws-text-center ir-ws-signin-logo-inner-container flex items-center justify-center !m-[0px]">
              <a href="/">
                <img
                  className="ir-ws-signin-logo-img"
                  src={require("../../assets/images/ir4u2.png")}
                  alt="logo"
                />
              </a>
            </div>
          </div>
          <p className="text-[10px] lg:text-[12px] xl:text-[14px] 3xl:text-[16px] text-center !my-[15px] lg:!my-[15px] xl:!my-[25px] 3xl:!my-[35px]">
            Click on the button to verify you email
          </p>

          <div className="ir-ws-signup-form-container !m-[0px]">
            <div className="ir-ws-text-center ir-ws-default-btn-container">
              <button
                className="!w-[170px] !p-[0px] !h-[36px] !text-[10px] lg:!h-[36px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px] xl:!h-[40px] 3xl:!h-[50px] ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                onClick={() => {
                  if (email || token) {
                    handleVerifyEmail(token, email);
                  }
                }}
              >
                <span> Verify Email</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
