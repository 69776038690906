import React from "react";
import "./CaseCategory.scss";
import { useNavigate } from "react-router-dom";

const Casecategory = () => {
  const navigate = useNavigate();
  const cardData = [
    {
      data: {
        purpleVarient: require("../../assets/images/chest-icon.png"),
        whiteVarient: require("../../assets/images/chest-white-icon.png"),
        categoryTitle: "Chest",
      },
    },
    {
      data: {
        purpleVarient: require("../../assets/images/gastrointestinal-icon.png"),
        whiteVarient: require("../../assets/images/gastrointestinal-white-icon.png"),
        categoryTitle: "⁠Gastrointestinal",
      },
    },
    {
      data: {
        purpleVarient: require("../../assets/images/liver.png"),
        whiteVarient: require("../../assets/images/liver-white.png"),
        categoryTitle: "Liver",
      },
    },
    {
      data: {
        purpleVarient: require("../../assets/images/g1.png"),
        whiteVarient: require("../../assets/images/g3.png"),
        categoryTitle: "⁠Genitourinary",
      },
    },
    {
      data: {
        purpleVarient: require("../../assets/images/vascular-icon.png"),
        whiteVarient: require("../../assets/images/vascular-white-icon.png"),
        categoryTitle: "Vascular",
      },
    },
    {
      data: {
        purpleVarient: require("../../assets/images/lymphatic-icon.png"),
        whiteVarient: require("../../assets/images/lymphatic-white-icon.png"),
        categoryTitle: "Lymphatics",
      },
    },
    {
      data: {
        purpleVarient: require("../../assets/images/trauma-icon.png"),
        whiteVarient: require("../../assets/images/trauma-white-icon.png"),
        categoryTitle: "T⁠rauma",
      },
    },
    {
      data: {
        purpleVarient: require("../../assets/images/venous-icon.png"),
        whiteVarient: require("../../assets/images/venous-white-icon.png"),
        categoryTitle: "Venous",
      },
    },
    {
      data: {
        purpleVarient: require("../../assets/images/mens-health-icon.png"),
        whiteVarient: require("../../assets/images/mens-health-white-icon.png"),
        categoryTitle: "⁠Men’s health",
      },
    },
    {
      data: {
        purpleVarient: require("../../assets/images/womens-health-icon.png"),
        whiteVarient: require("../../assets/images/womens-health-white-icon.png"),
        categoryTitle: "⁠Women’s health",
      },
    },
  ];
  const loggedIn = localStorage.getItem("isLoggedIn") === "true";
  return (
    <div className="ir-cases-categroy">
      <div className="ir-ws-banner-padding-tb ir-ws-padding-lr-132 ir-cases-category-main-container">
        <h3 className="ir-ws-font-700 ir-ws-heading-default-color ir-ws-text-center ir-ws-font-700 ir-ws-font-size-xl ir-ws-main-heading">
          Explore a wide range of cases
          <div>across multiple categories</div>
        </h3>
        <p className="ir-ws-text-center ir-ws-heading-default-color cat-sub-heading">
          Each designed to provide in-depth learning experiences in specific
          areas
        </p>
        <div className="ir-cases-cat-section-container">
          <div className="ir-cases-cat-main-section">
            {cardData.map((card, index) => (
              <div
                key={index}
                className="ir-case-cat-card"
                // tabIndex="0"
                onClick={() => {
                  // navigate(`/cases/category/${card.data.categoryTitle}`);

                  navigate(
                    `/cases/category/${card.data.categoryTitle.replace(
                      /[^a-zA-Z ]/g,
                      ""
                    )}`
                  );
                }}
              >
                <div className="cat-img-container">
                  <img
                    src={card.data.whiteVarient}
                    className="ir-cases-body-parts-icon white-icon"
                    alt="icon"
                  />
                  <img
                    src={card.data.purpleVarient}
                    className="ir-cases-body-parts-icon purple-icon"
                    alt="icon"
                  />
                </div>

                <p className="ir-cases-cat-title !m-[0px]">
                  {card.data.categoryTitle}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Casecategory;
