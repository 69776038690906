import React from "react";
import Footer from "../../Footer/Footer";
import Header from "../../Header/Header";

const CollaboratePage = () => {
  return (
    <div className="ir-ws-privacy-main-container">
      <Header />
      <div className="ir-ws-padding-lr-132 ir-ws-about-main-container ir-ws-gray-bg">
        <div className=" ir-ws-inner-privacy-main-container">
          <h2 className="ir-ws-position-relative ir-ws-heading-default-color ir-ws-hme-cases-title ir-ws-about-heading-container ir-ws-privacy-main-heading">
            Collaborate With Us
          </h2>
          <div className="ir-ws-privacy-policy-content-container">
            <h3 className="ir-ws-heading-default-color ir-ws-privay-subtitle">
              Welcome to IR4U's Privacy Policy
            </h3>
            <p className="ir-ws-heading-default-color ir-ws-privacy-para">
              Welcome to IR4U! This Privacy Policy outlines how IR4U ("we,"
              "our," or "us") collects, uses, discloses, and safeguards your
              personal information when you use our services and visit our
              website.
            </p>
          </div>
          <div className="ir-ws-privacy-policy-content-container">
            <h3 className="ir-ws-heading-default-color ir-ws-privay-subtitle">
              Information We Collect
            </h3>
            <p className="ir-ws-heading-default-color ir-ws-privacy-para">
              We may collect the following types of personal information:
            </p>
            <ul className="ir-ws-privacy-list-container">
              <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                Name
              </li>
              <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                Email address
              </li>
              <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                Phone number
              </li>
              <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                Address
              </li>
              <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                Payment information
              </li>
              <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                User-generated content (reviews, comments, etc.)
              </li>
              <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                Location data (if provided)
              </li>
              <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                Any other information you choose to provide
              </li>
            </ul>
          </div>
          <div className="ir-ws-privacy-policy-content-container">
            <h3 className="ir-ws-heading-default-color ir-ws-privay-subtitle">
              How We Collect Information
            </h3>
            <p className="ir-ws-heading-default-color ir-ws-privacy-para">
              We may collect your personal information through various means,
              including:
            </p>
            <ul className="ir-ws-privacy-list-container">
              <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                When you create an account
              </li>
              <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                When you make a purchase
              </li>
              <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                When you contact us through our website or customer support
              </li>
              <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                When you interact with our website or mobile app (e.g., through
                cookies or other tracking technologies)
              </li>
            </ul>
          </div>
          <div className="ir-ws-privacy-policy-content-container">
            <h3 className="ir-ws-heading-default-color ir-ws-privay-subtitle">
              How We Use Your Information
            </h3>
            <p className="ir-ws-heading-default-color ir-ws-privacy-para">
              We use your personal information for the following purposes:
            </p>
            <ul className="ir-ws-privacy-list-container">
              <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                To provide our products and services
              </li>
              <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                To process orders and payments
              </li>
              <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                To communicate with you (e.g., for customer support or
                marketing)
              </li>
              <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                To personalize your experience
              </li>
              <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                To comply with legal obligations
              </li>
            </ul>
          </div>
          <div className="ir-ws-privacy-policy-content-container">
            <h3 className="ir-ws-heading-default-color ir-ws-privay-subtitle">
              Data Sharing
            </h3>
            <p className="ir-ws-heading-default-color ir-ws-privacy-para">
              We may share your personal information with third parties for the
              following purposes:
            </p>
            <ul className="ir-ws-privacy-list-container">
              <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                Service providers who assist us in delivering our services
              </li>
              <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                Marketing partners for promotional purposes (you can opt-out)
              </li>
              <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                Legal compliance and law enforcement when required by law
              </li>
            </ul>
          </div>
          <div className="ir-ws-privacy-policy-content-container">
            <h3 className="ir-ws-heading-default-color ir-ws-privay-subtitle">
              Data Security
            </h3>
            <p className="ir-ws-heading-default-color ir-ws-privacy-para">
              We take reasonable measures to protect your personal information
              from unauthorized access, disclosure, alteration, or destruction.
              However, no method of data transmission or storage is entirely
              secure, and we cannot guarantee absolute security.
            </p>
          </div>
          <div className="ir-ws-privacy-policy-content-container">
            <h3 className="ir-ws-heading-default-color ir-ws-privay-subtitle">
              User Rights
            </h3>
            <p className="ir-ws-heading-default-color ir-ws-privacy-para">
              You have the right to:
            </p>
            <ul className="ir-ws-privacy-list-container">
              <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                Access your personal information
              </li>
              <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                Correct inaccurate information
              </li>
              <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                Delete your data (subject to legal requirements)
              </li>
              <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                Withdraw consent (where applicable)
              </li>
              <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                Object to data processing (where applicable)
              </li>
              <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                Lodge a complaint with the appropriate data protection authority
              </li>
            </ul>
          </div>
          <div className="ir-ws-privacy-policy-content-container">
            <h3 className="ir-ws-heading-default-color ir-ws-privay-subtitle">
              Cookies and Tracking Technologies
            </h3>
            <p className="ir-ws-heading-default-color ir-ws-privacy-para">
              We use cookies and similar technologies to improve your browsing
              experience and understand how you use our website. You can manage
              your cookie preferences in your browser settings.
            </p>
          </div>
          <div className="ir-ws-privacy-policy-content-container">
            <h3 className="ir-ws-heading-default-color ir-ws-privay-subtitle">
              Children's Privacy
            </h3>
            <p className="ir-ws-heading-default-color ir-ws-privacy-para">
              Our services are not intended for children under the age of
              [appropriate age in your jurisdiction]. We do not knowingly
              collect or maintain information from children under this age.
            </p>
          </div>
          <div className="ir-ws-privacy-policy-content-container">
            <h3 className="ir-ws-heading-default-color ir-ws-privay-subtitle">
              Changes to the Privacy Policy
            </h3>
            <p className="ir-ws-heading-default-color ir-ws-privacy-para">
              We may update this Privacy Policy from time to time. Changes will
              be posted on our website, and the date of the last revision will
              be updated. Your continued use of our services after any changes
              indicate your acceptance of the updated policy.
            </p>
          </div>
          <div className="ir-ws-privacy-policy-content-container">
            <h3 className="ir-ws-heading-default-color ir-ws-privay-subtitle">
              Contact Information
            </h3>
            <p className="ir-ws-heading-default-color ir-ws-privacy-para">
              If you have any questions, concerns, or requests regarding your
              personal information or this Privacy Policy, please contact us at:
              <br />
              +1 216 236 4624 <br />
              Cleveland, OH <br />
              info@ir4u.info
            </p>
          </div>
          <div className="ir-ws-privacy-policy-content-container">
            <h3 className="ir-ws-heading-default-color ir-ws-privay-subtitle">
              {" "}
              Legal Compliance
            </h3>
            <p className="ir-ws-heading-default-color ir-ws-privacy-para">
              IR4U complies with all applicable data protection and privacy laws
              and regulations in your jurisdiction.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CollaboratePage;
