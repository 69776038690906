import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import "./VideoProcessingLoader.scss";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Header from "../../../../components/Header/Header";
import { useContext } from "react";
import { useModalContext } from "../../../../components/WebinarAdminCreateWebinarTab/ModalContext";

const VideoProcessingLoader = ({ uploadingProgress, processedVideo }) => {
  const allSections = useSelector((state) => state.course.sections);
  const [updateStatus, setUpdateStatus] = useState("video is processing..");

  const allformdata = useSelector((state) => state.course.formData);
  const [isLoading, setIsloading] = useState(false);
  const navigate = useNavigate();
  const [courseId, setCourseId] = useState(null);
  const [hasPublished, setHasPublished] = useState(false);
  const [isError, setIsError] = useState(false);

  const { userDetail, courseUpdateId } = useModalContext();

  // const userProfile = JSON.parse(localStorage.getItem("userProfile"));

  const isAdmin = localStorage.getItem("loggedInUsername") === "admin";
  const userProfile = JSON.parse(localStorage.getItem("userProfile") || "[]");
  const isSecondAdmin = userProfile.includes("publisher");

  let role;
  if (userProfile) {
    if (userProfile?.includes("admin")) {
      role = "admin";
    } else if (userProfile?.includes("publisher")) {
      role = "publisher";
    }
  }

  const isPublisher = role === "publisher";

  const formattedFormData = {
    formData: {
      title: allformdata.title,
      createdBy: isPublisher ? `${userDetail?.username}` : "admin",
      subtitle: allformdata.subtitle,
      description: allformdata.description,
      coursePrice: allformdata.coursePrice,
      taught: allformdata.taught,
      totalDuration: allformdata.totalDuration,
      basicInfo: {
        language: allformdata.basicInfo.language,
        level: allformdata.basicInfo.level,
        category: allformdata.basicInfo.category,
      },
      learnPointArray: allformdata.learnPointArray.map((item) => item),
      courseIncludesArray: allformdata.courseIncludesArray.map((item) => item),
      requirementsArray: allformdata.requirementsArray.map((item) => item),
      completeDescription: allformdata.completeDescription,
      instructorName: allformdata.instructorName,
      instructorProfile: allformdata.instructorProfile,
      imagePreview: allformdata.imageprevUrl,
      videoPreview: allformdata.videoprevUrl,
      sections: allSections.map((section) => ({
        id: section.id,
        sectionNumber: section.sectionNumber,
        content: section.content,
        lectures: section.lectures.map((lecture) => ({
          id: lecture.id,
          content: lecture.content,
          lectureNumber: lecture.lectureNumber,
          video: {
            videoName: lecture.video.videoName,
            videoUrl: lecture.video.videoUrl,
            duration: lecture.video.duration,
          },
          document: {
            docName: lecture.document.docName,
            docUrl: lecture.document.docUrl,
          },
        })),
      })),
    },
  };

  const handleUploadCourse = () => {
    if (courseUpdateId) {
      const updateUrl =
        role === "publisher"
          ? `https://courses.ir4u.info/api/publisher/update-course/${courseUpdateId}`
          : `https://courses.ir4u.info/api/course/update/${courseUpdateId}`;

      if (uploadingProgress === 100 && processedVideo === 100) {
        axios
          .put(updateUrl, formattedFormData)
          .then((res) => {
            if (isPublisher) {
              Swal.fire({
                title: "Course updated and sent for Review",
                text: "Your course has been updated and sent for review. You will be notified once it is approved.",
                icon: "success",
              });
            } else {
              Swal.fire({
                title: "Course Updated",
                text: "Your course has been successfully updated",
                icon: "success",
              });
            }
          })
          .catch((error) => {
            setIsError(true);
            Swal.fire({
              title: "Error",
              text: "Something went wrong while updating the course.",
              icon: "error",
            });
          });
      }
    } else {
      const postUrl =
        role === "publisher"
          ? `https://courses.ir4u.info/api/create-pending-course`
          : `https://courses.ir4u.info/api/course/add`;

      if (uploadingProgress === 100 && processedVideo === 100) {
        axios
          .post(postUrl, formattedFormData)
          .then((response) => {
            const id = response.data.id;

            setCourseId(id);

            if (isPublisher) {
              Swal.fire({
                title: "Course Submitted for Review",
                text: "Your course has been submitted for review. You will be notified once it is approved.",
                icon: "success",
              });
            } else {
              Swal.fire({
                title: "Course Published",
                text: "Your course has been successfully published",
                icon: "success",
              });
            }
          })
          .catch((error) => {
            setIsError(true);
            Swal.fire({
              title: "Error",
              text: "Something went wrong while creating the course.",
              icon: "error",
            });
          });
      }
    }
  };

  // useEffect(() => {
  //   if (uploadingProgress === 100) {
  //     setUpdateStatus("It's done!");
  //   }
  // }, [uploadingProgress]);
  // useEffect(() => {
  //   const postUrl = "https://courses.ir4u.info/api/course/add";
  //   // const postUrl = 'http://localhost:3000/api/course/add'
  //   if (uploadingProgress === 100 && processedVideo === 100) {
  //     console.log("stated posting");
  //     //  console.log(formattedFormData)
  //     axios
  //       .post(postUrl, formattedFormData)
  //       .then((response) => {
  //         console.log("Response:", response);
  //         const id = response.data.id;
  //         console.log(id);
  //         setId(id);
  //         // navigate(`/course/${id}`);
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error);
  //       });
  //   }
  // }, [uploadingProgress, processedVideo]);

  const uploadBorderColors = {
    borderTopColor: uploadingProgress >= 10 ? "#6842ef" : "#ffff",
    borderRightColor: uploadingProgress >= 40 ? "#6842ef" : "#ffff",
    borderBottomColor: uploadingProgress >= 70 ? "#6842ef" : "#ffff",
    borderLeftColor: uploadingProgress >= 100 ? "#6842ef" : "#ffff",
    transform: "rotate(45deg)",
  };

  const processingBorderColors = {
    borderTopColor: processedVideo >= 10 ? "#6842ef" : "#ffff",
    borderRightColor: processedVideo >= 40 ? "#6842ef" : "#ffff",
    borderBottomColor: processedVideo >= 70 ? "#6842ef" : "#ffff",
    borderLeftColor: processedVideo >= 100 ? "#6842ef" : "#ffff",
    transform: "rotate(45deg)",
  };

  // useEffect(() => {
  //   if (uploadingProgress === 100 && !hasPublished) {
  //     setHasPublished(true); // Isse ensure karenge ki dobara call na ho
  //     setTimeout(() => {
  //       handleUploadCourse();
  //       // console.log("published");
  //     }, 500);
  //   }
  // }, [uploadingProgress, hasPublished]);

  // useEffect(() => {
  //   if (uploadingProgress === 100 && processedVideo === 100 && !hasPublished) {
  //     setHasPublished(true);
  //     const timer = setTimeout(() => {
  //       handleUploadCourse();
  //     }, 100);

  //     // Cleanup the timeout if component unmounts
  //     return () => clearTimeout(timer);
  //   }
  // }, [uploadingProgress, processedVideo, hasPublished, handleUploadCourse]);

  // useEffect(() => {
  //   if (uploadingProgress === 100 && processedVideo === 100) {
  //     const timer = setTimeout(() => {
  //       handleUploadCourse();
  //     }, 500);

  //     return () => clearTimeout(timer);
  //   }
  // }, [uploadingProgress, processedVideo]);

  useEffect(() => {
    if (uploadingProgress === 100 && processedVideo === 100) {
      const timer = setTimeout(() => {
        handleUploadCourse();
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [uploadingProgress, processedVideo]);

  return (
    <div>
      <Header />
      <section className="ir-course-preview-page-section ir-course-preview-loader-section">
        {uploadingProgress < 100 ? (
          <div className="ir-course-preview-loader-container">
            <p className="ir-course-preview-loader-status-text">Status</p>
            <p className="ir-course-preview-loader-converting-text">
              Uploading
            </p>
            <div
              className="ir-course-preview-loader-box"
              style={uploadBorderColors}
            >
              <p
                className="ir-course-preview-upload-status-text"
                style={{ transform: "rotate(315deg)" }}
              >
                {uploadingProgress}%
              </p>
            </div>

            <p className="ir-course-preview-loader-time-remaining-text">
              {updateStatus}
            </p>
            <div className="ir-ws-text-center ir-ws-default-btn-container">
              <button
                className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                style={
                  processedVideo !== 100 || uploadingProgress !== 100
                    ? { pointerEvents: "none" }
                    : null
                }
                disabled={processedVideo !== 100 || uploadingProgress !== 100}
              >
                <span>{isAdmin ? "Go to courses" : "Dashboard"}</span>
              </button>
            </div>
          </div>
        ) : (
          <div className="ir-course-preview-loader-container">
            <p className="ir-course-preview-loader-status-text">Status</p>
            {/* {isError && (
              <p className="ir-course-preview-loader-converting-text">Failed</p>
            )}
            {!isError && (
              <p className="ir-course-preview-loader-converting-text">
                {uploadingProgress !== 100 ? "Processing" : "uploaded"}
              </p>
            )} */}
            <p className="ir-course-preview-loader-converting-text">
              {isError
                ? "Failed"
                : uploadingProgress !== 100
                ? "Processing"
                : "Uploaded"}
            </p>
            <div
              className="ir-course-preview-loader-box"
              style={processingBorderColors}
            >
              {/* <p
                className="ir-course-preview-upload-status-text"
                style={{ transform: "rotate(315deg)" }}
              >
                {processedVideo}%
              </p> */}
              <p
                className="ir-course-preview-upload-status-text"
                style={{ transform: "rotate(315deg)" }}
              >
                {isError ? "Failed" : `${processedVideo}%`}
              </p>
            </div>
            {/* <p className="ir-course-preview-loader-time-remaining-text">
              {uploadingProgress === 100 ? "Good to GO" : "Video Processing"}
            </p> */}
            {isAdmin && !isError && (
              <p className="ir-course-preview-loader-time-remaining-text">
                {uploadingProgress !== 100 ? "Video Processing" : "Good to go"}
              </p>
            )}
            {isSecondAdmin && !isError && (
              <p className="ir-course-preview-loader-time-remaining-text">
                {uploadingProgress !== 100
                  ? "Video Processing"
                  : "Sent for review"}
              </p>
            )}
            {/* {isSecondAdmin && uploadingProgress === 100 && (
              <p className="ir-course-preview-loader-time-remaining-text text-center w-[60%]">
                To view the status of your product, please navigate to the
                dashboard and click on 'Products Status'
              </p>
            )} */}
            {isSecondAdmin && uploadingProgress === 100 && (
              <p className="ir-course-preview-loader-time-remaining-text text-center w-[60%]">
                {isError
                  ? `There was an error while ${
                      courseUpdateId ? "updating" : "creating"
                    } the course`
                  : `To view the status of your product, please navigate to the dashboard 
         and click on 'Products Status'`}
              </p>
            )}
            {/* <a
              href={isAdmin ? `/course/${courseId}` : "/publisher"}
              className="no-underline block"
            >
              <div
                className="ir-ws-text-center ir-ws-default-btn-container "
                // onClick={handleUploadCourse}
              >
                <button
                  className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                  disabled={uploadingProgress !== 100}
                >
                  <span>{isAdmin ? "Go to courses" : "Dashboard"}</span>
                </button>
              </div>
            </a> */}
            {/* <a
              href={
                isAdmin ? `/course/${courseUpdateId ?? courseId}` : "/publisher"
              }
              className="no-underline block"
            >
              <div className="ir-ws-text-center ir-ws-default-btn-container">
                <button
                  className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                  disabled={uploadingProgress !== 100}
                >
                  <span>{isAdmin ? "Go to courses" : "Dashboard"}</span>
                </button>
              </div>
            </a> */}
            <a
              href={
                isError
                  ? isAdmin
                    ? "/admin"
                    : "/publisher"
                  : isAdmin
                  ? `/course/${courseUpdateId ?? courseId}`
                  : "/publisher"
              }
              className={`no-underline block ${
                uploadingProgress !== 100
                  ? "pointer-events-none opacity-50"
                  : ""
              }`}
              onClick={(e) => {
                if (uploadingProgress !== 100) {
                  e.preventDefault();
                }
              }}
            >
              <div className="ir-ws-text-center ir-ws-default-btn-container">
                <button
                  className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                  disabled={uploadingProgress !== 100}
                >
                  <span>
                    {isError
                      ? "Dashboard"
                      : isAdmin
                      ? "Go to courses"
                      : "Dashboard"}
                  </span>
                </button>
              </div>
            </a>
          </div>
        )}
      </section>
    </div>
  );
};

export default VideoProcessingLoader;
