import React from "react";
// import './AssetRender.scss';

const MiniAssetRender = ({ data, options = { width: 80, height: 80 } }) => {
  let renderContent = null;

  // console.log("reply to:", data);

  if (data.message) {
    renderContent = <p className="chat-message">{data.message}</p>;
  }

  if (data.images || data.messageType === "image") {
    renderContent = (
      <img
        className="chat-image"
        src={data.images || data.imageUrl}
        alt="Preview"
        style={{ height: options.height, width: "auto" }}
      />
    );
  }

  if (data.videos || data.messageType === "video") {
    renderContent = (
      <div className="video-preview-reply">
        <video
          src={data.videos || data.videoUrl}
          height={options.height}
          muted
          controls={false}
          style={{ pointerEvents: "none", height: "50px", width: "50px" }}
        />
        {/* <div className="video-overlay">Video Preview</div> */}
      </div>
    );
  }

  // if (data.documents || data.messageType === 'audio') {
  //     const ext = data?.documents.split(".") || data?.documentUrl.split(".") ;
  //     const [,,, fileNameDetails, fileExtension] = ext;
  //     const fileName = fileNameDetails.split('/')[3];
  //     const truncatedFileName = data.documents.split('/').pop().slice(0, 18) + (data.documents.split('/').pop().length > 18 ? ' ..' : '');

  //     renderContent = (
  //         <div className="ir-chat-list-container-documents">
  //             <div className="doc-file-type-container">
  //                 <i className={`${fileExtension === 'pdf'
  //                     ? 'bi-filetype-pdf'
  //                     : fileExtension === 'doc'
  //                     ? 'bi-filetype-doc'
  //                     : fileExtension === 'docx'
  //                     ? 'bi-filetype-docx'
  //                     : fileExtension === 'xlsx'
  //                     ? 'bi-filetype-xlsx'
  //                     : 'bi-filetype-xls'
  //                 } doc-file-type bi`}></i>
  //             </div>
  //             <div className="ir-chat-doc">{truncatedFileName}</div>
  //             <div className="ir-chat-doc">.{fileExtension}</div>
  //             {/* <a href={data.documents} download>
  //                 <i className="bi bi-download"></i>
  //             </a> */}
  //         </div>
  //     );
  // }

  if (data.documents || data.documentUrl) {
    const filePath = data.documents || data.documentUrl; // Prioritize `data.documents`

    const getExtension = (filePath) => filePath?.split(".").pop() || "";
    const getFileName = (filePath) => filePath?.split("/").pop() || "";
    const truncateFileName = (name) =>
      name.slice(0, 18) + (name.length > 18 ? " .." : "");

    const fileExtension = getExtension(filePath);
    const fileNameDetails = getFileName(filePath);
    const truncatedFileName = truncateFileName(fileNameDetails);

    renderContent = (
      <div className="ir-chat-list-container-documents">
        <div className="doc-file-type-container">
          <i
            className={`${
              fileExtension === "pdf"
                ? "bi-filetype-pdf"
                : fileExtension === "doc"
                ? "bi-filetype-doc"
                : fileExtension === "docx"
                ? "bi-filetype-docx"
                : fileExtension === "xlsx"
                ? "bi-filetype-xlsx"
                : "bi-filetype-xls"
            } doc-file-type bi`}
          ></i>
        </div>
        <div className="ir-chat-doc">{truncatedFileName}</div>
        <div className="ir-chat-doc">.{fileExtension}</div>
      </div>
    );
  }

  if (data.audio || data.messageType === "audio") {
    renderContent = (
      <div className="ir-audio-container">
        <audio controls>
          <source src={data.audio || data.audioUrl} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </div>
    );
  }

  if (data.messageType === "question" && data.question) {
    renderContent = (
      <div className="question-preview">
        {data.question.images?.length > 0 && (
          <img
            src={data.question.images[0]}
            alt="Question Image"
            style={{ height: options.height, width: "auto" }}
          />
        )}
        <p className="question-message">
          <strong>Question:</strong> {data.question.message}
        </p>
      </div>
    );
  }

  if (data.messageType === "poll") {
    renderContent = (
      <div className="poll-preview">
        <i className="bi bi-bar-chart"></i> <span>Poll</span>
      </div>
    );
  }

  return <div className="asset-render">{renderContent}</div>;
};

export default MiniAssetRender;
