import React, { useEffect, useState } from "react";
import "./CoursesList.scss";
import { useNavigate } from "react-router-dom";
import ModalPlayer from "../../pages/Courses/CoursePreview/ModalPlayer";
import { useModalContext } from "../WebinarAdminCreateWebinarTab/ModalContext";

const CoursesList = ({ courses, coursesListRef }) => {
  const [coursesToShow, setCoursesToShow] = useState(6);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [videoSource, setVideoSource] = useState("");
  const [coursetransactions, setCoursetransactions] = useState([]);
  const [userBoughtCourses, setUserBoughtCourses] = useState([]);

  const userId = localStorage.getItem("userId");
  const isAdmin = localStorage.getItem("loggedInUsername") === "admin";

  const {
    courseTransactionDetails,
    // userId,
  } = useModalContext();

  const handleToggleVideo = (source) => {
    setVideoSource(source);
    setVideoModalOpen(!videoModalOpen);
    document.body.classList.toggle("modal-open");
  };

  function stripHtmlTags(html, maxLength) {
    let doc = new DOMParser().parseFromString(html, "text/html");
    let plainText = doc.body.innerText;
    return plainText.length > maxLength
      ? plainText.slice(0, maxLength) + "..."
      : plainText;
  }

  const navigate = useNavigate();

  const handleShowMoreCourses = () => {
    setCoursesToShow(coursesToShow + 6);
  };

  const handleShowLessCourses = () => {
    setCoursesToShow(coursesToShow - 6);
  };

  useEffect(() => {
    const fetchTransactionDetails = async () => {
      if (userId) {
        try {
          const transactionDetails = await courseTransactionDetails(userId);

          setCoursetransactions(transactionDetails);
        } catch (error) {
          return;
        }
      }
    };

    fetchTransactionDetails();
  }, [userId]);

  useEffect(() => {
    const boughtCourses = coursetransactions
      ?.filter((transaction) => transaction?.status.toLowerCase() === "success")
      ?.map((transaction) => transaction?.productID);

    setUserBoughtCourses(boughtCourses);
  }, [coursetransactions]);

  return (
    <div className="ir-cours-list-main-container" ref={coursesListRef}>
      <div className="ir-ws-banner-padding-tb ir-ws-padding-lr-132 ir-cours-inner-list-container ">
        <div className="ir-cours-list-grid-container">
          {courses?.slice(0, coursesToShow)?.map((course, index) => (
            <div className="ir-cours-list-grind-ind-container" key={index}>
              <div className="ir-cours-list-grid-ind-inner">
                <div className="ir-ws-flex ir-ws-course-list-main-box">
                  <div
                    className="ir-cours-list-grid-img-container"
                    onClick={() => {
                      if (course.formData.videoPreview !== "") {
                        handleToggleVideo(course.formData.videoPreview);
                      } else {
                        return;
                      }
                    }}
                  >
                    <img
                      className="ir-cours-list-grid-img"
                      src={course.formData.imagePreview}
                      alt="play"
                    />
                    <i className="ir-cours-list-grid-play-btn bi bi-play-circle-fill"></i>
                  </div>
                  <div className="ir-cours-list-grid-content-container">
                    <div className="ir-cours-list-grid-content ">
                      <h3>{course.formData.title}</h3>
                      <p title={stripHtmlTags(course.formData.description)}>
                        {stripHtmlTags(course.formData.description)}
                      </p>
                    </div>
                    <div className="ir-ws-flex ir-cours-list-grid-price-read-container">
                      <div className="ir-cours-list-grid-price-container">
                        {userBoughtCourses?.includes(course._id) ||
                        isAdmin ? //   View // > //   }} //     cursor: "pointer" //   style={{ //   onClick={() => navigate(`/course/${course._id}`)} //   className="ir-courses-container-readmore" // <div
                        // </div>
                        null : (
                          <div className="">
                            $ {course.formData.coursePrice}
                          </div>
                        )}
                      </div>

                      <a
                        href={`/course/${course._id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ir-courses-container-readmore"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="ir-ws-course-show-button-container">
          {coursesToShow > 6 && (
            <>
              <div
                onClick={handleShowLessCourses}
                className="ir-ws-course-homepage-show-more-text"
              >
                <p>Show Less</p>
              </div>
              <div className="ir-ws-course-homepage-show-more-text">
                <p>|</p>
              </div>
            </>
          )}
          {courses.length > coursesToShow && (
            <div
              onClick={handleShowMoreCourses}
              className="ir-ws-course-homepage-show-more-text"
            >
              <p>Show More</p>
            </div>
          )}
        </div>
      </div>
      {videoModalOpen && (
        <ModalPlayer videoSource={videoSource} onClick={handleToggleVideo} />
      )}
    </div>
  );
};

export default CoursesList;
