import React, { useState } from "react";
import "./WebinarAdminUserListTab.scss";
import DeleteUserModal from "./DeleteUserModal";
import { useModalContext } from "../WebinarAdminCreateWebinarTab/ModalContext";

import {
  addSecondaryAdmin,
  removeSecondaryAdmin,
} from "../../api/secondaryAdminApi";
import AdminConfirmModal from "../Chat/AdminConfirmModal/AdminConfirmModal";
import Swal from "sweetalert2";

const WebinarAdminUserListTab = ({
  firstname,
  lastname,
  userId,
  email,
  username,
  userInfo,
}) => {
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [isContentVisible, setIsContentVisible] = useState(false);
  // const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  // const [selectedAction, setSelectedAction] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const handleOpenModal = () => {
    setDeleteUserModal(true);
  };
  const {
    selectedAction,
    setSelectedAction,
    userListUpdate,
    setUserListUpdate,
  } = useModalContext();
  // const handlePromoteAdmin = () => {
  //   console.log("clickeddd on admin");
  //   setShowConfirmationModal(true);
  // };
  // const handleCancelPromoteAdmin = () => {
  //   setShowConfirmationModal(false);
  // };

  const handleAddAdmin = async (userId) => {
    try {
      const data = await addSecondaryAdmin(userId);
      // Refresh the user list or update the state
      setShowConfirmModal(false); // Close the modal
      setUserListUpdate(!userListUpdate);
    } catch (error) {
      // console.error("Error:", error);
      setShowConfirmModal(!userListUpdate); // Close the modal
      setUserListUpdate(null);
    }
  };
  const handleRemoveAdmin = async (userId) => {
    try {
      const data = await removeSecondaryAdmin(userId);
      // Refresh the user list or update the state
      setShowConfirmModal(false); // Close the modal
      setUserListUpdate(true);
    } catch (error) {
      // console.error("Error:", error);
      setShowConfirmModal(false); // Close the modal
      setUserListUpdate(null);
    }
  };

  const toggleContentVisibility = () => {
    setIsContentVisible(!isContentVisible);
  };
  const accordianListButton = ["Email", "Username", "Firstname", "Lastname"];
  const userData = [
    {
      email: email,
      username: username,
      firstname: firstname,
      lastname: lastname,
      userId: userId,
    },
  ];

  // Make a user a secondary admin
  // const handleAddAdmin = async () => {
  //   try {
  //     const data = await addSecondaryAdmin(userId);
  //     setShowConfirmationModal(false);
  //     console.log("User added as secondary admin:", data);
  //   } catch (error) {
  //     console.error("Error:", error);
  //     setShowConfirmationModal(false);
  //   }
  // };

  // Remove a secondary admin
  // const handleRemoveAdmin = async () => {
  //   try {
  //     const data = await removeSecondaryAdmin(userId);
  //     console.log("User removed from secondary admin role:", data);
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };
  const handleConfirm = () => {
    if (selectedAction === "makeAdmin") {
      handleAddAdmin(selectedUserId);
    } else if (selectedAction === "removeAdmin") {
      handleRemoveAdmin(selectedUserId);
    }
  };

  // Handle role change cancellation
  const handleCancel = () => {
    setShowConfirmModal(false);
  };

  return (
    <div className={`ir-ws-webinar-list-tab `}>
      <div className="ir-ws-admin-webinar-content">
        <div className="ir-ws-admin-webinar-title-container">
          <p className="ir-ws-webinar-title-text">{username}</p>
        </div>
        <div className="ir-ws-admin-list-button-container">
          {/* <button
            className="ir-ws-webinar-actions-button"
            disabled={userInfo.role.includes("publisher")}
       
            onClick={handlePromoteAdmin}
          >
            Promote
          </button> */}
          {/* <button
            className={`ir-ws-webinar-actions-button ${
              userInfo.role.includes("publisher")
                ? "!cursor-not-allowed opacity-50 pointer-events-none"
                : ""
            }`}
            onClick={handlePromoteAdmin}
            disabled={userInfo.role.includes("publisher")}
          >
            {userInfo.role.includes("publisher") ? "Promoted" : "Promote"}
          </button> */}
          <div className="bg-[#CDBFFF] mr-[8px] xl:mr-[10px] flex items-center  rounded-[8px] text-[#fff] text-[10px] lg:text-[8px] xl:text-[10px] 3xl:text-[12px]">
            <div
              className={` cursor-pointer flex items-center justify-center lg:w-[65px] h-[100%] xl:w-[80px] 3xl:w-[90px] text-center rounded-[8px] ${
                userInfo.role.includes("publisher") ? "bg-[#6842EF]" : ""
              }`}
              onClick={() => {
                if (userInfo?.role.includes("publisher")) {
                  Swal.fire({
                    icon: "info",
                    title: "Already an Admin",
                    text: "User is already an admin.",
                  });
                } else {
                  setSelectedAction("makeAdmin");
                  setSelectedUserId(userInfo.id);
                  setShowConfirmModal(true);
                }
              }}
            >
              2nd Admin
            </div>

            <div
              className={` cursor-pointer lg:w-[65px] flex items-center justify-center h-[100%] xl:w-[80px] 3xl:w-[90px] text-center rounded-[8px] ${
                userInfo.role.includes("user") &&
                !userInfo.role.includes("publisher")
                  ? "bg-[#6842EF]"
                  : ""
              }`}
              onClick={() => {
                if (
                  userInfo?.role.length === 1 &&
                  userInfo?.role.includes("user")
                ) {
                  Swal.fire({
                    icon: "info",
                    title: "Already a User",
                    text: "User is already a user.",
                  });
                } else {
                  setSelectedAction("removeAdmin");
                  setSelectedUserId(userInfo.id);
                  setShowConfirmModal(true);
                }
              }}
            >
              User
            </div>
          </div>
          <button
            className="ir-ws-webinar-actions-button"
            onClick={handleOpenModal}
          >
            Delete User
          </button>
          <button
            className={`ir-ws-webinar-transaction-button ir-ws-webinar-actions-button flex items-center gap-[4px] ${
              isContentVisible ? "ir-ws-webinar-transaction-button-active" : ""
            }`}
            onClick={toggleContentVisibility}
          >
            User Details
            <i className="bi bi-chevron-down"></i>
          </button>
        </div>
      </div>
      {isContentVisible && (
        <div>
          <ul className="ir-ws-webinar-list-accordian-tab ir-ws-webinar-admin-user-list">
            {accordianListButton.map((item, index) => (
              <li className="ir-ws-list-item-box" key={index}>
                {item}
              </li>
            ))}
          </ul>
          <div className="ir-ws-webinar-admin-userlist-detail">
            {userData.map((item, index) => (
              <ul className="ir-ws-webinar-list-data " key={index}>
                <li>{item.email}</li>
                <li>{item.username}</li>
                <li>{item.firstname}</li>
                <li>{item.lastname}</li>
              </ul>
            ))}
          </div>
        </div>
      )}
      {deleteUserModal && (
        <DeleteUserModal
          setDeleteUserModal={setDeleteUserModal}
          userId={userId}
          username={username}
        />
      )}

      {/* {showConfirmationModal && (
        <AdminConfirmModal
          onConfirm={handleAddAdmin}
          onCancel={handleCancelPromoteAdmin}
          title="Are you sure you want to promote?"
        />
      )} */}

      {showConfirmModal && (
        <AdminConfirmModal
          title={"Are you sure you want to change the role?"}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default WebinarAdminUserListTab;
