import React, { useEffect, useState } from "react";
import AdminCardComponent from "../AdminComponent/AdminCardComponent";
// import caseIcon from "../../../assets/images/case-icon.png";
// import courseIcon from "../../../assets/images/course-icon.png";
import caseIcon from "../../../assets/images/case-ina.png";
import courseIcon from "../../../assets/images/course-ina.png";
import webinarIcon from "../../../assets/images/webinar-ina.png";
import chatIcon from "../../../assets/images/chat-ina.png";

import AdminDataComponent from "../AdminComponent/AdminDataComponent";
import AdminProductComponent from "../AdminComponent/AdminProductComponent";
import { useModalContext } from "../../WebinarAdminCreateWebinarTab/ModalContext";
import axios from "../../../api/axios";
import useFetchProducts from "../../../hooks/useFetchAllProducts";

const DashboardPanel = () => {
  const { courses, cases, webinarData } = useModalContext();
  const [chatGroups, setChatGroups] = useState(null);

  const { allProducts } = useFetchProducts();
  // const activeWebinar = webinarData?.filter(
  //   (item) => item.status !== "completed"
  // )?.length;

  const activeWebinar = Array.isArray(allProducts.webinars)
    ? allProducts?.webinars?.filter((item) => item.status !== "completed")
        .length
    : 0;

  const isAdmin = localStorage.getItem("loggedInUsername") === "admin";
  const userProfile = JSON.parse(localStorage.getItem("userProfile") || "[]");
  const isSecondAdmin = userProfile.includes("publisher");

  // console.log("total length courses", allProducts.courses.length);
  // console.log("total length cases", allProducts.cases.length);
  // console.log("total length webinars", allProducts.webinars.length);
  // console.log("total length chats", allProducts.chats.length);

  const getChatRooms = async () => {
    const response = await axios({
      url: "chat/group",
      method: "get",
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
      },
    });
    let chatData = response?.data?.data || [];
    setChatGroups(chatData);
  };

  useEffect(() => {
    getChatRooms();
  }, []);

  return (
    <div className="flex flex-col gap-[30px] lg:gap-[8px] xl:gap-[12px] 3xl:gap-[20px] h-[100%]  ">
      <div className="flex items-start justify-between pt-[18px] lg:pt-[8px] xl:pt-[12px] 3xl:pt-[20px] w-[100%] lg:gap-[8px] gap-[20px] xl:gap-[12px] 3xl:gap-[20px] h-[18%]   xl:h-[18%] ">
        <AdminCardComponent
          imgSrc={chatIcon}
          type={"Chat Cases"}
          // total={chatGroups?.length ? chatGroups.length : "0"}
          total={allProducts.chats.length}
          active={
            allProducts.chats?.filter((g) => g?.status === "active")?.length ||
            0
          }
        />
        <AdminCardComponent
          imgSrc={courseIcon}
          type={"Courses"}
          // total={courses?.length ? courses.length : "0"}
          total={allProducts.courses.length}
          // active={courses?.length ? courses.length : "0"}
          active={allProducts.courses.length}
        />
        <AdminCardComponent
          imgSrc={caseIcon}
          type={"Cases"}
          // total={cases?.length ? cases.length : "0"}
          total={allProducts.cases.length}
          active={allProducts.cases.length}
        />
        <AdminCardComponent
          imgSrc={webinarIcon}
          type={"Webinar"}
          // total={webinarData?.length ? webinarData.length : "0"}
          total={allProducts.webinars.length}
          // active={activeWebinar !== undefined ? activeWebinar : "0"}
          active={activeWebinar !== undefined ? activeWebinar : "0"}
        />
      </div>
      <div className="flex  gap-[30px] lg:gap-[10px] xl:gap-[12px] 3xl:gap-[20px] lg:h-[37%] xl:h-[37%] 3xl:h-[34%]">
        <AdminDataComponent
          type="Live Products"
          title="Product Name"
          data="Type"
          status="Status"
          dataInfo="Sales Info"
        />
        <AdminProductComponent type="Create" />
      </div>
      <div className="flex  gap-[10px] lg:gap-[8px] xl:gap-[12px] 3xl:gap-[20px] ir-ad-pn-last-cont">
        <AdminProductComponent type="Sales" />
        {isSecondAdmin && (
          <AdminDataComponent
            type="Under Review Product"
            title="Product Name"
            status="Status"
            data="Type"
            // dataInfo="Mail ID"
          />
        )}
        {isAdmin && (
          <AdminDataComponent
            type="Users"
            title="User ID"
            status="Role"
            data="Name"
            dataInfo="Mail ID"
          />
        )}
      </div>
    </div>
  );
};

export default DashboardPanel;
