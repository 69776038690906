import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { signInApi1 } from "../../redux/authSlice";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "./SignIn.scss";
import axios from "./../../api/axios.js";
import { Link } from "react-router-dom";
import { useModalContext } from "../../components/WebinarAdminCreateWebinarTab/ModalContext";
import { saveFcmToken } from "../../firebase/notifications.js";
import { requestNotificationPermission } from "../../firebase/firebase.js";

const SignIn = (props) => {
  const dispatch = useDispatch();
  const [isFromAdmin, seIsFromAdmin] = useState(false);
  //   const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  //   const [email, setEmail] = useState("");
  const [emailOrUsername, setEmailOrUsername] = useState("");
  // const [pathname, setPathname] = useState("");
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [openCredentialModal, setOpenCredentialModal] = useState(false);
  const navigate = useNavigate();
  const Admin = localStorage.getItem("loggedInUsername") === "admin";
  const [isChatRoom, setIsChatRoom] = useState(false);
  const referer = document.referrer;

  // const isFromChatRoom = referer.includes("/chat-room");
  const isInternalReferrer = referer.includes(window.location.origin);
  const isFromSignUp = referer.includes("/signup");

  const location = useLocation();
  const { pathname } = location;
  const { userData, setUserData } = useModalContext();

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwtTokenApi1");
    if (jwtToken) {
      navigate("/");
    }
  }, [navigate]);

  const userProfileDetails = async () => {
    try {
      const response = await axios.get(
        `https://backend.ir4u.info/api/v1/user/profile`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
          },
        }
      );

      setUserData(response.data);

      localStorage.setItem("loggedInUsername", response.data?.username);
      localStorage.setItem("userId", response.data?.id);

      const prevURL = localStorage.getItem("prevURL");
      const isAdmin = localStorage.getItem("loggedInUsername") === "admin";

      if (response?.data) {
        localStorage.setItem("userProfile", JSON.stringify(response.data.role));
      }

      if (response.data?.isEmailVerified) {
        if (prevURL && !isAdmin) {
          navigate(prevURL);
          localStorage.removeItem("prevURL");
          const fcmToken = await requestNotificationPermission();
          if (fcmToken) {
            await saveFcmToken(fcmToken);
          }
        } else if (isChatRoom) {
          navigate("/chat-room");
          window.location.reload();
          const fcmToken = await requestNotificationPermission();
          if (fcmToken) {
            await saveFcmToken(fcmToken);
          }
        } else {
          navigate("/");
          const fcmToken = await requestNotificationPermission();
          if (fcmToken) {
            await saveFcmToken(fcmToken);
          }
        }
      } else {
        if (response.data.username !== "admin") {
          Swal.fire("", "Your mail id is not verified please verify");
        }
        navigate("/");
        const fcmToken = await requestNotificationPermission();
        if (fcmToken) {
          await saveFcmToken(fcmToken);
        }
      }
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    // Access the "isAdminRedirect" prop in useEffect
    const isAdminRedirect = props.isAdminRedirect;
    const isChatRoomRedirect = props.isChatRoomRedirect;
    if (isAdminRedirect) {
      // Handle logic specific to when redirected from the admin page

      seIsFromAdmin(true);
    }
    if (isChatRoomRedirect) {
      setIsChatRoom(true);
    }
  }, []);

  const onChangeEmailOrUsername = (e) => {
    const input = e.target.value;
    setEmailOrUsername(input);
    validateForm(input, password);
  };

  const onChangePassword = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validateForm(emailOrUsername, newPassword);
  };

  const validateForm = (identifier, newPassword) => {
    const areAllFieldsFilled = identifier !== "" && newPassword !== "";
    // Update form validity state if needed
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoginLoading(true);
    try {
      const formData = new FormData();

      let isEmail = emailOrUsername.includes("@");
      if (isEmail) {
        formData.append("email", emailOrUsername);
      } else {
        formData.append("username", emailOrUsername.toLowerCase());
      }

      formData.append("password", password);

      const [api1Response] = await Promise.all([
        dispatch(signInApi1(formData)),
      ]);

      // ✅ Extract status code & error message
      const statusCode = api1Response.payload?.status;
      const errorMessage = api1Response.payload?.message; // Backend ka error message

      if (statusCode === 403 && errorMessage === "user not found") {
        Swal.fire({
          icon: "",
          title: "Login Failed",
          text: "User not found. Please check your credentials.",
        });
      } else if (statusCode === 403) {
        // ✅ Agar 403 error aaya but "user not found" nahi hai, toh modal kholega
        setOpenCredentialModal(true);
      } else if (api1Response.payload?.headers?.authorization) {
        // ✅ Successful login case
        const jwtTokenApi1 =
          api1Response.payload.headers["authorization"].split("Bearer ")[1];

        localStorage.setItem("jwtTokenApi1", jwtTokenApi1);
        localStorage.setItem("isLoggedIn", true);

        if (isEmail) {
          localStorage.setItem("loggedInEmail", emailOrUsername);
        } else {
          localStorage.setItem(
            "loggedInUsername",
            emailOrUsername.toLowerCase()
          );
        }

        // Fetch user profile details and handle navigation
        await userProfileDetails();
      } else {
        setOpenCredentialModal(true);
      }
    } catch (error) {
      setIsLoginLoading(false);
      Swal.fire("", "Something went wrong. Please try again.", "error");
    } finally {
      setIsLoginLoading(false);
    }
  };

  return (
    <div className="ir-ws-sign-in-main-container">
      <div className="ir-ws-signin-inner-container ir-ws-sign-inner-container md:!max-w-[350px] xl:!max-w-[400px]  3xl:!max-w-[500px] ">
        <div className="ir-ws-sign-in-content-container">
          <div className="ir-ws-signin-logo-container">
            <div className="ir-ws-text-center flex items-center justify-center ir-ws-signin-logo-inner-container">
              <a href="/">
                <img
                  className="ir-ws-signin-logo-img w-[60px] lg:w-[60px] xl:w-[70px] 3xl:w-[100px]"
                  src={require("../../assets/images/ir4u2.png")}
                  alt="logo"
                />
              </a>
            </div>
          </div>
          <form onSubmit={handleLogin}>
            <div className="ir-ws-signup-content-inner-container">
              <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title !text-[20px]  lg:!text-[24px] xl:!text-[26px] 3xl:!text-[30px]">
                Get Started
              </h3>
            </div>

            <div className="ir-ws-signup-form-container !mt-[15px] lg:!mt-[15px] xl:!mt-[25px] 3xl:!mt-[30px]">
              <div className="ir-ws-signup-content-group !my-[15px] lg:!my-[15px] xl:!my-[25px] 3xl:!my-[35px]">
                <input
                  className="ir-ws-signup-input-field !text-[12px] lg:!text-[12px] xl:!text-[14px] 3xl:!text-[16px]"
                  type="text"
                  required="required"
                  autoComplete="off"
                  value={emailOrUsername}
                  name="emailOrUsername"
                  onChange={onChangeEmailOrUsername}
                />
                <label className="ir-ws-signup-label !text-[10px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px]">
                  Email or Username
                </label>
                <span className="ir-ws-signup-highlight"></span>
                <span className="ir-ws-signup-bar"></span>
              </div>
              <div className="ir-ws-signup-content-group !my-[15px] lg:!my-[15px] xl:!my-[25px] 3xl:!my-[35px]">
                <input
                  className="ir-ws-signup-input-field !text-[12px] lg:!text-[12px] xl:!text-[14px] 3xl:!text-[16px]"
                  type="password"
                  required="required"
                  autoComplete="off"
                  value={password}
                  onChange={onChangePassword}
                />
                <label className="ir-ws-signup-label !text-[10px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px]">
                  Password
                </label>
                <span className="ir-ws-signup-highlight"></span>
                <span className="ir-ws-signup-bar"></span>
              </div>
              <div className="ir-ws-sign-footer-lin-container !mt-[0px]  ir-forgot-password-link my-[15px] xl:my-[25px] 3xl:my-[30px]">
                <p
                  className="ir-ws-app-color !mt-[0px]"
                  onClick={() => navigate("/forgot-password")}
                >
                  Forgot Password?
                </p>
                <span className="mx-[4px]">/</span>
                <p
                  className="ir-ws-app-color !mt-[0px]"
                  onClick={() => navigate("/forgot-username")}
                >
                  Forgot Username?
                </p>
              </div>
              <div
                className={`ir-ws-text-center ir-ws-default-btn-container ir-ws-register-button-container`}
              >
                <button
                  className={`!w-[170px] !p-[0px] !h-[36px] !text-[10px] lg:!h-[36px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px] xl:!h-[40px] 3xl:!h-[50px] ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button ir-ws-sign-button`}
                  // style={{ width: "170px", height: "58px" }}
                  onClick={handleLogin}
                  disabled={isLoginLoading}
                >
                  <span>Sign In</span>
                </button>
              </div>
            </div>
          </form>
          <div className="ir-ws-signup-form-footer-container">
            <div className="ir-ws-login-social-container"></div>
            <div className="ir-ws-sign-footer-lin-container">
              <p className="ir-default-color">
                Not having an account? &nbsp;
                <b>
                  <Link to={"/signup"} className="ir-ws-app-color">
                    SignUp
                  </Link>
                </b>
              </p>
            </div>
          </div>
        </div>
      </div>
      {openCredentialModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
          <div className="relative w-[400px]  max-w-[90vw] max-h-[90vh] flex flex-col items-center bg-[#fff] rounded-[10px]">
            {/* <div
                    className="absolute close-button-pop-ui"
                    onClick={()=>setOpenCredentialModal(false)}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div> */}
            <div className="flex flex-col justify-center items-center p-[30px] gap-[10px] lg:gap-[15px] ">
              <p className="text-[20px] lg:text-[20px] xl:text-[24px] font-[500] text-center">
                Invalid Credential
              </p>
              <p className="text-center text-[12px] lg:text-[10px] xl:text-[12px] 3xl:text-[14px]">
                Please enter correct password
              </p>
              <div className=" mt-[10px] lg:mt-[10px] xl:mt-[10px] 3xl:mt-[10px] flex items-center gap-[10px] lg:gap-[15xp] xl:gap-[20px] 3xl:gap-[30px]">
                <div
                  className="text-[12px] cursor-pointer flex items-center justify-center text-[#fff] h-[40px] lg:h-[35px] xl:h-[40px] 3xl:h-[50px] lg:text-[10px] xl:text-[12px] 3xl:text-[14px] w-[150px] rounded-[10px] bg-[#6842ef]"
                  onClick={() => setOpenCredentialModal(false)}
                >
                  Ok
                </div>
                {/* <div
                  className="text-[12px] cursor-pointer flex items-center justify-center text-[#fff] h-[40px] lg:h-[35px] xl:h-[40px] 3xl:h-[50px] lg:text-[10px] xl:text-[12px] 3xl:text-[14px] w-[150px] rounded-[10px] bg-[#6842ef]"
                  onClick={() => navigate("/forgot-password")}
                >
                  Reset Password
                </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignIn;
