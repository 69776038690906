import React, { useState } from "react";
import { useModalContext } from "../../../components/WebinarAdminCreateWebinarTab/ModalContext";
import ConfirmationModal from "../AdminCreateCourseTab/ConfirmationModal";
import { useNavigate } from "react-router-dom";

const AdminCourseTrashList = () => {
  const { deletedCourses, restoreCourseFunction, secondAdminDeletedCourses } =
    useModalContext();

  const userProfile = JSON.parse(localStorage.getItem("userProfile") || "[]");
  const isSecondAdmin = userProfile.includes("publisher");

  const navigate = useNavigate();

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [courseId, setCourseId] = useState("");

  const deletedCourseList = isSecondAdmin
    ? secondAdminDeletedCourses
    : deletedCourses;

  const toggleConfirmationModal = (id) => {
    setCourseId(id);
    setOpenConfirmationModal(!openConfirmationModal);
  };
  const handleRestoreConfirmation = async () => {
    if (courseId) {
      await restoreCourseFunction(courseId);
      setCourseId("");
      setOpenConfirmationModal(false);
    }
  };
  return (
    // <div>
    //   {
    //   deletedCourseList?.map((course, index) => (
    //     <div className="ir-ws-webinar-list-tab" key={index}>
    //       <div className="ir-ws-admin-webinar-content">
    //         <div className="ir-ws-admin-webinar-title-container">
    //           {course?.formData?.imagePreview ? (
    //             <img
    //               src={course.formData.imagePreview}
    //               className="ir-ws-course-list-image rounded-[4px]"
    //               alt="course"
    //             />
    //           ) : (
    //             <div className="w-[24px] h-[24px] lg:w-[24px] lg:h-[24px] ml-[8px] lg:ml-[8px] xl:ml-[10px] 3xl:ml-[15px] rounded-[4px] bg-[#cdcdcd]"></div>
    //           )}
    //           <p className="ir-ws-webinar-title-text">
    //             {course.formData.title}
    //           </p>
    //         </div>
    //         <div className="ir-ws-admin-list-button-container">
    //           <button
    //             className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
    //             onClick={() => navigate(`/course/${course._id}`)}
    //           >
    //             View Course
    //           </button>
    //           <button
    //             className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
    //             onClick={() => toggleConfirmationModal(course._id)}
    //           >
    //             Restore
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   ))}
    //   {openConfirmationModal && (
    //     <ConfirmationModal
    //       toggleModal={toggleConfirmationModal}
    //       confirmationFunc={handleRestoreConfirmation}
    //       title="restore"
    //     />
    //   )}
    // </div>

    <div>
      {deletedCourseList?.length > 0 ? (
        deletedCourseList.map((course, index) => (
          <div className="ir-ws-webinar-list-tab" key={index}>
            <div className="ir-ws-admin-webinar-content">
              <div className="ir-ws-admin-webinar-title-container">
                {course?.formData?.imagePreview ? (
                  <img
                    src={course.formData.imagePreview}
                    className="ir-ws-course-list-image rounded-[4px]"
                    alt="course"
                  />
                ) : (
                  <div className="w-[24px] h-[24px] lg:w-[24px] lg:h-[24px] ml-[8px] lg:ml-[8px] xl:ml-[10px] 3xl:ml-[15px] rounded-[4px] bg-[#cdcdcd]"></div>
                )}
                <p className="ir-ws-webinar-title-text">
                  {course.formData.title}
                </p>
              </div>
              <div className="ir-ws-admin-list-button-container">
                <button
                  className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
                  onClick={() => navigate(`/course/${course._id}`)}
                >
                  View Course
                </button>
                <button
                  className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
                  onClick={() => toggleConfirmationModal(course._id)}
                >
                  Restore
                </button>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-center mt-[30px] text-[10px] lg:text-[10px] xl:text-[12px] 3xl:text-[14px]">
          No Trash courses found
        </div>
      )}
      {openConfirmationModal && (
        <ConfirmationModal
          toggleModal={toggleConfirmationModal}
          confirmationFunc={handleRestoreConfirmation}
          title="restore"
        />
      )}
    </div>
  );
};

export default AdminCourseTrashList;
