import React, { useEffect, useState } from "react";
import ModalPlayer from "../CoursePreview/ModalPlayer";
import axios from "axios";

const SingleCoursePrevLecture = ({
  sections,
  allformdata,
  isPaid,
  userId,
  courseId,
  isCreator,
}) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [videoSource, setVideoSource] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const [videoDurations, setVideoDurations] = useState({});
  const [totalDuration, setTotalDuration] = useState({});
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [checkedVideos, setCheckedVideos] = useState({});
  const [clickedvidoeLectureId, setClickedvideoLectureId] = useState();
  const [curseUuid, serCourseUuid] = useState(courseId);
  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const isAdmin = localStorage.getItem("loggedInUsername") === "admin";
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };
  const durationInSeconds = (formattedTime) => {
    const [minutes, seconds] = formattedTime.split(":").map(Number);
    return minutes * 60 + seconds;
  };

  const getVideoDuration = async (videoUrl) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.onloadedmetadata = () => {
        resolve(formatTime(video.duration));
      };
      video.onerror = () => {
        reject(new Error("Unable to load video"));
      };
      video.src = videoUrl;
      video.load();
    });
  };

  useEffect(() => {
    const fetchVideoDurations = async () => {
      const durations = {};
      for (const section of sections) {
        let sectionDuration = 0;
        for (const lecture of section.lectures) {
          try {
            const duration = lecture.video.duration;
            const durationParts = duration.split(":");
            const minutes = parseInt(durationParts[0]);
            const seconds = parseInt(durationParts[1]);

            // Check if duration is NaN, if so, set it to 0
            if (isNaN(minutes) || isNaN(seconds)) {
              // Set invalid duration to 0
              sectionDuration += 0;
            } else {
              const totalDurationInSeconds = minutes * 60 + seconds;
              sectionDuration += totalDurationInSeconds;
            }
          } catch (error) {
            // console.error("Error fetching video duration:", error);
            // Set invalid duration to 0
            sectionDuration += 0;
            return;
          }
        }
        durations[section.id] = formatTime(sectionDuration);
      }
      setVideoDurations(durations);
    };

    fetchVideoDurations();
  }, [sections]);
  const handleToggleVideo = (source, title, lecutreId) => {
    setClickedvideoLectureId(lecutreId);
    setVideoSource(source);
    setVideoTitle(title);
    setVideoModalOpen(!videoModalOpen);
    document.body.classList.toggle("modal-open");
  };

  const handleDownload = async (docUrl) => {
    try {
      const response = await fetch(docUrl);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = docUrl.substring(docUrl.lastIndexOf("/") + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      // console.error("Error downloading file:", error);
      return;
    }
  };

  const handleVideoEnded = (lectureId) => {
    const requestData = {
      userId: userId,
      courseId: courseId,
      lectureId: lectureId,
    };

    setCheckedVideos((prevState) => ({
      ...prevState,
      [lectureId]: true,
    }));

    axios
      .put("https://courses.ir4u.info/api/watched/update", requestData)
      .then((response) => {
        getUserCourseLectures(userId, allformdata.id);
      })
      .catch((error) => {
        // console.error("Error:", error);
        return;
      });
  };

  useEffect(() => {
    if (allformdata.id) {
      if (userId) {
        const courseId = curseUuid;
        getUserCourseLectures(userId, courseId);
      }
    }
  }, [allformdata, userId]);

  async function getUserCourseLectures(userId, courseId) {
    const url = `https://courses.ir4u.info/api/watched/user/${userId}?courseId=${courseId}`;

    if (userId) {
      try {
        const response = await fetch(url);
        const data = await response.json();

        if (response.status === 404) {
          // console.log("the user not found");
          return;
        } else {
          const allLectures = data.lectures;

          if (allLectures) {
            allLectures.forEach((lecture) => {
              const lectureId = lecture.lectureId;

              setCheckedVideos((prevState) => ({
                ...prevState,
                [lectureId]: true,
              }));
            });
          }
        }

        // return allLectures;
      } catch (error) {
        // throw new Error("Failed to fetch data");
        return;
      }
    }
  }

  return (
    <div>
      <section className="ir-course-accordion-section">
        <div className="ir-course-accordian-container ir-ws-padding-lr-132">
          <p className="ir-courses-course-content-text">Courses Content</p>
          <p
            className="ir-courses-course-expand-sections-text"
            onClick={() => setActiveIndex(activeIndex === null ? -1 : null)}
          >
            {activeIndex === null
              ? "Expand all sections"
              : "Collapse all sections"}
          </p>
          {sections?.map((section, index) => (
            <div key={section.id} className="ir-ws-accordion-container">
              <div
                className="ir-ws-accordion-header"
                onClick={() => handleClick(index)}
              >
                <div className="ir-ws-section-accordian-number">
                  {activeIndex !== index ? (
                    <i className="bi bi-chevron-down text-[22px] leading-[0px]"></i>
                  ) : (
                    <i className="bi bi-chevron-up text-[22px] leading-[0px]"></i>
                  )}

                  <p className="ir-course-accordion-section-text">
                    Section - {index + 1} {section.content}
                  </p>
                </div>
                <div className="ir-course-section-accordian-duration">
                  {section.lectures.length} Lectures -{" "}
                  {videoDurations[section.id] || "Loading..."}
                </div>
              </div>
              {(activeIndex === index || activeIndex === -1) && (
                <>
                  {section?.lectures?.map((lecture, index) => (
                    <div
                      className="ir-course-accordian-active-container"
                      key={lecture.id}
                    >
                      {lecture?.video?.videoUrl && (
                        <div className="ir-ws-accordian-content">
                          <div className="ir-ws-lecture-checkbox-name-container">
                            <div class="checkbox" style={{ marginLeft: "0px" }}>
                              <input
                                type="checkbox"
                                id={`videoCheckbox${lecture.id}`}
                                className="checkbox__input"
                                checked={checkedVideos[lecture.id] || false}
                              />
                              <label
                                htmlFor={`videoCheckbox${lecture.id}`}
                                className="checkbox__label"
                              >
                                {/* Checkbox 1 */}
                              </label>
                            </div>
                            <div className="lecture-name">
                              {" "}
                              {index + 1} Lectures -{lecture.content}{" "}
                            </div>
                            {isPaid || isAdmin || isCreator ? (
                              <svg
                                className="feather feather-play"
                                fill="none"
                                height="24px"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                viewBox="0 0 24 24"
                                width="24px"
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={() =>
                                  handleToggleVideo(
                                    lecture.video.videoUrl,
                                    lecture.content,
                                    lecture.id
                                  )
                                }
                              >
                                <polygon points="5 3 19 12 5 21 5 3" />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="feather feather-play"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                                />
                              </svg>
                            )}
                          </div>
                          <div className="ir-ws-lecture-duration">
                            {" "}
                            {lecture?.video.duration || "00"}
                          </div>
                        </div>
                      )}

                      {lecture?.document?.docName && (
                        <div className="ir-ws-accordian-content">
                          <div className="ir-ws-lecture-checkbox-name-container">
                            <div
                              className="checkbox"
                              style={{ marginLeft: "0px" }}
                            >
                              <input
                                type="checkbox"
                                id={`documentCheckbox${lecture.id}`}
                                className="checkbox__input"
                              />
                              <label
                                htmlFor={`documentCheckbox${lecture.id}`}
                                className="checkbox__label"
                              >
                                {/* Checkbox 1 */}
                              </label>
                            </div>
                            <div className="lecture-name">
                              {lecture.document.docName}
                            </div>
                            {isPaid || isAdmin || isCreator ? (
                              <i className="bi bi-file-earmark course-file"></i>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="feather feather-play"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                                />
                              </svg>
                            )}
                          </div>
                          <div className="ir-ws-lecture-duration">
                            {/* {" "}
                          {videoDurations[lecture.id] || "00:00"} */}
                            {isPaid || isAdmin || isCreator ? (
                              <a
                                href={lecture.document.docUrl}
                                onClick={() =>
                                  handleDownload(lecture.document.docUrl)
                                }
                                title="Download"
                              >
                                <i className="bi bi-download course-doc-download-icon"></i>
                              </a>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="feather feather-play"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                                />
                              </svg>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>
          ))}
        </div>
        {videoModalOpen && (
          <ModalPlayer
            videoSource={videoSource}
            onClick={handleToggleVideo}
            onEnd={() => handleVideoEnded(clickedvidoeLectureId)}
          />
        )}
      </section>
      <div className="ir-ws-padding-lr-132 ir-course-preview-learn-description-section">
        <div className="ir-ws-single-course-point-container">
          {allformdata?.requirementsArray.length !== 0 && (
            <div className="ir-ws-single-course-learn-point-container">
              <div>
                <p className="ir-single-learn-point-text">Requirements</p>
              </div>
              <div className="ir-ws-requirements-arrray-box">
                {allformdata?.requirementsArray?.map((point, index) => (
                  <div className="ir-single-requirement-point-box" key={index}>
                    <div className="ir-single-requirement-point-list-text">
                      <i className="bi bi-circle-fill"></i>
                      <p className="ir-ws-point-text">{point}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <section className="ir-course-requirement-description-section">
        <div className="ir-course-requirement-description-container ir-ws-padding-lr-132">
          <p className="ir-course-requirement-text">Description</p>
          <p className="ir-course-requirement-text-description ir-default-color ">
            {allformdata?.completeDescription}
          </p>
        </div>
      </section>
    </div>
  );
};

export default SingleCoursePrevLecture;
