// import React, { useState } from "react";
// // import { Cropper } from "react-cropper";
// import Cropper from "react-easy-crop";

// const ThumbnailCropModal = ({
//   cropThumbnail,
//   setCropImageModal,
//   deleteSelectedImage,
//   handleSaveCroppedImage,
//   imagePreview,
// }) => {
//   const [crop, setCrop] = useState({ x: 0, y: 0 });
//   const [zoom, setZoom] = useState(1);
//   const onCropComplete = (croppedArea, croppedAreaPixels) => {
//     try {
//       const canvas = document.createElement("canvas");
//       const image = new Image();

//       image.src = cropThumbnail;
//       image.onload = () => {
//         canvas.width = croppedAreaPixels.width;
//         canvas.height = croppedAreaPixels.height;

//         const ctx = canvas.getContext("2d");

//         // Calculate scale factors
//         const scaleX = image.naturalWidth / image.width;
//         const scaleY = image.naturalHeight / image.height;

//         // Draw cropped image
//         ctx.drawImage(
//           image,
//           croppedAreaPixels.x * scaleX,
//           croppedAreaPixels.y * scaleY,
//           croppedAreaPixels.width * scaleX,
//           croppedAreaPixels.height * scaleY,
//           0,
//           0,
//           canvas.width,
//           canvas.height
//         );

//         // Ensure imagePreview is defined and has a type property
//         console.log(imagePreview, "image preview");
//         if (imagePreview && imagePreview.type) {
//           canvas.toBlob((blob) => {
//             console.log(blob, "image preview");
//             handleSaveCroppedImage(blob);
//           }, imagePreview.type);
//         } else {
//           console.error(
//             "imagePreview is not defined or does not have a 'type' property.",
//             imagePreview
//           );
//         }
//       };
//     } catch (error) {
//       console.error("Error cropping image:", error);
//     }
//   };

//   const handleSave = (e) => {
//     e.preventDefault();
//     // setCropImageModal(false);
//   };

//   return (
//     <div className="ir-ws-position-fixed ir-ws-sign-popup-container ">
//       <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container !pt-[0px]">
//         <div className="ir-ws-signup-content-container">
//           <div
//             className="ir-ws-position-absolute ir-ws-signup-close-container"
//             onClick={deleteSelectedImage}
//           >
//             <i class="bi bi-x"></i>
//           </div>
//           {/* <div className="ir-ws-signup-content-inner-container">
//             <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
//             </h3>
//           </div> */}
//           <form className="ir-ws-signup-form-container !mt-[0px]">
//             <div>
//               <div className="ir-ws-webinar-image-crop-container">
//                 <Cropper
//                   image={cropThumbnail}
//                   crop={crop}
//                   zoom={zoom}
//                   aspect={520 / 360}
//                   cropSize={{ width: 520, height: 360 }}
//                   onCropChange={setCrop}
//                   onCropComplete={onCropComplete}
//                   onZoomChange={setZoom}
//                   minZoom={1}
//                 />
//               </div>

//               {/* <div
//                 className="ir-ws-webinar-image-editor-btn-container "
//                 style={{ marginTop: "70px" }}
//               > */}
//               {/* <div className="ir-ws-webinar-image-controls">
//                   <div
//                     className="ir-ws-zoom-button"
//                     onClick={(e) => {
//                       e.preventDefault();
//                       setZoom(zoom - 0.03);
//                     }}
//                   >
//                     <img
//                       src={require("../../../../assets/images/chat-images/magnifying-glass (1).png")}
//                       className="ir-ws-zoom-button-images"
//                       alt="zoom button"
//                     />
//                   </div>
//                   <div
//                     className="ir-ws-zoom-button"
//                     onClick={(e) => {
//                       e.preventDefault();
//                       setZoom(zoom + 0.03);
//                     }}
//                   >
//                     <img
//                       src={require("../../../../assets/images/chat-images/magnifying-glass.png")}
//                       className="ir-ws-zoom-button-images"
//                       alt="zoom button"
//                     />
//                   </div>
//                 </div> */}

//               <div className="ir-ws-text-center ir-ws-default-btn-container">
//                 <button
//                   className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button !w-[120px] !p-[0px] lg:!h-[36px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px] xl:!h-[40px] 3xl:!h-[50px]"
//                   // style={{ width: "200px", height: "50px" }}
//                   onClick={handleSave}
//                 >
//                   <span>Save</span>
//                 </button>
//               </div>
//             </div>
//             {/* </div> */}
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ThumbnailCropModal;

import React, { useState } from "react";
import "cropperjs/dist/cropper.css";
import Cropper from "react-cropper";

const ThumbnailCropModal = ({
  cropThumbnail,
  setCropImageModal,
  deleteSelectedImage,
  handleSaveCroppedImage,
  imagePreview,
}) => {
  const [cropper, setCropper] = useState(null);

  // const handleSave = (e) => {
  //   e.preventDefault();
  //   if (cropper) {
  //     const canvas = cropper.getCroppedCanvas();
  //     if (canvas) {
  //       canvas.toBlob((blob) => {
  //         if (blob) {
  //           console.log(blob, "Cropped Image Blob"); // Debugging ke liye
  //           handleSaveCroppedImage(blob);
  //           setCropImageModal(false);
  //         } else {
  //           console.error("Blob generation failed!");
  //         }
  //       }, "image/png"); // Yahan "image/png" type set kiya h
  //     } else {
  //       console.error("Cropped canvas not available");
  //     }
  //   }
  // };
  const handleSave = (e) => {
    e.preventDefault();
    if (cropper) {
      const canvas = cropper.getCroppedCanvas();
      // console.log("Clicked");
      if (canvas) {
        // Base64 image ko console me print karein
        const base64Image = canvas.toDataURL("image/png");
        // console.log(base64Image, "Base64 Cropped Image");

        canvas.toBlob((blob) => {
          if (blob) {
            // console.log(blob, "Cropped Image Blob"); // Debugging ke liye
            handleSaveCroppedImage(blob);
            setCropImageModal(false);
          } else {
            console.error("Blob generation failed!");
          }
        }, "image/png");
      } else {
        console.error("Cropped canvas not available");
      }
    }
  };

  return (
    <div className="ir-ws-position-fixed ir-ws-sign-popup-container ">
      <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container !p-[15px]">
        <div className="ir-ws-signup-content-container">
          <div
            className="ir-ws-position-absolute ir-ws-signup-close-container"
            onClick={deleteSelectedImage}
          >
            <i className="bi bi-x"></i>
          </div>
          <form className="ir-ws-signup-form-container !mt-[0px]">
            <div>
              <div className="ir-ws-webinar-image-crop-container">
                {/* <Cropper
                  src={cropThumbnail}
                  style={{ height: 400, width: "100%" }}
                  initialAspectRatio={16 / 9}
                  guides={false}
                  cropBoxResizable={false}
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  background={false}
                /> */}
                <Cropper
                  src={cropThumbnail}
                  style={{ height: "100%", width: "100%" }}
                  initialAspectRatio={16 / 9} // Set initial aspect ratio
                  aspectRatio={16 / 9} // Force 16:9 aspect ratio
                  guides={false}
                  cropBoxResizable={false} // Crop box resize disable (only width/height scale allowed)
                  viewMode={1} // Restrict cropping area to fit inside the image
                  dragMode="none" // Disable dragging the crop box
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  background={false}
                />
              </div>
              <div className="ir-ws-text-center ir-ws-default-btn-container">
                <button
                  className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button !w-[120px] !p-[0px] lg:!h-[36px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px] xl:!h-[40px] 3xl:!h-[50px]"
                  onClick={handleSave}
                >
                  <span>Save</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ThumbnailCropModal;
