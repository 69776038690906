import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="ir-ws-hme-footer-main-container">
      <div className="ir-ws-padding-tb-50 ir-ws-padding-lr-132 ir-ws-flex ir-ws-justify-space-btw ir-ws-footer-main-inner-container">
        <div className="ir-ws-footer-division-main-container">
          <h4 className="ir-ws-heading-default-color ir-ws-footer-division-title-container">
            Company Info
          </h4>
          <ul className="ir-ws-footer-list-container">
            <li className="ir-ws-footer-list-item">
              <Link
                to="/aboutus"
                className="ir-default-color ir-ws-footer-list-link"
              >
                About Us
              </Link>
            </li>
            <li className="ir-ws-footer-list-item">
              <Link
                to="/privacy-policy"
                className="ir-default-color ir-ws-footer-list-link"
              >
                Privacy & Policy
              </Link>
            </li>
            <li className="ir-ws-footer-list-item">
              <Link
                to="/terms-conditions"
                className="ir-default-color ir-ws-footer-list-link"
              >
                Terms & Conditions
              </Link>
            </li>
          </ul>
        </div>
        <div className="ir-ws-footer-division-main-container">
          <h4 className="ir-ws-heading-default-color ir-ws-footer-division-title-container">
            Get In Touch
          </h4>
          <ul className="ir-ws-footer-list-container">
            <li className="ir-ws-footer-list-item">
              <a
                href="tel:9051290512"
                className="ir-default-color ir-ws-footer-list-link"
              >
                <i className="bi bi-telephone"></i>
                +1 216 236 4624
              </a>
            </li>
            <li className="ir-ws-footer-list-item">
              <span className="ir-default-color ir-ws-footer-list-link">
                <i className="bi bi-geo-alt-fill"></i>
                Cleveland, OH
              </span>
            </li>
            <li className="ir-ws-footer-list-item">
              <a
                href="mailto:someone@example.com"
                className="ir-default-color ir-ws-footer-list-link"
              >
                <i className="bi bi-envelope"></i>
                info@ir4u.info
              </a>
            </li>
          </ul>
        </div>
        <div className="ir-footer-man-title-container">
          <img
            src={require("../../assets/images/ir4u2.png")}
            alt="footer logo"
          />
          <p>Interventional Radiology 4U</p>
          <p className="ir-ws-footer-tagline">
            Minimal Access - Maximum Result
          </p>
        </div>
      </div>
      <div className="ir-ws-width-100 ir-ws-gray-bg ir-ws-footer-division-main-container ir-ws-footer-social">
        {/* <h4 className="ir-ws-heading-default-color ir-ws-footer-division-title-container">Connect via social</h4> */}
        <ul className="ir-ws-list-style-none ir-ws-footer-list-container">
          <li className="animate__animated animate__fadeInUp ir-ws-footer-list-item">
            <a
              target="blank"
              href="https://www.facebook.com/profile.php?id=100095105780088"
              className="ir-default-color ir-ws-footer-list-link pointer-events-none"
            >
              <i className="bi bi-facebook"></i>
            </a>
          </li>
          <li className="animate__animated animate__fadeInDown ir-ws-footer-list-item">
            <a
              target="blank"
              href="https://instagram.com/ir4u___?igshid=MzRlODBiNWFlZA=="
              className="ir-default-color ir-ws-footer-list-link pointer-events-none"
            >
              <i className="bi bi-instagram"></i>
            </a>
          </li>
          {/* <li  className="animate__animated animate__fadeInUp ir-ws-footer-list-item">
                        <a  href="/" className="ir-default-color ir-ws-footer-list-link">
                            <i className="bi bi-twitter"></i>
                        </a>
                    </li> */}
          <li className="animate__animated animate__fadeInDown ir-ws-footer-list-item">
            <a
              target="blank"
              href="https://www.linkedin.com/in/ir4u-0a9238284"
              className="ir-default-color ir-ws-footer-list-link pointer-events-none"
            >
              <i className="bi bi-linkedin"></i>
            </a>
          </li>
          {/* <li  className="animate__animated animate__fadeInUp ir-ws-footer-list-item">
                        <a  href="/" className="ir-default-color ir-ws-footer-list-link">
                            <i className="bi bi-google"></i>
                        </a>
                    </li> */}
        </ul>
      </div>
    </div>
  );
};

export default Footer;
