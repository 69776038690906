import React, { useEffect, useState } from "react";
import Footer from "../../../components/Footer/Footer";
import "./CoursePreviewPage.scss";
import Header from "../../../components/Header/Header";
import { useModalContext } from "../../../components/WebinarAdminCreateWebinarTab/ModalContext";
import { useDispatch, useSelector } from "react-redux";
import VideoProcessingLoader from "./VideoProcessingLoader/VideoProcessingLoader";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../AdminCreateCourseTab/ConfirmationModal";
import CoursePrevLecture from "./CoursePrevLecture";
import {
  updatelectureVideo,
  resetFormData,
  updateLectureDocUrl,
  updateVideoPreview,
  updateImagePreview,
  updateLectureVideoDuration,
  updateTotalDuration,
} from "../Store/CourseSlice";
import StarRating from "./StarRating";
import ModalPlayer from "./ModalPlayer";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

const CoursePreviewPage = () => {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const { handleCreateCourse, handleSaveAndContinue, setCurrentPage } =
    useModalContext();
  const handleGoBackCoursePage = () => {
    setCurrentPage(1);
  };

  const allSections = useSelector((state) => state.course.sections);

  const allformdata = useSelector((state) => state.course.formData);

  var fileURL;
  if (
    allformdata.videoPreview &&
    typeof allformdata.videoPreview !== "string"
  ) {
    fileURL = URL.createObjectURL(allformdata.videoPreview);
  } else {
    fileURL = allformdata.videoPreview;
  }

  const dispatch = useDispatch();
  const [videoSource, setVideoSource] = useState("");
  const [shareModal, setShareModal] = useState(false);
  const [favourite, setFavourite] = useState(false);
  const [tooltipText, setTooltipText] = useState(false);
  const [videoTitle, setVideoTitle] = useState("");
  const [videoModalOpen, setVideoModalOpen] = useState(false);

  const userProfile = JSON.parse(localStorage.getItem("userProfile"));

  let role;
  if (userProfile) {
    if (userProfile?.includes("admin")) {
      role = "admin";
    } else if (userProfile?.includes("publisher")) {
      role = "publisher";
    }
  }

  const isPublisher = role === "publisher";

  // const [videoThumbnail, setVideoThumbnail] = useState("");
  var videoThumbnail;
  if (typeof allformdata.imagePreview !== "string") {
    videoThumbnail = URL.createObjectURL(allformdata.imagePreview);
  } else if (allformdata.imagePreview) {
    videoThumbnail = allformdata.imagePreview;
  }
  const navigate = useNavigate();
  const handleToggleVideo = (source, title) => {
    setVideoSource(source);
    setVideoTitle(title);
    setVideoModalOpen(!videoModalOpen);
    document.body.classList.toggle("modal-open");
  };
  const handlefavourite = () => {
    setFavourite(!favourite);
  };
  const toggleConfirmationModal = () => {
    setOpenConfirmationModal(!openConfirmationModal);
  };
  const [totalVideoDuration, setTotalVideoDuration] = useState("Loading...");
  const [uploadProgress, setUploadedprogress] = useState(0);
  const [processedVideo, setProcessedvideo] = useState(0);
  const isAdmin = localStorage.getItem("loggedInUsername") === "admin";
  useEffect(() => {
    const calculateTotalVideoDuration = async () => {
      let totalDurationInSeconds = 0;

      for (const section of allSections) {
        for (const lecture of section.lectures) {
          if (lecture.video && lecture.video.videoUrl) {
            try {
              const duration = await getVideoDuration(lecture.video.videoUrl);
              totalDurationInSeconds += duration;
            } catch (error) {
              // console.error("Error fetching video duration:", error);
              return;
            }
          }
        }
      }
      const allTotalDuration = formatTime(totalDurationInSeconds);

      dispatch(updateTotalDuration({ totalDuration: allTotalDuration }));
      setTotalVideoDuration(allTotalDuration);
    };

    calculateTotalVideoDuration();
  }, []);

  const getVideoDuration = async (videoUrl) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");

      video.onloadedmetadata = () => {
        resolve(Math.floor(video.duration));
      };

      video.onerror = () => {
        reject(new Error("Unable to load video"));
      };

      video.src = videoUrl;
      video.load();
    });
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours} hr ${minutes} min`;
  };
  let totalVideos = 0;
  let uploadedVideoCount = 0;
  let processedVideoCount = 0;
  const videoQueue = [];
  let filesFound = false;
  const handleSubmit = async () => {
    let preVidoeLocation = allformdata.title
      .replace(/[^a-zA-Z\s]/g, "")
      .replace(/\s+/g, "");

    if (typeof allformdata.videoPreview !== "string") {
      uploadVideopreview(allformdata.videoPreview, preVidoeLocation);
    } else {
      dispatch(updateVideoPreview(allformdata.videoPreview));
    }
    if (typeof allformdata.imagePreview !== "string") {
      uploadImage(allformdata.imagePreview, preVidoeLocation);
    } else {
      dispatch(updateImagePreview(allformdata.imagePreview));
    }
    setUploadedprogress(1);
    allSections.forEach((section, sectionIndex) => {
      section.lectures.forEach((lecture, lectureIndex) => {
        if (lecture.video.file) {
          filesFound = true;
          totalVideos++;
          let inputFile = lecture.video.file;
          let sectionId = section.id;
          let lectureId = lecture.id;
          const videoLocation = getVideoLocation(
            sectionIndex,
            lectureIndex,
            lecture.video.videoName
          );

          uploadFile(inputFile, videoLocation, sectionId, lectureId);
        }

        if (lecture.document.file) {
          let inputFile = lecture.document.file;
          let sectionId = section.id;
          let lectureId = lecture.id;
          const docLocation = getVideoLocation(
            sectionIndex,
            lectureIndex,
            lecture.document.file.name
          );

          docUpload(inputFile, docLocation, sectionId, lectureId);
        }
      });
    });

    if (!filesFound) {
      if (uploadProgress !== 100) {
        setUploadedprogress(100);
      }
      if (processedVideo !== 100) {
        setProcessedvideo(100);
      }
    }
  };

  const getVideoLocation = (sectionIndex, lectureIndex) => {
    // const titleWithoutSpaces = allformdata.title.replace(/\s/g, '');
    let titleWithoutSpaces = allformdata.title
      ? allformdata.title.replace(/[^a-zA-Z\s]/g, "").replace(/\s+/g, "")
      : "courses";

    const sectionNumber = sectionIndex + 1;
    const lectureNumber = lectureIndex + 1;
    return `${titleWithoutSpaces}/section${sectionNumber}/lecture${lectureNumber}`;
  };
  function generateRandomFileName(length) {
    const characters = "abcdefghijklmnopqrstuvwxyz";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  }

  const uploadFile = async (file, videoLocation, sectionId, lectureId) => {
    // const lastDotIndex = file.name.lastIndexOf(".");
    // const fileExtension = file.name.slice(lastDotIndex);
    // let sanitizedFileName = file.name
    //   .slice(0, lastDotIndex)
    //   .replace(/[^\w\s]/gi, "")
    //   .replace(/\d/g, "")
    //   .toLowerCase();
    const lastDotIndex = file?.name?.lastIndexOf(".");
    const fileExtension = file?.name?.slice(lastDotIndex);
    let sanitizedFileName = file?.name
      ?.slice(0, lastDotIndex)
      ?.replace(/[^a-zA-Z0-9 ]/g, "") // Remove all non-alphanumeric characters except spaces
      ?.replace(/\s+/g, "_") // Replace spaces with underscores
      .toLowerCase();
    if (!sanitizedFileName) {
      sanitizedFileName = generateRandomFileName(10); // Generate a random name with 10 characters
    }

    const finalFileName = sanitizedFileName + fileExtension;

    const url = "https://courses.ir4u.info/api/course/upload";
    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          fileName: finalFileName,
          contentType: file.type,
          location: videoLocation,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        const signedUrl = data.signedUrl;

        await uploadToPresignedUrl(
          file,
          signedUrl,
          videoLocation,
          sectionId,
          lectureId,
          finalFileName
        );
      } else {
        // console.error("Failed to obtain pre-signed URL:", response.statusText);
        return;
      }
    } catch (error) {
      // console.error("Error obtaining pre-signed URL:", error);
      return;
    }
  };

  const uploadToPresignedUrl = async (
    file,
    signedUrl,
    videoLocation,
    sectionId,
    lectureId,
    finalFileName
  ) => {
    try {
      const response = await fetch(signedUrl, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
        },
      });
      if (response.ok) {
        if (isVideoFile(file.type)) {
          const filename = finalFileName;
          const newFilename = filename.replace(".mp4", ".m3u8");
          const videoUrl = `https://dou8xtfkj2dbq.cloudfront.net/${videoLocation}/${newFilename}`;
          dispatch(
            updatelectureVideo({
              sectionId: sectionId,
              lectureId: lectureId,
              videoUrl: videoUrl,
            })
          );

          uploadedVideoCount++;
          // videoQueue.push({ file, videoLocation, sectionId, lectureId });
          if (uploadedVideoCount === totalVideos) {
            //   // processVideoQueue();
            const fakeProgressInSeconds = 30;
            processedVideoCount += fakeProgressInSeconds;
            const newprogress = Math.round(
              (processedVideoCount / totalVideos) * 100
            );
            setProcessedvideo(100);
          }
          const progress = Math.round((uploadedVideoCount / totalVideos) * 100);
          setUploadedprogress(progress);
        } else {
          // const docUrl = `https://dou8xtfkj2dbq.cloudfront.net/${videoLocation}/${file.name}`;
          const docUrl = `https://course-video-encoding-input.s3.amazonaws.com/${videoLocation}/${file.name}`;
          dispatch(
            updateLectureDocUrl({
              sectionId: sectionId,
              lectureId: lectureId,
              docUrl: docUrl,
            })
          );
        }
      } else {
        // console.error(
        //   "Failed to upload file to pre-signed URL:",
        //   response.statusText
        // );
        return;
      }
    } catch (error) {
      // console.error("Error uploading file to pre-signed URL:", error);
      return;
    }
  };

  const processVideoQueue = async () => {
    while (videoQueue.length > 0) {
      const videoData = videoQueue.shift();

      // await getVideoUrl(
      //   videoData.file.name,
      //   videoData.videoLocation,
      //   videoData.sectionId,
      //   videoData.lectureId
      // );
    }
  };

  const getVideoUrl = async (filename, location, sectionId, lectureId) => {
    const url = "https://courses.ir4u.info/api/course/videourl";
    // const url = "http://localhost:3000/api/course/videourl"
    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({ location, filename }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        processedVideoCount++;
        const data = await response.json();
        const videoUrl = data.videoUrl;
        dispatch(
          updatelectureVideo({
            sectionId: sectionId,
            lectureId: lectureId,
            videoUrl: videoUrl,
          })
        );
        if (videoQueue.length > 0) {
          // processVideoQueue();
        }
        const progress = Math.round((processedVideoCount / totalVideos) * 100);
        setProcessedvideo(progress);
      } else {
        // console.error("Failed to get video URL:", response.statusText);
        return;
      }
    } catch (error) {
      // console.error("Error getting video URL:", error);
      return;
    }
  };
  function isVideoFile(fileType) {
    const videoMimeTypes = [
      "video/mp4",
      "video/mpeg",
      "video/quicktime",
      "video/x-msvideo",
    ];

    return videoMimeTypes.includes(fileType);
  }
  const docUpload = async (file, videoLocation, sectionId, lectureId) => {
    const url = "https://courses.ir4u.info/api/course/image";
    // const url = 'http://localhost:3000/api/course/image'
    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          fileName: file.name,
          contentType: file.type,
          location: videoLocation,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        const signedUrl = data.signedUrl;
        await uploadToPresignedUrl(
          file,
          signedUrl,
          videoLocation,
          sectionId,
          lectureId
        );
      } else {
        // console.error("Failed to obtain pre-signed URL:", response.statusText);
        return;
      }
    } catch (error) {
      // console.error("Error obtaining pre-signed URL:", error);
      return;
    }
  };

  const uploadVideopreview = async (file, videoLocation) => {
    // const lastDotIndex = file?.name?.lastIndexOf(".");
    // const fileExtension = file?.name?.slice(lastDotIndex);
    // const sanitizedFileName = file?.name
    //   ?.slice(0, lastDotIndex)
    //   ?.replace(/[^\w\s]/gi, "")
    //   .toLowerCase();
    const lastDotIndex = file?.name?.lastIndexOf(".");
    const fileExtension = file?.name?.slice(lastDotIndex);
    const sanitizedFileName = file?.name
      ?.slice(0, lastDotIndex)
      ?.replace(/[^a-zA-Z0-9 ]/g, "") // Remove all non-alphanumeric characters except spaces
      ?.replace(/\s+/g, "_") // Replace spaces with underscores
      .toLowerCase();
    const finalFileName = sanitizedFileName + fileExtension;
    const url = "https://courses.ir4u.info/api/course/upload";
    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          fileName: finalFileName,
          contentType: file.type,
          location: videoLocation,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        const signedUrl = data.signedUrl;
        await newuploadToPresignedUrl(
          file,
          signedUrl,
          videoLocation,
          finalFileName
          // sectionId,
          // lectureId
        );
      } else {
        // console.error("Failed to obtain pre-signed URL:", response.statusText);
        return;
      }
    } catch (error) {
      // console.error("Error obtaining pre-signed URL:", error);
      return;
    }
  };
  const uploadImage = async (file, videoLocation) => {
    // setIsuploaded(true);
    // const lastDotIndex = file.name.lastIndexOf(".");
    // const fileExtension = file.name.slice(lastDotIndex);
    // const sanitizedFileName = file.name
    //   .slice(0, lastDotIndex)
    //   .replace(/[^\w\s]/gi, "")
    //   .toLowerCase();
    const lastDotIndex = file?.name?.lastIndexOf(".");
    const fileExtension = file?.name?.slice(lastDotIndex);
    const sanitizedFileName = file?.name
      ?.slice(0, lastDotIndex)
      ?.replace(/[^a-zA-Z0-9 ]/g, "") // Remove all non-alphanumeric characters except spaces
      ?.replace(/\s+/g, "_") // Replace spaces with underscores
      .toLowerCase();
    const finalFileName = sanitizedFileName + fileExtension;
    const url = "https://courses.ir4u.info/api/course/image";
    // const url = "http://localhost:3000/api/course/image"
    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          fileName: finalFileName,
          contentType: file.type,
          location: videoLocation,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        const signedUrl = data.signedUrl;
        await newuploadToPresignedUrl(
          file,
          signedUrl,
          videoLocation,
          finalFileName
        );
      } else {
        // console.error("Failed to obtain pre-signed URL:", response.statusText);
        return;
      }
    } catch (error) {
      // console.error("Error obtaining pre-signed URL:", error);
      return;
    }
  };

  const newuploadToPresignedUrl = async (
    file,
    signedUrl,
    videoLocation,
    finalFileName
  ) => {
    try {
      const response = await fetch(signedUrl, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
        },
      });
      if (response.ok) {
        if (isVideoFile(file.type)) {
          // getpreVideoUrl(file.name, videoLocation);
          const filename = finalFileName;
          const newFilename = filename.replace(".mp4", ".m3u8");
          const videoUrl = `https://dou8xtfkj2dbq.cloudfront.net/${videoLocation}/${newFilename}`;
          dispatch(updateVideoPreview(videoUrl));
        } else {
          const fileName = finalFileName;
          const imageUrl = `https://course-video-encoding-input.s3.amazonaws.com/${videoLocation}/${fileName}`;
          dispatch(updateImagePreview(imageUrl));
        }
      } else {
        // console.error("error uploading image");
        return;
      }
    } catch (error) {
      // console.error("Error uploading file to pre-signed URL:", error);
      return;
    }
  };

  const getpreVideoUrl = async (filename, location) => {
    // const url = "https://courses.ir4u.info/api/course/videourl";
    const url = "http://localhost:8000/api/course/videourl";
    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({ location, filename }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        // processedVideoCount++;
        const data = await response.json();
        const videoUrl = data.videoUrl;
        dispatch(updateVideoPreview(videoUrl));
      } else {
        // console.error("Failed to get video URL:", response.statusText);
        return;
      }
    } catch (error) {
      // console.error("Error getting video URL:", error);
      return;
    }
  };

  function isVideoFile(fileType) {
    const videoMimeTypes = [
      "video/mp4",
      "video/mpeg",
      "video/quicktime",
      "video/x-msvideo",
    ];

    return videoMimeTypes.includes(fileType);
  }

  const currentDate = new Date();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();
  const formattedDate = `${month.toString().padStart(2, "0")}/${year}`;

  const shareUrl = window.location.href;
  const showShareModal = () => {
    setShareModal(true);
  };
  const shareCloseModalHandle = () => {
    setShareModal(false);
  };
  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(shareUrl);
    setTooltipText(true);
    setTimeout(() => setTooltipText(false), 2000);
  };
  function stripHtmlTags(html, maxLength) {
    let doc = new DOMParser().parseFromString(html, "text/html");
    let plainText = doc.body.innerText;
    return plainText.length > maxLength
      ? plainText.slice(0, maxLength) + "..."
      : plainText;
  }
  return (
    <div className="ir-ws-courses-detail-page-main-container">
      {uploadProgress === 0 && (
        <>
          <Header />
          <div className=" ir-courses-single-page-main-container">
            <div className="ir-ws-padding-lr-132 ir-courses-single-page-inner-container">
              <div className="ir-ws-flex ir-ws-justify-space-btw ir-course-preview-detail-page-container ir-course-preview-top-container">
                <div className="ir-ws-width-60 ir-course-preview-detail-container">
                  <h1 className="ir-ws-heading-default-color ir-ws-font-700 ir-ws-font-size-xl ir-ws-courses-banner-title ir-course-preview-title-text">
                    {allformdata.title}
                  </h1>
                  <p className="ir-ws-app-color ir-course-preview-topic-text">
                    {allformdata.basicInfo.category}
                  </p>
                  <h2 className="ir-ws-heading-default-color ir-ws-font-700 ir-ws-font-size-m ir-course-preview-subtitle-text">
                    {allformdata.subtitle}
                  </h2>

                  {/* <p className="ir-default-color ir-course-preview-course-description-text">
                    {`Course description: ${stripHtmlTags(
                      allformdata.description
                    )}`}
                  </p> */}
                  {/* <div
                    dangerouslySetInnerHTML={{
                      __html: `
                     <p className="ir-default-color ir-course-preview-course-description-text">
                       Course description: ${allformdata.description}
                           </p>
                          `,
                    }}
                  /> */}

                  <p
                    className="ir-default-color ir-course-preview-course-description-text"
                    dangerouslySetInnerHTML={{
                      __html: allformdata.description,
                    }}
                  />

                  <p className="ir-ws-app-color ir-course-preview-created-text">
                    Created by {allformdata.instructorName}
                  </p>
                  <p className="ir-default-color ir-course-preview-created-description-text">
                    {`Profile Description: ${allformdata.instructorProfile} `}
                  </p>
                  <div className="ir-course-preview-rating-container ir-ws-flex ">
                    <StarRating iconWidth={25} iconHeight={25} iconGap={15} />
                  </div>
                </div>
                <div className="ir-ws-width-30 ir-course-prview-video-player-buy-container">
                  <div className="ir-ws-position-relative ir-course-prview-video-player-container">
                    <div className="ir-course-preview-video-player">
                      <img
                        src={videoThumbnail}
                        alt="course thumbnail"
                        className="ir-ws-course-preview-video-thumbnail"
                      />
                    </div>
                    <div
                      className="ir-ws-position-absolute ir-course-preview-course-button-container"
                      onClick={() =>
                        handleToggleVideo(fileURL, allformdata.title)
                      }
                    >
                      <div className="ir-course-preview-course-button-box">
                        <i className="ir-course-preview-btn bi bi-play-circle-fill"></i>
                      </div>
                      <p className="ir-course-preview-course-text">
                        Preview this course
                      </p>
                    </div>
                  </div>
                  <div className="ir-course-preview-buy-course-container">
                    <div className="ir-course-preview-primarily-container">
                      <p className="ir-default-color ir-course-preview-primarily-taught-text ir-ws-course-primary-text-align">
                        {allformdata.taught}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ir-single-course-buy-final-main-container">
                <div className="ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center ir-single-course-buy-final-inner-container">
                  <div className="ir-default-color ir-ws-width-60 ir-course-language-difficulty-update-container ir-ws-flex ir-ws-justify-space-btw">
                    <div className="ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center ir-course-language-container">
                      <img
                        className="single-page-det-icon"
                        src={require("../../../assets/images/language-icon.png")}
                      />
                      <p>{allformdata.basicInfo.language}</p>
                    </div>
                    <div className="ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center ir-course-language-container">
                      <img
                        className="single-page-det-icon"
                        src={require("../../../assets/images/level-icon.png")}
                      />
                      <p>{allformdata.basicInfo.level}</p>
                    </div>
                    <div className="ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center ir-course-language-container">
                      <img
                        className="single-page-det-icon"
                        src={require("../../../assets/images/update-icon.png")}
                      />
                      <p>Last update: {formattedDate}</p>
                    </div>
                  </div>
                  <div className="ir-ws-flex ir-ws-width-30 ir-ws-align-center ir-course-preview-buy-course-box">
                    <div className="ir-ws-text-center ir-ws-default-btn-container ir-course-preview-buy-course-text">
                      <button className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button ir-ws-course-price-main-btn">
                        <span className="ir-ws-preview-course-price-text">
                          {" "}
                          {`$ ${allformdata.coursePrice}`}
                        </span>
                      </button>
                    </div>
                    <div className="ir-course-preview-heart-box">
                      {!favourite ? (
                        <i
                          className="bi bi-suit-heart"
                          onClick={handlefavourite}
                        ></i>
                      ) : (
                        <i
                          className="bi bi-suit-heart-fill"
                          onClick={handlefavourite}
                        ></i>
                      )}
                    </div>
                    <div
                      className="ir-course-preview-heart-box"
                      onClick={showShareModal}
                    >
                      <i className="material-symbols-outlined ir-ws-create-course-g-font">
                        share
                      </i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ir-course-preview-review-cont">
                <div className="ir-course-preview-review-text-container ir-course-preview-one-cont">
                  <p>R</p>
                  <p>E</p>
                  <p>V</p>
                  <p>I</p>
                  <p>E</p>
                  <p>W</p>
                </div>
              </div>
              <div className="ir-course-preview-review-text-main-cont">
                <div className="ir-course-preview-review-text-container">
                  <p>R</p>
                  <p>E</p>
                  <p>V</p>
                  <p>I</p>
                  <p>E</p>
                  <p>W</p>
                </div>
              </div>
            </div>
          </div>

          <div className="ir-ws-padding-lr-132 ir-course-preview-learn-description-section">
            <div className="ir-ws-single-course-point-container">
              {allformdata?.learnPointArray.length !== 0 && (
                <div className="ir-ws-single-course-learn-point-container">
                  <div>
                    <p className="ir-single-learn-point-text">
                      What You'll learn
                    </p>
                  </div>
                  {allformdata?.learnPointArray?.map((point, index) => (
                    <div className="ir-single-learn-point-box">
                      <div className="ir-single-learn-point-list-text">
                        <i className="bi bi-check2"></i>{" "}
                        <p className="ir-ws-point-text">{point}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {allformdata?.courseIncludesArray.length !== 0 && (
                <div className="ir-ws-single-course-learn-point-container">
                  <div>
                    <p className="ir-single-learn-point-text">
                      This Course includes
                    </p>
                  </div>
                  {allformdata?.courseIncludesArray?.map((item, index) => (
                    <div className="ir-single-learn-point-box">
                      <div className="ir-single-learn-point-list-text">
                        {item === "Duration" && <i className="bi bi-clock"></i>}
                        {item === "Exercises" && (
                          <i className="material-symbols-outlined">contract</i>
                        )}
                        {item === "Downloadable Resources" && (
                          <i className="material-symbols-outlined">download</i>
                        )}
                        {item === "Access on mobile" && (
                          <i className="bi bi-phone"></i>
                        )}
                        {item === "Duration" ? (
                          <p className="ir-ws-point-text">
                            {totalVideoDuration}
                          </p>
                        ) : (
                          <p className="ir-ws-point-text">{item}</p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <CoursePrevLecture sections={allSections} allformdata={allformdata} />
          {videoModalOpen && (
            <ModalPlayer
              videoSource={videoSource}
              videoTitle={videoTitle}
              onClick={handleToggleVideo}
            />
          )}
          <div className="ir-course-review-cancel-button-container">
            <div
              className="ir-ws-text-center ir-ws-default-btn-container"
              onClick={() => {
                handleSaveAndContinue();
                navigate(-1);
                // handleCreateCourse();
              }}
            >
              <button className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button">
                <span>Back</span>
              </button>
            </div>

            <div
              className="ir-ws-text-center ir-ws-default-btn-container"
              onClick={handleSubmit}
            >
              <button className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button">
                <span>
                  {isAdmin ? "Review and Publish" : "Upload and Review"}
                </span>
              </button>
            </div>

            <div
              className="ir-ws-text-center ir-ws-default-btn-container"
              onClick={toggleConfirmationModal}
            >
              <button className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button">
                <span>Cancel</span>
              </button>
            </div>
          </div>
        </>
      )}
      {openConfirmationModal && (
        <ConfirmationModal
          toggleModal={toggleConfirmationModal}
          confirmationFunc={() => {
            dispatch(resetFormData());
            handleGoBackCoursePage();
            handleCreateCourse();
            if (isAdmin) {
              navigate("/admin");
            } else {
              navigate("/publisher");
            }
          }}
          title="cancel"
        />
      )}
      {uploadProgress > 0 && (
        <VideoProcessingLoader
          uploadingProgress={uploadProgress}
          processedVideo={processedVideo}
        />
      )}
      {shareModal && (
        <div className="ir-ws-position-fixed ir-ws-sign-popup-container ir-ws-share-main-container">
          <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
            <div className="iir-ws-signup-content-container">
              <div
                className="ir-ws-position-absolute ir-ws-signup-close-container"
                onClick={shareCloseModalHandle}
              >
                <i className="bi bi-x-lg"></i>
              </div>
              <div className="ir-ws-signup-content-inner-container">
                <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                  Share Case
                </h3>
              </div>
              <div className="ir-share-modal-inside-content">
                <p>Invite your friends through social network</p>
                <div className="ir-share-container">
                  <div className="ir-share-icon-container ir-share-icon-container-hover">
                    <FacebookShareButton
                      url={shareUrl}
                      quote={"Dummy text!"}
                      hashtag="#ir4u"
                    >
                      <FacebookIcon size={50} round />
                    </FacebookShareButton>
                  </div>
                  <div className="ir-share-icon-container ir-share-icon-container-hover">
                    <WhatsappShareButton url={shareUrl} quote={"Dummy text!"}>
                      <WhatsappIcon size={50} round />
                    </WhatsappShareButton>
                  </div>
                  <div className="ir-share-icon-container ir-share-icon-container-hover">
                    {!tooltipText ? (
                      <div
                        className="ir-share-icon-copy-link ir-share-icon-link"
                        onClick={handleCopyLink}
                        title="Copy Link"
                      >
                        <i className="bi bi-link-45deg"></i>
                      </div>
                    ) : (
                      <div
                        className="ir-share-icon-copy-link ir-share-icon-link"
                        title="Link Copied"
                      >
                        <i className="bi bi-check-lg"></i>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CoursePreviewPage;
