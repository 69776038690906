import React, { useEffect, useState } from "react";

const Rapid = ({
  activeSlideObject,
  activeAccordions,
  accordionRefs,
  toggleAccordion,
  initialTimeStart,
  rapidUserAnswers,
  handleRapidInputChange,
  isRunning,
  rapidSubmittedStatus,
  answerType,
  timerStopped,
  checkRapidQuestion,
  formatTime,
  timeLeft,
  startTimer,
  index,
  newRapidData,
  questionComponentIndex,
  markQuestionAsAnswered,
  activeQuestionindex,
  goToNextSlide,
  totalQuestions,
  handleShowLearningPopup,
  isLearningPointExist,
  isAllQuestionsSubmitted,
  handleSubmitAllAnser,
}) => {
  const [userAnswer, setNewUserAnswer] = useState("");
  const [isSubmitted, setIssubmitted] = useState(false);

  const fidHandleChange = (e) => {
    setNewUserAnswer(e.target.value);
  };
  const isLastQuestion = questionComponentIndex === totalQuestions - 1;

  // useEffect(() => {
  //   if (isAllQuestionsSubmitted) {
  //     markQuestionAsAnswered(questionComponentIndex);
  //   }
  // }, [isAllQuestionsSubmitted]);

  const singleCheckrapidquestion = () => {
    const correctAnswer = newRapidData.data[0].answer.toLowerCase();
    const yourAnswerElement = document.getElementById(
      `rapid-your-answer-${newRapidData.data[0].id}`
    );
    const correctAnswerElement = document.getElementById(
      `rapid-correct-answer-${newRapidData.data[0].id}`
    );

    if (!userAnswer) {
      yourAnswerElement.textContent = "Your Answer: (No answer provided)";
      yourAnswerElement.className = "your-answer wrong";
    }
    if (userAnswer.toLowerCase() === correctAnswer) {
      yourAnswerElement.textContent = "Your answer is Correct";
      yourAnswerElement.className = "your-answer correct";
    } else {
      yourAnswerElement.textContent = "Your answer is Wrong";
      yourAnswerElement.className = "your-answer wrong";

      correctAnswerElement.textContent = `The answer is ${correctAnswer}`;
      correctAnswerElement.className = "correct-answer";
    }
  };

  const submitHandler = (questionComponentIndex) => {
    singleCheckrapidquestion();
    markQuestionAsAnswered(questionComponentIndex);
    setIssubmitted(true);
  };

  useEffect(() => {
    if (timerStopped && !isSubmitted) {
      if (answerType === "single") {
        submitHandler(questionComponentIndex);
      } else {
        markQuestionAsAnswered(questionComponentIndex);
      }
    }
  }, [timerStopped, isSubmitted, questionComponentIndex]);

  return (
    <>
      {activeSlideObject?.rapidQuestionData?.length !== 0 && (
        <>
          <div className="ir-ws-case-accordion-cont">
            <div
              // className={`ir-ws-case-accordion-header ${
              //   activeAccordions[questionComponentIndex]
              //     ? "ir-ws-case-accordian-active-border"
              //     : "ir-ws-case-accordian-inactive-border "
              // }`}
              // onClick={() => toggleAccordion(questionComponentIndex)}
              className="ir-ws-case-accordion-header ir-ws-case-accordian-active-border"
            >
              {/* <span className="ir-ws-case-slide-title-text">
                <p className="mul-question">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: newRapidData?.data[0]?.content,
                    }}
                  />
                </p>
              </span> */}
              {/* <span className="ir-cs-section-icon">
                {activeAccordions[questionComponentIndex] ? (
                  <i className="bi bi-chevron-up"></i>
                ) : (
                  <i className="bi bi-chevron-down"></i>
                )}
              </span> */}
            </div>
            <div
              className="ir-ws-case-accordion-accordion-content"
              // ref={(el) => (accordionRefs.current[questionComponentIndex] = el)}
              // style={{
              //   maxHeight: activeAccordions[questionComponentIndex]
              //     ? `${accordionRefs.current[questionComponentIndex]?.scrollHeight}px`
              //     : "0px",
              // }}
              // style={{ height: "100%" }}
            >
              <div className="ir-ws-case-content-box">
                {newRapidData?.data.map((question, questionIndex) => (
                  <div key={question.id} className="rapid-question-data-main">
                    {!initialTimeStart ? (
                      <p className="rapid-question">
                        Please click on start to see the question.
                      </p>
                    ) : (
                      <h5 className="rapid-question">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: question.content,
                          }}
                        />
                      </h5>
                    )}

                    <div className="rapid-content-container">
                      <div className="rapid-content-container-left">
                        <input
                          className="rapid-ans-input"
                          type="text"
                          value={rapidUserAnswers[question.id] || ""}
                          onChange={(e) => {
                            handleRapidInputChange(question.id, e);
                            fidHandleChange(e);

                            if (answerType === "group")
                              markQuestionAsAnswered(questionComponentIndex);

                            // markQuestionAsAnswered(questionComponentIndex)
                          }}
                          disabled={!isRunning || rapidSubmittedStatus}
                        />
                      </div>
                      <div
                        // style={{
                        //   width: "50%",
                        // }}
                        className="result-message"
                        id={`rapid-result-${question.id}`}
                      >
                        <span
                          className="your-answer"
                          id={`rapid-your-answer-${question.id}`}
                        ></span>
                        <span
                          className="correct-answer"
                          id={`rapid-correct-answer-${question.id}`}
                        ></span>
                      </div>
                    </div>
                  </div>
                ))}
                {/* </div> */}
                {/* )
                )} */}
                <div className="ir-ws-case-rapid-submit-timer-cont">
                  {answerType === "single" && (
                    <div
                      className=" ir-ws-default-btn-container rapid-btn-container"
                      style={{
                        width: "100%",
                        marginTop: "10px",
                      }}
                    >
                      <button
                        className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                        disabled={!isRunning || rapidSubmittedStatus}
                        // onClick={singleCheckrapidquestion}
                        onClick={() => submitHandler(questionComponentIndex)}
                        style={{ width: "240px" }}
                      >
                        <span>Submit</span>
                      </button>
                    </div>
                  )}
                  {/* {!isLastQuestion && answerType === "group" && (
                    <div
                      className=" ir-ws-default-btn-container"
                      style={{
                        width: "100%",
                        marginTop: "10px",
                      }}
                    >
                      <button
                        className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                        // onClick={checkAnswerFid}
                        onClick={goToNextSlide}
                        // onClick={() => submitHandler(questionIndex)}
                        disabled={isLastQuestion}
                        style={{ width: "240px" }}
                      >
                        <span>Next</span>
                      </button>
                    </div>
                  )} */}

                  {answerType === "group" && (
                    <div
                      className="ir-ws-default-btn-container"
                      style={{
                        width: "100%",
                        marginTop: "10px",
                      }}
                    >
                      {isLastQuestion ? (
                        <button
                          className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                          onClick={handleSubmitAllAnser}
                          style={{ width: "240px" }}
                          disabled={isAllQuestionsSubmitted}
                        >
                          <span>Finish</span>
                        </button>
                      ) : (
                        <button
                          className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                          onClick={goToNextSlide}
                          style={{ width: "240px" }}
                        >
                          <span>Next</span>
                        </button>
                      )}
                    </div>
                  )}

                  <div className="rapid-content-container-right">
                    <h1 className="rapid-time-countdown">
                      {timerStopped ? "Times up!" : formatTime(timeLeft)}
                    </h1>

                    <div
                      className=" ir-ws-default-btn-container"
                      style={{
                        width: "100%",
                        marginTop: "10px",
                      }}
                    >
                      <button
                        className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                        onClick={() => startTimer()}
                        // disabled={isRunning || rapidSubmittedStatus}
                        disabled={
                          isRunning ||
                          rapidSubmittedStatus ||
                          timerStopped ||
                          isSubmitted
                        }
                        style={{ width: "240px" }}
                      >
                        <span>Start</span>
                      </button>
                    </div>
                  </div>
                </div>
                {(isLearningPointExist && isLastQuestion && isSubmitted) ||
                (isLearningPointExist &&
                  answerType === "group" &&
                  isLastQuestion &&
                  isAllQuestionsSubmitted) ? (
                  <div
                    className="ir-ws-default-btn-container"
                    style={{
                      width: "100%",
                      marginTop: "20px",
                      // marginLeft: "5px",
                    }}
                  >
                    <button
                      className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                      // onClick={checkJumblingAnswers}
                      onClick={() => handleShowLearningPopup()}
                      style={{ width: "240px" }}
                    >
                      <span>Learning Points</span>
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Rapid;
