import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import {
  addLecture,
  deleteLecture,
  updateSectionContent,
} from "../../Store/CourseSlice";

import CreateCourseSectionContent from "./CreateCourseSectionContent";
const CreateCourseSecondPageSection = ({
  sectionNumber,
  onDeleteSection,
  sectionId,
  allLectures,
  sectionContent,
  errorMessage,
  setErrorMessage,
}) => {
  const [editable, setEditable] = useState(false);
  const [content, setContent] = useState(sectionContent);
  const dispatch = useDispatch();

  // console.log("this is the allsectindata", allLectures);

  const HandleaddLecture = () => {
    // If there are no lectures in the section or the last lecture has videoUrl or docUrl
    if (
      allLectures.length === 0 ||
      allLectures[allLectures.length - 1].video.videoUrl ||
      allLectures[allLectures.length - 1].document.docUrl
    ) {
      const lecture = {
        id: uuidv4(),
        content: "Lecture title",
        lectureNumber: allLectures.length,
        video: { videoName: "", videoUrl: "" },
        document: { docName: "", docUrl: "" },
      };
      dispatch(addLecture({ sectionId: sectionId, lecture }));
    } else {
      setErrorMessage(
        "Add video or document in the lecture to add new lecture"
      );
      return;
    }
  };

  const handleEdit = () => {
    setEditable(!editable);
  };
  const handleChange = (e) => {
    setContent(e.target.value);
  };
  const handleSave = () => {
    setEditable(false);
    dispatch(updateSectionContent({ sectionId: sectionId, content: content }));
  };
  const HandleDeleteLecture = (lectureId) => {
    dispatch(deleteLecture({ sectionId: sectionId, lectureId: lectureId }));
  };

  return (
    <div className="ir-ws-second-page-section">
      <div className="ir-ws-second-page-section-heading">
        <h3 className="heading-h">Section {sectionNumber + 1}: </h3>
        {editable ? (
          <input
            className="ir-ws-text-edit-input"
            type="text"
            value={content}
            onChange={handleChange}
            onBlur={handleSave}
          />
        ) : (
          <p className="heading-p">{sectionContent}</p>
        )}

        <svg
          height="18px"
          version="1.1"
          viewBox="0 0 18 18"
          width="18px"
          xmlns="http://www.w3.org/2000/svg"
          className="ir-ws-editor-svg "
          onClick={handleEdit}
        >
          <title />
          <desc />
          <defs />
          <g
            fill="none"
            fillRule="evenodd"
            id="Page-1"
            stroke="none"
            strokeWidth="1"
          >
            <g
              fill="#424242"
              id="Core"
              transform="translate(-213.000000, -129.000000)"
            >
              <g id="create" transform="translate(213.000000, 129.000000)">
                <path
                  d="M0,14.2 L0,18 L3.8,18 L14.8,6.9 L11,3.1 L0,14.2 L0,14.2 Z M17.7,4 C18.1,3.6 18.1,3 17.7,2.6 L15.4,0.3 C15,-0.1 14.4,-0.1 14,0.3 L12.2,2.1 L16,5.9 L17.7,4 L17.7,4 Z"
                  id="Shape"
                />
              </g>
            </g>
          </g>
        </svg>
        <svg
          height="26"
          viewBox="0 0 48 48"
          width="26"
          xmlns="http://www.w3.org/2000/svg"
          fill="#424242"
          className="ir-course-selected-image-video-delete-icon"
          onClick={onDeleteSection}
        >
          <path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z" />
          <path d="M0 0h48v48H0z" fill="none" />
        </svg>
      </div>
      <div className="ir-ws-lecture-container">
        {allLectures.map((lecture, index) => (
          <CreateCourseSectionContent
            key={lecture.id}
            lectureNumber={lecture.lectureNumber}
            lectureId={lecture.id}
            sectionId={sectionId}
            lectureContent={lecture.content}
            video={lecture.video}
            document={lecture.document}
            onDelete={() => HandleDeleteLecture(lecture.id)}
            setErrorMessage={setErrorMessage}
          />
        ))}
        {/* {errorMessage !== "" && (
          <p className="ir-ws-course-error-message-lecture">{errorMessage}</p>
        )} */}
        <div className="ir-ws-add-lacture-button" onClick={HandleaddLecture}>
          + Add Lecture
        </div>
      </div>
    </div>
  );
};

export default CreateCourseSecondPageSection;
