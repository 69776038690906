import React from "react";
import "./CoursesCategory.scss";
import { useNavigate } from "react-router-dom";

const CoursesCategory = ({ scrollToCoursesList }) => {
  const navigate = useNavigate();
  const cardData = [
    {
      frontContent: {
        imageSrc: require("../../assets/images/chest-icon.png"),
        frontText: "Chest",
      },
      backContent: {
        backText: "complex organ that controls all functions of the human body",
      },
    },
    {
      frontContent: {
        imageSrc: require("../../assets/images/gastrointestinal-icon.png"),
        frontText: "⁠Gastrointestinal",
      },
      backContent: {
        backText:
          "Kidneys are a pair of bean-shaped organs located in the abdomen",
      },
    },
    {
      frontContent: {
        imageSrc: require("../../assets/images/liver.png"),
        frontText: "Liver",
      },
      backContent: {
        backText:
          "An elongated, tapered organ located across the back of the belly",
      },
    },
    {
      frontContent: {
        imageSrc: require("../../assets/images/genitourinary-icon.png"),
        frontText: "⁠Genitourinary",
      },
      backContent: {
        backText:
          "The bladder is a hollow organ in the lower abdomen that stores urine",
      },
    },
    {
      frontContent: {
        imageSrc: require("../../assets/images/vascular-icon.png"),
        frontText: "Vascular",
      },
      backContent: {
        backText: "The lungs and respiratory system allow us to breathe",
      },
    },
    {
      frontContent: {
        imageSrc: require("../../assets/images/lymphatic-icon.png"),
        frontText: "Lymphatics",
      },
      backContent: {
        backText: "A muscle that pumps blood and oxygen throughout the body",
      },
    },
    {
      frontContent: {
        imageSrc: require("../../assets/images/trauma-icon.png"),
        frontText: "T⁠rauma",
      },
      backContent: {
        backText:
          "An organ that sits just under the rib cage on the right side of the abdomen",
      },
    },
    {
      frontContent: {
        imageSrc: require("../../assets/images/venous-icon.png"),
        frontText: "Venous",
      },
      backContent: {
        backText:
          "The bladder is a hollow organ in the lower abdomen that stores urine",
      },
    },
    {
      frontContent: {
        imageSrc: require("../../assets/images/bleeding-icon.png"),
        frontText: "⁠Bleed",
      },
      backContent: {
        backText: "A muscle that pumps blood and oxygen throughout the body",
      },
    },
    {
      frontContent: {
        imageSrc: require("../../assets/images/womens-health-icon.png"),
        frontText: "⁠Women’s health",
      },
      backContent: {
        backText:
          "An elongated, tapered organ located across the back of the belly",
      },
    },
    {
      frontContent: {
        imageSrc: require("../../assets/images/mens-health-icon.png"),
        frontText: "⁠Men’s health",
      },
      backContent: {
        backText: "The lungs and respiratory system allow us to breathe",
      },
    },
    // {
    //   frontContent: {
    //     imageSrc: require("../../assets/images/liver.png"),
    //     frontText: "Liver",
    //   },
    //   backContent: {
    //     backText:
    //       "An organ that sits just under the rib cage on the right side of the abdomen",
    //   },
    // },
  ];
  const loggedIn = localStorage.getItem("isLoggedIn") === "true";
  return (
    <div className="ir-crs-categroy">
      <div className="ir-ws-banner-padding-tb ir-ws-padding-lr-132 ir-crs-category-inner-container">
        <h3 className="ir-ws-font-700 ir-ws-text-center ir-ws-text-uppercase ir-ws-heading-default-color text-[30px]">
          Courses Category
        </h3>
        <div className="ir-crs-cat-section">
          <div className="ir-crs-cat-inner-section">
            {cardData.map((card, index) => (
              <div
                key={index}
                className="flip-card"
                tabIndex="0"
                onClick={() => {
                  console.log("clicked");
                  navigate(`/courses/category/${card.frontContent.frontText}`);
                }}
              >
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img
                      src={card.frontContent.imageSrc}
                      className="ir-crs-body-parts-icon"
                      alt="icon"
                    />
                    <p className="ir-crs-body-parts-front-text">
                      {card.frontContent.frontText}
                    </p>
                  </div>
                  <div className="flip-card-back">
                    <p className="ir-crs-body-parts-back-text">
                      {card.backContent.backText}
                    </p>

                    <div className="arrow-1"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="ir-ws-banner-padding-tb ir-ws-padding-lr-132 ir-courses-cate-context">
        <div className="ir-courses-cate-context-inner">
          <p className="ir-ws-course-unlock-text">
            Unlock Your Potential in Interventional Radiology with IR4U's
            Expert-Led Video Courses
          </p>
          {loggedIn ? (
            <div
              className="ir-ws-default-btn-container ir-ws-course-list-browsw-btn ir-ws-book-modal-btn"
              onClick={scrollToCoursesList}
            >
              <button className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button ir-ws--create-webinar-submit-button">
                <span>Browse Courses</span>
              </button>
            </div>
          ) : (
            <div
              className="ir-ws-default-btn-container ir-ws-book-modal-btn ir-ws-course-list-browsw-btn"
              onClick={() => {
                navigate("/signup");
              }}
            >
              <button className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button ir-ws--create-webinar-submit-button">
                <span>Join Us</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CoursesCategory;
