import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import CropImageModal from "../CaseFirstPage/CropImageModal";
import { useRef } from "react";
import ConfirmationModal from "../../Courses/AdminCreateCourseTab/ConfirmationModal";
import Swal from "sweetalert2";

const FindAbnormalityQuestion = ({
  onDelete,
  id,
  onFindAbnormalityData,
  question,
  point,
  video,
  image,
  // findAbnormalityData,
}) => {
  const [cropImage, setCropImage] = useState(null);
  const [imageThumbnail, setImageThumbnail] = useState("");
  const [cropImageModal, setCropImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isEditIcon, setIsEditIcon] = useState(true);
  const [questionData, setQuestionData] = useState({
    data: "",
    point: "",
    image: "",
    video: "",
  });
  const [editorContent, setEditorContent] = useState(questionData.data);
  const findAbnormalityRef = useRef(null);
  useEffect(() => {
    setQuestionData({
      data: question || "",
      point: point || { x: 250, y: 250 },
      image: image || "",
      video: video || "",
    });

    setCirclePosition(point || { x: 250, y: 250 });
    setImageThumbnail(image || "");
    setSelectedVideo(video || null);
    setEditorContent(question || "");
  }, []);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const modules = {
    toolbar: [["bold", "italic"]],
  };
  // const [circlePosition, setCirclePosition] = useState({ x: null, y: null });
  const [circlePosition, setCirclePosition] = useState({ x: 250, y: 250 }); // Default position

  const handleImageClick = (evt) => {
    if (!isEditIcon) {
      const rect = evt.target.getBoundingClientRect();
      const offsetX = evt.clientX - rect.left;
      const offsetY = evt.clientY - rect.top;

      setCirclePosition({ x: offsetX, y: offsetY });
    }
  };

  const handleDeleteClick = () => {
    onDelete(id);
  };
  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };
  const handleSelectedCropImage = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const imageUrl = URL.createObjectURL(selectedFile);
      setCropImage(imageUrl);
      setCropImageModal(true);
    }
    setSelectedImage(selectedFile);
  };
  const handleCloseImageCrop = () => {
    setSelectedImage([]);
    setCropImage("");
    setImageThumbnail("");
    setCropImageModal(false);
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
    if (findAbnormalityRef.current) {
      findAbnormalityRef.current.value = "";
    }
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile) {
      const imageUrl = URL.createObjectURL(droppedFile);
      setCropImage(imageUrl);
      setCropImageModal(true);
      setSelectedImage(droppedFile);
    } else {
      Swal.fire({
        text: "Please drop a valid image file.",
        icon: "warning",
      });
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleSelectedVideo = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const videoUrl = URL.createObjectURL(selectedFile);
      setSelectedVideo(videoUrl);
    }
  };
  const handleDeleteSelectedVideo = () => {
    setSelectedVideo(null);
  };
  const handleDropVideo = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile) {
      setSelectedVideo(URL.createObjectURL(droppedFile));
    }
  };

  const handleDragOverVideo = (event) => {
    event.preventDefault();
  };
  const handleEditImage = () => {
    setIsEditIcon(!isEditIcon);

    const updatedQuestionData = {
      ...questionData,
      image: imageThumbnail,
      video: selectedVideo,
      point: circlePosition,
      data: editorContent,
    };

    onFindAbnormalityData(updatedQuestionData);
  };

  return (
    <div className="ir-ws-multiple-choice-question-container">
      <div className="ir-ws-multiple-choice-question-box">
        <ReactQuill
          placeholder="Type Question (Find Abnormality)"
          theme="snow"
          modules={modules}
          value={editorContent}
          onChange={setEditorContent}
        />

        <svg
          height="30"
          viewBox="0 0 48 48"
          width="30"
          xmlns="http://www.w3.org/2000/svg"
          className="ir-ws-creat-case-icon ir-ws-creat-case-icon-margin"
          onClick={toggleDeleteModal}
        >
          <path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z" />
          <path d="M0 0h48v48H0z" fill="none" />
        </svg>
      </div>
      <div className="ir-ws-flex">
        <div
          className="ir-ws-create-course-select-image-box"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <label
            htmlFor={`fileInput${id}`}
            className="ir-ws-create-case-select-image-label"
          >
            <div className="ir-ws-create-case-select-image-container">
              <img
                src={require("../../../assets/images/imgaeicon.png")}
                alt="icon"
                className="ir-ws-create-case-image-icon"
              />
              <input
                type="file"
                id={`fileInput${id}`}
                ref={findAbnormalityRef}
                className="ir-ws-create-case-image"
                onChange={handleSelectedCropImage}
                accept="image/*"
              />
            </div>
          </label>
          <div>
            <p className="ir-ws-create-case-upload-image-text">Upload Image</p>
          </div>
        </div>
      </div>
      {imageThumbnail && (
        <div className="ir-ws-rapid-question-time-add-btn-container">
          <p className="ir-ws-create-case-mark-answer-text">Mark The Answer</p>
          <div className="ir-ws-create-case-selected-image">
            <div
              style={{
                position: "relative",
                display: "inline-block",
                // padding: "25px",
              }}
            >
              <img
                src={imageThumbnail}
                alt="selected"
                onClick={handleImageClick}
              />
              {circlePosition.x !== null && circlePosition.y !== null && (
                <div
                  style={{
                    position: "absolute",
                    left: circlePosition.x - 25,
                    top: circlePosition.y - 25,
                    width: 50,
                    height: 50,
                    border: "2px solid red",
                  }}
                ></div>
              )}
            </div>
            <svg
              height="30"
              viewBox="0 0 48 48"
              width="30"
              xmlns="http://www.w3.org/2000/svg"
              className="ir-ws-creat-case-delete-selected-image"
              onClick={handleCloseImageCrop}
            >
              <path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z" />
              <path d="M0 0h48v48H0z" fill="none" />
            </svg>
            {isEditIcon ? (
              // <svg
              //   viewBox="0 0 512 512"
              //   xmlns="http://www.w3.org/2000/svg"
              //   className="ir-ws-creat-case-edit-selected-image"
              //   onClick={handleEditImage}
              //   title="edit point"
              // >
              //   <path d="M497.9 74.16L437.8 14.06c-18.75-18.75-49.19-18.75-67.93 0l-56.53 56.55l127.1 128l56.56-56.55C516.7 123.3 516.7 92.91 497.9 74.16zM290.8 93.23l-259.7 259.7c-2.234 2.234-3.755 5.078-4.376 8.176l-26.34 131.7C-1.921 504 7.95 513.9 19.15 511.7l131.7-26.34c3.098-.6191 5.941-2.141 8.175-4.373l259.7-259.7L290.8 93.23z" />
              // </svg>
              <i
                className="bi bi-pencil-fill pencile-edit-icon"
                onClick={handleEditImage}
                title="edit point"
              ></i>
            ) : (
              <i
                className="bi bi-check-lg  check-edit-image"
                onClick={handleEditImage}
                title="save point"
              >
                {" "}
              </i>
            )}
          </div>
        </div>
      )}

      {cropImageModal && (
        <CropImageModal
          cropImage={cropImage}
          setImageThumbnail={setImageThumbnail}
          setCropImageModal={setCropImageModal}
          handleCloseImageCrop={handleCloseImageCrop}
        />
      )}
      {showDeleteModal && (
        <ConfirmationModal
          toggleModal={toggleDeleteModal}
          confirmationFunc={handleDeleteClick}
          title="delete"
        />
      )}
    </div>
  );
};

export default FindAbnormalityQuestion;
