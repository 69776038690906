import React, { useEffect, useState } from "react";
import Header from "../../../components/Header/Header";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StarRating from "../CoursePreview/StarRating";
import ModalPlayer from "../CoursePreview/ModalPlayer";
import CryptoJS from "crypto-js";

import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { useModalContext } from "../../../components/WebinarAdminCreateWebinarTab/ModalContext";
import SingleCoursePrevLecture from "./SingleCoursePrevLecture";
import Footer from "../../../components/Footer/Footer";
import HomeJoinContainer from "../../../components/HomeJoinContainer/HomeJoinContainer";
import Swal from "sweetalert2";
import BookNowModal from "../../../components/HomeWebinarFeatureContainer/BookNowModal";
import TermsAndConditionModal from "../../../components/HomeWebinarFeatureContainer/TermsAndConditionModal";

const SingleCourse = () => {
  const { id } = useParams();
  const { userId } = useModalContext();
  const { pathname } = useLocation();

  const [courseData, setCourseData] = useState(null);
  const [pendingCourse, setPendingCourse] = useState(null);
  const [isPendingCourseReview, setIsPendingCourseReview] = useState(false);

  const [favourite, setFavourite] = useState(false);
  const [totalVideoDuration, setTotalVideoDuration] = useState("Loading...");
  const [isPaied, setIspaied] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [termsAndCondition, setTermsAndCondition] = useState(false);
  const [bookNowModal, setBookNowModal] = useState(false);
  const [username, setUsername] = useState("");
  const [userEmail, setUseremail] = useState("");

  const [avgRating, setAvgRating] = useState(null);

  const userProfile = JSON.parse(localStorage.getItem("userProfile") || "[]");
  const isSecondAdmin = userProfile.includes("publisher");

  const isAdmin = localStorage.getItem("loggedInUsername") === "admin";
  const handleTermsAndCondition = () => {
    setTermsAndCondition(!termsAndCondition);
  };

  // console.log("courseData", courseData?.createdBy);
  // console.log("username", username);
  const FIXED_SECRET_KEY =
    "0123456789abcdef0123456789abcdef0123456789abcdef0123456789abcdef";

  const SECRET_KEY = CryptoJS.enc.Hex.parse(FIXED_SECRET_KEY);
  const ALGORITHM = "aes-256-cbc";

  const dataDecrypt = (data) => {
    const [iv, encrypted] = data.split(":");
    const ivBytes = CryptoJS.enc.Hex.parse(iv);
    const encryptedBytes = CryptoJS.enc.Hex.parse(encrypted);
    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: encryptedBytes },
      SECRET_KEY,
      { iv: ivBytes }
    );
    const decryptedString = CryptoJS.enc.Utf8.stringify(decrypted);

    return JSON.parse(CryptoJS.enc.Utf8.stringify(decrypted));
  };

  useEffect(() => {
    const userProfileDetails = async () => {
      try {
        const response = await axios.get(
          `https://backend.ir4u.info/api/v1/user/profile`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
            },
          }
        );

        setUseremail(response.data?.email);
        setUsername(response.data?.username);
        if (response.data?.isEmailVerified) {
          setEmailVerified(true);
        }
      } catch (error) {
        return;
      }
    };
    userProfileDetails();
  }, []);
  const navigate = useNavigate();
  const loggedIn = localStorage.getItem("isLoggedIn") === "true";
  useEffect(() => {
    const url = `https://courses.ir4u.info/api/course/${id}`;
    axios
      .get(url)
      .then((response) => {
        if (response.data.data) {
          const encryptedData = response.data.data;

          const decryptedCourseData = dataDecrypt(encryptedData);

          setCourseData(decryptedCourseData.formData);
        }
        // console.log(response.data.formData);
        // setAvgRating(response.data.formData.averageRating);
        // calculateTotalVideoDuration();
      })
      .catch((error) => {
        return;
      });
  }, [id]);
  useEffect(() => {
    const fetchPendingCourse = async (courseId) => {
      const token = localStorage.getItem("jwtTokenApi1");

      try {
        const response = await axios.get(
          `https://courses.ir4u.info/api/pending-course/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log(`pending course ${id}`, response.data.course);
        if (response.status === 200) {
          setPendingCourse(response.data.course);
          setIsPendingCourseReview(true);
        } else {
          throw new Error("Failed to fetch pending course");
        }
      } catch (error) {
        return;
      }
    };
    fetchPendingCourse();
  }, [id]);

  const [videoSource, setVideoSource] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [tooltipText, setTooltipText] = useState(false);
  const handlefavourite = () => {
    setFavourite(!favourite);
  };
  const handleToggleVideo = (source, title) => {
    setVideoSource(source);
    setVideoTitle(title);
    setVideoModalOpen(!videoModalOpen);
  };

  useEffect(() => {
    const calculateTotalVideoDuration = () => {
      if (courseData && courseData.sections) {
        let sum = 0;
        courseData.sections.forEach((section) => {
          section.lectures?.forEach((lecture) => {
            const durationInSeconds = parseDurationToSeconds(
              lecture.video.duration
            );
            sum += durationInSeconds;
          });
        });
        setTotalVideoDuration(sum);
      }
    };

    const parseDurationToSeconds = (duration) => {
      const [minutes, seconds] = duration?.split(":");
      return parseInt(minutes) * 60 + parseInt(seconds);
    };

    calculateTotalVideoDuration();
    // setTotalVideoDuration(total);
  }, [courseData]);

  const formatTime = (seconds) => {
    const hours = Math.round(seconds / 3600);
    const minutes = Math.round((seconds % 3600) / 60);
    return `${hours} hr ${minutes} min`;
  };

  const shareUrl = window.location.href;

  const showShareModal = () => {
    setShareModal(true);
  };
  const shareCloseModalHandle = () => {
    setShareModal(false);
  };
  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(shareUrl);
    setTooltipText(true);
    setTimeout(() => setTooltipText(false), 2000);
  };

  const handleBuyCourse = () => {
    Swal.fire({
      title: "Success!",
      text: "Payment Successfull",
      icon: "success",
    }).then(() => {
      setIspaied(true);
    });
  };

  function convertUTCToLocalAndFormat(utcTimeString) {
    // Create a JavaScript Date object from the UTC time string
    const utcDateTime = new Date(utcTimeString);

    // Get the local time zone offset in minutes
    const localTimeZoneOffset = new Date().getTimezoneOffset();

    // Convert UTC time to local time
    const localDateTime = new Date(
      utcDateTime.getTime() - localTimeZoneOffset * 60000
    );

    // Get the month and year from local date
    const month = ("0" + (localDateTime.getMonth() + 1)).slice(-2); // Adding leading zero if month < 10
    const year = localDateTime.getFullYear();

    // Format local time as "MM/YYYY"
    const outputString = `${month}/${year}`;

    return outputString;
  }
  function stripHtmlTags(html, maxLength) {
    let doc = new DOMParser().parseFromString(html, "text/html");
    let plainText = doc.body.innerText;
    return plainText.length > maxLength
      ? plainText.slice(0, maxLength) + "..."
      : plainText;
  }
  const buyNewCourse = () => {
    // To navigate to the previous URL
    localStorage.setItem("prevURL", pathname);

    if (loggedIn) {
      if (emailVerified) {
        // handleRegisterUserMeeting(webinar.id);
        // handleBuyNowClick(webinar);
        handleTermsAndCondition();

        // setSendWebinarId(webinar.id);
      } else {
        setBookNowModal(true);
      }
    } else if (!loggedIn) {
      setBookNowModal(true);
    }
  };

  const BuyConfirmationFunc = async () => {
    const courseId = courseData;
    const data = {
      userID: userId,
      CourseID: id,
      userEmail: userEmail,
      successUrl: `${origin}/success`,
      cancelUrl: `${origin}/failed`,
    };
    const token = localStorage.getItem("jwtTokenApi1");

    try {
      const response = await axios.post(
        "https://courses.ir4u.info/api/course/create-checkout-session",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status == 200) {
        const sessionUrl = response.data.paymentDetails.sessionURL;
        window.location.href = sessionUrl;
      }
    } catch (error) {
      return;
    }
  };
  useEffect(() => {
    if (courseData) {
      if (userId) {
        const newUserId = userId;
        const courseId = id;
        checkCourse(newUserId, courseId);
      }
    }
  }, [courseData, userId]);

  async function checkCourse(userId, courseId) {
    try {
      const response = await axios.post(
        "https://courses.ir4u.info/api/check-course",
        {
          userId: userId,
          courseId: courseId,
        }
      );

      if (response.status === 200) {
        if (response.data) {
          setIspaied(true);
        }
      } else {
        // console.log(
        //   "Error while checking course paid or not:",
        //   response.data.message
        // );
        return;
        // setIspaied(false)
      }
    } catch (error) {
      // console.error("Error:", error.message);
      return;
    }
  }

  useEffect(() => {
    // Fetch the favorite status from the API
    const fetchFavoriteStatus = async () => {
      const userID = userId;
      const courseId = id;
      const url = `https://courses.ir4u.info/api/favorites/${userId}`;
      try {
        const response = await axios.get(url);
        const favoriteCourses = response.data;
        const isFavorite = favoriteCourses.some(
          (course) => course.courseId === courseId
        );
        setFavourite(isFavorite);
      } catch (error) {
        return;
      }
    };

    fetchFavoriteStatus();
  }, [userId, id]);

  const handleAddToFavorites = async () => {
    const userID = userId;
    const courseId = id;

    const url = `https://courses.ir4u.info/api/favorites/${userID}`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ courseId }),
      });

      const result = await response.json();

      if (response.ok) {
        setFavourite(true);
        Swal.fire(
          "Success",
          "Course added to favorites successfully!",
          "success"
        );
      } else {
        Swal.fire(
          "Error",
          result.message || "Failed to add course to favorites",
          "error"
        );
      }
    } catch (error) {
      Swal.fire("Error", "An error occurred", "error");
    }
  };

  const handleRemoveFromFavorites = async () => {
    const userID = userId;
    const courseId = id;

    const url = `https://courses.ir4u.info/api/favorites/${userID}/${courseId}`;
    try {
      const response = await fetch(url, {
        method: "DELETE",
      });

      const result = await response.json();

      if (response.ok) {
        setFavourite(false);
        Swal.fire(
          "Success",
          "Course removed from favorites successfully!",
          "success"
        );
      } else {
        Swal.fire(
          "Error",
          result.message || "Failed to remove course from favorites",
          "error"
        );
      }
    } catch (error) {
      Swal.fire("Error", "An error occurred", "error");
    }
  };

  const handleFavouriteClick = async () => {
    if (loggedIn && !isAdmin) {
      if (emailVerified) {
        if (!favourite) {
          await handleAddToFavorites();
        } else {
          await handleRemoveFromFavorites();
        }
      } else {
        Swal.fire(
          "Warning!",
          "Please verify your email to favorite courses.",
          "warning"
        );
      }
    } else if (!loggedIn) {
      Swal.fire("Warning!", "Please log in to favorite courses.", "warning");
    }
  };

  // const addOrUpdateRating = async (rating) => {

  //   const userID= userId;
  //   const courseId= id;

  //   const url = `https://courses.ir4u.info/api/${courseId}/rate`;
  //   const requestBody = {
  //     userId: userID,
  //     rating: 4.5
  //   };
  //     console.log("requestBody data from single page :",requestBody);
  //     try {
  //       const response = await fetch(url, {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           userId: userID,
  //           rating: 4
  //         })
  //       });

  //       if (!response.ok) {
  //         throw new Error(`Error: ${response.statusText}`);
  //       }

  //       const data = await response.json();
  //       console.log(data);
  //       setAvgRating(data?.averageRating)
  //     } catch (error) {
  //       console.error('Failed to add or update rating', error);
  //     }

  // };

  return (
    <div className="ir-ws-courses-detail-page-main-container">
      <Header />
      <div className="ir-ws-banner-padding-tb ir-courses-single-page-main-container">
        <div className="ir-ws-padding-lr-132 ir-courses-single-page-inner-container">
          <div className="ir-ws-flex ir-ws-justify-space-btw ir-course-preview-detail-page-container">
            <div className="ir-ws-width-60 ir-course-preview-detail-container">
              <h1 className="ir-ws-heading-default-color ir-ws-font-700 ir-ws-font-size-xl ir-ws-courses-banner-title ir-course-preview-title-text">
                {isPendingCourseReview
                  ? pendingCourse?.formData.title
                  : courseData
                  ? courseData.title
                  : "Loading..."}
              </h1>
              <p className="ir-ws-app-color ir-course-preview-topic-text">
                {" "}
                {isPendingCourseReview
                  ? pendingCourse?.formData.basicInfo.category
                  : courseData
                  ? courseData.basicInfo.category
                  : "loading.."}
              </p>
              <h2 className="ir-ws-heading-default-color ir-ws-font-700 ir-ws-font-size-m ir-course-preview-subtitle-text">
                {isPendingCourseReview
                  ? pendingCourse?.formData.subtitle
                  : courseData
                  ? courseData.subtitle
                  : "loading.."}
              </h2>
              {courseData ? (
                <p
                  className="ir-default-color ir-course-preview-course-description-text"
                  dangerouslySetInnerHTML={{
                    __html: courseData.description,
                  }}
                />
              ) : (
                <p className="ir-default-color ir-course-preview-course-description-text">
                  Loading
                </p>
              )}

              <p className="ir-ws-app-color ir-course-preview-created-text">
                Created by{" "}
                {isPendingCourseReview
                  ? pendingCourse?.formData?.instructorName
                  : courseData
                  ? courseData?.instructorName
                  : "loading.."}
              </p>
              <p className="ir-default-color ir-course-preview-created-description-text">
                Profile description:{" "}
                {isPendingCourseReview
                  ? pendingCourse?.formData?.instructorProfile
                  : courseData
                  ? courseData?.instructorProfile
                  : "loading.."}
              </p>
              {!isPendingCourseReview && (
                <div className="ir-course-preview-rating-container ir-ws-flex ">
                  <StarRating
                    iconWidth={25}
                    iconHeight={25}
                    iconGap={15}
                    courseId={id}
                    userId={userId}
                    paid={isPaied}
                  />
                </div>
              )}
            </div>
            <div className="ir-ws-width-30 ir-course-prview-video-player-buy-container">
              <div className="ir-ws-position-relative ir-course-prview-video-player-container">
                <div className="ir-course-preview-video-player">
                  <img
                    src={
                      isPendingCourseReview
                        ? pendingCourse?.formData?.imagePreview
                        : courseData?.imagePreview
                    }
                    alt="course thumbnail"
                    className="ir-ws-course-preview-video-thumbnail"
                  />
                </div>
                <div
                  className="ir-ws-position-absolute ir-course-preview-course-button-container"
                  // onClick={() =>
                  //   handleToggleVideo(
                  //     courseData?.videoPreview,
                  //     courseData?.title
                  //   )
                  // }
                  onClick={() =>
                    handleToggleVideo(
                      isPendingCourseReview
                        ? pendingCourse?.formData?.videoPreview
                        : courseData?.videoPreview,
                      isPendingCourseReview
                        ? pendingCourse?.formData?.title
                        : courseData?.title
                    )
                  }
                >
                  <div className="ir-course-preview-course-button-box">
                    <i className="ir-course-preview-btn bi bi-play-circle-fill"></i>
                  </div>
                  <p className="ir-course-preview-course-text">
                    Preview this course
                  </p>
                </div>
              </div>
              <div className="ir-course-preview-buy-course-container">
                <div className="ir-course-preview-primarily-container">
                  <p className="ir-default-color ir-course-preview-primarily-taught-text ir-ws-course-primary-text-align">
                    {isPendingCourseReview
                      ? pendingCourse?.formData?.taught
                      : courseData?.taught}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="ir-single-course-buy-final-main-container">
            <div className="ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center ir-single-course-buy-final-inner-container">
              <div className="ir-default-color ir-ws-width-60 ir-course-language-difficulty-update-container ir-ws-flex ir-ws-justify-space-btw">
                <div className="ir-ws-flex  ir-ws-align-center ir-course-language-container">
                  <img
                    className="single-page-det-icon"
                    src={require("../../../assets/images/language-icon.png")}
                  />
                  {/* <p>
                    {courseData ? courseData?.basicInfo.language : "loading.."}
                  </p> */}

                  <p>
                    {isPendingCourseReview
                      ? pendingCourse?.formData?.basicInfo?.language ||
                        "loading..."
                      : courseData?.basicInfo?.language || "loading..."}
                  </p>
                </div>
                <div className="ir-ws-flex  ir-ws-align-center ir-course-language-container">
                  <img
                    className="single-page-det-icon"
                    src={require("../../../assets/images/level-icon.png")}
                  />
                  {/* <p>
                    {courseData ? courseData?.basicInfo.level : "loading.."}
                  </p> */}
                  <p>
                    {isPendingCourseReview
                      ? pendingCourse?.formData?.basicInfo?.level ||
                        "loading..."
                      : courseData?.basicInfo?.level || "loading..."}
                  </p>
                </div>
                <div className="ir-ws-flex  ir-ws-align-center ir-course-language-container">
                  <img
                    className="single-page-det-icon"
                    src={require("../../../assets/images/update-icon.png")}
                  />
                  {/* <p>
                    Last update:{" "}
                    {convertUTCToLocalAndFormat(courseData?.createdAt)}
                  </p> */}
                  <p>
                    Last update:{" "}
                    {convertUTCToLocalAndFormat(
                      isPendingCourseReview
                        ? pendingCourse?.formData?.createdAt
                        : courseData?.createdAt
                    )}
                  </p>
                </div>
              </div>
              <div className="ir-ws-flex ir-ws-width-30 ir-ws-align-center ir-course-preview-buy-course-box">
                {!loggedIn ||
                (!isPaied && courseData?.createdBy !== username && !isAdmin) ? (
                  <div className="ir-ws-text-center ir-ws-default-btn-container ir-course-preview-buy-course-text">
                    <button
                      className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button ir-ws-course-price-main-btn"
                      style={{
                        ...(isAdmin
                          ? { pointerEvents: "none", cursor: "default" }
                          : {}),
                      }}
                      onClick={buyNewCourse}
                      disabled={isAdmin}
                    >
                      <span className="ir-ws-preview-course-price-text">{`$ ${
                        isPendingCourseReview
                          ? pendingCourse?.formData?.coursePrice
                          : courseData?.coursePrice
                      }`}</span>
                    </button>
                  </div>
                ) : (
                  <div className="ir-ws-text-center ir-ws-default-btn-container ir-course-preview-buy-course-text">
                    <button
                      className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button ir-ws-course-price-main-btn"
                      style={{
                        ...(isAdmin
                          ? { pointerEvents: "none", cursor: "default" }
                          : {}),
                      }}
                      disabled={isAdmin}
                    >
                      <span
                        className="ir-ws-preview-course-price-text"
                        style={{ cursor: "none" }}
                      >
                        Watch Videos
                      </span>
                    </button>
                  </div>
                )}
                {!isPendingCourseReview && (
                  <div className="ir-course-preview-heart-box">
                    {!favourite ? (
                      <i
                        className="bi bi-suit-heart"
                        onClick={handleFavouriteClick}
                        disabled={isAdmin}
                      ></i>
                    ) : (
                      <i
                        className="bi bi-suit-heart-fill"
                        onClick={handleFavouriteClick}
                        disabled={isAdmin}
                      ></i>
                    )}
                  </div>
                )}

                <div
                  className="ir-course-preview-heart-box"
                  onClick={showShareModal}
                >
                  <i className="material-symbols-outlined ir-ws-create-course-g-font">
                    share
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="ir-ws-padding-lr-132 ir-course-preview-learn-description-section">
        <div className="ir-ws-single-course-point-container">
          {isPendingCourseReview
            ? pendingCourse?.formData?.learnPointArray.length !== 0
            : courseData?.learnPointArray.length !== 0 && (
                <div className="ir-ws-single-course-learn-point-container">
                  <div>
                    <p className="ir-single-learn-point-text">
                      What You'll learn
                    </p>
                  </div>
                  {courseData?.learnPointArray?.map((point, index) => (
                    <div className="ir-single-learn-point-box">
                      <div className="ir-single-learn-point-list-text">
                        <i class="bi bi-check2"></i>{" "}
                        <p className="ir-ws-point-text">{point}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}

          {courseData?.courseIncludesArray.length !== 0 && (
            <div className="ir-ws-single-course-learn-point-container">
              <div>
                <p className="ir-single-learn-point-text">
                  This Course includes
                </p>
              </div>
              {courseData?.courseIncludesArray?.map((item, index) => (
                <div className="ir-single-learn-point-box">
                  <div className="ir-single-learn-point-list-text">
                    {item === "Duration" && <i className="bi bi-clock"></i>}
                    {item === "Exercises" && (
                      <i className="material-symbols-outlined">contract</i>
                    )}
                    {item === "Downloadable Resources" && (
                      <i className="material-symbols-outlined">download</i>
                    )}
                    {item === "Access on mobile" && (
                      <i className="bi bi-phone"></i>
                    )}
                    {item === "Duration" ? (
                      <p className="ir-ws-point-text">
                        {formatTime(totalVideoDuration)}
                      </p>
                    ) : (
                      <p className="ir-ws-point-text">{item}</p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div> */}

      <div className="ir-ws-padding-lr-132 ir-course-preview-learn-description-section">
        <div className="ir-ws-single-course-point-container">
          {/* What You'll Learn Section */}
          {(isPendingCourseReview
            ? pendingCourse?.formData?.learnPointArray?.length !== 0
            : courseData?.learnPointArray?.length !== 0) && (
            <div className="ir-ws-single-course-learn-point-container">
              <div>
                <p className="ir-single-learn-point-text">What You'll Learn</p>
              </div>
              {(isPendingCourseReview
                ? pendingCourse?.formData?.learnPointArray
                : courseData?.learnPointArray
              )?.map((point, index) => (
                <div className="ir-single-learn-point-box" key={index}>
                  <div className="ir-single-learn-point-list-text">
                    <i className="bi bi-check2"></i>{" "}
                    <p className="ir-ws-point-text">{point}</p>
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* This Course Includes Section */}
          {(isPendingCourseReview
            ? pendingCourse?.formData?.courseIncludesArray?.length !== 0
            : courseData?.courseIncludesArray?.length !== 0) && (
            <div className="ir-ws-single-course-learn-point-container">
              <div>
                <p className="ir-single-learn-point-text">
                  This Course Includes
                </p>
              </div>
              {(isPendingCourseReview
                ? pendingCourse?.formData?.courseIncludesArray
                : courseData?.courseIncludesArray
              )?.map((item, index) => (
                <div className="ir-single-learn-point-box" key={index}>
                  <div className="ir-single-learn-point-list-text">
                    {item === "Duration" && <i className="bi bi-clock"></i>}
                    {item === "Exercises" && (
                      <i className="material-symbols-outlined">contract</i>
                    )}
                    {item === "Downloadable Resources" && (
                      <i className="material-symbols-outlined">download</i>
                    )}
                    {item === "Access on mobile" && (
                      <i className="bi bi-phone"></i>
                    )}
                    {item === "Duration" ? (
                      <p className="ir-ws-point-text">
                        {formatTime(totalVideoDuration)}
                      </p>
                    ) : (
                      <p className="ir-ws-point-text">{item}</p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* {courseData && (
        <SingleCoursePrevLecture
          userId={userId}
          isPaid={isPaied}
          sections={courseData?.sections}
          allformdata={courseData}
          courseId={id}
        />
      )} */}

      {isPendingCourseReview
        ? pendingCourse?.formData && (
            <SingleCoursePrevLecture
              userId={userId}
              isPaid={true}
              sections={pendingCourse?.formData?.sections}
              allformdata={pendingCourse?.formData}
              courseId={id}
              isCreator={courseData?.createdBy === username}
            />
          )
        : courseData && (
            <SingleCoursePrevLecture
              userId={userId}
              isPaid={isPaied}
              sections={courseData?.sections}
              allformdata={courseData}
              courseId={id}
              isCreator={courseData?.createdBy === username}
            />
          )}

      {videoModalOpen && (
        <ModalPlayer
          videoSource={videoSource}
          videoTitle={videoTitle}
          onClick={handleToggleVideo}
        />
      )}
      {shareModal && (
        <div className="ir-ws-position-fixed ir-ws-sign-popup-container ir-ws-share-main-container">
          <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
            <div className="iir-ws-signup-content-container">
              <div
                className="ir-ws-position-absolute ir-ws-signup-close-container"
                onClick={shareCloseModalHandle}
              >
                <i className="bi bi-x-lg"></i>
              </div>
              <div className="ir-ws-signup-content-inner-container">
                <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                  Share Case
                </h3>
              </div>
              <div className="ir-share-modal-inside-content">
                <p>Invite your friends through social network</p>
                <div className="ir-share-container">
                  <div className="ir-share-icon-container ir-share-icon-container-hover">
                    <FacebookShareButton
                      url={shareUrl}
                      quote={"Dummy text!"}
                      hashtag="#ir4u"
                    >
                      <FacebookIcon size={50} round />
                    </FacebookShareButton>
                  </div>
                  <div className="ir-share-icon-container ir-share-icon-container-hover">
                    <WhatsappShareButton url={shareUrl} quote={"Dummy text!"}>
                      <WhatsappIcon size={50} round />
                    </WhatsappShareButton>
                  </div>
                  <div className="ir-share-icon-container ir-share-icon-container-hover">
                    {!tooltipText ? (
                      <div
                        className="ir-share-icon-copy-link ir-share-icon-link"
                        onClick={handleCopyLink}
                        title="Copy Link"
                      >
                        <i className="bi bi-link-45deg"></i>
                      </div>
                    ) : (
                      <div
                        className="ir-share-icon-copy-link ir-share-icon-link"
                        title="Link Copied"
                      >
                        <i className="bi bi-check-lg"></i>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {bookNowModal && loggedIn && !emailVerified && (
        <BookNowModal
          type="Verify Email"
          emailVerified={!emailVerified}
          loggedIn={loggedIn}
          username={username}
          setBookNowModal={setBookNowModal}
          category={"course"}
        />
      )}
      {bookNowModal && !loggedIn && (
        <BookNowModal
          type="Sign up"
          loggedIn={!loggedIn}
          setBookNowModal={setBookNowModal}
          category={"course"}
        />
      )}
      {termsAndCondition && (
        <TermsAndConditionModal
          handleTermsAndCondition={handleTermsAndCondition}
          id={id}
          confirmationFunc={BuyConfirmationFunc}
        />
      )}
      <HomeJoinContainer />
      <Footer />
    </div>
  );
};

export default SingleCourse;
