import React, { useEffect, useState, useContext } from "react";
import axios from "../../api/axios";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { RootStore } from "../../store/store";
import ChatContent from "../../components/Chat/ChatContent/ChatContent";
import ChatNavigation from "../../components/Chat/ChatNavigation/ChatNavigation";
import ChatProfile from "../../components/Chat/ChatProfile/ChatProfile";
import ChatSidebar from "../../components/Chat/ChatSidebar/ChatSidebar";
import "./ChatRoom.scss";
import ChatVerifyEmail from "./ChatVerifyEmail";
import { useModalContext } from "../../components/WebinarAdminCreateWebinarTab/ModalContext";

let roomHistoryInterval = null;

const ChatRoom = (props) => {
  const routeParams = useParams();
  let location = useLocation();
  // console.log("location", location);
  const { handleChatChange } = useModalContext();
  const navigate = useNavigate();
  const [store, setStore] = useContext(RootStore);
  const [token, setToken] = useState("");
  const [chatRooms, setChatRooms] = useState([]);
  const [chatData, setChatData] = useState([]);
  const [activeChatRoom, setActiveChatRoom] = useState(null);
  const [navMenuItemActive, setNavMenuItemActive] = useState(0);
  const { userStore, chatStore, socketConnection } = store;
  const [isChatLoading, setIsChatLoading] = useState(false);

  const [updateChatRoomFlag, setUpdateChatRoomFlag] = useState(false);
  const [isEmailVerified, setIsemailverified] = useState(false);

  const fetchData = async () => {
    const isAdmin = localStorage.getItem("loggedInUsername") === "admin";
    try {
      const response = await axios.get(
        "https://backend.ir4u.info/api/v1/user/profile",
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
            "Content-Type": "application/json",
          },
        }
      );
      userStore.profile = response?.data;
      setStore({ ...store, userStore });
      if (!response.data.isEmailVerified && !isAdmin) {
        // console.log('hi this is saheb and soon')
        setIsemailverified(true);
      }
    } catch (error) {
      // console.log(error);
      return;
    }
  };

  // useEffect(() => {
  //   const token = localStorage.getItem("jwtTokenApi1");
  //   fetchData();
  //   if (!token) {
  //     navigate("/signin?redirect=chat-room");
  //   } else {
  //     setToken(token);
  //     // getProducts() commented for the reason because of this line the chat is not loading in the begining of the page need to fix it
  //     getChatRooms();
  //     if (Object?.keys(userStore?.profile)?.length <= 0) {
  //       // getProfile();
  //       fetchData();
  //     }
  //   }
  //   //if routeparams.id exists, history.replace keep chat-room
  // }, []);

  useEffect(() => {
    const token = localStorage.getItem("jwtTokenApi1");

    if (!token) {
      navigate("/signin?redirect=chat-room");
    } else {
      setToken(token);
      getChatRooms();

      if (Object.keys(userStore?.profile ?? {}).length <= 0) {
        fetchData();
      }
    }
  }, []);

  // useEffect(() => {
  //   const token = localStorage.getItem("jwtTokenApi2");
  //   fetchData();
  //   setToken(token);
  //   // getProducts() commented for the reason because of this line the chat is not loading in the begining of the page need to fix it
  //   getChatRooms();
  //   if (Object.keys(userStore.profile).length <= 0) {
  //     getProfile();
  //   }
  // }, []);

  //   useEffect(() => {
  //     console.log("Socket", props.socket);
  //     if (!props.socket.connected) {
  //       props.socket.connect();
  //     }
  //   }, [props.socket]);

  //   const getProfile = async () => {
  //     const response = await axios({
  //       url: "user/profile",
  //       method: "get",
  //       withCredentials: false,
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + localStorage.getItem("jwtTokenApi2"),
  //       },
  //     });
  //     console.log("Get Chat History", response);
  //     // setChatRooms(response?.data?.data);
  //     userStore.profile = response?.data?.data;
  //     setStore({ ...store, userStore });
  //   };

  const getChatRooms = async (userProvidedId = null) => {
    const response = await axios({
      url: "chat/group",
      method: "get",
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
      },
    });
    let chatData = response?.data?.data || [];
    let chatHistoryObject = {};
    let chatHistory = { ...chatStore.chatHistory };
    chatData.map((d) => {
      // creatign default chat history for all the grups
      if (!Object.keys(chatHistory).includes(d.id)) {
        chatHistoryObject[d.id] = [];
      }

      d.image = d.image.indexOf(",") ? d.image.split(",") : [d.image];
      d["images"] = [...d.image];
      d["deleteImages"] = [];
      return d;
    });

    setChatRooms(chatData);

    chatStore.groups = chatData;
    // chatStore.chatHistory = { ...chatHistoryObject };
    chatStore.chatHistory = { ...chatStore.chatHistory, ...chatHistory };

    setStore({ ...store, chatStore: { ...chatStore } });

    if (chatData.length > 0) {
      //   let id =
      //     routeParams?.id && routeParams.id !== ""
      //       ? routeParams.id
      //       : chatData[chatData.length - 1].id;

      // let id =
      //   routeParams?.id && routeParams.id !== ""
      //     ? routeParams.id
      //     : chatData[0]?.id;
      let id =
        userProvidedId ||
        (routeParams?.id && routeParams.id !== ""
          ? routeParams.id
          : chatData[0]?.id);

      setActiveChatRoom(parseInt(id));
      getChatHistory(id);
      // navigate('/chat-room/' + id);
    }
  };

  // const getChatRooms = async (userProvidedId = null) => {
  //   const response = await axios({
  //     url: "chat/group",
  //     method: "get",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
  //     },
  //   });

  //   console.log("Get Chat History", response);

  //   console.log("Get Chat History", response?.data?.data);
  //   console.log(
  //     "Chat History from the store before update",
  //     chatStore.chatHistory
  //   );

  //   let chatData = response?.data?.data || [];
  //   let chatHistory = { ...chatStore.chatHistory }; // Preserve old history

  //   chatData.forEach((d) => {
  //     if (!chatHistory[d.id]) {
  //       chatHistory[d.id] = []; // Only add missing rooms
  //     }

  //     d.image = d.image.includes(",") ? d.image.split(",") : [d.image];
  //     d["images"] = [...d.image];
  //     d["deleteImages"] = [];
  //   });

  //   setChatRooms(chatData);
  //   chatStore.groups = chatData;
  //   chatStore.chatHistory = { ...chatStore.chatHistory, ...chatHistory }; // Merge instead of overwrite

  //   setStore({ ...store, chatStore: { ...chatStore } });

  //   console.log(
  //     "Chat History from the store after update",
  //     chatStore.chatHistory
  //   );

  //   console.log("chatData", chatData);

  //   if (chatData.length > 0) {
  //     // let id =
  //     //   routeParams?.id && routeParams.id !== ""
  //     //     ? routeParams.id
  //     //     : chatData[0]?.id;

  //     // 🔹 Use the userProvidedId if available, otherwise, fall back to the default logic
  //     let id =
  //       userProvidedId ||
  //       (routeParams?.id && routeParams.id !== ""
  //         ? routeParams.id
  //         : chatData[0]?.id);

  //     console.log("this is the id of the chat", id);

  //     if (id) {
  //       setActiveChatRoom(parseInt(id));
  //       getChatHistory(id);
  //     } else {
  //       console.log("id not found skipped the get chat history", id);
  //     }
  //     console.log(
  //       "Chat History from the store after getting history",
  //       chatStore.chatHistory
  //     );
  //   }

  //   console.log(
  //     "Chat History from the store at the end",
  //     chatStore.chatHistory
  //   );
  // };

  // const getChatHistory = async (id) => {
  //   if (!id) return; // Avoid calling with undefined ID

  //   setIsChatLoading(true);
  //   setActiveChatRoom(id);

  //   const response = await axios({
  //     url: `chat/history/${id}`,
  //     method: "get",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
  //     },
  //   });

  //   setIsChatLoading(false);
  //   console.log("Get Chat History", response);

  //   let chatHistoryData = { ...chatStore.chatHistory };

  //   if (!chatHistoryData[id]) {
  //     chatHistoryData[id] = [];
  //   }

  //   // Append new messages instead of overwriting
  //   chatHistoryData[id] = [
  //     ...chatHistoryData[id],
  //     ...(response?.data?.data || []),
  //   ];

  //   // Only update store if there is new data
  //   if (response?.data?.data?.length > 0) {
  //     setStore({
  //       ...store,
  //       chatStore: {
  //         ...chatStore,
  //         chatHistory: { ...chatHistoryData },
  //       },
  //     });
  //   }

  //   setChatData(chatHistoryData);
  //   console.log("Updated chatHistoryData:", chatHistoryData);
  // };

  useEffect(() => {
    if (activeChatRoom != null) {
      getChatHistory(activeChatRoom);
    }
  }, [activeChatRoom]);

  const updateChatRoom = (data) => {
    setUpdateChatRoomFlag(data);
  };

  const getChatHistory = async (id) => {
    setIsChatLoading(true);
    setActiveChatRoom(id);
    const response = await axios({
      url: "chat/history/" + id,
      method: "get",
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
      },
    });
    setIsChatLoading(false);
    if (response.status === 200) {
      handleChatChange(
        response?.data?.chatGroupDetails?.id,
        response?.data?.chatGroupDetails?.ads
      );
    }
    let chatHistoryData = { ...chatStore.chatHistory };
    if (!Object.keys(chatHistoryData).includes(id)) {
      chatHistoryData[id] = [];
    }
    chatHistoryData[id] = response?.data?.data;
    setStore({
      ...store,
      chatStore: {
        ...chatStore,
        chatHistory: { ...chatHistoryData },
      },
    });

    setChatData(chatHistoryData);
    //    console.log("chatHistoryData", chatHistoryData)
    // props.socket.emit('join_room', id);
  };

  const getProducts = async () => {
    try {
      let response = await axios({
        url: "payment/product",
        method: "get",
        withCredentials: false,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Barier " + token,
        },
      });
      //   debugger
      response = response.data;
      if (response?.status === 200) {
        let allProducts =
          (response?.data?.length > 0 &&
            response?.data?.filter((product) => product.active == true)) ||
          [];
        let groups = [...chatStore.groups];
        if (groups?.length > 0) {
          groups.map((group) => {
            let index = allProducts.findIndex(
              (product) => product.name === group.name
            );
            if (index !== -1) {
              group["paymentInfo"] = allProducts[index];
            }
            return group;
          });
        }
        setChatRooms(groups);

        chatStore.groups = groups;
        setStore({ ...store, chatStore: { ...chatStore } });
      }

      //* check response status code ==200
      //* filter the response active = true
      // get chat room object from context
      // map chat room with products based on room name
      // store product as a value(for a key stripeProducts) within chat room object

      // const response = await fetch("http://3.84.171.136:5000/payment/product");
      // const json = await response.json();
    } catch (err) {
      console.error(err);
    }
  };

  // useEffect(() => {
  // 	getProducts();
  // }, []);

  return (
    <div className="ir-ws-flex ir-bg-white ir-chat-room-main-container">
      <ChatNavigation
        navMenuItemActive={navMenuItemActive}
        setNavMenuItemActive={setNavMenuItemActive}
      />
      <ChatSidebar
        // socket={props.socket}
        data={chatStore.groups}
        getChatHistory={getChatHistory}
        activeChatRoom={activeChatRoom}
        updateActiveChat={setActiveChatRoom}
        navMenuItemActive={navMenuItemActive}
        updateChatRoom={updateChatRoom}
        updateChatRoomFlag={updateChatRoomFlag}
        isChatLoading={isChatLoading}
        updateChatLoading={setIsChatLoading}
        setNavMenuItemActive={setNavMenuItemActive}
        getChatRooms={getChatRooms}
      />
      <div className="ir-ws-flex ir-ws-width-100 ir-split">
        <ChatContent
          //   socket={props.socket}
          data={chatStore.chatHistory}
          activeChatRoom={activeChatRoom}
          navMenuItemActive={navMenuItemActive}
          updateActiveChat={setActiveChatRoom}
          profile={userStore.profile}
          getChatHistory={getChatHistory}
          isChatLoading={isChatLoading}
          updateChatLoading={setIsChatLoading}
          chatData={chatData}
          getChatRooms={getChatRooms}
        />
        <ChatProfile
          activeChatRoom={activeChatRoom}
          updateChatRoom={updateChatRoom}
          updateChatRoomFlag={updateChatRoomFlag}
          getChatHistory={getChatHistory}
          chatData={chatData}
        />
      </div>
      {isEmailVerified && <ChatVerifyEmail />}
    </div>
  );
};

export default ChatRoom;
