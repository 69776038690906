import React, { useEffect, useState } from "react";
import ModalPlayer from "./ModalPlayer";
import "./CoursePrevLecture.scss";
import { updateLectureVideoDuration } from "../Store/CourseSlice";
import { useDispatch } from "react-redux";
const CoursePrevLecture = ({ sections, allformdata }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [videoSource, setVideoSource] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const [videoDurations, setVideoDurations] = useState({});
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const dispatch = useDispatch();
  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleToggleVideo = (source, title) => {
    setVideoSource(source);
    setVideoTitle(title);
    setVideoModalOpen(!videoModalOpen);
    document.body.classList.toggle("modal-open");
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const getVideoDuration = async (videoUrl) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.onloadedmetadata = () => {
        resolve(formatTime(video.duration));
      };
      video.onerror = () => {
        reject(new Error("Unable to load video"));
      };
      video.src = videoUrl;
      video.load();
    });
  };

  const durationInSeconds = (formattedTime) => {
    const [minutes, seconds] = formattedTime.split(":").map(Number);
    return minutes * 60 + seconds;
  };

  useEffect(() => {
    const fetchVideoDurations = async () => {
      const durations = {};
      for (const section of sections) {
        const sectionId = section.id;
        // console.log("this is the section id", sectionId);
        let sectionDuration = 0;
        for (const lecture of section.lectures) {
          try {
            const duration = await getVideoDuration(lecture.video.videoUrl);
            dispatch(
              updateLectureVideoDuration({
                sectionId: sectionId,
                lectureId: lecture.id,
                duration: duration,
              })
            );
            durations[lecture.id] = duration;
            sectionDuration += durationInSeconds(duration);
          } catch (error) {
            console.error("Error fetching video duration:", error);
          }
        }
        durations[section.id] = formatTime(sectionDuration);
      }
      setVideoDurations(durations);
    };

    fetchVideoDurations();
  }, [sections]);

  return (
    <div>
      <section className="ir-course-accordion-section">
        <div className="ir-course-accordian-container ir-ws-padding-lr-132">
          <p className="ir-courses-course-content-text">Courses Content</p>
          <p
            className="ir-courses-course-expand-sections-text"
            onClick={() => setActiveIndex(activeIndex === null ? -1 : null)}
          >
            {activeIndex === null
              ? "Expand all sections"
              : "Collapse all sections"}
          </p>
          {sections?.map((section, index) => (
            <div key={section.id} className="ir-ws-accordion-container">
              <div
                className="ir-ws-accordion-header"
                onClick={() => handleClick(index)}
              >
                <div className="ir-ws-section-accordian-number">
                  {activeIndex !== index ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="dorpdown-svg-accordian"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m19.5 8.25-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="dorpdown-svg-accordian"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m4.5 15.75 7.5-7.5 7.5 7.5"
                      />
                    </svg>
                  )}

                  <p className="ir-course-accordion-section-text">
                    Section - {index + 1} {section.content}
                  </p>
                </div>
                <div className="ir-course-section-accordian-duration">
                  {section.lectures.length} Lectures -{" "}
                  {videoDurations[section.id] || "Loading..."}
                </div>
              </div>
              {(activeIndex === index || activeIndex === -1) && (
                <>
                  {section?.lectures?.map((lecture, index) => (
                    <div
                      className="ir-course-accordian-active-container"
                      key={lecture.id}
                    >
                      {lecture?.video?.videoUrl && (
                        <div className="ir-ws-accordian-content">
                          <div className="ir-ws-lecture-checkbox-name-container">
                            <div class="checkbox" style={{ marginLeft: "0px" }}>
                              <input
                                type="checkbox"
                                id={`videoCheckbox${lecture.id}`}
                                class="checkbox__input"
                              />
                              <label
                                htmlFor={`videoCheckbox${lecture.id}`}
                                class="checkbox__label"
                              >
                                {/* Checkbox 1 */}
                              </label>
                            </div>
                            <div className="lecture-name">
                              {" "}
                              {index + 1} Lectures -{lecture.content}{" "}
                            </div>
                            <svg
                              class="feather feather-play"
                              fill="none"
                              height="24px"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              viewBox="0 0 24 24"
                              width="24px"
                              xmlns="http://www.w3.org/2000/svg"
                              onClick={() =>
                                handleToggleVideo(
                                  lecture.video.videoUrl,
                                  lecture.content
                                )
                              }
                            >
                              <polygon points="5 3 19 12 5 21 5 3" />
                            </svg>
                          </div>
                          <div className="ir-ws-lecture-duration">
                            {" "}
                            {videoDurations[lecture.id] || "00:00"}
                          </div>
                        </div>
                      )}

                      {lecture?.document?.docName && (
                        <div className="ir-ws-accordian-content">
                          <div className="ir-ws-lecture-checkbox-name-container">
                            <div class="checkbox" style={{ marginLeft: "0px" }}>
                              <input
                                type="checkbox"
                                id={`documentCheckbox${lecture.id}`}
                                class="checkbox__input"
                              />
                              <label
                                htmlFor={`documentCheckbox${lecture.id}`}
                                class="checkbox__label"
                              >
                                {/* Checkbox 1 */}
                              </label>
                            </div>
                            <div className="lecture-name">
                              {lecture.document.docName}
                            </div>
                            <i class="bi bi-file-earmark course-file"></i>
                          </div>
                          <div
                            className="ir-ws-lecture-duration"
                            title="Download"
                          >
                            {/* {" "}
                          {videoDurations[lecture.id] || "00:00"} */}
                            <a href={lecture.document.docUrl} download>
                              <i class="bi bi-download course-doc-download-icon"></i>
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>
          ))}
        </div>
        {videoModalOpen && (
          <ModalPlayer videoSource={videoSource} onClick={handleToggleVideo} />
        )}
      </section>
      <div className="ir-ws-padding-lr-132 ir-course-preview-learn-description-section">
        <div className="ir-ws-single-course-point-container">
          {allformdata?.requirementsArray.length !== 0 && (
            <div className="ir-ws-single-course-requirement-point-container">
              <div>
                <p className="ir-single-learn-point-text">Requirements</p>
              </div>
              <div className="ir-ws-requirements-arrray-box">
                {allformdata?.requirementsArray?.map((point, index) => (
                  <div className="ir-single-requirement-point-box">
                    <div className="ir-single-requirement-point-list-text">
                      <i class="bi bi-circle-fill"></i>
                      <p className="ir-ws-point-text">{point}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <section className="ir-course-requirement-description-section">
        <div className="ir-course-requirement-description-container ir-ws-padding-lr-132">
          <p className="ir-course-requirement-text">Description</p>
          <p className="ir-course-requirement-text-description ir-default-color ">
            {allformdata?.completeDescription}
          </p>
        </div>
      </section>
    </div>
  );
};

export default CoursePrevLecture;
