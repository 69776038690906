import React from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "./Carousel";
import "./Banner.scss";

const Banner = () => {
  const navigate = useNavigate();

  const chatNavigate = () => {
    navigate("/chat-room");
  };

  const caseNavigate = () => {
    navigate("/cases");
  };

  const coursesNavigate = () => {
    navigate("/courses");
  };

  return (
    <div className="ir-ws-position-relative ir-ws-gray-bg ir-ws-banner-main-container">
      <Carousel>
        <div className="ir-ws-banner-height ir-ws-position-relative ir-ws-banner-item">
          <div className="ir-ws-padding-lr-132 ir-ws-width-50 ir-ws-banner-padding-tb ir-ws-banner-content-container">
            <h5 className="ir-ws-app-color ir-ws-banner-highlight-text">
              <span className="animate__animated animate__fadeInDown ir-ws-animate-learn">
                For Radiologists, &nbsp;
              </span>
              <span className="animate__animated animate__fadeInDown ir-ws-animate-practice">
                the Industry ,&nbsp;{" "}
              </span>
              <span className="animate__animated animate__fadeInDown ir-ws-animate-excel">
                {" "}
                and Professional
              </span>
            </h5>
            <h1 className="animate__animated animate__fadeInLeft ir-ws-heading-default-color ir-ws-banner-heading ir-ws-font-size-xxl ir-ws-line-height-xxl">
              Connect with Experts across
              <br /> <span className="ir-ws-app-color">the IR field</span>
            </h1>
            <p className="animate__animated animate__fadeInUp ir-ws-banner-para-font">
              Engage in real time conversions through the IR4U Chat Feature.
              Seek advice, discuss complex cases, gain various professional
              insights, and build a peer network.
            </p>
            <div className="animate__animated animate__slideInUp ir-ws-banner-btn-container">
              <button
                className="ir-ws-app-bg ir-color-white ir-ws-no-border ir-ws-banner-btn"
                onClick={chatNavigate}
              >
                <span>Explore IR4U Chat</span>
              </button>
            </div>
          </div>
          <div className="ir-ws-width-50 ir-ws-position-relative animate__animated animate__fadeIn ir-ws-height-100-percent ir-ws-banner-img-container">
            <img
              src={require("../../assets/images/home-banner-carousel.webp")}
              className="ir-ws-position-absolute ir-ws-hme-banner-carousel-bg-img non-draggable"
              alt="img"
            />
            <img
              src={require("../../assets/images/doc-2.webp")}
              className="ir-ws-position-absolute ir-ws-carousel-item-img non-draggable"
              alt="banner carousel one"
              style={{ userSelect: "none", pointerEvents: "none" }}
            />
          </div>
        </div>
        <div className="ir-ws-banner-height ir-ws-position-relative ir-ws-banner-item">
          <div className="ir-ws-padding-lr-132 ir-ws-width-50 ir-ws-banner-padding-tb ir-ws-banner-content-container">
            <h5 className="ir-ws-app-color ir-ws-banner-highlight-text">
              <span className="animate__animated animate__fadeInDown ir-ws-animate-learn">
                Learn, &nbsp;
              </span>
              <span className="animate__animated animate__fadeInDown ir-ws-animate-practice">
                Practice,&nbsp;{" "}
              </span>
              <span className="animate__animated animate__fadeInDown ir-ws-animate-excel">
                Excel
              </span>
            </h5>
            <h1 className="animate__animated animate__fadeInLeft ir-ws-heading-default-color ir-ws-font-size-xxl ir-ws-line-height-xxl ir-ws-banner-heading">
              Unlock Mastery in{" "}
              <span className="ir-ws-app-color">Interventional Radiology</span>
            </h1>
            <p className="animate__animated animate__fadeInUp ir-ws-banner-para-font">
              IR4U will help you become an expert in the Interventional
              Radiology field through Case Studies, P2P Chats & State of the Art
              digital testing tools.
            </p>
            <div className="animate__animated animate__slideInUp ir-ws-banner-btn-container">
              <button
                className="ir-ws-app-bg ir-color-white ir-ws-no-border ir-ws-banner-btn"
                onClick={caseNavigate}
              >
                <span>Know How Cases Works</span>
              </button>
            </div>
          </div>
          <div className="ir-ws-width-50 ir-ws-position-relative animate__animated animate__fadeIn ir-ws-height-100-percent ir-ws-banner-img-container">
            <img
              src={require("../../assets/images/home-banner-carousel.webp")}
              className="ir-ws-position-absolute ir-ws-hme-banner-carousel-bg-img non-draggable"
              alt="img"
            />
            <img
              src={require("../../assets/images/doc-3.webp")}
              className="ir-ws-position-absolute ir-ws-carousel-item-img non-draggable"
              alt="banner carousel one"
            />
          </div>
        </div>
        <div className="ir-ws-banner-height ir-ws-position-relative ir-ws-banner-item">
          <div className="ir-ws-padding-lr-132 ir-ws-width-50 ir-ws-banner-padding-tb ir-ws-banner-content-container">
            <h5 className="ir-ws-app-color ir-ws-banner-highlight-text">
              <span className="animate__animated animate__fadeInDown ir-ws-animate-learn">
                Learn, &nbsp;
              </span>
              <span className="animate__animated animate__fadeInDown ir-ws-animate-practice">
                Practice,&nbsp;{" "}
              </span>
              <span className="animate__animated animate__fadeInDown ir-ws-animate-excel">
                Excel
              </span>
            </h5>
            <h1 className="animate__animated animate__fadeInLeft ir-ws-heading-default-color ir-ws-banner-heading ir-ws-font-size-xxl ir-ws-line-height-xxl">
              Access Free IR Courses, Cases &{" "}
              <span className="ir-ws-app-color">Presentations</span>
            </h1>
            <p className="animate__animated animate__fadeInUp ir-ws-banner-para-font">
              Sign up now, to access <b>IR4U's</b> study materials which include
              field level case studies. Learn about the best practices and
              techniques in Interventional Radiology from seasoned mentors.
            </p>
            <div className="animate__animated animate__slideInUp ir-ws-banner-btn-container">
              <button
                className="ir-ws-app-bg ir-color-white ir-ws-no-border ir-ws-banner-btn"
                onClick={coursesNavigate}
              >
                <span>Explore Coursess</span>
              </button>
            </div>
          </div>
          <div className="ir-ws-width-50 ir-ws-position-relative animate__animated animate__fadeIn ir-ws-height-100-percent ir-ws-banner-img-container">
            <img
              src={require("../../assets/images/home-banner-carousel.webp")}
              className="ir-ws-position-absolute ir-ws-hme-banner-carousel-bg-img non-draggable"
              alt="img"
            />
            <img
              src={require("../../assets/images/doc-1.webp")}
              className="ir-ws-position-absolute ir-ws-carousel-item-img non-draggable"
              alt="banner carousel one"
            />
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default Banner;
