// import { useState, useEffect, useContext } from 'react';
// import { RootStore } from './store/store';

// const Socket = ({ socket }) => {
//     const [store, setStore] = useContext(RootStore)
//     const [isConnected, setIsConnected] = useState(socket.connected);
//     const [lastPong, setLastPong] = useState(null);
//     const { socketConnection, chatStore, userStore } = store

//     console.log("Socket Store", socket)

//     const groupId = chatStore?.groups[0]?.id;
//     console.log("group id", groupId)

//     const updateSocketStore = (key, value) => {
//         socketConnection[key] = value;
//         console.log("Update Socket Store", socketConnection)
//         setStore({ ...store, socketConnection });
//     };

//     const updateChatStore = (key, value) => {
//         // debugger;
//         // let roomId = value[0].groupId;
//         // chatStore[key][roomId] = value;
//         chatStore[key] = value;
//         console.log("Update Chat Store", chatStore)
//         setStore({ ...store, chatStore });
//     };

//     const updateUserStore = (key, value) => {
//         userStore[key] = value;
//         userStore.profile = value;
//         console.log("Update User Store", userStore)
//         setStore({ ...store, userStore });
//     };

//     useEffect(() => {
//         //  Updating the socket connection
//         updateSocketStore("connection", socket.connected);
//     }, [socket.connected])

//     useEffect(() => {

//         socket.on('connect', (res) => {
//             console.log("connection Successful", res)
//             updateSocketStore("connection", true);
//             updateSocketStore("ioState", true);
//             setIsConnected(true);
//         });

//         socket.on('disconnect', () => {
//             console.log("Socket Disconnected")
//             setIsConnected(false);
//             updateSocketStore("connection", false);
//         });

//         socket.on('remote_message', (data) => {
//             // debugger;
//             console.log("Socket Message Data", data);
//             updateChatStore("chatHistory", data)
//         })

//         socket.on('room_message', (data) => {
//             // debugger;
//             console.log("Socket Message Data", data);
//             updateChatStore("chatHistory", data)
//         })

//         socket.on('chatRoom_messages', (data) => {
//             // debugger;
//             let roomId = data[0].groupId;
//             let chatHistoryData = { ...chatStore.chatHistory }
//             if (!Object.keys(chatHistoryData).includes(String(roomId))) {
//                 chatHistoryData[roomId] = []
//             }
//             chatHistoryData[roomId] = [...data];
//             console.log("Socket Message Data", chatHistoryData);
//             updateChatStore("chatHistory", chatHistoryData)
//         })

//         // socket.on('remote_typing', (data) => {
//         //     debugger;
//         //     let typingList = { ...chatStore.typing }
//         //     if (Object.keys(typingList).length === 0 && !Object.keys(typingList).includes(String(data.groupId))) {
//         //         typingList[data.groupId] = []
//         //     }
//         //     let index = typingList[data.groupId].findIndex(e => e === data.user)
//         //     if (index === -1) {
//         //         typingList[data.groupId].push(data.user)
//         //     }
//         //     updateChatStore("typing", typingList)
//         //     console.log("Typing Started", chatStore.typing)
//         // })
//         // socket.on('remote_stop_typing', (data) => {
//         //     let typingList = { ...chatStore.typing }
//         //     if (Object.keys(typingList).length > 0 && Object.keys(typingList).includes(String(data.groupId))) {
//         //         let index = typingList[data.groupId].findIndex(e => e === data.user)
//         //         if (index !== -1) {
//         //             typingList[data.groupId].splice(index, 1);
//         //             if (typingList[data.groupId].length === 0) delete typingList[data.groupId]
//         //             updateChatStore("typing", typingList)
//         //         }
//         //     }
//         //     console.log("Typing Stopped", chatStore.typing)
//         // })
//     }, []);
//     return null;
// }

// export default Socket;

import { useState, useEffect, useContext, useRef } from "react";
import { RootStore } from "./store/store";

const Socket = () => {
  const [store, setStore] = useContext(RootStore);
  const { socketConnection, chatStore, userStore } = store;
  const [isConnected, setIsConnected] = useState(false);
  const [lastPong, setLastPong] = useState(null);

  const groupID = chatStore?.groups[0]?.id;

  // console.log("Socket Store");

  const ws = useRef(null);

  useEffect(() => {
    // WebSocket URL format for connecting to the server
    const wsUrl = `wss://chats.ir4u.info/ws?group=${groupID}`;

    // Initialize WebSocket connection
    ws.current = new WebSocket(wsUrl);
    // console.log("WebSocket connection", ws)

    // Event listener for successful connection
    ws.current.onopen = () => {
      // console.log("WebSocket connected for group", groupID);
    };

    // Event listener for receiving messages
    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      // console.log("Message received:", data);
      // Handle received messages or typing events here if needed
    };

    // Event listener for connection closure
    ws.current.onclose = () => {
      // console.log("WebSocket disconnected");
    };

    // Clean up function to close the WebSocket when component unmounts
    return () => {
      if (ws.current) ws.current.close();
    };
  }, [groupID]);

  return null; // Since this step is just setting up the connection

  return null;
};

export default Socket;
