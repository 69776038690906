import React, { useState, useContext, useRef, useEffect, useMemo } from "react";
import axios from "../../../api/axios";
import "./ChatSidebar.scss";
import "react-quill/dist/quill.snow.css";
import { RootStore } from "../../../store/store";
import { useParams } from "react-router-dom";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import Swal from "sweetalert2";
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";
import DeleteConfirmation from "../ChatContent/DeleteConfirmation";
import { useModalContext } from "../../WebinarAdminCreateWebinarTab/ModalContext";
import ReactQuill from "react-quill";
const max_file_limit = 9;
const MAX_FILE_SIZE = 20000000; // 3MB

const ChatSidebar = ({
  socket,
  data,
  setData,
  getChatHistory,
  activeChatRoom,
  updateActiveChat,
  navMenuItemActive,
  updateChatRoomFlag,
  updateChatRoom,
  setNavMenuItemActive,
  getChatRooms,
}) => {
  const routeParams = useParams();
  const {
    handleSidebar,
    chatSidebarOpen,
    chatSidebarOpt,
    setChatSidebarOpt,
    handleToggleChatSideBarOpt,
    popupAd,
    setPopupAd,
    handleChatChange,
    isChatDataUpdated,
    setIsChatDataUpdated,
  } = useModalContext();
  const [shareModal, setShareModal] = useState(false);
  const [newCaseModal, setNewCaseModal] = useState(false);
  const [displayedCaseString, setDisplayedCaseString] = useState("");

  const [favorite, setFavorite] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fillterUplaodFile, setFillerUploadFile] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [agree, setAgree] = useState(false);
  const [fileSizeLimit, setFileSizeLimit] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [profileData, setProfileData] = useState(null);
  const [enableInputField, setEnableInputField] = useState({});
  const [updateProfileInputData, setUpdateProfileInputData] = useState({});
  const [isRoomUpdating, setIsRoomUpdating] = useState(false);
  const [updateProfilePicModal, setUpdateProfilePicModal] = useState(false);
  const [updateProfilePic, setUpdateProfilePic] = useState(false);
  // const [imageEdit, setImageEdit] = useState(false);
  const [tooltipText, setTooltipText] = useState(false);
  const [store, setStore] = useContext(RootStore);
  const { chatStore, userStore } = store;
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [deleteRoomId, setDeleteRoomId] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [deleteRoomDataLength, setDeleteRoomDataLength] = useState(null);
  const [isSynopsisModalVisible, setIsSynopsisModalVisible] = useState(false);
  const [synopsisObjective, setSynopsisObjective] = useState("");
  const [synopsisDetails, setSynopsisDetails] = useState("");
  const [activeSynopsisRoom, setActiveSynopsisRoom] = useState(null);
  const [synopsisAction, setSynopsisAction] = useState("Add");

  // const [activeChatId, setActiveChatId] = useState(null);
  // const [popupAd, setPopupAd] = useState(false);
  // const [popupAdState, setPopupAdState] = useState({});
  const ref = useRef();
  const fileInputRef = useRef(null);
  const updateCaseInputRef = useRef(null);
  // const shareUrlCopyRef = useRef();

  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [isImageEditOpen, setImageEditOpen] = useState(false);

  // console.log("data from sidebar", data);

  const openSynopsisModal = (room) => {
    const synopsisDetails = room.synopsis?.details || "";
    const synopsisObjective = room.name;

    const actionType =
      !synopsisDetails && !synopsisObjective ? "Add" : "Update";

    setActiveSynopsisRoom(room.id);
    setIsSynopsisModalVisible(true);

    setSynopsisAction(actionType);
    setSynopsisDetails(synopsisDetails);
    setSynopsisObjective(synopsisObjective);
    // setSynopsisContent(isEdit ? room.synopsis || "" : "");
  };
  const closeSynopsisModal = (isEdit) => {
    // setIsEditingSynopsis(isEdit);
    setIsSynopsisModalVisible(false);
    setSynopsisDetails("");
    setSynopsisObjective("");
    setActiveSynopsisRoom(null);
    // setSynopsisContent(isEdit ? room.synopsis || "" : "");
  };

  const synopsisSubmitHandler = async (event) => {
    event.preventDefault();

    const synopsisData = {
      objective: synopsisObjective,
      details: synopsisDetails,
    };

    try {
      const response = await axios({
        url: `add/synopsis/${activeSynopsisRoom}`,
        method: "PUT",
        data: synopsisData,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
          "Content-Type": "application/json",
        },
      });

      if (response) {
        Swal.fire({
          title: "Success",
          text: "Synopsis added successfully!",
          icon: "success",
        });

        closeSynopsisModal();
      }
    } catch (error) {
      console.error("Error submitting synopsis:", error);
    }
  };

  const chatData = useMemo(() => {
    return data.filter((el) => {
      let searchValue = el.name.toLowerCase();
      return searchValue.indexOf(displayedCaseString) !== -1;
    });
  }, [data, displayedCaseString]);

  const [activeMenus, setActiveMenus] = useState(
    Array(chatData.length).fill(false)
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".ir-drop-downmenu-trigger-container")) {
        setActiveMenus(Array(chatData.length).fill(false));
      }
    };

    if (!isImageEditOpen) {
      document.body.addEventListener("click", handleClickOutside);
    }
    return () => {
      if (!isImageEditOpen) {
        document.body.removeEventListener("click", handleClickOutside);
      }
    };
  }, [chatData.length, isImageEditOpen]);

  const triggerOptionsHandle = (index) => {
    const newActiveMenus = Array(chatData.length).fill(false);
    newActiveMenus[index] = !activeMenus[index];
    setActiveMenus(newActiveMenus);
  };

  const getChatHistoryOfRoom = (room) => {
    if (room) {
      // let reqData = {
      // 	"token": 'Bearer ' + localStorage.getItem('jwtTokenApi2'),
      // 	"roomId": room,
      // }
      // socket.emit("chatdata_room", reqData)
      getChatHistory(room);
    }
  };

  // useEffect(() => {
  //   getChatHistoryOfRoom(newCaseData?.id);
  // }, [isChatDataUpdated]);

  const searchCaseChats = (event) => {
    let searchCaseQuery = event.target.value.toLowerCase();
    setDisplayedCaseString(searchCaseQuery);
  };

  const showShareModal = () => {
    setShareModal(true);
  };
  const shareCloseModalHandle = () => {
    setShareModal(false);
  };

  const newChatCaseModalHandle = () => {
    setUploadedFiles([]);
    setNewCaseModal(true);
    setIsRoomUpdating(false);
  };

  const [newCaseData, setNewCaseData] = useState({
    id: 0,
    name: "",
    description: "",
    image: [],
    images: [],
    deleteImages: [],
    status: "active",
    archive: "false",
  });

  useEffect(() => {
    if (updateChatRoomFlag) {
      // upen modal of update room and with active roomid
      let roomData = chatStore.groups.find((e) => e.id === activeChatRoom);
      updateRoom(roomData);
    }
  }, [updateChatRoomFlag]);

  const newCaseInputChange = (e) => {
    setNewCaseData({ ...newCaseData, [e.target.name]: e.target.value });
  };

  // Fetch favorite chat groups on mount
  const fetchFavoriteGroups = async () => {
    const token = localStorage.getItem("jwtTokenApi1");
    try {
      const response = await axios.get("/get/favorite-group", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response) {
        setFavorite(response.data.favoriteChats);
      }
    } catch (error) {
      console.error("Error fetching favorite chat groups:", error);
      // Swal.fire({
      //   title: "Error",
      //   text: "Failed to load favorite chat groups.",
      //   icon: "error",
      // });
    }
  };

  useEffect(() => {
    fetchFavoriteGroups();
  }, []);

  const addToFavorite = async (room) => {
    // Check if the room is already in the favorites list
    const isFavorite = favorite.some(
      (fav) => String(fav.chatGroupID) === String(room.id)
    );
    const response = await axios({
      url: "chat/favourite/" + room.id,
      method: "POST",
      withCredentials: false,
      headers: {
        // "Content-Type": "multiplart/form-data",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
      },
    });

    if (response) {
      fetchFavoriteGroups();
      //   let chatgroups = response.data.data;
      //   let userstore = { ...userStore };
      //   userstore.profile.chatgroups = chatgroups;
      //   setStore({ ...store, userstore });
      //   updateFavoriteObject();
      //   Swal.fire({
      //     title: "Success",
      //     text: "Added to favourites",
      //     icon: "success",
      //   });

      //   console.log("favorite", favorite);

      Swal.fire({
        title: "Success",
        text: isFavorite ? "Removed from favourites" : "Added to favourites",
        icon: "success",
      });
    }
  };

  const addToArchieve = async (room) => {
    const response = await axios({
      url: "chat/archive/" + room.id,
      method: "post",
      withCredentials: false,
      headers: {
        "Content-Type": "multiplart/form-data",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi2"),
      },
    });

    if (response) {
      // let chatgroups = response.data.data;
      let chatStore = { ...store.chatStore };
      let responseData = { ...response.data.data };

      if (responseData.image !== "" && responseData.image.indexOf(", ")) {
        responseData.image = responseData.image.split(", ");
      }

      let groups = [...chatStore.groups];
      let index = null;
      for (let i = 0; i < groups.length; i++) {
        let id = groups[i].id;
        if (id === responseData.id) {
          index = i;
          break;
        }
      }
      // let index = responseData?.id &&  groups.findIndex(responseData?.id) || null;

      if (index != null) {
        groups[index] = { ...responseData };
      }

      // chatStore.groups.push(responseData);

      // setArchive(true);
      setStore({
        ...store,
        chatStore: { ...chatStore, groups: [...groups] },
      });
    }
  };

  const removeFromArchive = async (room) => {
    const response = await axios({
      url: "chat/archive/" + room.id,
      method: "DELETE",
      withCredentials: false,
      headers: {
        "Content-Type": "multiplart/form-data",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi2"),
      },
    });

    if (response) {
      // let chatgroups = response.data.data;
      let chatStore = { ...store.chatStore };
      let responseData = { ...response.data.data };
      if (responseData.image !== "" && responseData.image.indexOf(", ")) {
        responseData.image = responseData.image.split(", ");
      }

      let groups = [...chatStore.groups];
      let index = null;
      for (let i = 0; i < groups.length; i++) {
        let id = groups[i].id;
        if (id === responseData.id) {
          index = i;
          break;
        }
      }
      // let index = responseData?.id &&  groups.findIndex(responseData?.id) || null;

      if (index != null) {
        groups[index] = { ...responseData };
      }

      // chatStore.groups.push(responseData);

      // setArchive(false);
      setStore({
        ...store,
        chatStore: { ...chatStore, groups: [...groups] },
      });
    }
  };

  // const getChatRooms = async () => {
  //   const response = await axios({
  //     url: "chat/group",
  //     method: "get",
  //     withCredentials: false,
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
  //     },
  //   });
  //   console.log("Get Chat History", response);
  //   let chatData = response?.data?.data || [];
  //   let chatHistoryObject = {};
  //   let chatHistory = { ...chatStore.chatHistory };
  //   console.log("%c Get Chat History", { color: "#c00" });
  //   chatData.map((d) => {
  //     // creatign default chat history for all the grups
  //     if (!Object.keys(chatHistory).includes(d.id)) {
  //       chatHistoryObject[d.id] = [];
  //     }

  //     d.image = d.image.indexOf(",") ? d.image.split(",") : [d.image];
  //     d["images"] = [...d.image];
  //     d["deleteImages"] = [];
  //     return d;
  //   });

  //   chatStore.groups = chatData;
  //   chatStore.chatHistory = { ...chatHistoryObject };

  //   setStore({ ...store, chatStore: { ...chatStore } });
  // };

  const [createCaseLoading, setCreateCaseLoading] = useState(false);

  // const newCaseSubmitFormHandle = async (e) => {
  //   setCreateCaseLoading(true);
  //   e.preventDefault();
  //   try {
  //     // Step 1: Upload new images to S3
  //     let uploadedImageUrls = []; // Array to store uploaded image URLs
  //     for (let i = 0; i < uploadedFiles.length; i++) {
  //       const file = uploadedFiles[i];
  //       const formData = new FormData();
  //       formData.append("uploadFile", file);

  //       const uploadResponse = await axios({
  //         url: "/uploadfile",
  //         method: "post",
  //         data: formData,
  //         headers: {
  //           Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
  //         },
  //       });

  //       if (uploadResponse) {
  //         uploadedImageUrls.push(uploadResponse.data.data[0]); // Add URL to array
  //       } else {
  //         throw new Error("Failed to upload image to S3");
  //       }
  //     }

  //     // Step 2: Merge existing and new images
  //     const existingImages = isRoomUpdating ? newCaseData.images || [] : []; // Retain existing images if updating
  //     const allImages = [...existingImages, ...uploadedImageUrls].join(","); // Combine existing and new image URLs as a comma-separated string

  //     // Step 3: Prepare JSON payload
  //     const payload = {
  //       name: newCaseData.name,
  //       description: newCaseData.description,
  //       image: allImages, // Sending as a single comma-separated string
  //     };

  //     // Step 4: Make the API call
  //     const url = isRoomUpdating
  //       ? `update-group/${newCaseData.id}`
  //       : "/create/chat";

  //     const response = await axios({
  //       url: url,
  //       method: isRoomUpdating ? "put" : "post",
  //       data: payload,
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
  //       },
  //     });

  //     if (response) {
  //       if (!isRoomUpdating) {
  //         console.log("This is newely created case id", response.data.groupId);
  //         updateActiveChat(response.data.groupId);
  //       }
  //       // console.log("This is newely created case:", response);
  //       // Refresh chat rooms and reset modal state
  //       getChatRooms();
  //       setCreateCaseLoading(false);

  //       setNewCaseModal(false);
  //       setNewCaseData({
  //         name: "",
  //         description: "",
  //         image: "", // Reset to an empty string
  //         images: [], // Clear additional fields if necessary
  //         deleteImages: [],
  //         status: "",
  //         archive: "",
  //       });
  //       setIsRoomUpdating(false);
  //       setUploadedFiles([]);
  //       updateChatRoom(false);

  //       Swal.fire({
  //         title: "Success!",
  //         text: isRoomUpdating
  //           ? "Case Updated Successfully"
  //           : "Case Created Successfully",
  //         icon: "success",
  //       });
  //     }
  //   } catch (error) {
  //     setCreateCaseLoading(false);
  //     console.error("Error during case submission:", error);
  //     Swal.fire({
  //       title: "Error",
  //       text: error.response?.data?.message || "Something went wrong!",
  //       icon: "error",
  //     });
  //   }
  // };

  const newCaseSubmitFormHandle = async (e) => {
    setCreateCaseLoading(true);
    e.preventDefault();

    try {
      // Step 1: Identify existing images (for update case)
      const existingImages = isRoomUpdating ? newCaseData.images || [] : [];

      // Step 2: Filter out API images (URLs) from uploadedFiles (to prevent re-upload)
      const newFiles = uploadedFiles.filter((file) => typeof file !== "string"); // Only new file uploads
      const retainedImages = uploadedFiles.filter(
        (file) => typeof file === "string"
      ); // Already existing API images

      // Step 3: Upload only new files to S3
      let uploadedImageUrls = [];
      for (let i = 0; i < newFiles.length; i++) {
        const file = newFiles[i];
        const formData = new FormData();
        formData.append("uploadFile", file);

        const uploadResponse = await axios.post("/uploadfile", formData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
          },
        });

        if (uploadResponse) {
          uploadedImageUrls.push(uploadResponse.data.data[0]); // Store uploaded image URL
        } else {
          throw new Error("Failed to upload image to S3");
        }
      }

      // Step 4: Detect deleted images
      const deletedImages = existingImages.filter(
        (img) => !retainedImages.includes(img)
      );

      // Step 5: Merge retained + new uploaded images
      const allImages = [...retainedImages, ...uploadedImageUrls].join(",");

      // Step 6: Prepare JSON payload
      const payload = {
        name: newCaseData.name,
        description: newCaseData.description,
        image: allImages, // Send all images as a single comma-separated string
        deleteImages: deletedImages, // Send deleted images separately
      };

      // Step 7: Make the API call
      const url = isRoomUpdating
        ? `update-group/${newCaseData.id}`
        : "/create/chat";

      const response = await axios({
        url: url,
        method: isRoomUpdating ? "put" : "post",
        data: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
        },
      });

      // if (response) {
      //   if (!isRoomUpdating) {
      //     console.log("This is newly created case id", response.data.groupId);
      //     updateActiveChat(response.data.groupId);
      //   }
      //   getChatRooms();
      //   setCreateCaseLoading(false);
      //   setNewCaseModal(false);
      //   setNewCaseData({
      //     name: "",
      //     description: "",
      //     image: "",
      //     images: [],
      //     deleteImages: [],
      //     status: "",
      //     archive: "",
      //   });
      //   setIsRoomUpdating(false);
      //   setUploadedFiles([]); // Clear uploaded files
      //   updateChatRoom(false);

      //   Swal.fire({
      //     title: "Success!",
      //     text: isRoomUpdating
      //       ? "Case Updated Successfully"
      //       : "Case Created Successfully",
      //     icon: "success",
      //   });
      // }
      if (response) {
        setCreateCaseLoading(false);
        setNewCaseModal(false);
        setNewCaseData({
          name: "",
          description: "",
          image: "",
          images: [],
          deleteImages: [],
          status: "",
          archive: "",
        });
        setIsRoomUpdating(false);
        setUploadedFiles([]); // Clear uploaded files
        updateChatRoom(false);
        Swal.fire({
          title: "Success!",
          text: isRoomUpdating
            ? "Case Updated Successfully"
            : "Case Created Successfully",
          icon: "success",
        }).then(() => {
          // This runs only when the user clicks "OK"
          // if (!isRoomUpdating) {
          //   console.log("This is newly created case id", response.data.groupId);
          //   // updateActiveChat(response.data.groupId);
          // }
          getChatRooms(newCaseData.id);
          // getChatHistoryOfRoom(newCaseData?.id);
          // setIsChatDataUpdated(!isChatDataUpdated);
        });
      }
    } catch (error) {
      setCreateCaseLoading(false);
      console.error("Error during case submission:", error);
      Swal.fire({
        title: "Error",
        text: error.response?.data?.message || "Something went wrong!",
        icon: "error",
      });
    }
  };

  // const updateRoom = (data) => {
  //   setIsRoomUpdating(true);
  //   console.log("Update Room", data);
  //   setNewCaseData({ ...newCaseData, ...data });

  // };

  const updateRoom = (data) => {
    setIsRoomUpdating(true);

    // Merge new data with existing case data
    const updatedCase = { ...newCaseData, ...data };
    setNewCaseData(updatedCase);

    // Update images only when the updateRoom function is called
    if (updatedCase?.images?.length > 0) {
      setUploadedFiles(updatedCase.images); // Load API images
    }
  };

  // useEffect(() => {
  //   console.log(uploadedFiles, "Uploaded files here");
  // }, [uploadedFiles]);
  const deleteImage = (img, data) => {
    // find image in images
    let index = data.images.findIndex((a) => a === img);
    // delelte form images
    data.images.splice(index, 1);
    // add image to deleteImages
    data.deleteImages.push(img);

    data.description = "New Description udpate with deleted content";
    setNewCaseData({ ...newCaseData, ...data });
  };

  const toggleDeleteModal = (room, i, dataLength) => {
    if (i !== undefined) {
      setDeleteRoomId(room);
      setDeleteIndex(i);
      setDeleteRoomDataLength(dataLength);
      setShowDeleteModal(!showDeleteModal);
    } else {
      setShowDeleteModal(!showDeleteModal);
      setDeleteRoomId(null);
      setDeleteIndex(null);
      setDeleteRoomDataLength(null);
    }
  };

  const deleteRoom = async (room, currentRoom, totalRooms) => {
    const response = await axios({
      url: "group/delete/" + room.id,
      method: "delete",
      withCredentials: false,
      headers: {
        // "Content-Type": "multiplart/form-data",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
      },
    });
    if (response.status === 200) {
      let groups = [...chatStore.groups];
      let index = groups.findIndex((e) => e.id === room.id);
      groups.splice(index, 1);
      // chatStore.groups.push(responseData);
      setStore({
        ...store,
        chatStore: {
          ...chatStore,
          groups: [...groups],
        },
      });
      let selectedRom = currentRoom;
      if (currentRoom === totalRooms - 1) {
        selectedRom = currentRoom - 1;
      } else if (currentRoom === 0) {
        selectedRom = currentRoom + 1;
      } else {
        selectedRom = currentRoom + 1;
      }
      // updateActiveChat(data[selectedRom].id);
      toggleDeleteModal();
      Swal.fire({
        title: "Success!",
        text: "Case Deleted Successfully",
        icon: "success",
      }).then(() => {
        // Executes after the user clicks "OK"
        updateActiveChat(data[selectedRom].id);
      });
    }
  };

  //empty input fields on close button
  const newChatCaseCloseModalHandle = () => {
    setNewCaseModal(false);
    setAgree(false);
    setFileSizeLimit(false);
    setFillerUploadFile([]);
    setIsRoomUpdating(false);
    // setImageEdit(false);
    setImageEditOpen(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the input field
    }
    setNewCaseData({
      name: "",
      description: "",
      image: "", // Reset to an empty string
      images: [], // Clear additional fields if necessary
      deleteImages: [],
      status: "",
      archive: "",
    });
    setUploadedFiles([]);
  };

  const updateChatCaseCloseModalHandle = () => {
    setIsRoomUpdating(false);
    setFileSizeLimit(false);
    updateChatRoom(false);
    setFillerUploadFile([]);
    // setImageEdit(false);
    setImageEditOpen(false);
    if (updateCaseInputRef.current) {
      updateCaseInputRef.current.value = ""; // Clear the input field
    }
    setNewCaseData({
      name: "",
      description: "",
      image: "", // Reset to an empty string
      images: [], // Clear additional fields if necessary
      deleteImages: [],
      status: "",
      archive: "",
    });
  };

  const removeUploadItem = (id) => {
    const newUploadedFiles = uploadedFiles.filter((_, i) => i !== id);
    setUploadedFiles(newUploadedFiles);
  };

  const handleUploadedFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    let result = files.some((file) => {
      let file_size = file.size;
      if (file_size < MAX_FILE_SIZE) {
        setFileSizeLimit(false);
        if (uploaded.findIndex((f) => f.name === file.name) === -1) {
          uploaded.push(file);
          if (uploaded.length === max_file_limit) setFileLimit(true);
          if (uploaded.length > max_file_limit) {
            alert("you can only upload 4 files");
            setFileLimit(false);
            limitExceeded = true;
            return true;
          }
        }
      } else {
        setFileSizeLimit(true);
      }
      return false;
    });

    if (!limitExceeded) setUploadedFiles(uploaded);
    return result;
  };

  // const handleFileChange = (event) => {
  //   const chosenFiles = Array.prototype.slice.call(event.target.files);
  //   // handleUploadedFiles(chosenFiles);
  //   setFillerUploadFile(chosenFiles);
  //   setImageEdit(true);
  //   console.log("clicked");
  // };
  const handleFileChange = (event) => {
    const chosenFiles = Array.from(event.target.files); // Convert FileList to array
    setUploadedFiles((prevFiles) => [...prevFiles, ...chosenFiles]); // Append new files
  };

  // const handleRemoveImage = (imageFile) => {
  //   setUploadedFiles((prevFiles) =>
  //     prevFiles.filter((file) => file !== imageFile)
  //   );
  // };
  const handleRemoveImage = (index) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleEditImage = (imageFile) => {
    setSelectedImageFile(imageFile); // Store selected image for editing
    setImageEditOpen(true); // Open image editor
  };

  // const handleEditImage = (index) => {
  //   const imageFile = uploadedFiles[index]; // Get image at index
  //   setImageEditOpen(true); // Open image editor

  //   if (typeof imageFile === "string") {
  //     // If it's an API image (URL), fetch and convert it to a File object
  //     fetch(imageFile)
  //       .then((res) => res.blob())
  //       .then((blob) => {
  //         const file = new File([blob], `edited-${index}.jpg`, {
  //           type: "image/jpeg",
  //         });
  //         setSelectedImageFile(file); // Store converted file
  //       });
  //   } else {
  //     // If it's already a File object, directly set it
  //     setSelectedImageFile(imageFile);
  //   }
  // };

  // const handleEditImage = (index) => {
  //   const imageFile = uploadedFiles[index]; // Get image at index

  //   if (typeof imageFile === "string") {
  //     // If it's an API image (URL), fetch and convert it to a File object
  //     fetch(imageFile)
  //       .then((res) => res.blob())
  //       .then((blob) => {
  //         const file = new File([blob], `edited-${index}.jpg`, {
  //           type: "image/jpeg",
  //         });
  //         setSelectedImageFile(file); // Replace URL with file object for editor
  //       })
  //       .catch((error) => console.error("Failed to fetch image:", error));
  //   } else {
  //     // If it's already a File object (newly uploaded)
  //     setSelectedImageFile(imageFile);
  //   }
  //   setImageEditOpen(true); // Open image editor
  // };

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  // const onImageSave = async (editedImageObject, designState) => {
  //   const imageCropFile = await dataURLtoFile(
  //     editedImageObject.imageBase64,
  //     editedImageObject.fullName
  //   );
  //   console.log("saved", imageCropFile);
  //   let uploadedImagesArr = [...uploadedFiles];
  //   uploadedImagesArr.push(imageCropFile);
  //   setUploadedFiles(uploadedImagesArr);
  //   // submitImage(imageCropFile)
  //   handleUploadedFiles(uploadedImagesArr);
  //   setImageEdit(false);
  //   setFillerUploadFile([]);
  //   return true;
  // };
  const onImageSave = (savedImageData) => {
    // Convert base64 to Blob
    const editedImageBlob = fetch(savedImageData.imageCanvas.toDataURL())
      .then((res) => res.blob())
      .then((blob) => {
        const editedFile = new File([blob], selectedImageFile.name, {
          type: "image/jpeg",
        });

        // Replace the old file with the edited file in uploadedFiles
        setUploadedFiles((prevFiles) =>
          prevFiles.map((file) =>
            file === selectedImageFile ? editedFile : file
          )
        );

        // Close the editor
        setImageEditOpen(false);
        setSelectedImageFile(null);
      });
  };

  const closeImgEditor = () => {
    // setImageEdit(false);
    setImageEditOpen(false);
  };

  const handleAcceptChange = (event) => {
    setAgree(event.target.checked);
  };

  const enableInputFieldHandle = (name) => {
    const key = name;
    setEnableInputField({
      ...enableInputField,
      [key]: !enableInputField[key],
    });
  };

  const updateProfilePicHandle = (event) => {
    setUpdateProfilePicModal(true);
    setSelectedFile(event.target.files[0]);
    setUpdateProfilePic(URL.createObjectURL(event.target.files[0]));
  };

  const updateProfilePicCloseModalHandle = () => {
    setUpdateProfilePicModal(false);
  };

  const updateProfileInputChange = (e) => {
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
    setUpdateProfileInputData({
      ...updateProfileInputData,
      [e.target.name]: e.target.value,
    });
  };

  const upldateProfileSubmitFormHandle = async (e) => {
    e.preventDefault();
    const updateProfileFormData = new FormData();
    if (selectedFile !== "") {
      updateProfileFormData.append("image", selectedFile || "");
    }
    Object.keys(updateProfileInputData).map((k, i) => {
      updateProfileFormData.append(k, updateProfileInputData[k]);
      return true;
    });

    const response = await axios({
      url: "user/profile/" + userStore.profile.id,
      method: "PUT",
      data: updateProfileFormData,
      withCredentials: false,
      headers: {
        "Content-Type": "multiplart/form-data",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi2"),
      },
    });
    if (response?.status === 200) {
      // Swal.fire( 'Good job!','You clicked the button','success');
      Swal.fire({
        title: "Success!",
        text: "Your Profile is Updated.",
        icon: "success",
      });

      setStore({
        ...store,
        userStore: {
          ...userStore,
          profile: {
            ...userStore.profile,
            ...response.data.data,
          },
        },
      });
      let profileInputData = {};
      Object.keys(updateProfileInputData).map((k, i) => {
        profileInputData[k] = response.data.data[k];
      });
      setUpdateProfileInputData(profileInputData);
      setProfileData(response.data.data);
      setUpdateProfileInputData({});
      setUpdateProfilePicModal(false);
    }
  };

  useEffect(() => {
    // updateFavoriteObject();
    setProfileData(userStore.profile);
  }, [userStore.profile]);

  useEffect(() => {
    if (routeParams?.id && routeParams?.id !== ("" || undefined || null)) {
      let id = parseInt(routeParams.id);
      updateActiveChat(id);
    }
  }, [routeParams]);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, []);

  const shareUrl = window.location.href + "/" + JSON.stringify(activeChatRoom);
  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(shareUrl);
    setTooltipText(true);
    setTimeout(() => setTooltipText(false), 2000);
  };
  // console.log("profileData", profileData);

  // useEffect(() => {
  //   console.log("active activeChatRoom changed", activeChatRoom);
  // }, [activeChatRoom]);

  return (
    <div
      className={`ir-chat-sidebar-main-container app-sidebar ${
        chatSidebarOpen ? "ir-ws-chat-display-block" : "ir-ws-chat-display-none"
      } `}
    >
      <div className="tab-content">
        <div
          className={`tab-pane fade ${
            navMenuItemActive === 0 ? "active show" : ""
          }`}
          id="chats"
        >
          <form>
            <div className="ir-ws-chat-case-search-cont">
              <div className="input-icon-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Cases"
                  onChange={searchCaseChats}
                />
                <i className="bi bi-search"></i>
              </div>
              <div className="ir-ws-chat-none-toggle ir-ws-chat-bar-tab">
                <div
                  className="ir-chat-bg-btn"
                  onClick={handleToggleChatSideBarOpt}
                >
                  <i className="bi bi-three-dots-vertical"></i>
                </div>
              </div>
              {chatSidebarOpt && (
                <div className="ir-ws-chat-toggle-ph-opt ir-chat-none-flex-toggle ir-chat-bar-tab-flex">
                  <div
                    className="ir-ws-chat-ph-opt"
                    onClick={() => {
                      setNavMenuItemActive(0);
                      setChatSidebarOpt(false);
                    }}
                  >
                    <div className="ir-chat-bg-btn">
                      <i className="bi bi-chat-text"></i>
                    </div>
                    <div>Case List</div>
                  </div>
                  <div
                    className="ir-ws-chat-ph-opt"
                    onClick={() => {
                      setNavMenuItemActive(1);
                      setChatSidebarOpt(false);
                    }}
                  >
                    <div className="ir-chat-bg-btn">
                      <i className="bi bi-star"></i>
                    </div>
                    <div>Favourites</div>
                  </div>
                  <div
                    className="ir-ws-chat-ph-opt "
                    onClick={() => {
                      setNavMenuItemActive(2);
                      setChatSidebarOpt(false);
                    }}
                  >
                    <div className="ir-chat-bg-btn">
                      <i className="bi bi-archive"></i>
                    </div>
                    <div>Archive</div>
                  </div>
                </div>
              )}
            </div>
            <div></div>
          </form>
          <div className="tab-content-scroll ps ps--active-y" ref={ref}>
            <div className="d-flex gap-4 flex-column">
              {/* <h5 className="mb-0 ir-tab-heading">Case Chats {JSON.stringify(routeParams)} {JSON.stringify(activeChatRoom)}</h5> */}
              <h5 className="mb-0 ir-tab-heading">Case Chats</h5>
              <div className="list-group gap-1">
                {/* {chatData.length > 0 && chatData.sort((a,b) => (b.id - a.id)).map((room, i) => { */}
                {chatData.length > 0 &&
                  chatData
                    .sort((a, b) => b.id - a.id)
                    .map((room, i) => {
                      //   console.log("room from case chats: ", room);
                      const isFavorite = favorite.some(
                        (fav) => String(fav.chatGroupID) === String(room.id)
                      );
                      //   console.log("favorite from case chats: ",isFavorite);

                      return (
                        <div
                          className={
                            "list-group-item d-flex align-items-center " +
                            (parseInt(activeChatRoom) === room.id &&
                              "chat-list-side-active")
                          }
                          // onClick={handleSidebar}
                          key={i}
                        >
                          <div
                            className={`avatar me-2 ir-chat-list-state-status ${
                              !room.archive
                                ? "ir-chat-list-state-active"
                                : "ir-chat-list-state-archieve"
                            }`}
                            onClick={() => {
                              getChatHistoryOfRoom(room.id);
                            }}
                          >
                            <img
                              src={room?.image[0]}
                              className="rounded-circle"
                              alt="case chat icon"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = require("../../../assets/images/chat-images/no-image.png");
                              }}
                            />
                          </div>
                          <div className="ir-side-list-content">
                            <div
                              onClick={() => {
                                getChatHistoryOfRoom(room.id);
                                handleSidebar();
                                setChatSidebarOpt(false);
                                // setPopupAd(true);
                                // handleChatChange(room.id, room.ads);
                              }}
                            >
                              <h5 className="ir-room-title-container">
                                <p className="text-truncate ir-room-title">
                                  {room?.name}
                                </p>
                                {/* <div
                                  className={`ir-chat-archive-lock ${
                                    !room.archive
                                      ? "ir-chat-list-state-archive-lock"
                                      : "ir-chat-list-state-archieve-lock-display"
                                  }`}
                                >
                                  <span className="material-symbols-outlined">
                                    lock
                                  </span>
                                </div> */}
                                <div
                                  className={`ir-chat-archive-lock ${
                                    room.archive === "true"
                                      ? "ir-chat-list-state-archieve-lock-display"
                                      : "ir-chat-list-state-archive-lock"
                                  }`}
                                >
                                  {room.archive === "true" && (
                                    <span className="material-symbols-outlined">
                                      lock
                                    </span>
                                  )}
                                </div>
                              </h5>
                              {/* <p className="text-truncate mb-1 ir-ws-chat-sidebar-content">
                                {room?.description}
                              </p> */}
                              <p
                                className="text-truncate mb-1 !p-[0px] ir-ws-chat-sidebar-content"
                                dangerouslySetInnerHTML={{
                                  __html: room?.description,
                                }}
                              ></p>
                            </div>

                            <div className="ir-side-list-action-time-container">
                              <div className="text-muted ir-drop-downmenu-trigger-container">
                                <i
                                  className="bi bi-three-dots"
                                  onClick={() => triggerOptionsHandle(i)}
                                ></i>
                                {activeMenus[i] && (
                                  <div className={`ir-dropdown-container`}>
                                    <ul>
                                      <li
                                        className="dropdown-item"
                                        onClick={showShareModal}
                                      >
                                        Share
                                      </li>
                                      {(profileData.username ===
                                        room.createdBy ||
                                        profileData.username === "admin") && (
                                        <li
                                          className="dropdown-item"
                                          onClick={() => updateRoom(room)}
                                        >
                                          Update
                                        </li>
                                      )}

                                      <li
                                        className="dropdown-item"
                                        onClick={() => addToFavorite(room)}
                                      >
                                        {isFavorite
                                          ? "Remove Favourite"
                                          : "Add to Favourites"}
                                      </li>
                                      {(profileData.username ===
                                        room.createdBy ||
                                        profileData.username === "admin") && (
                                        <li
                                          className="dropdown-item"
                                          onClick={() =>
                                            openSynopsisModal(room)
                                          }
                                        >
                                          {room.synopsis?.details &&
                                          room.synopsis?.objective
                                            ? "Update Synopsis"
                                            : "Add Synopsis"}
                                        </li>
                                      )}

                                      {/* <li className="dropdown-divider"></li> */}
                                      {(profileData.username ===
                                        room.createdBy ||
                                        profileData.username === "admin") && (
                                        <li
                                          className="dropdown-item text-danger"
                                          onClick={() =>
                                            toggleDeleteModal(
                                              room,
                                              i,
                                              data.length
                                            )
                                          }
                                        >
                                          Delete
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                )}
                              </div>
                              {/* <p className="text-muted ir-chat-list-time">
                                                        {moment().format("hh:mm A")}
                                                    </p> */}
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
          <button
            className="btn btn-primary bg-app-default-color btn-lg btn-block ir-btn-default ir-create-case-btn"
            id="newChat"
            onClick={newChatCaseModalHandle}
          >
            <span>
              <i className="bi bi-chat-dots me-1"></i> Create Case
            </span>
          </button>
        </div>
        <div
          className={`tab-pane fade ${
            navMenuItemActive === 1 ? "active show" : ""
          }`}
          id="favourites"
        >
          <form>
            {/* <div className="input-icon-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search Chats"
              />
              <i className="bi bi-search"></i>
            </div> */}
            <div className="ir-ws-chat-case-search-cont">
              <div className="input-icon-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Cases"
                  onChange={searchCaseChats}
                />
                <i className="bi bi-search"></i>
              </div>
              <div className="ir-ws-chat-none-toggle ir-ws-chat-bar-tab">
                <div
                  className="ir-chat-bg-btn"
                  onClick={handleToggleChatSideBarOpt}
                >
                  <i className="bi bi-three-dots-vertical"></i>
                </div>
              </div>
              {chatSidebarOpt && (
                <div className="ir-ws-chat-toggle-ph-opt ir-chat-none-flex-toggle ir-chat-bar-tab-flex">
                  <div
                    className="ir-ws-chat-ph-opt"
                    onClick={() => {
                      setNavMenuItemActive(0);
                      setChatSidebarOpt(false);
                    }}
                  >
                    <div className="ir-chat-bg-btn">
                      <i className="bi bi-chat-text"></i>
                    </div>
                    <div>Case List</div>
                  </div>
                  <div
                    className="ir-ws-chat-ph-opt"
                    onClick={() => {
                      setNavMenuItemActive(1);
                      setChatSidebarOpt(false);
                    }}
                  >
                    <div className="ir-chat-bg-btn">
                      <i className="bi bi-star"></i>
                    </div>
                    <div>Favourites</div>
                  </div>
                  <div
                    className="ir-ws-chat-ph-opt "
                    onClick={() => {
                      setNavMenuItemActive(2);
                      setChatSidebarOpt(false);
                    }}
                  >
                    <div className="ir-chat-bg-btn">
                      <i className="bi bi-archive"></i>
                    </div>
                    <div>Archive</div>
                  </div>
                </div>
              )}
            </div>
            <div></div>
          </form>
          <div className="tab-content-scroll ps ps--active-y" ref={ref}>
            <div className="d-flex gap-4 flex-column">
              {/* <h5 className="mb-0 ir-tab-heading">Favourites  {favorite}</h5> */}
              <h5 className="mb-0 ir-tab-heading">Favourites</h5>
              <div className="list-group gap-1">
                {data.length > 0 &&
                  data
                    // .filter((a) => favorite.includes(String(a.id)))
                    .filter((a) =>
                      favorite.some(
                        (fav) => String(fav.chatGroupID) === String(a.id)
                      )
                    )
                    .sort((a, b) => b.id - a.id)
                    .map((room, i) => {
                      // console.log("Rooms", room);
                      const isFavorite = favorite?.some(
                        (fav) => String(fav.chatGroupID) === String(room.id)
                      );
                      return (
                        <div
                          className={
                            "list-group-item d-flex align-items-center " +
                            (parseInt(activeChatRoom) === room.id &&
                              "chat-list-side-active")
                          }
                          key={i}
                          onClick={() => getChatHistoryOfRoom(room.id)}
                        >
                          <div
                            className={`avatar me-2 ir-chat-list-state-status ${
                              !room.archive
                                ? "ir-chat-list-state-active"
                                : "ir-chat-list-state-archieve"
                            }`}
                          >
                            <img
                              className="rounded-circle"
                              src={room?.image[0]}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = require("../../../assets/images/chat-images/no-image.png");
                              }}
                              alt="profile pic"
                            />
                          </div>
                          <div className="ir-side-list-content">
                            <div
                              onClick={() => {
                                getChatHistoryOfRoom(room.id);
                                handleSidebar();
                                setChatSidebarOpt(false);
                              }}
                            >
                              <h5 className="ir-room-title-container">
                                <p className="text-truncate ir-room-title">
                                  {room?.name}
                                </p>
                                <div
                                  className={`ir-chat-archive-lock ${
                                    !room.archive
                                      ? "ir-chat-list-state-archive-lock"
                                      : "ir-chat-list-state-archieve-lock-display"
                                  }`}
                                >
                                  {/* <span className="material-symbols-outlined">
                                    lock
                                  </span> */}
                                </div>
                              </h5>
                              <p className="text-truncate mb-1">
                                {room?.description}
                              </p>
                            </div>
                            <div className="ir-side-list-action-time-container">
                              <div className="text-muted ir-drop-downmenu-trigger-container">
                                <i
                                  className="bi bi-three-dots"
                                  onClick={() => triggerOptionsHandle(i)}
                                ></i>
                                {activeMenus[i] && (
                                  <div className={`ir-dropdown-container`}>
                                    <ul>
                                      <li
                                        className="dropdown-item"
                                        onClick={showShareModal}
                                      >
                                        Share
                                      </li>
                                      {(profileData.username ===
                                        room.createdBy ||
                                        profileData.username === "admin") && (
                                        <li
                                          className="dropdown-item"
                                          onClick={() => updateRoom(room)}
                                        >
                                          Update
                                        </li>
                                      )}
                                      {profileData.role === "admin" &&
                                        (profileData.role === "admin" &&
                                        !room.archive ? (
                                          <li
                                            className="dropdown-item"
                                            onClick={() => addToArchieve(room)}
                                          >
                                            Add to Archive
                                          </li>
                                        ) : (
                                          <li
                                            className="dropdown-item"
                                            onClick={() =>
                                              removeFromArchive(room)
                                            }
                                          >
                                            Remove From Archive
                                          </li>
                                        ))}

                                      <li
                                        className="dropdown-item"
                                        onClick={() => addToFavorite(room)}
                                      >
                                        {isFavorite
                                          ? "Remove Favourite"
                                          : "Add to Favourites"}
                                      </li>

                                      <li className="dropdown-divider"></li>
                                      {((Number(room.createdBy) ===
                                        Number(profileData?.id || "") &&
                                        Number(room.chatCount) === 0) ||
                                        profileData?.role === "admin") && (
                                        <li
                                          className="dropdown-item text-danger"
                                          onClick={() =>
                                            toggleDeleteModal(
                                              room,
                                              i,
                                              data.length
                                            )
                                          }
                                        >
                                          Delete
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                )}
                              </div>
                              {/* <p className="text-muted mb-1 ir-chat-list-time">
                                                        {moment().format("hh:mm A")}
                                                    </p> */}
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`tab-pane fade ${
            navMenuItemActive === 2 ? "active show" : ""
          }`}
          id="archive"
        >
          <form>
            {/* <div className="input-icon-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search Chats"
              />
              <i className="bi bi-search"></i>
            </div> */}
            <div className="ir-ws-chat-case-search-cont ">
              <div className="input-icon-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Cases"
                  onChange={searchCaseChats}
                />
                <i className="bi bi-search"></i>
              </div>
              <div className="ir-ws-chat-none-toggle ir-ws-chat-bar-tab">
                <div
                  className="ir-chat-bg-btn"
                  onClick={handleToggleChatSideBarOpt}
                >
                  <i className="bi bi-three-dots-vertical"></i>
                </div>
              </div>
              {chatSidebarOpt && (
                <div className="ir-ws-chat-toggle-ph-opt ir-chat-none-flex-toggle ir-chat-bar-tab-flex">
                  <div
                    className="ir-ws-chat-ph-opt"
                    onClick={() => {
                      setNavMenuItemActive(0);
                      setChatSidebarOpt(false);
                    }}
                  >
                    <div className="ir-chat-bg-btn">
                      <i className="bi bi-chat-text"></i>
                    </div>
                    <div>Case List</div>
                  </div>
                  <div
                    className="ir-ws-chat-ph-opt"
                    onClick={() => {
                      setNavMenuItemActive(1);
                      setChatSidebarOpt(false);
                    }}
                  >
                    <div className="ir-chat-bg-btn">
                      <i className="bi bi-star"></i>
                    </div>
                    <div>Favourites</div>
                  </div>
                  <div
                    className="ir-ws-chat-ph-opt "
                    onClick={() => {
                      setNavMenuItemActive(2);
                      setChatSidebarOpt(false);
                    }}
                  >
                    <div className="ir-chat-bg-btn">
                      <i className="bi bi-archive"></i>
                    </div>
                    <div>Archive</div>
                  </div>
                </div>
              )}
            </div>
            <div></div>
          </form>
          <div className="tab-content-scroll ps ps--active-y" ref={ref}>
            <div className="d-flex gap-4 flex-column">
              <h5 className="mb-0 ir-tab-heading">Archived Cases</h5>
              <div className="list-group gap-1">
                {data.filter((e) => e.archive === "true")?.length === 0 && (
                  <p className="ir-found-no-results-text">
                    There are No Archived Rooms
                  </p>
                )}
                {data.length > 0 &&
                  data
                    .filter((e) => e.archive === "true")
                    .sort((a, b) => b.id - a.id)
                    .map((room, i) => {
                      return (
                        <div
                          className={
                            "list-group-item d-flex align-items-center " +
                            (parseInt(activeChatRoom) === room.id &&
                              "chat-list-side-active")
                          }
                          key={i}
                          onClick={() => getChatHistoryOfRoom(room.id)}
                        >
                          <div
                            className={`avatar me-2 ir-chat-list-state-status ${
                              !room.archive
                                ? "ir-chat-list-state-active"
                                : "ir-chat-list-state-archieve"
                            }`}
                          >
                            <img
                              src={room?.image[0]}
                              className="rounded-circle"
                              alt="case chat icon"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = require("../../../assets/images/chat-images/no-image.png");
                              }}
                            />
                            {/* <div className="new bg-app-default-color">
                                                    <span>+7</span>
                                                </div> */}
                          </div>
                          <div className="ir-side-list-content">
                            <div
                              onClick={() => {
                                getChatHistoryOfRoom(room.id);
                                handleSidebar();
                                setChatSidebarOpt(false);
                              }}
                            >
                              <h5 className="ir-room-title-container">
                                <p className="text-truncate ir-room-title">
                                  {room?.name}
                                </p>
                                <div
                                  className={`ir-chat-archive-lock ${
                                    !room.archive
                                      ? "ir-chat-list-state-archive-lock"
                                      : "ir-chat-list-state-archieve-lock-display"
                                  }`}
                                >
                                  <span className="material-symbols-outlined">
                                    lock
                                  </span>
                                </div>
                              </h5>
                              <p className="text-truncate mb-1">
                                {room?.description}
                              </p>
                            </div>
                            <div className="ir-side-list-action-time-container">
                              <div className="text-muted ir-drop-downmenu-trigger-container">
                                <i
                                  className="bi bi-three-dots"
                                  onClick={() => triggerOptionsHandle(i)}
                                ></i>
                                {activeMenus[i] && (
                                  <div className={`ir-dropdown-container`}>
                                    <ul>
                                      <li
                                        className="dropdown-item"
                                        onClick={showShareModal}
                                      >
                                        Share
                                      </li>
                                      {(Number(room.createdBy) ===
                                        Number(profileData?.id || "") ||
                                        profileData?.role === "admin") && (
                                        <li
                                          className="dropdown-item"
                                          onClick={() => updateRoom(room)}
                                        >
                                          Update
                                        </li>
                                      )}
                                      {profileData.role === "admin" &&
                                        (profileData.role === "admin" &&
                                        !room.archive ? (
                                          <li
                                            className="dropdown-item"
                                            onClick={() => addToArchieve(room)}
                                          >
                                            Add to Archive
                                          </li>
                                        ) : (
                                          <li
                                            className="dropdown-item"
                                            onClick={() =>
                                              removeFromArchive(room)
                                            }
                                          >
                                            Remove From Archive
                                          </li>
                                        ))}
                                      {favorite.includes(String(room.id)) ? (
                                        <li
                                          className="dropdown-item"
                                          //   onClick={() =>
                                          //     removeFromFavorite(room)
                                          //   }
                                        >
                                          Remove Favourite
                                        </li>
                                      ) : (
                                        <li
                                          className="dropdown-item"
                                          onClick={() => addToFavorite(room)}
                                        >
                                          Add to Favourites
                                        </li>
                                      )}

                                      <li className="dropdown-divider"></li>
                                      {((Number(room.createdBy) ===
                                        Number(profileData?.id || "") &&
                                        Number(room.chatCount) === 0) ||
                                        profileData?.role === "admin") && (
                                        <li
                                          className="dropdown-item text-danger"
                                          onClick={() =>
                                            toggleDeleteModal(
                                              room,
                                              i,
                                              data.length
                                            )
                                          }
                                        >
                                          Delete
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                )}
                              </div>
                              {/* <p className="text-muted mb-1 ir-chat-list-time">
                                                        {moment().format("hh:mm A")}
                                                    </p> */}
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`tab-pane fade ${
            navMenuItemActive === 3 ? "active show" : ""
          }`}
          id="notifications"
        >
          <div className="tab-content-scroll ps ps--active-y" ref={ref}>
            <div className="d-flex gap-4 flex-column">
              <h5 className="mb-0 ir-tab-heading">Notifications</h5>
              <div className="list-group gap-1">
                {data.length > 0 &&
                  data
                    .sort((a, b) => b.id - a.id)
                    .map((room, i) => {
                      return (
                        <div
                          className={
                            "list-group-item d-flex align-items-center " +
                            (parseInt(activeChatRoom) === room.id &&
                              "chat-list-side-active")
                          }
                          key={i}
                          onClick={() => getChatHistoryOfRoom(room.id)}
                        >
                          <div
                            className={`avatar me-2 ir-chat-list-state-status ${
                              !room.archive
                                ? "ir-chat-list-state-active"
                                : "ir-chat-list-state-archieve"
                            }`}
                          >
                            <img
                              src={require("../../../assets/images/chat-images/case-chat-icon.png")}
                              className="rounded-circle"
                              alt="case chat icon"
                            />
                            {/* <div className="new bg-app-default-color">
                                                    <span>+7</span>
                                                </div> */}
                          </div>
                          <div className="ir-side-list-content">
                            <div>
                              <h5 className="ir-room-title-container">
                                <p className="text-truncate ir-room-title">
                                  {room?.name}
                                </p>
                                <div
                                  className={`ir-chat-archive-lock ${
                                    !room.archive
                                      ? "ir-chat-list-state-archive-lock"
                                      : "ir-chat-list-state-archieve-lock-display"
                                  }`}
                                >
                                  <span className="material-symbols-outlined">
                                    lock
                                  </span>
                                </div>
                              </h5>
                              <p className="text-truncate mb-1">
                                {room?.description}
                              </p>
                            </div>
                            <div className="ir-side-list-action-time-container">
                              <div className="text-muted ir-drop-downmenu-trigger-container">
                                <i
                                  className="bi bi-three-dots"
                                  onClick={() => triggerOptionsHandle(i)}
                                ></i>
                                {activeMenus[i] && (
                                  <div className={`ir-dropdown-container`}>
                                    <ul>
                                      <li
                                        className="dropdown-item"
                                        onClick={showShareModal}
                                      >
                                        Share
                                      </li>
                                      <li className="dropdown-item">
                                        Add to Favourites
                                      </li>
                                      <li className="dropdown-divider"></li>
                                      <li className="dropdown-item text-danger">
                                        Delete
                                      </li>
                                    </ul>
                                  </div>
                                )}
                              </div>
                              {/* <p className="text-muted mb-1 ir-chat-list-time">
                                                        {moment().format("hh:mm A")}
                                                    </p> */}
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`tab-pane fade ${
            navMenuItemActive === 4 ? "active show" : ""
          }`}
          id="settings"
        >
          <div className="tab-content-scroll ps ps--active-y">
            <div className="d-flex gap-4 flex-column">
              <div className="list-group gap-1 position-relative">
                <div className="user-profile-img">
                  <img
                    src={require("../../../assets/images/chat-images/profile-bg-9.jpg")}
                    className="profile-img"
                    alt=""
                  />
                  <div className="overlay-content">
                    <div className="flex-grow-1">
                      <h5 className="text-white mb-0">Settings</h5>
                    </div>
                  </div>
                </div>
                <div className="text-center ir-side-profile-container position-relative">
                  <div className="mb-lg-3 mb-2">
                    <div className="mb-2 profile-user">
                      {userStore?.profile?.image === "" ? (
                        <p className="profile-bg-replace">
                          {userStore?.profile?.firstname[0] +
                            userStore?.profile?.lastname[0]}
                        </p>
                      ) : (
                        <img
                          className="avatar-lg"
                          src={userStore?.profile?.image}
                          alt="profile pic"
                        />
                      )}
                      <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                        <input
                          id="profile-img-file-input"
                          type="file"
                          className="profile-img-file-input"
                          accept="image/png, image/jpeg"
                          name="file"
                          onChange={updateProfilePicHandle}
                        />
                        <label
                          htmlFor="profile-img-file-input"
                          className="profile-photo-edit avatar-xs form-label"
                        >
                          <span className="avatar-title rounded-circle bg-light text-body">
                            <i className="bi bi-pencil"></i>
                          </span>
                        </label>
                      </div>
                    </div>
                    <h5 className="font-size-16 mb-1 text-truncate">
                      {userStore?.profile?.firstname || ""}{" "}
                      {userStore?.profile?.lastname || ""}
                    </h5>
                    {/* <p className="text-muted text-truncate mb-0">Front end Developer</p> */}
                  </div>
                </div>
                <form onSubmit={upldateProfileSubmitFormHandle}>
                  <div className="p-4 ir-profile-desc">
                    <div className="simplebar-wrapper">
                      <div className="simplebar-mask">
                        <div className="simplebar-offset">
                          <div
                            className="simplebar-content-wrapper"
                            tabIndex="0"
                            role="region"
                            aria-label="scrollable content"
                          >
                            <div className="simplebar-content">
                              <div>
                                <div className="d-flex py-2 ir-set-prof-content-container">
                                  <div className="ir-set-prof-info-container">
                                    <div className="mb-1 ir-set-prof-info-title-container">
                                      First Name
                                    </div>
                                    <input
                                      type="text"
                                      disabled={!enableInputField["firstname"]}
                                      className="ir-set-prof-info-content-container"
                                      name="firstname"
                                      value={profileData?.firstname || ""}
                                      onChange={updateProfileInputChange}
                                    />
                                  </div>
                                  <div className="ir-set-prof-info-icon-container">
                                    <i
                                      onClick={() =>
                                        enableInputFieldHandle("firstname")
                                      }
                                      className="bi bi-pencil-fill"
                                    ></i>
                                  </div>
                                </div>
                                <div className="d-flex py-2 ir-set-prof-content-container">
                                  <div className="ir-set-prof-info-container">
                                    <div className="mb-1 ir-set-prof-info-title-container">
                                      Last Name
                                    </div>
                                    <input
                                      type="text"
                                      disabled={!enableInputField["lastname"]}
                                      className="ir-set-prof-info-content-container"
                                      name="lastname"
                                      value={profileData?.lastname || ""}
                                      onChange={updateProfileInputChange}
                                    />
                                  </div>
                                  <div className="ir-set-prof-info-icon-container">
                                    <i
                                      onClick={() =>
                                        enableInputFieldHandle("lastname")
                                      }
                                      className="bi bi-pencil-fill"
                                    ></i>
                                  </div>
                                </div>
                                <div className="d-flex py-2 ir-set-prof-content-container">
                                  <div className="ir-set-prof-info-container">
                                    <div className="mb-1 ir-set-prof-info-title-container">
                                      Username
                                    </div>
                                    <input
                                      type="text"
                                      disabled
                                      className="ir-set-prof-info-content-container"
                                      value={profileData?.username || ""}
                                      name="username"
                                    />
                                  </div>
                                  {/* <div onClick={enableInputFieldHandle} className="ir-set-prof-info-icon-container">
                                                                        <i className="bi bi-pencil-fill"></i>
                                                                    </div> */}
                                </div>

                                <div className="d-flex py-2 ir-set-prof-content-container">
                                  <div className="ir-set-prof-info-container">
                                    <div className="mb-1 ir-set-prof-info-title-container">
                                      Email
                                    </div>
                                    <input
                                      type="email"
                                      disabled={!enableInputField["email"]}
                                      className="ir-set-prof-info-content-container"
                                      name="email"
                                      value={profileData?.email || ""}
                                      onChange={updateProfileInputChange}
                                    />
                                  </div>
                                  <div className="ir-set-prof-info-icon-container">
                                    <i
                                      onClick={() =>
                                        enableInputFieldHandle("email")
                                      }
                                      className="bi bi-pencil-fill"
                                    ></i>
                                  </div>
                                </div>
                                <div className="d-flex py-2 ir-set-prof-content-container">
                                  <div className="ir-set-prof-info-container">
                                    <div className="mb-1 ir-set-prof-info-title-container">
                                      Phone Number
                                    </div>
                                    <input
                                      type="text"
                                      disabled={
                                        !enableInputField["phonenumber"]
                                      }
                                      className="ir-set-prof-info-content-container"
                                      // defaultValue={userStore?.profile?.phonenumber || "NIL"}
                                      name="phonenumber"
                                      value={profileData?.phonenumber || ""}
                                      onChange={updateProfileInputChange}
                                    />
                                  </div>
                                  <div className="ir-set-prof-info-icon-container">
                                    <i
                                      onClick={() =>
                                        enableInputFieldHandle("phonenumber")
                                      }
                                      className="bi bi-pencil-fill"
                                    ></i>
                                  </div>
                                </div>
                                <div className="d-flex py-2 ir-set-prof-content-container">
                                  <div className="ir-set-prof-info-container">
                                    <div className="mb-1 ir-set-prof-info-title-container">
                                      Gender
                                    </div>
                                    <input
                                      type="text"
                                      disabled={!enableInputField["gender"]}
                                      className="ir-set-prof-info-content-container"
                                      // defaultValue={userStore?.profile?.gender || "NIL"}
                                      name="gender"
                                      value={profileData?.gender || ""}
                                      onChange={updateProfileInputChange}
                                    />
                                  </div>
                                  <div className="ir-set-prof-info-icon-container">
                                    <i
                                      onClick={() =>
                                        enableInputFieldHandle("gender")
                                      }
                                      className="bi bi-pencil-fill"
                                    ></i>
                                  </div>
                                </div>
                                <div className="d-flex py-2 ir-set-prof-content-container">
                                  <div className="ir-set-prof-info-container">
                                    <div className="mb-1 ir-set-prof-info-title-container">
                                      Location
                                    </div>
                                    <input
                                      type="text"
                                      disabled={!enableInputField["country"]}
                                      className="ir-set-prof-info-content-container"
                                      // defaultValue={userStore?.profile?.country || "NIL"}
                                      name="country"
                                      value={profileData?.country || ""}
                                      onChange={updateProfileInputChange}
                                    />
                                  </div>
                                  <div className="ir-set-prof-info-icon-container">
                                    <i
                                      onClick={() =>
                                        enableInputFieldHandle("country")
                                      }
                                      className="bi bi-pencil-fill"
                                    ></i>
                                  </div>
                                </div>
                                <div className="d-flex py-2 ir-set-prof-content-container">
                                  <div className="ir-set-prof-info-container">
                                    <div className="mb-1 ir-set-prof-info-title-container">
                                      Password
                                    </div>
                                    <input
                                      type="text"
                                      disabled={!enableInputField["password"]}
                                      className="ir-set-prof-info-content-container"
                                      // defaultValue={userStore?.profile?.password}
                                      name="password"
                                      value={profileData?.password || ""}
                                      onChange={updateProfileInputChange}
                                    />
                                  </div>
                                  <div className="ir-set-prof-info-icon-container">
                                    <i
                                      onClick={() =>
                                        enableInputFieldHandle("password")
                                      }
                                      className="bi bi-pencil-fill"
                                    ></i>
                                  </div>
                                </div>
                              </div>
                              {/* <button className="btn btn-submit" type="submit">Submit</button> */}
                              <button
                                className="btn btn-primary bg-app-default-color btn-lg btn-block full-width-btn ir-btn-default"
                                type="submit"
                              >
                                {/* <i className="bi bi-chat-dots me-1"></i>  */}
                                <span>Update Profile</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`tab-pane fade ${
            navMenuItemActive === 5 ? "active show" : ""
          }`}
          id="profile"
        >
          <div className="tab-content-scroll ps ps--active-y">
            <div className="d-flex gap-4 flex-column">
              <div className="list-group gap-1 position-relative">
                <div className="user-profile-img">
                  {/* <img
                    src="https://doot-light.react.themesbrand.com/static/media/img-4.c7a84ad5058b9382090e.jpg"
                    className="profile-img"
                    alt=""
                  /> */}
                  <img
                    src={require("../../../assets/images/chat-images/profile-bg.jpg")}
                    className="profile-img"
                    alt=""
                  />
                  <div className="overlay-content">
                    <div className="flex-grow-1">
                      <h5 className="text-white mb-0">My Profile</h5>
                    </div>
                  </div>
                </div>
                <div className="text-center ir-side-profile-container position-relative">
                  <div className="mb-lg-3 mb-2">
                    {/* {userStore?.profile?.image === "" ? (
                      <p className="profile-bg-replace">
                        {userStore?.profile?.firstname[0] +
                          userStore?.profile?.lastname[0]}
                      </p>
                    ) : (
                      <img
                        className="avatar-lg"
                        src={userStore?.profile?.image}
                        alt="profile pic"
                      />
                    )} */}

                    {userStore?.profile?.userImage ? (
                      <img
                        className="avatar-lg"
                        src={userStore.profile.userImage}
                        alt="profile pic"
                      />
                    ) : (
                      <p className="profile-bg-replace">
                        {userStore?.profile?.firstname
                          ? userStore.profile.firstname[0].toUpperCase()
                          : ""}
                        {userStore?.profile?.lastname
                          ? userStore.profile.lastname[0].toUpperCase()
                          : ""}
                      </p>
                    )}

                    <h5 className="font-size-16 mb-1 text-truncate">
                      {userStore?.profile?.username === "admin" ? (
                        "Ramki"
                      ) : (
                        <>
                          {userStore?.profile?.firstname || ""}{" "}
                          {userStore?.profile?.lastname || ""}
                        </>
                      )}
                    </h5>
                    {/* <p className="text-muted text-truncate mb-0">Front end Developer</p> */}
                  </div>
                </div>
                <div className="p-4 ir-profile-desc">
                  <div className="simplebar-wrapper">
                    <div className="simplebar-mask">
                      <div className="simplebar-offset">
                        <div
                          className="simplebar-content-wrapper"
                          tabIndex="0"
                          role="region"
                          aria-label="scrollable content"
                        >
                          <div className="simplebar-content">
                            <div>
                              <div className="d-flex py-2">
                                <div className="flex-shrink-0 me-3">
                                  <i className="bi bi-person align-middle text-muted"></i>
                                </div>
                                <div className="flex-grow-1">
                                  <p className="ir-prof-details mb-0">
                                    {userStore?.profile?.username ===
                                    "admin" ? (
                                      "Ramki"
                                    ) : (
                                      <>
                                        {userStore?.profile?.firstname || ""}{" "}
                                        {userStore?.profile?.lastname || ""}
                                      </>
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex py-2">
                                <div className="flex-shrink-0 me-3">
                                  <i className="bi bi-person align-middle text-muted"></i>
                                </div>
                                <div className="flex-grow-1">
                                  <p className="ir-prof-details mb-0">
                                    {userStore?.profile?.username || ""}
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex py-2">
                                <div className="flex-shrink-0 me-3">
                                  <i className="bi bi-chat-dots align-middle text-muted"></i>
                                </div>
                                <div className="flex-grow-1">
                                  <p className="ir-prof-details mb-0">
                                    {userStore?.profile?.email || ""}
                                  </p>
                                </div>
                              </div>
                              {/* {userStore?.profile?.gender === "" ? (
                                ""
                              ) : (
                                <div className="d-flex py-2">
                                  <div className="flex-shrink-0 me-3">
                                    <i className="bi bi-person align-middle text-muted"></i>
                                  </div>
                                  <div className="flex-grow-1">
                                    <p className="ir-prof-details mb-0">
                                      {userStore?.profile?.gender || ""}
                                    </p>
                                  </div>
                                </div>
                              )} */}

                              {userStore?.profile?.gender && (
                                <div className="d-flex py-2">
                                  <div className="flex-shrink-0 me-3">
                                    <i className="bi bi-person align-middle text-muted"></i>
                                  </div>
                                  <div className="flex-grow-1">
                                    <p className="ir-prof-details mb-0">
                                      {userStore.profile.gender}
                                    </p>
                                  </div>
                                </div>
                              )}

                              {userStore?.profile?.phonenumber && (
                                <div className="d-flex py-2">
                                  <div className="flex-shrink-0 me-3">
                                    <i className="bi bi-telephone align-middle text-muted"></i>
                                  </div>
                                  <div className="flex-grow-1">
                                    <p className="ir-prof-details mb-0">
                                      {userStore?.profile?.phonenumber || ""}
                                    </p>
                                  </div>
                                </div>
                              )}
                              {userStore?.profile?.country && (
                                <div className="d-flex py-2">
                                  <div className="flex-shrink-0 me-3">
                                    <i className="bi bi-geo-alt align-middle text-muted"></i>
                                  </div>
                                  <div className="flex-grow-1">
                                    <p className="ir-prof-details mb-0">
                                      {userStore?.profile?.country || ""}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {newCaseModal &&
        (!isImageEditOpen ? (
          <div className="ir-ws-position-fixed ir-ws-sign-popup-container">
            <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container !py-[10px] md:!py-[15px] lg:!py-[20px] xl:!py-[30px] !max-w-[400px] md:!max-w-[400px] xl:!max-w-[500px] 3xl:!max-w-[600px]">
              <div className="ir-ws-signup-content-container ">
                <div
                  className="ir-ws-position-absolute ir-ws-signup-close-container"
                  onClick={newChatCaseCloseModalHandle}
                >
                  <i className="bi bi-x-lg"></i>
                </div>
                <div className="ir-ws-signup-content-inner-container">
                  <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title !text-[20px]  lg:!text-[24px] xl:!text-[26px] 3xl:!text-[30px]">
                    Create Case
                  </h3>
                </div>
                {fileSizeLimit === false ? (
                  ""
                ) : (
                  <p className="ir-file-exceeded">
                    File size should be less than 2MB
                  </p>
                )}
                <form
                  className={`ir-ws-signup-form-container ir-ws-chat-create-case-cont !mt-[10px] lg:!mt-[20px] xl:!mt-[40px] 3xl:!mt-[50px] !px-[15px] md:!px-[20px] lg:!px-[30px] xl:!px-[40px] 3xl:!px-[50px] ${
                    isImageEditOpen ? "imageEditModalOpened" : ""
                  }`}
                  onSubmit={newCaseSubmitFormHandle}
                >
                  <div className="ir-ws-signup-content-group !my-[15px] lg:!my-[25px] xl:!my-[30px] 3xl:!my-[35px]">
                    <input
                      className="ir-ws-signup-input-field !text-[10px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px]"
                      type="text"
                      name="name"
                      onChange={newCaseInputChange}
                      value={newCaseData.name}
                      required
                    />
                    <span className="ir-ws-signup-highlight"></span>
                    <span className="ir-ws-signup-bar"></span>
                    <label className="ir-ws-signup-label !text-[8px] lg:!text-[8px] xl:!text-[10px] 3xl:!text-[12px]">
                      Case Name
                    </label>
                  </div>
                  {/* <div className="ir-ws-signup-content-group !my-[15px] lg:!my-[25px] xl:!my-[30px] 3xl:!my-[35px]">
                    <textarea
                      className="ir-ws-signup-input-field !text-[12px] lg:!text-[12px] xl:!text-[14px] 3xl:!text-[16px]"
                      type="text"
                      name="description"
                      onChange={newCaseInputChange}
                      value={newCaseData.description}
                      required
                    ></textarea>
                    <span className="ir-ws-signup-highlight"></span>
                    <span className="ir-ws-signup-bar"></span>
                    <label className="ir-ws-signup-label !text-[10px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px]">
                      Case Description
                    </label>
                  </div> */}
                  <p className="ir-ws-signup-label pl-[5px] mb-[8px] text-[#15186d99]  !text-[10px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px]">
                    Case Description
                  </p>
                  <div className="ir-chat-quill-cont  ">
                    <ReactQuill
                      className="ir-ws-signup-input-field text-[#0b2f8a] !text-[12px] lg:!text-[12px] xl:!text-[14px] 3xl:!text-[16px]"
                      theme="snow"
                      value={newCaseData.description}
                      onChange={(value) =>
                        newCaseInputChange({
                          target: { name: "description", value },
                        })
                      }
                      modules={{
                        toolbar: [["bold"]], // Only Bold option
                      }}
                    />
                  </div>

                  <div className="ir-ws-file-field">
                    {/* <div className="ir-ws-file-path-wrapper">
                      {uploadedFiles.map((file, id) => (
                        <div key={id} className="file-field-wrapper">
                          <span className="file-field-name">{file.name}</span>
                          <span
                            className="remove-file-item"
                            onClick={() => removeUploadItem(id)}
                          >
                            X
                          </span>
                        </div>
                      ))}
                    </div> */}

                    <div className=" flex items-center gap-[20px]">
                      {uploadedFiles?.map((item, index) => (
                        <div
                          className=" flex items-center flex-col relative"
                          key={item?.id}
                        >
                          <img
                            src={URL?.createObjectURL(item)}
                            alt="edited img"
                            className="w-[40px] relative h-[40px] lg:w-[36px] lg:h-[36px] xl:w-[40px] xl:h-[40px] 3xl:w-[50px] 3xl:h-[50px] rounded-[4px]"
                          />
                          <div
                            className="ir-ws-chat-multi-image-func"
                            onClick={() => handleEditImage(item)}
                          >
                            <i className="bi bi-pencil text-[12px] cursor-pointer lg:text-[10px] xl:text-[12px] 3xl:text-[14px]"></i>
                          </div>
                          <div
                            className="absolute top-[-12px] right-[-14px] cursor-pointer"
                            onClick={() => handleRemoveImage(index)}
                          >
                            <i className="bi bi-x text-[12px] lg:text-[12px] xl:text-[14px] 3xl:text-[16px]"></i>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="ir-ws-create-case-file-btn-container">
                      <span className="btn blue-gradient btn-sm ">
                        Add file
                      </span>
                      <input
                        type="file"
                        ref={fileInputRef}
                        // multiple={false}
                        multiple
                        onChange={handleFileChange}
                        accept="image/*"
                        className={`${
                          uploadedFiles.length === 0
                            ? "doesnt-exists"
                            : "input-file-exist"
                        }`}
                      />
                    </div>
                  </div>
                  <div className="ir-accept-terms-container">
                    <div className="ir-checkbox ir-ws-text-center">
                      <input
                        onChange={handleAcceptChange}
                        type="checkbox"
                        id="terms-conditions"
                      />
                      <label
                        htmlFor="terms-conditions"
                        className=" !text-[10px] lg:!text-[8px] xl:!text-[12px] "
                      >
                        Accept Terms and Conditions.
                      </label>
                      <a
                        href={`${window.location.origin}/terms-conditions`}
                        target="_blank"
                        rel="noreferrer"
                        className="!text-[10px] lg:!text-[8px] xl:!text-[12px] "
                      >
                        Read guidelines here
                      </a>
                    </div>
                  </div>
                  <div className="ir-ws-text-center ir-ws-default-btn-container">
                    <button
                      className="!w-[200px] !p-[0px] !h-[36px] !text-[10px] lg:!h-[36px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px] xl:!h-[40px] 3xl:!h-[50px] ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                      disabled={!agree || createCaseLoading}
                      type="submit"
                    >
                      {createCaseLoading ? (
                        <div className="create-case-loader-container">
                          <span className="loading-spinner"></span>
                          <span className="loading-text">Creating</span>
                        </div>
                      ) : (
                        <span>Create Case</span>
                      )}

                      {/* <span>Create Case</span> */}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        ) : (
          <div className="ir-ws-position-fixed ir-ws-sign-popup-container imageEditModalMainContainer">
            <div
              className={`ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container`}
            >
              <div className="ir-ws-signup-content-container ">
                <div
                  className="ir-ws-position-absolute ir-ws-signup-close-container"
                  onClick={newChatCaseCloseModalHandle}
                >
                  <i className="bi bi-x-lg"></i>
                </div>
                <div className="ir-ws-signup-content-inner-container">
                  <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                    Create Case
                  </h3>
                </div>
                <div className="ir-ws-chat-create-case-image-editor">
                  <FilerobotImageEditor
                    source={URL.createObjectURL(selectedImageFile)}
                    onSave={onImageSave}
                    onBeforeSave={(savedImageData, save) => {
                      savedImageData.name = selectedImageFile.name;
                      return false;
                    }}
                    Text={{
                      text: "Double click to add text ",
                      fontSize: 22,
                    }}
                    defaultSavedImageType="jpeg" // Always save as JPEG
                    defaultSavedImageQuality={1} // Set quality to 80%
                    Pen={{
                      selectAnnotationAfterDrawing: true,
                    }}
                    tabsIds={[TABS.ADJUST, TABS.ANNOTATE]}
                    Rotate={{ angle: 90, componentType: "slider" }}
                    Crop={{ noPresets: true, ratio: "custom" }}
                    onClose={() => {
                      setImageEditOpen(false);
                      setSelectedImageFile(null);
                    }}
                    // onClose={() => {
                    //   if (fileInputRef.current) {
                    //     fileInputRef.current.value = ""; // Clear the input field
                    //   }
                    //   closeImgEditor();
                    // }}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      {shareModal && (
        <div className="ir-ws-position-fixed ir-ws-sign-popup-container ir-ws-share-main-container">
          <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
            <div className="ir-ws-signup-content-container">
              <div
                className="ir-ws-position-absolute ir-ws-signup-close-container"
                onClick={shareCloseModalHandle}
              >
                <i className="bi bi-x-lg"></i>
              </div>
              <div className="ir-ws-signup-content-inner-container">
                <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                  Share Case
                </h3>
              </div>
              <div className="ir-share-modal-inside-content">
                <p>Invite your friends through social network</p>
                <div className="ir-share-container">
                  <div className="ir-share-icon-container ir-share-icon-container-hover">
                    <FacebookShareButton
                      url={shareUrl}
                      quote={"Dummy text!"}
                      hashtag="#ir4u"
                    >
                      <FacebookIcon size={50} round />
                    </FacebookShareButton>
                  </div>
                  <div className="ir-share-icon-container ir-share-icon-container-hover">
                    <WhatsappShareButton url={shareUrl} quote={"Dummy text!"}>
                      <WhatsappIcon size={50} round />
                    </WhatsappShareButton>
                  </div>
                  <div className="ir-share-icon-container ir-share-icon-container-hover">
                    {/* <div
                      className="ir-share-icon-copy-link"
                      onClick={() => navigator.clipboard.writeText(shareUrl)}
                      title="Copy Link"
                    >
                      <i className="bi bi-link-45deg"></i>
                    </div> */}
                    {!tooltipText ? (
                      <div
                        className="ir-share-icon-copy-link ir-share-icon-link"
                        onClick={handleCopyLink}
                        title="Copy Link"
                      >
                        <i className="bi bi-link-45deg"></i>
                      </div>
                    ) : (
                      <div
                        className="ir-share-icon-copy-link ir-share-icon-link"
                        title="Link Copied"
                      >
                        <i className="bi bi-check-lg"></i>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {updateProfilePicModal && (
        <div className="iks-mint-cust-modal">
          <div className="iks-mint-modal-container ">
            <div className="iks-mint-modal-content">
              <div className="ir-form-title">
                <h2>Update Profile Pic</h2>
                <button onClick={updateProfilePicCloseModalHandle}>
                  <i className="bi bi-x-lg"></i>
                </button>
              </div>
              <form
                className="ir-case-form-container"
                onSubmit={upldateProfileSubmitFormHandle}
              >
                <div className="it-case-update-profile-img-container">
                  <img
                    src={updateProfilePic}
                    alt="update profile pic"
                    className="ir-update-prof-pic-onupdate"
                  />
                </div>
                {/* </div> */}
                <div className="ir-modal-btn-container">
                  <button
                    className="btn btn-submit ir-btn-default"
                    type="submit"
                  >
                    <span>Update</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {isRoomUpdating && (
        <div
          className={`ir-ws-position-fixed ir-ws-sign-popup-container ${
            isImageEditOpen ? "imageEditModalMainContainer" : ""
          }`}
        >
          <div
            className={`ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container !py-[10px] md:!py-[15px] lg:!py-[20px] xl:!py-[30px] !max-w-[400px] md:!max-w-[400px] xl:!max-w-[500px] 3xl:!max-w-[600px]`}
          >
            <div className="ir-ws-signup-content-container">
              <div
                className="ir-ws-position-absolute ir-ws-signup-close-container"
                onClick={updateChatCaseCloseModalHandle}
              >
                <i className="bi bi-x-lg"></i>
              </div>
              <div className="ir-ws-signup-content-inner-container">
                <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title !text-[20px]  lg:!text-[24px] xl:!text-[26px] 3xl:!text-[30px]">
                  Update Case
                </h3>
              </div>
              {fileSizeLimit === false ? (
                ""
              ) : (
                <p className="ir-file-exceeded">
                  File size should be less than 2MB
                </p>
              )}
              <form
                className={`ir-ws-signup-form-container ir-ws-chat-create-case-cont !mt-[10px] lg:!mt-[20px] xl:!mt-[40px] 3xl:!mt-[50px] !px-[15px] md:!px-[20px] lg:!px-[30px] xl:!px-[40px] 3xl:!px-[50px] ${
                  isImageEditOpen ? "imageEditModalOpened" : ""
                }`}
                onSubmit={newCaseSubmitFormHandle}
              >
                <div className="ir-ws-signup-content-group !my-[15px] lg:!my-[25px] xl:!my-[30px] 3xl:!my-[35px]">
                  <input
                    className="ir-ws-signup-input-field !text-[12px] lg:!text-[12px] xl:!text-[14px] 3xl:!text-[16px]"
                    type="text"
                    name="name"
                    onChange={newCaseInputChange}
                    value={newCaseData.name}
                    required
                  />
                  <span className="ir-ws-signup-highlight"></span>
                  <span className="ir-ws-signup-bar"></span>
                  <label className="ir-ws-signup-label !text-[10px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px]">
                    Case Name
                  </label>
                </div>
                {/* <div className="ir-ws-signup-content-group !my-[15px] lg:!my-[25px] xl:!my-[30px] 3xl:!my-[35px]">
                  <textarea
                    className="ir-ws-signup-input-field !text-[12px] lg:!text-[12px] xl:!text-[14px] 3xl:!text-[16px]"
                    type="text"
                    name="description"
                    onChange={newCaseInputChange}
                    value={newCaseData.description}
                    required
                  ></textarea>
                  <span className="ir-ws-signup-highlight"></span>
                  <span className="ir-ws-signup-bar"></span>
                  <label className="ir-ws-signup-label !text-[10px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px]">
                    Case Description
                  </label>
                </div> */}
                <p className="ir-ws-signup-label pl-[5px] mb-[8px] text-[#15186d99]  !text-[10px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px]">
                  Case Description
                </p>
                <div className="ir-chat-quill-cont ">
                  <ReactQuill
                    className="ir-ws-signup-input-field text-[#0b2f8a] !text-[12px] lg:!text-[12px] xl:!text-[14px] 3xl:!text-[16px]"
                    theme="snow"
                    value={newCaseData.description}
                    onChange={(value) =>
                      newCaseInputChange({
                        target: { name: "description", value },
                      })
                    }
                    modules={{
                      toolbar: [["bold"]], // Only Bold option
                    }}
                  />
                </div>
                {/* <div className="ir-images-row images">
                  {newCaseData?.images?.length > 0 &&
                    newCaseData.images.map((d, i) => {
                      return (
                        <>
                          <div className="ir-image-col">
                            <a onClick={() => deleteImage(d, newCaseData)}>X</a>
                            <img src={d} alt="a" height={80} />
                          </div>
                        </>
                      );
                    })}
                </div> */}

                {/* <div className="ir-ws-signup-content-group"> */}
                <div className="flex items-center gap-[20px] mt-[15px]">
                  {uploadedFiles
                    ?.filter((item) => item)
                    .map((item, index) => (
                      <div
                        className="flex items-center flex-col relative"
                        key={index}
                      >
                        <img
                          src={
                            typeof item === "string"
                              ? item
                              : URL.createObjectURL(item)
                          }
                          alt="edited img"
                          className="w-[40px] h-[40px] rounded-[4px]"
                        />

                        <div
                          // className="ir-ws-chat-multi-image-func"
                          className={`ir-ws-chat-multi-image-func ${
                            typeof item === "string"
                              ? "opacity-0 pointer-events-none"
                              : ""
                          }`}
                          onClick={() => handleEditImage(item)}
                        >
                          <i className="bi bi-pencil text-[12px] cursor-pointer"></i>
                        </div>
                        {/* )} */}

                        {/* Delete Image Button */}
                        <div
                          className="absolute top-[-12px] right-[-14px] cursor-pointer"
                          onClick={() => handleRemoveImage(index)}
                        >
                          <i className="bi bi-x text-[12px]"></i>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="ir-ws-file-field !mt-[0px]">
                  {/* <div className="ir-ws-file-path-wrapper">
                    {uploadedFiles.map((file, id) => (
                      <div key={id} className="file-field-wrapper">
                        <span className="file-field-name">{file.name}</span>
                        <span
                          className="remove-file-item"
                          onClick={() => removeUploadItem(id)}
                        >
                          X
                        </span>
                      </div>
                    ))}
                  </div> */}
                  <div className="ir-ws-create-case-file-btn-container">
                    <span className="btn blue-gradient btn-sm">Add file</span>
                    <input
                      type="file"
                      accept="image/*"
                      ref={updateCaseInputRef}
                      // multiple={false}
                      multiple
                      onChange={handleFileChange}
                      className={`${
                        uploadedFiles.length === 0
                          ? "doesnt-exists"
                          : "input-file-exist"
                      }`}
                    />
                  </div>
                </div>
                <div className="ir-ws-text-center ir-ws-default-btn-container">
                  <button
                    className="!w-[200px] !p-[0px] !h-[36px] !text-[10px] lg:!h-[36px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px] xl:!h-[40px] 3xl:!h-[50px] ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                    type="submit"
                    disabled={createCaseLoading}
                  >
                    {/* <span>Update Case</span> */}
                    {createCaseLoading ? (
                      <div className="create-case-loader-container">
                        <span className="loading-spinner"></span>
                        <span className="loading-text">Updating</span>
                      </div>
                    ) : (
                      <span>Update Case</span>
                    )}
                  </button>
                </div>
              </form>
              {isImageEditOpen && (
                <div className="ir-ws-chat-create-case-image-editor">
                  {/* <FilerobotImageEditor
                    source={URL.createObjectURL(fillterUplaodFile[0])}
                    onSave={onImageSave}
                    onBeforeSave={(savedImageData, save) => {
                      savedImageData.name = fillterUplaodFile[0].name;
                      return false;
                    }}
                    Text={{ text: "Double click to add text ", fontSize: 22 }}
                    defaultSavedImageType="jpeg" // Always save as JPEG
                    defaultSavedImageQuality={1} // Set quality to 80%
                    Pen={{
                      selectAnnotationAfterDrawing: true,
                    }}
                    tabsIds={[TABS.ADJUST, TABS.ANNOTATE]}
                    Rotate={{ angle: 90, componentType: "slider" }}
                    Crop={{ noPresets: true, ratio: "custom" }}
                    onClose={() => {
                      if (updateCaseInputRef.current) {
                        updateCaseInputRef.current.value = ""; // Clear the input field
                      }
                      closeImgEditor();
                    }}
                  /> */}
                  <FilerobotImageEditor
                    source={URL.createObjectURL(selectedImageFile)}
                    onSave={onImageSave}
                    onBeforeSave={(savedImageData, save) => {
                      savedImageData.name = selectedImageFile.name;
                      return false;
                    }}
                    Text={{
                      text: "Double click to add text ",
                      fontSize: 22,
                    }}
                    defaultSavedImageType="jpeg" // Always save as JPEG
                    defaultSavedImageQuality={1} // Set quality to 80%
                    Pen={{
                      selectAnnotationAfterDrawing: true,
                    }}
                    tabsIds={[TABS.ADJUST, TABS.ANNOTATE]}
                    Rotate={{ angle: 90, componentType: "slider" }}
                    Crop={{ noPresets: true, ratio: "custom" }}
                    onClose={() => {
                      setImageEditOpen(false);
                      setSelectedImageFile(null);
                    }}
                    // onClose={() => {
                    //   if (fileInputRef.current) {
                    //     fileInputRef.current.value = ""; // Clear the input field
                    //   }
                    //   closeImgEditor();
                    // }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {isSynopsisModalVisible && (
        <div
          className={`ir-ws-position-fixed ir-ws-sign-popup-container
          `}
        >
          <div
            className={`ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container`}
          >
            <div className="ir-ws-signup-content-container">
              <div
                className="ir-ws-position-absolute ir-ws-signup-close-container"
                onClick={closeSynopsisModal}
              >
                <i className="bi bi-x-lg"></i>
              </div>
              <div className="ir-ws-signup-content-inner-container">
                <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                  {synopsisAction === "Add"
                    ? "Add Synopsis"
                    : "Update Synopsis"}
                </h3>
              </div>

              <form
                className="ir-ws-signup-form-container ir-ws-chat-create-case-cont"
                onSubmit={synopsisSubmitHandler}
              >
                <div className="ir-ws-signup-content-group">
                  <input
                    className="ir-ws-signup-input-field"
                    type="text"
                    name="objective"
                    //   placeholder="Objective"
                    value={synopsisObjective}
                    disabled
                    // onChange={(e) => setSynopsisObjective(e.target.value)}

                    required
                  />
                  <span className="ir-ws-signup-highlight"></span>
                  <span className="ir-ws-signup-bar"></span>
                  {/* <label className="ir-ws-signup-label">Objective</label> */}
                </div>
                <div className="ir-ws-signup-content-group">
                  <textarea
                    className="ir-ws-signup-input-field"
                    name="details"
                    //   placeholder="Details"
                    value={synopsisDetails}
                    onChange={(e) => setSynopsisDetails(e.target.value)}
                    required
                  ></textarea>
                  <span className="ir-ws-signup-highlight"></span>
                  <span className="ir-ws-signup-bar"></span>
                  <label className="ir-ws-signup-label">Details</label>
                </div>

                <div className="ir-ws-text-center ir-ws-default-btn-container">
                  <button
                    className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                    type="submit"
                  >
                    <span>
                      {synopsisAction === "Add" ? "Create" : "Update"}
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {showDeleteModal && (
        <DeleteConfirmation
          toggleModal={toggleDeleteModal}
          confirmationFunc={() =>
            deleteRoom(deleteRoomId, deleteIndex, deleteRoomDataLength)
          }
        />
      )}
    </div>
  );
};

export default ChatSidebar;
