import React from "react";
import ProductTab from "../SecondAdmin/ProductTab";
import ProductList from "../SecondAdmin/ProductList";

const ProductPanel = () => {
  return (
    <div className="h-[100%]">
      <ProductTab />
      <ProductList />
    </div>
  );
};

export default ProductPanel;
