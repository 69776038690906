import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./HomePreviewTabContainer.scss";
import { IoCloseOutline } from "react-icons/io5";
import { BiLoaderAlt } from "react-icons/bi";
import { PreviewTabComponent } from "./PreviewTabComponent";
import BookNowModal from "../HomeWebinarFeatureContainer/BookNowModal";
import { useModalContext } from "../WebinarAdminCreateWebinarTab/ModalContext";

const HomePreviewTabContainer = () => {
  const [modalChatOne, setModalChatOne] = useState(false);
  const [modalChatTwo, setModalChatTwo] = useState(false);
  const [modalChatThree, setModalChatThree] = useState(false);
  const [videoLoading, setVideoLoading] = useState(true);
  const [bookNowModal, setBookNowModal] = useState(false);
  // const [emailVerified, setEmailVerified] = useState(false);
  const { emailVerified, setEmailVerified, username } = useModalContext();
  const loggedIn = localStorage.getItem("isLoggedIn") === "true";
  const isAdmin = localStorage.getItem("loggedInUsername") === "admin";
  const userProfile = JSON.parse(localStorage.getItem("userProfile") || "[]");
  const isSecondAdmin = userProfile.includes("publisher");

  const navigate = useNavigate();

  const openModalChatOne = () => {
    setModalChatOne(!modalChatOne);
  };

  const closeModalChatOne = () => {
    setModalChatOne(!modalChatOne);
  };
  const openModalChatTwo = () => {
    setModalChatTwo(!modalChatTwo);
  };

  const closeModalChatTwo = () => {
    setModalChatTwo(!modalChatTwo);
  };

  const openModalChatThree = () => {
    setModalChatThree(!modalChatThree);
  };

  const closeModalChatThree = () => {
    setModalChatThree(!modalChatThree);
  };

  const spinner = () => {
    setVideoLoading(!videoLoading);
  };

  const navigateToCases = () => {
    navigate("/cases");
  };

  const navigateToCourses = () => {
    navigate("/courses");
  };

  const navigateToWebinar = () => {
    navigate("/webinar");
  };

  const [activeIndex, setActiveIndex] = useState(1);
  const handleClick = (index) => setActiveIndex(index);
  const checkActive = (index, className) =>
    activeIndex === index ? className : "";

  const containers = [
    {
      isActive: checkActive(1, "ir-ws-tab-prvw-active"),
      cards: [
        {
          image: require("../../assets/images/chat-2.webp"),
          title: "IVC filter placement gone wrong",
          points: [
            "Elderly female present with lower extremity swelling.",
            "High risk for PE.",
            "US demonstrate acute femoral vein DVT.",
            "Contraindications for anticoagulation.",
          ],
          onClick: openModalChatOne,
        },
        {
          image: require("../../assets/images/cather-1.webp"),
          title: "How bad can a tunneled dialysis catheter be placed",
          points: [
            "Young male with CKD requiring emergent hemodialysis.",
            "Bedside placement of Tunneled dialysis catheter, which is non-functional.",
            "Abnormal findings on Fluoroscopy.",
            "Successfully managed by Interventional radiology.",
          ],
          onClick: openModalChatTwo,
        },
        {
          image: require("../../assets/images/anatomy-2.webp"),
          title: "Know the anatomy",
          points: [
            "Elderly male presented to ED with acute abdominal pain.",
            "An Interventional radiologist should have the mind of a clinician, surgeon and a diagnostic radiologist.",
            "Assist in procedural planning.",
            "Assist in interpret, diagnose or investigate the problem.",
          ],
          onClick: openModalChatThree,
        },
      ],
    },
    {
      isActive: checkActive(2, "ir-ws-tab-prvw-active"),
      cards: [
        {
          image: require("../../assets/images/case-1.jpg"),
          title: "Cholecystostomy",
          points: [
            "Elderly patient presenting with acute abdominal pain.",
            "High risk for surgery.",
            "Percutaneous drainage requested.",
          ],
          onClick: navigateToCases,
        },
        {
          image: require("../../assets/images/case-2.jpg"),
          title: "Intermittent GI bleed",
          points: [
            "Middle age patient with acute abdominal pain.",
            "Acute on chronic pancreatitis.",
            "Pseudocysts drainages.",
            "Now presents with drop in Hb, requiring transfusions.",
          ],
          onClick: navigateToCases,
        },
        {
          image: require("../../assets/images/case-3.jpg"),
          title: "Acute GI Bleed",
          points: [
            "Young female with acute on chronic pancreatitis.",
            "Underwent cystogastrostomy for drainage.",
            "Presents with drop in Hb.",
            "Initial negative endoscopy and CT scans.",
            "Repeat endoscopy demonstrates massive GI bleed.",
          ],
          onClick: navigateToCases,
        },
      ],
    },
    {
      isActive: checkActive(3, "ir-ws-tab-prvw-active"),
      cards: [
        {
          image: require("../../assets/images/courses-small-1.jpg"),
          title: "Basics in Interventional Radiology",
          points: [
            "Interventional radiology (IR) is referred as surgical radiology.",
            "It requires years of training to master the skills needed to perform the wide breadth of minimally invasive procedures.",
            "The training ranges from learning the basics to latest advancements.",
          ],
          onClick: navigateToCourses,
        },
        {
          image: require("../../assets/images/courses-small-2.jpg"),
          title: "Women Interventions",
          points: [
            "Over the past few decades, role of Interventional radiology in women’s health has accelerated.",
            "Interventional radiology is fundamental in the treatment of multiple conditions that affect women.",
            "These include uterine fibroids, infertility, chronic pelvic pain, and varicose veins.",
          ],
          onClick: navigateToCourses,
        },
        {
          image: require("../../assets/images/courses-small-3.jpg"),
          title: "Oncologic Interventions",
          points: [
            "Interventional radiology plays a prominent role in the oncological management.",
            "IR input begins with establishing the initial diagnosis of cancer and involvement now extends to minimally invasive treatment of malignancy.",
            "Often in combination with other modalities.",
          ],
          onClick: navigateToCourses,
        },
      ],
    },
    {
      isActive: checkActive(4, "ir-ws-tab-prvw-active"),
      cards: [
        {
          image: require("../../assets/images/web-2h.jpg"),
          title: "Gastrointestinal Bleeding Interventions",
          points: [
            "A multidisciplinary perspective in the management of gastrointestinal bleeding.",
            "Speakers from different fields provide a comprehensive perspective to enhance understanding of pathology and management of bleeding.",
          ],
          onClick: navigateToWebinar,
        },
        {
          image: require("../../assets/images/web-1h.jpg"),
          title: "Lymphatic Interventions",
          points: [
            "Lymphatics are the overflow system for the veins.",
            "Lymphangiogram and embolization play a significant role in managing leaks.",
            "A multidisciplinary perspective in the assessment and management of various lymphatic disorders.",
          ],
          onClick: navigateToWebinar,
        },
        {
          image: require("../../assets/images/web-3h.jpg"),
          title: "Venous Sampling",
          points: [
            "Venous sampling procedures are generally safe and useful for localizing abnormal tissue and tumors in various organs.",
            "Competent interventional radiologists must understand the clinical, anatomical, and technical aspects of these procedures.",
            "A multidisciplinary perspective on the role of venous sampling, techniques, and knowledge sharing.",
          ],
          onClick: navigateToWebinar,
        },
      ],
    },
  ];

  return (
    <div className="ir-ws-padding-tb-100 ir-ws-padding-lr-132 ir-ws-hme-prvw-tab-main-container">
      <div className="ir-ws-hme-prvw-tabs-panel-main-container">
        <div className="ir-ws-hme-prvw-signup-container">
          <div className="ir-ws-hme-prvw-signup-inner-container ir-ws-hme-prvw-mb-cont flex flex-col items-center justify-center">
            <div className="ir-ws-prvw-signup-content-container">
              <h4 className="ir-ws-heading-default-color ir-ws-prvw-signup-title text-center">
                {/* <span className="ir-ws-text-uppercase">Sign up</span> to Access
                All These Features and More */}
                Empower Your Creativity – Join Our Leadership Team!
              </h4>
              {/* <div className="ir-ws-flex ir-ws-align-center ir-ws-prvw-signup-dot-main-container">
                <div className="ir-ws-prvw-signup-dot-small"></div>
                <div className="ir-ws-prvw-signup-dot-small"></div>
                <div className="ir-ws-prvw-signup-dot-large"></div>
              </div> */}
              <p className="ir-default-color text-center">
                Become a part of our leadership team and collaborate to create
                impactful webinars, cases, and courses.
              </p>
            </div>
            <div className="flex flex-col md:flex-row items-center gap-[10px] lg:gap-[15px] xl:gap-[30px] mt-[30px] mb-[30px] md:mb-[80px]">
              {!(isAdmin || isSecondAdmin) && (
                <div
                  className="ir-ws-prvw-signup-btn-container !w-[237px]"
                  // onClick={() => {
                  //   if (loggedIn) {
                  //     if (emailVerified) {
                  //       // handleTermsAndCondition();
                  //       // setSendWebinarId(webinar.id);
                  //       navigate("/collaborate");
                  //     } else {
                  //       setBookNowModal(true);
                  //     }
                  //   } else if (!loggedIn) {
                  //     setBookNowModal(true);
                  //   }
                  // }}
                  onClick={() => {
                    if (loggedIn) {
                      navigate("/collaborate");
                    } else if (!loggedIn) {
                      setBookNowModal(true);
                    }
                  }}

                  // disabled={isAdmin || isbookLoading}
                >
                  <p className="ir-ws-app-bg ir-ws-text-uppercase !w-[237px] ir-color-white ir-ws-no-border ir-ws-prvw-signup-btn cursor-pointer">
                    <span>Collaborate Now</span>
                  </p>
                </div>
              )}
              <a href="/collaborate-policy">
                <div className="ir-ws-prvw-signup-btn-container !w-[237px]">
                  <p className="ir-ws-app-bg ir-ws-text-uppercase !w-[100%]  ir-color-white ir-ws-no-border ir-ws-prvw-signup-btn cursor-pointer">
                    <span>Learn More</span>
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <h2 className="ir-ws-text-center ir-ws-hme-prvw-title">
        Preview our popular features, Watch videos to know more
      </h2>
      <div className="ir-ws-hme-tabs-container">
        <div className="ir-ws-flex ir-ws-hme-tabs-header-container">
          <div
            className={`ir-ws-position-relative ir-ws-text-center ir-ws-prvw-tab-title-container ${checkActive(
              1,
              "ir-ws-tab-prvw-active"
            )}`}
            onClick={() => handleClick(1)}
          >
            <img
              className="ir-ws-block ir-ws-prvw-tab-img"
              src={require("../../assets/images/chat.png")}
              alt="Chat icon"
            />
            <div className="ir-ws-prvw-tab-title">Chat</div>
          </div>
          <div
            className={`ir-ws-position-relative ir-ws-text-center ir-ws-prvw-tab-title-container ${checkActive(
              2,
              "ir-ws-tab-prvw-active"
            )}`}
            onClick={() => handleClick(2)}
          >
            <img
              className="ir-ws-block ir-ws-prvw-tab-img"
              src={require("../../assets/images/cases.png")}
              alt="cases icon"
            />
            <div className="ir-ws-prvw-tab-title">Cases</div>
          </div>
          <div
            className={`ir-ws-position-relative ir-ws-text-center ir-ws-prvw-tab-title-container ${checkActive(
              3,
              "ir-ws-tab-prvw-active"
            )}`}
            onClick={() => handleClick(3)}
          >
            <img
              className="ir-ws-block ir-ws-prvw-tab-img"
              src={require("../../assets/images/courses.png")}
              alt="Courses icon"
            />
            <div className="ir-ws-prvw-tab-title">Courses</div>
          </div>
          <div
            className={`ir-ws-position-relative ir-ws-text-center ir-ws-prvw-tab-title-container ${checkActive(
              4,
              "ir-ws-tab-prvw-active"
            )}`}
            onClick={() => handleClick(4)}
          >
            <img
              className="ir-ws-block ir-ws-prvw-tab-img"
              src={require("../../assets/images/webinar.png")}
              alt="Webinar icon"
            />
            <div className="ir-ws-prvw-tab-title">Webinar</div>
          </div>
        </div>
        <div className="ir-ws-hme-prvw-tabs-panel-main-container">
          <div className="ir-ws-hme-tab-prvw-panels-container">
            {containers.map((container, index) => (
              <PreviewTabComponent
                key={index}
                isActive={container.isActive}
                cards={container.cards}
              />
            ))}
          </div>
        </div>

        <div className="ir-ws-hme-prvw-tabs-panel-main-container">
          {!loggedIn && (
            <div className="ir-ws-hme-prvw-signup-container">
              <div className="ir-ws-hme-prvw-signup-inner-container ir-ws-hme-prvw-mb-cont">
                <div className="ir-ws-prvw-signup-content-container">
                  <h4 className="ir-ws-heading-default-color ir-ws-prvw-signup-title">
                    <span className="ir-ws-text-uppercase">Sign up</span> to
                    Access All These Features and More
                  </h4>
                  <div className="ir-ws-flex ir-ws-align-center ir-ws-prvw-signup-dot-main-container">
                    <div className="ir-ws-prvw-signup-dot-small"></div>
                    <div className="ir-ws-prvw-signup-dot-small"></div>
                    <div className="ir-ws-prvw-signup-dot-large"></div>
                  </div>
                  <p className="ir-default-color">
                    Communicate with experts and interact with the best in the
                    Interventional Radiology field.{" "}
                  </p>
                </div>
                <div className="ir-ws-prvw-signup-btn-container">
                  <a
                    href="/signup"
                    className="ir-ws-app-bg ir-ws-text-uppercase ir-color-white ir-ws-no-border ir-ws-prvw-signup-btn"
                  >
                    <span>Join IR4U it's free</span>
                  </a>
                </div>
              </div>
            </div>
          )}
          {modalChatOne ? (
            <section className="modal__bg">
              <div className="modal__align">
                <div className="modal__content" modal={modalChatOne}>
                  <IoCloseOutline
                    className="modal__close"
                    arial-label="Close modal"
                    onClick={closeModalChatOne}
                  />
                  <div className="modal__video-align">
                    {videoLoading ? (
                      <div className="modal__spinner">
                        <BiLoaderAlt
                          className="modal__spinner-style"
                          fadeIn="none"
                        />
                      </div>
                    ) : null}
                    <iframe
                      className="modal__video-style"
                      onLoad={spinner}
                      loading="lazy"
                      width="800"
                      height="500"
                      src="https:d3skdvaex782lg.cloudfront.net/ir4u-chatvideo-1.1-finaledit.mp4"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen="allowfullscreen"
                      mozallowfullscreen="mozallowfullscreen"
                      msallowfullscreen="msallowfullscreen"
                      oallowfullscreen="oallowfullscreen"
                      webkitallowfullscreen="webkitallowfullscreen"
                    ></iframe>
                  </div>
                </div>
              </div>
            </section>
          ) : null}
          {modalChatTwo ? (
            <section className="modal__bg">
              <div className="modal__align">
                <div className="modal__content" modal={modalChatTwo}>
                  <IoCloseOutline
                    className="modal__close"
                    arial-label="Close modal"
                    onClick={closeModalChatTwo}
                  />
                  <div className="modal__video-align">
                    {videoLoading ? (
                      <div className="modal__spinner">
                        <BiLoaderAlt
                          className="modal__spinner-style"
                          fadeIn="none"
                        />
                      </div>
                    ) : null}
                    <iframe
                      className="modal__video-style"
                      onLoad={spinner}
                      loading="lazy"
                      width="800"
                      height="500"
                      src="https:d3skdvaex782lg.cloudfront.net/ir4u-chatvideo-2.1-finaledit.mp4"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen="allowfullscreen"
                      mozallowfullscreen="mozallowfullscreen"
                      msallowfullscreen="msallowfullscreen"
                      oallowfullscreen="oallowfullscreen"
                      webkitallowfullscreen="webkitallowfullscreen"
                    ></iframe>
                  </div>
                </div>
              </div>
            </section>
          ) : null}
          {modalChatThree ? (
            <section className="modal__bg">
              <div className="modal__align">
                <div className="modal__content" modal={modalChatThree}>
                  <IoCloseOutline
                    className="modal__close"
                    arial-label="Close modal"
                    onClick={closeModalChatThree}
                  />
                  <div className="modal__video-align">
                    {videoLoading ? (
                      <div className="modal__spinner">
                        <BiLoaderAlt
                          className="modal__spinner-style"
                          fadeIn="none"
                        />
                      </div>
                    ) : null}
                    <iframe
                      className="modal__video-style"
                      onLoad={spinner}
                      loading="lazy"
                      width="800"
                      height="500"
                      src="https:d3skdvaex782lg.cloudfront.net/ir4u-chatvideo-3.1-finaledit.mp4"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen="allowfullscreen"
                      mozallowfullscreen="mozallowfullscreen"
                      msallowfullscreen="msallowfullscreen"
                      oallowfullscreen="oallowfullscreen"
                      webkitallowfullscreen="webkitallowfullscreen"
                    ></iframe>
                  </div>
                </div>
              </div>
            </section>
          ) : null}
        </div>
      </div>

      {bookNowModal && loggedIn && !emailVerified && (
        <BookNowModal
          type="Verify Email"
          emailVerified={!emailVerified}
          loggedIn={loggedIn}
          username={username}
          setBookNowModal={setBookNowModal}
          // category={"Case"}
        />
      )}
      {bookNowModal && !loggedIn && (
        <BookNowModal
          type="Sign up"
          loggedIn={!loggedIn}
          setBookNowModal={setBookNowModal}
          // category={"Case"}
        />
      )}
    </div>
  );
};

export default HomePreviewTabContainer;
