import React from "react";
import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  updateLectureContent,
  addLectureVideo,
  addLectureDoc,
  deleteSectionSelectedVideo,
  deleteSectionSelectedDoc,
} from "../../Store/CourseSlice";
import "./CreateCourseSectionContent.scss";

const CreateCourseSectionContent = ({
  onDelete,
  lectureNumber,
  lectureContent,
  sectionId,
  lectureId,
  video,
  document,
  setErrorMessage,
}) => {
  const [editable, setEditable] = useState(false);
  const [content, setContent] = useState(lectureContent);
  const [expanded, setExpanded] = useState(false);
  const videoUploadRef = useRef(null);
  const fileUploadRef = useRef(null);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const handleEdit = () => {
    setEditable(!editable);
    if (inputRef.current) {
      inputRef.current.focus(); // Focus on the input element
      inputRef.current.select(); // Select the input content
    }
  };
  const handleChange = (e) => {
    setContent(e.target.value);
  };
  const handleSave = () => {
    setEditable(false);
    dispatch(
      updateLectureContent({
        sectionId: sectionId,
        lectureId: lectureId,
        content: content,
      })
    );
  };
  // const toggleExpand = () => {
  //   setExpanded(!expanded);
  // };
  const toggleExpand = () => {
    if (!expanded) {
      // If expanded is true, toggle to false
      setExpanded(true);
    } else {
      // If expanded is false, check if there is any video or document uploaded
      if (video.videoName || document.docName) {
        // If there is a video or document uploaded, set expanded to true
        setExpanded(true);
      } else {
        // If there is no video or document uploaded, keep expanded as false
        setExpanded(false);
      }
    }
  };
  const handleFileUploadClick = (inputRef) => {
    inputRef.current.click();
  };
  const handleVidoeUpload = (e) => {
    setErrorMessage("");
    const file = e.target.files[0];
    const fileURL = URL.createObjectURL(file);
    const fileName = file.name;
    dispatch(
      addLectureVideo({
        sectionId: sectionId,
        lectureId: lectureId,
        videoUrl: fileURL,
        videoName: fileName,
        file: file,
      })
    );
  };

  const handleDocUpload = (e, bck, leck) => {
    setErrorMessage("");
    const doc = e.target.files[0];
    const docUrl = URL.createObjectURL(doc);
    const docName = doc.name;
    dispatch(
      addLectureDoc({
        sectionId: sectionId,
        lectureId: lectureId,
        docUrl: docUrl,
        docName: docName,
        file: doc,
      })
    );
  };
  const handleFileDrop = (event, type) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];

    if (!droppedFile) return;

    if (type === "video" && droppedFile.type.startsWith("video/")) {
      handleVidoeUpload({ target: { files: [droppedFile] } });
    } else if (
      type === "document" &&
      /\.(pdf|doc|docx|ppt|pptx)$/i.test(droppedFile.name)
    ) {
      handleDocUpload({ target: { files: [droppedFile] } });
    } else {
      alert(`Please drop a valid ${type} file.`);
    }
  };

  const allowDrop = (event) => {
    event.preventDefault();
  };

  return (
    <div className="ir-ws-single-lecture-content">
      <div
        className={`ir-ws-lecture-with-upload ${
          expanded ? "ir-ws-lecture-with-upload-active " : ""
        }`}
      >
        <div className={`ir-ws-lecture ${expanded ? "expanded" : ""}`}>
          {editable ? (
            <input
              className="ir-ws-text-edit-input"
              type="text"
              value={content}
              onChange={handleChange}
              onBlur={handleSave}
              ref={inputRef}
            />
          ) : (
            <p className="ir-ws-lecture-paragraph">
              Lecture {lectureNumber + 1} : <span>{lectureContent}</span>
            </p>
          )}
          <svg
            height="18px"
            version="1.1"
            viewBox="0 0 18 18"
            width="18px"
            xmlns="http://www.w3.org/2000/svg"
            className="ir-ws-editor-svg"
            onClick={handleEdit}
          >
            <title />
            <desc />
            <defs />
            <g
              fill="none"
              fillRule="evenodd"
              id="Page-1"
              stroke="none"
              strokeWidth="1"
            >
              <g
                fill="#424242"
                id="Core"
                transform="translate(-213.000000, -129.000000)"
              >
                <g id="create" transform="translate(213.000000, 129.000000)">
                  <path
                    d="M0,14.2 L0,18 L3.8,18 L14.8,6.9 L11,3.1 L0,14.2 L0,14.2 Z M17.7,4 C18.1,3.6 18.1,3 17.7,2.6 L15.4,0.3 C15,-0.1 14.4,-0.1 14,0.3 L12.2,2.1 L16,5.9 L17.7,4 L17.7,4 Z"
                    id="Shape"
                  />
                </g>
              </g>
            </g>
          </svg>
          <svg
            height="26"
            viewBox="0 0 48 48"
            width="26"
            xmlns="http://www.w3.org/2000/svg"
            fill="#424242"
            className="ir-course-selected-image-video-delete-icon"
            onClick={onDelete}
          >
            <path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z" />
            <path d="M0 0h48v48H0z" fill="none" />
          </svg>
        </div>
        {expanded && (
          <div className="ir-ws-lecture-file-upload">
            <div
              className="ir-ws-lecture-file-upload-content"
              onClick={() => handleFileUploadClick(videoUploadRef)}
              onDragOver={allowDrop}
              onDrop={(event) => handleFileDrop(event, "video")}
            >
              <svg
                viewBox="0 0 48 48"
                xmlns="http://www.w3.org/2000/svg"
                height="40px"
                width="40px"
              >
                <path d="M0 0h48v48H0z" fill="none"></path>
                <path
                  d="M24 4C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.95 20-20S35.05 4 24 4zm-4 29V15l12 9-12 9z"
                  fill="#ffffff"
                  class="fill-000000"
                ></path>
              </svg>
              <p className="ir-ws-uploaded-video-name">Video</p>
              <input
                type="file"
                id="video-upload"
                ref={videoUploadRef}
                onChange={handleVidoeUpload}
                className="ir-ws-course-image-input"
                accept="video/*"
              />
            </div>

            <div
              className="ir-ws-lecture-file-upload-content"
              onClick={() => handleFileUploadClick(fileUploadRef)}
              onDragOver={allowDrop}
              onDrop={(event) => handleFileDrop(event, "document")}
            >
              <svg
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
                enableBackground="new 0 0 512 512"
                height="40px"
                width="40px"
              >
                <path
                  d="M398.6 169.2c-.9-2.2-2-4.3-3.5-6.1l-83.8-91.7c-1.9-2.1-4.2-3.6-6.7-4.9-2.9-1.5-6.1-2.1-9.5-2.1H135.2c-12.4 0-22.7 10.6-22.7 23.9v335.2c0 13.4 10.3 24.9 22.7 24.9h243.1c12.4 0 22.2-11.5 22.2-24.9V179.4c0-3.6-.5-7.1-1.9-10.2zm-238.1 9.4c0-1.5 1.8-2.1 3.4-2.1h70.8c1.6 0 2.8.6 2.8 2.1v10.8c0 1.4-1.1 3.1-2.8 3.1h-70.8c-1.6 0-3.4-1.7-3.4-3.1v-10.8zm0 128c0-1.5 1.8-2.1 3.4-2.1h122.2c1.6 0 2.4.6 2.4 2.1v10.8c0 1.4-.7 3.1-2.4 3.1H163.9c-1.6 0-3.4-1.7-3.4-3.1v-10.8zm160 74.8c0 1.4-.7 3.1-2.4 3.1H163.9c-1.6 0-3.4-1.7-3.4-3.1v-10.8c0-1.5 1.8-2.1 3.4-2.1h154.2c1.6 0 2.4.6 2.4 2.1v10.8zm32-128c0 1.4-.7 3.1-2.4 3.1H163.9c-1.6 0-3.4-1.7-3.4-3.1v-10.8c0-1.5 1.8-2.1 3.4-2.1h186.2c1.6 0 2.4.6 2.4 2.1v10.8zm-46.9-75.9c-5.6 0-11.1-5.2-11.1-11.3v-66l71.2 77.3h-60.1z"
                  fill="#ffffff"
                  class="fill-000000"
                ></path>
              </svg>
              <p className="ir-ws-uploaded-video-name"> Doc </p>
              <input
                type="file"
                id="file-upload"
                ref={fileUploadRef}
                accept=".pdf,.doc,.docx,.ppt,.pptx"
                onChange={handleDocUpload}
                className="ir-ws-course-image-input"
              />
            </div>
            {(video.videoName || document.docName) && (
              <div className="ir-ws-uploaded-file-show-container">
                {video.videoName && (
                  <div className="ir-ws-flex ir-ws-align-center">
                    <div className="ir-ws-uploaded-file-show">
                      <p
                        className="ir-ws-uploaded-file-name"
                        title={video.videoName}
                      >
                        {video.videoName.length > 30
                          ? `${video.videoName.slice(0, 30)}...`
                          : video.videoName}
                      </p>
                      <svg
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                        height="30px"
                        width="30px"
                      >
                        <path
                          d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48Zm74.77 217.3-114.45 69.14a10.78 10.78 0 0 1-16.32-9.31V186.87a10.78 10.78 0 0 1 16.32-9.31l114.45 69.14a10.89 10.89 0 0 1 0 18.6Z"
                          fill="#ffffff"
                          class="fill-000000"
                        ></path>
                      </svg>
                    </div>
                    <svg
                      height="26"
                      viewBox="0 0 48 48"
                      width="26"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#424242"
                      className="ir-course-selected-image-video-delete-icon"
                      onClick={() => {
                        dispatch(
                          deleteSectionSelectedVideo({
                            sectionId: sectionId,
                            lectureId: lectureId,
                            videoName: video.videoName,
                          })
                        );
                      }}
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                    >
                      <path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z" />
                      <path d="M0 0h48v48H0z" fill="none" />
                    </svg>
                  </div>
                )}
                {document.docName && (
                  <div className="ir-ws-flex ir-ws-align-center">
                    <div className="ir-ws-uploaded-file-show">
                      <p
                        className="ir-ws-uploaded-file-name"
                        title={document.docName}
                      >
                        {document.docName.length > 30
                          ? `${document.docName.slice(0, 30)}...`
                          : document.docName}
                      </p>
                      <svg
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                        enableBackground="new 0 0 512 512"
                        height="30px"
                        width="30px"
                        className="ir-course-edit-icon"
                      >
                        <path
                          d="M398.6 169.2c-.9-2.2-2-4.3-3.5-6.1l-83.8-91.7c-1.9-2.1-4.2-3.6-6.7-4.9-2.9-1.5-6.1-2.1-9.5-2.1H135.2c-12.4 0-22.7 10.6-22.7 23.9v335.2c0 13.4 10.3 24.9 22.7 24.9h243.1c12.4 0 22.2-11.5 22.2-24.9V179.4c0-3.6-.5-7.1-1.9-10.2zm-238.1 9.4c0-1.5 1.8-2.1 3.4-2.1h70.8c1.6 0 2.8.6 2.8 2.1v10.8c0 1.4-1.1 3.1-2.8 3.1h-70.8c-1.6 0-3.4-1.7-3.4-3.1v-10.8zm0 128c0-1.5 1.8-2.1 3.4-2.1h122.2c1.6 0 2.4.6 2.4 2.1v10.8c0 1.4-.7 3.1-2.4 3.1H163.9c-1.6 0-3.4-1.7-3.4-3.1v-10.8zm160 74.8c0 1.4-.7 3.1-2.4 3.1H163.9c-1.6 0-3.4-1.7-3.4-3.1v-10.8c0-1.5 1.8-2.1 3.4-2.1h154.2c1.6 0 2.4.6 2.4 2.1v10.8zm32-128c0 1.4-.7 3.1-2.4 3.1H163.9c-1.6 0-3.4-1.7-3.4-3.1v-10.8c0-1.5 1.8-2.1 3.4-2.1h186.2c1.6 0 2.4.6 2.4 2.1v10.8zm-46.9-75.9c-5.6 0-11.1-5.2-11.1-11.3v-66l71.2 77.3h-60.1z"
                          fill="#ffffff"
                          class="fill-000000"
                        ></path>
                      </svg>
                    </div>
                    <svg
                      height="26"
                      viewBox="0 0 48 48"
                      width="26"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ir-course-selected-image-video-delete-icon"
                      fill="#424242"
                      onClick={() => {
                        dispatch(
                          deleteSectionSelectedDoc({
                            sectionId: sectionId,
                            lectureId: lectureId,
                            docName: document.docName,
                          })
                        );
                      }}
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                    >
                      <path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z" />
                      <path d="M0 0h48v48H0z" fill="none" />
                    </svg>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <div
        className={`ir-ws-lecture-add-button ${
          expanded ? "ir-ws-lecture-add-button-active" : ""
        }`}
        onClick={() => toggleExpand()}
      >
        + Content
      </div>
    </div>
  );
};

export default CreateCourseSectionContent;
