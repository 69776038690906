import React, { useEffect, useState } from "react";
import { useModalContext } from "../../WebinarAdminCreateWebinarTab/ModalContext";

const ProductList = () => {
  const { activeProductTab, setActiveProductTab } = useModalContext();
  const [webinars, setWebinars] = useState([]);
  const [courses, setCourses] = useState([]);
  const [cases, setCases] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const statusBgColors = {
    approved: "!bg-[#37b24d]", // Green
    pending: "!bg-[#1c7ed6]", // Blue (for pending status)
    declined: "!bg-[#f59f00]", // Orange
    deleted: "!bg-[#f03e3e]", // Red
  };

  // Fetch product status from the API
  const getProductStatus = async () => {
    try {
      const res = await fetch(
        "https://backend.ir4u.info/api/v1/user/product-status",
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
            "Content-Type": "application/json",
          },
        }
      );

      const data = await res.json();

      // Check if there are no products
      if (!data.products || data.products.length === 0) {
        setWebinars([]);
        setCourses([]);
        setCases([]);
        setIsLoading(false);
        return;
      }

      // Separate products based on productType
      const webinarsData = data.products.filter(
        (product) => product.productType === "webinar"
      );
      const coursesData = data.products.filter(
        (product) => product.productType === "course"
      );
      const casesData = data.products.filter(
        (product) => product.productType === "case"
      );

      // Update state with filtered data
      setWebinars(webinarsData);
      setCourses(coursesData);
      setCases(casesData);
    } catch (error) {
      console.error("Error fetching Product status:", error);
    } finally {
      setIsLoading(false); // Set loading to false after fetching
    }
  };

  useEffect(() => {
    getProductStatus();
  }, []);

  // Determine which product list to display based on activeProductTab
  const productList =
    activeProductTab === "webinars"
      ? webinars
      : activeProductTab === "courses"
      ? courses
      : cases;

  // Display "No data found" if there are no products
  const renderNoDataFound = () => {
    return (
      <p className="text-center mt-[30px] text-[10px] lg:text-[10px] xl:text-[12px] 3xl:text-[14px] ">
        You haven't created any {activeProductTab}
      </p>
    );
  };

  return (
    <div className="ir-ws-admin-create-webinar-tab ir-ws-webinar-tb-main-container ir-ws-admin-create-course-container">
      {isLoading ? (
        <p className="text-center mt-[30px] text-[10px] lg:text-[10px] xl:text-[12px] 3xl:text-[14px] ">
          Loading ...
        </p>
      ) : (
        <div>
          {productList?.length === 0
            ? renderNoDataFound()
            : productList?.map((data, index) => (
                <div className="ir-ws-webinar-list-tab" key={index}>
                  <div className="ir-ws-admin-webinar-content">
                    <div className="ir-ws-admin-webinar-title-container">
                      <p className="ir-ws-webinar-title-text">
                        {data?.productName}
                      </p>
                    </div>
                    <div className="ir-ws-admin-list-button-container">
                      <button
                        className={`ir-ws-webinar-cancel-button capitalize ${
                          statusBgColors[data?.status.toLowerCase()] ||
                          "bg-gray-400"
                        } text-white`}
                      >
                        {data?.status}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      )}
    </div>
  );
};

export default ProductList;
