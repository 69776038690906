import React, { useState } from "react";
import axios from "axios";
import "./HomeJoinContainer.scss";
import Swal from "sweetalert2";

const HomeJoinContainer = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [existingSubscriber, setExistingSubscriber] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubscribe = async () => {
    if (!validateEmail(email)) {
      Swal.fire({
        icon: "warning",
        title: "Invalid Email",
        text: "Please enter a valid email address.",
      });
      return;
    }

    try {
      const formData = new FormData();
      formData.append("email", email);

      const baseUrl = "https://webinar-staging.backend.ir4u.info";
      const response = await axios.post(
        `${baseUrl}/api/v1/newsletter/subscribe`,
        formData
      );

      if (response.status === 200) {
        setEmail("");
        setSuccessMessage(true);
        setTimeout(() => {
          setSuccessMessage(false);
        }, 3000);
      } else if (response.status === 208) {
        setEmail("");
        setExistingSubscriber(true);
        setTimeout(() => {
          setExistingSubscriber(false);
        }, 3000);
      }
    } catch (error) {
      console.log("Error subscribing:", error);
      setErrorMessage(error?.response?.data.error);
      setEmail("");
      if (errorMessage) {
        console.error(errorMessage);
      }
    }
  };

  return (
    <div className="ir-ws-gray-bg ir-ws-padding-tb-100 ir-ws-padding-lr-132 ir-ws-hme-join-main-container">
      <div className="ir-ws-hme-join-inner-container">
        <p className="ir-ws-app-color ir-ws-text-center ir-ws-hme-highlight-text">
          Newsletter
        </p>
        <h2 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-text-uppercase ir-ws-hme-join-title">
          Join Us
        </h2>
        <p className="ir-ws-text-center ir-default-color ir-ws-hme-join-para-content">
          Subscribe to our IR4U newsletter for the latest updates and exclusive
          learning opportunities.
        </p>
        <div className="ir-ws-flex ir-ws-align-center input-group-append ir-ws-hme-join-input-container">
          <input
            className="form-control ir-ws-hme-join-input-field"
            placeholder="Email Address"
            onChange={handleEmailChange}
            value={email}
            type="email"
            required
          />
          <button
            className="input-group-text ir-ws-app-bg ir-color-white ir-ws-no-border ir-ws-hme-join-sub-btn"
            onClick={handleSubscribe}
          >
            <span>Subscribe</span>
          </button>
        </div>
        <div
          className={`animate__animated animate__fadeInUp ir-ws-app-color ir-ws-thansk-newsletter-subsciber-container ${
            successMessage ? "ir-ws-display-successMessage" : ""
          }`}
        >
          <p>Thanks for subscribing to our Newsletter!!!</p>
        </div>
        <div
          className={`animate__animated animate__fadeInUp ir-ws-app-color ir-ws-thansk-newsletter-subsciber-container ${
            existingSubscriber ? "ir-ws-display-existingSubscriberMessage" : ""
          }`}
        >
          <p>The entered EmailId is already subscribed!!!</p>
        </div>
      </div>
    </div>
  );
};

export default HomeJoinContainer;
