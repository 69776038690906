import React, { useEffect, useRef, useState } from "react";
import "./CoursesBanner.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const CoursesBanner = ({ courses }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const searchContainerRef = useRef(null);
  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const navigate = useNavigate();
  useEffect(() => {
    // Filter courses based on search query
    const filtered = courses?.filter((course) =>
      course.formData.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredCourses(filtered.slice(0, 3));
    setNoResults(filtered.length === 0); // Display only the first 4 filtered courses
  }, [searchQuery, courses]);

  useEffect(() => {
    // Add event listener to document body to handle clicks
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        // Clicked outside the search container, hide the message
        setNoResults(false);
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    // Clean up event listener on component unmount
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleSearch = () => {
    if (searchQuery !== "") {
      navigate(`/courses/search/${searchQuery}`);
    }
  };
  return (
    <div className="ir-ws-banner-padding-tb ir-courses-banner-main-container">
      <div className="ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center ir-ws-padding-lr-132 ir-courses-banner-inner-container">
        <div className="ir-ws-width-60 ir-courses-banner-content-container">
          <h3 className="ir-ws-heading-default-color ir-ws-font-700 ir-ws-font-size-xl ir-ws-courses-banner-title">
            Dive deep into interventional radiology specializations with our
            courses
          </h3>
          <p className="ir-courses-banner-title">
            Explore Interventional Radiology Specializations deeply. <br />
            From vascular to neuro interventions, delve into intricate
            procedures.
            <br />
            Master advanced techniques for aspiring and seasoned radiologists.
          </p>
          <div
            ref={searchContainerRef}
            className="ir-courses-banner-search-container ir-ws-course-home-search-bar-container"
          >
            <div className="ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center ir-courses-banner-search-inner">
              <input
                type="text"
                name="courses-search"
                placeholder="Search courses here"
                className="ir-courses-banner-search-input"
                value={searchQuery}
                onChange={handleChange}
              />
              <div
                className="ir-ws-default-btn-container ir-ws-book-modal-btn ir-courses-banner-search-btn-container"
                onClick={handleSearch}
              >
                <button className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-courses-banner-search-btn">
                  <span>Search</span>
                </button>
              </div>
              <div
                className="ir-course-banner-mobile-search-box"
                onClick={handleSearch}
              >
                <i className="bi bi-search"></i>
              </div>
            </div>
            {searchQuery !== "" && noResults && (
              <div className="ir-ws-course-home-search-container">
                <div className="ir-ws-course-suggested-keywords-container">
                  <div className="ir-ws-course-suggested-keywords-icon-box">
                    <i className="bi bi-search"></i>
                  </div>
                  <div className="ir-ws-course-suggested-keywords-title">
                    <p>No search results found</p>
                  </div>
                </div>
              </div>
            )}
            {filteredCourses.length !== 0 && searchQuery !== "" && (
              <div className="ir-ws-course-home-search-container">
                {filteredCourses?.map((course, index) => (
                  <div
                    className="ir-ws-course-suggested-keywords-container"
                    onClick={() => navigate(`/course/${course._id}`)}
                    key={course._id}
                  >
                    <div className="ir-ws-course-suggested-keywords-icon-box">
                      <img
                        src={course.formData.imagePreview}
                        alt="medical"
                        className="ir-ws-course-suggested-img"
                      />
                    </div>
                    <div className="ir-ws-course-suggested-keywords-title">
                      <p>{course.formData.title}</p>
                      <p className="ir-ws-course-suggested-author-name">
                        Course by {course.formData.instructorName}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="ir-ws-width-30 ir-courses-banner-img-container">
          <div className="ir-courses-banner-img-inner-container ir-ws-position-relative">
            <img
              className="ir-ws-max-width-100"
              src={require("../../assets/images/course-banner2.webp")}
              alt="banner"
            />
            {/* <img
              src={require("../../assets/images/hme-banner-carousel-bg.png")}
              className="ir-ws-course-spiral-img"
              alt="img"
            />
            <img
              className="ir-ws-course-doc-img"
              src={require("../../assets/images/courses-banner.png")}
              alt="banner"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CoursesBanner;
