import React from "react";
import { useModalContext } from "../../WebinarAdminCreateWebinarTab/ModalContext";
import CollaborateNotificationList from "../ProductNotifications/CollaborateNotificationList";
import NotificationsTab from "../ProductNotifications/NotificationsTab";
import ProductReviewNotification from "../ProductNotifications/ProductReviewNotification";

const NotificationsPanel = () => {
  const { notificationTab, setNotificationTab } = useModalContext();
  return (
    <div className="h-[100%]">
      <NotificationsTab
        toggle={notificationTab}
        setToggle={setNotificationTab}
      />
      {notificationTab === 1 && (
        <CollaborateNotificationList
          toggle={notificationTab}
          setToggle={setNotificationTab}
        />
      )}
      {notificationTab === 2 && (
        <ProductReviewNotification
        // toggle={notificationTab}
        // setToggle={setNotificationTab}
        />
      )}
    </div>
  );
};

export default NotificationsPanel;
