import React, { useEffect, useState } from "react";
import ConfirmationModal from "../../../pages/Courses/AdminCreateCourseTab/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { useModalContext } from "../../WebinarAdminCreateWebinarTab/ModalContext";

const ProductReviewNotification = () => {
  const [isContentVisible, setContentVisible] = useState(false);
  const [declineModal, setDeclineModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [pendingWebinars, setPendingWebinars] = useState(null);
  const [pendingCases, setPendingCases] = useState([]);
  const [pendingCourses, setPendingCourses] = useState([]);
  const [isPendingWebLoading, setIsPendingWebLoading] = useState(false);
  const [selectedWebinar, setSelectedWebinar] = useState(null);
  const [selectedCase, setSelectedCase] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const navigate = useNavigate();

  const { userDetail } = useModalContext();

  const BASE_URL = "https://webinar-staging.backend.ir4u.info/api/v1";
  const BASE_URL_CASE = "https://case.ir4u.info/api/v1";
  const BASE_URL_COURSE = "https://courses.ir4u.info/api";

  const toggleContentVisibility = (id) => {
    setContentVisible((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const toggleConfirmationModal = () => {
    setOpenConfirmationModal(!openConfirmationModal);
  };

  const toggleDeclineModal = () => {
    setDeclineModal(!declineModal);
  };

  useEffect(() => {
    const fetchPendingWebinars = async () => {
      setIsPendingWebLoading(true);
      try {
        const response = await fetch(
          `${BASE_URL}/webinar/admin/pending-webiners`,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setPendingWebinars(data?.data || []); // Ensure it's always an array
        } else {
          console.error("Failed to fetch pending webinars");
          setPendingWebinars([]); // Handle errors by setting empty array
        }
      } catch (error) {
        console.error("Error fetching pending webinars:", error);
        setPendingWebinars([]);
      } finally {
        setIsPendingWebLoading(false);
      }
    };

    const fetchPendingCases = async () => {
      try {
        const response = await fetch(
          `${BASE_URL_CASE}/admin/case/pending-cases`,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
            },
          }
        );

        if (response.ok) {
          const result = await response.json();
          setPendingCases(result.data || []);
        } else {
          console.error("Failed to fetch pending cases");
          setPendingCases([]);
        }
      } catch (error) {
        console.error("Error fetching pending cases:", error);
        setPendingCases([]);
      }
    };
    const fetchPendingCourses = async () => {
      try {
        const response = await fetch(
          `${BASE_URL_COURSE}/get-all-pending-courses`,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
            },
          }
        );

        if (response.ok) {
          const result = await response.json();
          setPendingCourses(result || []);
        } else {
          console.error("Failed to fetch pending cases");
          setPendingCourses([]);
        }
      } catch (error) {
        console.error("Error fetching pending cases:", error);
        setPendingCourses([]);
      }
    };

    fetchPendingWebinars();
    fetchPendingCases();
    fetchPendingCourses();
  }, [selectedWebinar, selectedCase, openConfirmationModal, declineModal]);

  const approveAndPublishPendingWebinars = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/webinar/admin/approve/${selectedWebinar?.id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
          },
        }
      );
      setOpenConfirmationModal(false);
      if (response) {
        Swal.fire({
          title: "Success!",
          text: "Webinar published successfully",
          icon: "success",
        });
      }
    } catch (error) {
      setOpenConfirmationModal(false);
    }
  };

  const declineAndPublishPendingWebinars = async () => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/webinar/admin/decline/${selectedWebinar?.id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
          },
        }
      );
      setDeclineModal(false);
      if (response) {
        Swal.fire({
          title: "Success!",
          text: "Webinar declined successfully",
          icon: "success",
        });
      }
    } catch (error) {
      setDeclineModal(false);
    }
  };

  const approvePendingCase = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL_CASE}/admin/case/approve/${selectedCase?._id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
          },
        }
      );
      setOpenConfirmationModal(false);
      if (response) {
        Swal.fire({
          title: "Success!",
          text: "Case approved successfully",
          icon: "success",
        });
      }
    } catch (error) {
      setOpenConfirmationModal(false);
    }
  };

  const declinePendingCase = async () => {
    try {
      const response = await axios.delete(
        `${BASE_URL_CASE}/admin/case/decline/${selectedCase?._id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
          },
        }
      );
      setDeclineModal(false);
      if (response) {
        Swal.fire({
          title: "Success!",
          text: "Case declined successfully",
          icon: "success",
        });
      }
    } catch (error) {
      setDeclineModal(false);
    }
  };
  // const approvePendingCourse = async () => {
  //   try {
  //     const response = await axios.post(
  //       `${BASE_URL_COURSE}/approve-course/${selectedCase?._id}`,
  //       {},
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
  //         },
  //       }
  //     );
  //     setOpenConfirmationModal(false);
  //     if (response) {
  //       Swal.fire({
  //         title: "Success!",
  //         text: "Course approved successfully",
  //         icon: "success",
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setOpenConfirmationModal(false);
  //   }
  // };

  // const declinePendingCourse = async () => {
  //   try {
  //     const response = await axios.delete(
  //       `${BASE_URL_CASE}/decline-course/${selectedCase?._id}`,
  //       {
  //         headers: {
  //           Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
  //         },
  //       }
  //     );
  //     setDeclineModal(false);
  //     if (response) {
  //       Swal.fire({
  //         title: "Success!",
  //         text: "Course declined successfully",
  //         icon: "success",
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setDeclineModal(false);
  //   }
  // };

  const approvePendingCourse = async () => {
    try {
      await axios.post(
        `${BASE_URL_COURSE}/approve-course/${selectedCourse?._id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
          },
        }
      );
      setOpenConfirmationModal(false);
      Swal.fire({
        title: "Success!",
        text: "Course approved successfully",
        icon: "success",
      });
    } catch (error) {
      setOpenConfirmationModal(false);
      Swal.fire({
        title: "Error!",
        text: "Failed to approve course. Please try again.",
        icon: "error",
      });
    }
  };

  const declinePendingCourse = async () => {
    try {
      await axios.post(
        `${BASE_URL_COURSE}/decline-course/${selectedCourse?._id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
          },
        }
      );
      setDeclineModal(false);
      Swal.fire({
        title: "Success!",
        text: "Course declined successfully",
        icon: "success",
      });
    } catch (error) {
      setDeclineModal(false);
      Swal.fire({
        title: "Error!",
        text: "Failed to decline course. Please try again.",
        icon: "error",
      });
    }
  };

  return (
    <div className="ir-ws-admin-create-webinar-tab ir-ws-webinar-tb-main-container">
      {!isPendingWebLoading &&
        pendingWebinars !== null &&
        pendingCases !== null &&
        pendingWebinars.length === 0 &&
        pendingCases.length === 0 && (
          <div className="ir-ws-admin-webiner-list-container mt-[10px] text-center text-[10px] lg:text-[10px] xl:text-[12px] 3xl:text-[14px]">
            No Pending requests found
          </div>
        )}

      {pendingWebinars?.map((webinar) => (
        <div className="ir-ws-admin-webiner-list-container" key={webinar.id}>
          <div
            className={`ir-ws-webinar-list-tab !m-[10px] ${
              !isContentVisible[webinar.id] ? "active" : ""
            }`}
          >
            <div className="ir-ws-admin-webinar-content">
              <div className="ir-ws-admin-webinar-title-container">
                <p className="ir-ws-webinar-title-text">
                  {webinar?.createdBy} created a webinar
                </p>
              </div>
              <div className="ir-ws-admin-list-button-container">
                <div className="flex items-center ">
                  <button
                    className="ir-ws-webinar-transaction-button ir-ws-webinar-actions-button"
                    onClick={() => {
                      setDeclineModal(true);
                      setSelectedWebinar(webinar);
                    }}
                  >
                    Decline
                  </button>
                  <button
                    className="ir-ws-webinar-transaction-button ir-ws-webinar-actions-button"
                    onClick={() => {
                      setSelectedWebinar(webinar);
                      setOpenConfirmationModal(true);
                    }}
                  >
                    Approve
                  </button>
                  <button
                    className={`ir-ws-webinar-transaction-button ir-ws-webinar-actions-button flex items-center gap-[4px] ${
                      isContentVisible[webinar.id]
                        ? "ir-ws-webinar-transaction-button-active"
                        : ""
                    }`}
                    onClick={() => toggleContentVisibility(webinar.id)}
                  >
                    Details
                    <i className="bi bi-chevron-down"></i>
                  </button>
                </div>
              </div>
            </div>

            {isContentVisible[webinar.id] && (
              <div className="p-[20px] rounded-b-[8px] bg-[#efefef] text-[10px] lg:text-[10px] xl:text-[12px] 3xl:text-[14px]">
                <div className="flex py-[10px]">
                  <div className="w-[10%]">
                    <p className="">Created by</p>
                  </div>
                  <div className="w-[100%]">
                    <p>{webinar?.createdBy}</p>
                  </div>
                </div>
                <div className="flex py-[10px]">
                  <div className="w-[10%]">
                    <p className="">Date</p>
                  </div>
                  <div className="w-[100%]">
                    <p>{new Date(webinar?.dateAndTime).toLocaleDateString()}</p>
                  </div>
                </div>
                <div className="flex py-[10px]">
                  <div className="w-[10%]">
                    <p className="">title</p>
                  </div>
                  <div className="w-[100%]">
                    <p>{webinar?.title}</p>
                  </div>
                </div>
                <div className="flex py-[10px]">
                  <div className="w-[10%]">
                    <p className="">description</p>
                  </div>
                  <div className="w-[100%]">
                    <p>{webinar?.description}</p>
                  </div>
                </div>
                <div className="flex py-[10px]">
                  <div className="w-[10%]">
                    <p className="">no of seats</p>
                  </div>
                  <div className="w-[100%]">
                    <p>{webinar?.noOfSeats}</p>
                  </div>
                </div>
                <div className="flex py-[10px]">
                  <div className="w-[10%]">
                    <p className="">price</p>
                  </div>
                  <div className="w-[100%]">
                    <p>$ {webinar?.price || 0}</p>
                  </div>
                </div>
                {/* <div className="flex py-[10px]">
                  <a
                    href={`/webinar/${webinar?.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="w-[100%] text-[#6842EF] cursor-pointer">
                      <p>View more details</p>
                    </div>
                  </a>
                </div> */}
              </div>
            )}
          </div>
        </div>
      ))}

      {pendingCases?.map((caseItem) => (
        <div className="ir-ws-admin-webiner-list-container" key={caseItem._id}>
          <div
            className={`ir-ws-webinar-list-tab !m-[10px] ${
              !isContentVisible[caseItem._id] ? "active" : ""
            }`}
          >
            <div className="ir-ws-admin-webinar-content">
              <div className="ir-ws-admin-webinar-title-container">
                <p className="ir-ws-webinar-title-text">
                  {caseItem?.createdBy} created a case
                </p>
              </div>
              <div className="ir-ws-admin-list-button-container">
                <div className="flex items-center ">
                  <button
                    className="ir-ws-webinar-transaction-button ir-ws-webinar-actions-button"
                    onClick={() => {
                      setDeclineModal(true);
                      setSelectedCase(caseItem);
                    }}
                  >
                    Decline
                  </button>
                  <button
                    className="ir-ws-webinar-transaction-button ir-ws-webinar-actions-button"
                    onClick={() => {
                      setSelectedCase(caseItem);
                      setOpenConfirmationModal(true);
                    }}
                  >
                    Approve
                  </button>
                  <button
                    className={`ir-ws-webinar-transaction-button ir-ws-webinar-actions-button flex items-center gap-[4px] ${
                      isContentVisible[caseItem.id]
                        ? "ir-ws-webinar-transaction-button-active"
                        : ""
                    }`}
                    onClick={() => toggleContentVisibility(caseItem._id)}
                  >
                    Details
                    <i className="bi bi-chevron-down"></i>
                  </button>
                </div>
              </div>
            </div>

            {isContentVisible[caseItem._id] && (
              <div className="p-[20px] rounded-b-[8px] bg-[#efefef] text-[10px] lg:text-[10px] xl:text-[12px] 3xl:text-[14px]">
                <div className="flex py-[10px]">
                  <div className="w-[10%]">
                    <p className="">Created by</p>
                  </div>
                  <div className="w-[100%]">
                    <p>{caseItem?.createdBy}</p>
                  </div>
                </div>
                <div className="flex py-[10px]">
                  <div className="w-[10%]">
                    <p className="">Categorie</p>
                  </div>
                  <div className="w-[100%]">
                    <p>{caseItem?.categorie}</p>
                  </div>
                </div>
                <div className="flex py-[10px]">
                  <div className="w-[10%]">
                    <p className="">title</p>
                  </div>
                  <div className="w-[100%]">
                    <p>{caseItem?.slides[0].name}</p>
                  </div>
                </div>
                <div className="flex py-[10px]">
                  <div className="w-[10%]">
                    <p className="">Price</p>
                  </div>
                  <div className="w-[100%]">
                    <p>$ {caseItem?.casePrice}</p>
                  </div>
                </div>
                <div className="flex py-[10px]">
                  <a
                    href={`/case/${caseItem?._id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="w-[100%] text-[#6842EF] cursor-pointer">
                      <p>View more details</p>
                    </div>
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
      {pendingCourses?.map((course) => (
        <div className="ir-ws-admin-webiner-list-container" key={course._id}>
          <div
            className={`ir-ws-webinar-list-tab !m-[10px] ${
              !isContentVisible[course._id] ? "active" : ""
            }`}
          >
            <div className="ir-ws-admin-webinar-content">
              <div className="ir-ws-admin-webinar-title-container">
                <p className="ir-ws-webinar-title-text">
                  {course?.formData?.createdBy} created a Course
                </p>
              </div>
              <div className="ir-ws-admin-list-button-container">
                <div className="flex items-center ">
                  <button
                    className="ir-ws-webinar-transaction-button ir-ws-webinar-actions-button"
                    onClick={() => {
                      setDeclineModal(true);
                      setSelectedCourse(course);
                    }}
                  >
                    Decline
                  </button>
                  <button
                    className="ir-ws-webinar-transaction-button ir-ws-webinar-actions-button"
                    onClick={() => {
                      setSelectedCourse(course);
                      setOpenConfirmationModal(true);
                    }}
                  >
                    Approve
                  </button>
                  <button
                    className={`ir-ws-webinar-transaction-button ir-ws-webinar-actions-button flex items-center gap-[4px] ${
                      isContentVisible[course._id]
                        ? "ir-ws-webinar-transaction-button-active"
                        : ""
                    }`}
                    onClick={() => toggleContentVisibility(course._id)}
                  >
                    Details
                    <i className="bi bi-chevron-down"></i>
                  </button>
                </div>
              </div>
            </div>

            {isContentVisible[course._id] && (
              <div className="p-[20px] rounded-b-[8px] bg-[#efefef] text-[10px] lg:text-[10px] xl:text-[12px] 3xl:text-[14px]">
                <div className="flex py-[10px]">
                  <div className="w-[10%]">
                    <p className="">Created by</p>
                  </div>
                  <div className="w-[100%]">
                    <p>{course?.formData?.createdBy}</p>
                  </div>
                </div>
                <div className="flex py-[10px]">
                  <div className="w-[10%]">
                    <p className="">Description</p>
                  </div>
                  <div className="w-[100%]">
                    <p>{course?.formData?.completeDescription || ""}</p>
                  </div>
                </div>
                <div className="flex py-[10px]">
                  <div className="w-[10%]">
                    <p className="">title</p>
                  </div>
                  <div className="w-[100%]">
                    <p>{course?.formData.title || ""}</p>
                  </div>
                </div>
                <div className="flex py-[10px]">
                  <div className="w-[10%]">
                    <p className="">Price</p>
                  </div>
                  <div className="w-[100%]">
                    <p>$ {course?.formData?.coursePrice}</p>
                  </div>
                </div>
                <div className="flex py-[10px]">
                  <a
                    href={`/course/${course?._id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="w-[100%] text-[#6842EF] cursor-pointer">
                      <p>View more details</p>
                    </div>
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}

      {openConfirmationModal && (
        <ConfirmationModal
          toggleModal={() => setOpenConfirmationModal(false)}
          // confirmationFunc={
          //   selectedWebinar
          //     ? approveAndPublishPendingWebinars
          //     : approvePendingCase
          // }
          confirmationFunc={
            selectedWebinar
              ? approveAndPublishPendingWebinars
              : selectedCase
              ? approvePendingCase
              : approvePendingCourse
          }
          title="approve"
        />
      )}
      {declineModal && (
        <ConfirmationModal
          toggleModal={() => setDeclineModal(false)}
          // confirmationFunc={
          //   selectedWebinar
          //     ? declineAndPublishPendingWebinars
          //     : declinePendingCase
          // }
          confirmationFunc={
            selectedWebinar
              ? declineAndPublishPendingWebinars
              : selectedCase
              ? declinePendingCase
              : declinePendingCourse
          }
          title="decline"
        />
      )}
    </div>
  );
};

export default ProductReviewNotification;
