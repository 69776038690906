import React from "react";
import { useModalContext } from "../../WebinarAdminCreateWebinarTab/ModalContext";

const ProductTab = () => {
  const { activeProductTab, setActiveProductTab } = useModalContext();
  return (
    <div className="ir-ws-webinar-admin-custom-tab">
      <p
        className={`ir-ws-custom-tab-button w-[150px] lg:w-[100px]  xl:w-[120px]  text-center  ${
          activeProductTab === "courses" ? "" : "ir-ws-custom-tab-active"
        }`}
        onClick={() => setActiveProductTab("courses")}
      >
        Courses
      </p>
      <p
        className={`ir-ws-custom-tab-button w-[150px] lg:w-[100px]  xl:w-[120px]  text-center ${
          activeProductTab === "cases" ? "" : "ir-ws-custom-tab-active"
        }`}
        onClick={() => setActiveProductTab("cases")}
      >
        Cases
      </p>
      <p
        className={`ir-ws-custom-tab-button w-[150px] lg:w-[100px]  xl:w-[120px] text-center ${
          activeProductTab === "webinars" ? "" : "ir-ws-custom-tab-active"
        }`}
        onClick={() => setActiveProductTab("webinars")}
      >
        Webinars
      </p>
    </div>
  );
};
export default ProductTab;
