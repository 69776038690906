import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./HomeCoursesFeatureContainer.scss";

const HomeCoursesFeatureContainer = () => {
  const [spaceBetween, setSpaceBetween] = useState(50);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 640) {
        setSpaceBetween(0); // Mobile view
      } else {
        setSpaceBetween(50); // Default for larger screens
      }
    };

    handleResize(); // Call initially
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="ir-ws-hme-courses-inside-gradient">
      <div className="ir-ws-padding-tb-100 ir-ws-hme-courses-main-container">
        <h2 className="ir-ws-text-center ir-ws-hme-courses-title">
          Preview Our Most Popular Courses
        </h2>
        <div className="ir-ws-flex ir-ws-align-center ir-ws-hme-courses-inner-container">
          <Swiper
            spaceBetween={spaceBetween}
            slidesPerView={"auto"}
            // onSlideChange={() => console.log("slide change")}
            // onSwiper={(swiper) => console.log(swiper)}
          >
            <SwiperSlide>
              <div className="ir-ws-hme-course-carousel-main-container">
                <p className="ir-ws-hme-course-heading-text">
                  Basics in Interventional Radiology
                </p>
                <div className="ir-ws-hme-course-car-inner-container">
                  <div className="ir-ws-position-relative ir-ws-hme-img-container">
                    <img
                      className="ir-ws-hme-course-img"
                      src={require("../../assets/images/basics-1.webp")}
                      alt="carousel"
                    />
                    <div className="ir-ws-position-absolute ir-ws-hme-img-thumbnail-container">
                      <h4 className="ir-color-white ir-ws-hme-img-thumbnail-container-title">
                        Basics in Interventional Radiology
                      </h4>
                      <p className="ir-color-white ir-ws-hme-img-thumbnail-container-para">
                        Interventional radiology (IR) is referred as surgical
                        radiology. It requires years of training to master the
                        skills needed to perform the wide breadth of minimally
                        invasive procedures. The training ranges from learning
                        the basics to latest advancements. This course is
                        dedicated in exploring the basic concepts in
                        Interventional radiology.
                      </p>
                      <div className="ir-ws-flex ir-ws-hme-thumb-container-inner">
                        <div className="ir-ws-hme-thumb-division-container">
                          {/* <img src={require('../../assets/images/hme-course-thumbail-1.png')} className="ir-ws-course-car-thumbnail-item" alt="thumb" /> */}
                          <p className="ir-color-white ir-ws-hme-course-thumbnail-course-title">
                            Introduction
                          </p>
                        </div>
                        <div className="ir-ws-hme-thumb-division-container">
                          {/* <img src={require('../../assets/images/hme-course-thumbail-1.png')} className="ir-ws-course-car-thumbnail-item" alt="thumb" /> */}
                          <p className="ir-color-white ir-ws-hme-course-thumbnail-course-title">
                            Equipment
                          </p>
                        </div>
                        <div className="ir-ws-hme-thumb-division-container">
                          {/* <img src={require('../../assets/images/hme-course-thumbail-1.png')} className="ir-ws-course-car-thumbnail-item" alt="thumb" /> */}
                          <p className="ir-color-white ir-ws-hme-course-thumbnail-course-title">
                            Needles
                          </p>
                        </div>
                        <div className="ir-ws-hme-thumb-division-container">
                          {/* <img src={require('../../assets/images/hme-course-thumbail-1.png')} className="ir-ws-course-car-thumbnail-item" alt="thumb" /> */}
                          <p className="ir-color-white ir-ws-hme-course-thumbnail-course-title">
                            Sheaths
                          </p>
                        </div>
                        <div className="ir-ws-hme-thumb-division-container">
                          {/* <img src={require('../../assets/images/hme-course-thumbail-1.png')} className="ir-ws-course-car-thumbnail-item" alt="thumb" /> */}
                          <p className="ir-color-white ir-ws-hme-course-thumbnail-course-title">
                            Guidewires
                          </p>
                        </div>
                        <div className="ir-ws-hme-thumb-division-container">
                          {/* <img src={require('../../assets/images/hme-course-thumbail-1.png')} className="ir-ws-course-car-thumbnail-item" alt="thumb" /> */}
                          <p className="ir-color-white ir-ws-hme-course-thumbnail-course-title">
                            Guidewires
                          </p>
                        </div>
                        <div className="ir-ws-hme-thumb-division-container">
                          {/* <img src={require('../../assets/images/hme-course-thumbail-1.png')} className="ir-ws-course-car-thumbnail-item" alt="thumb" /> */}
                          <p className="ir-color-white ir-ws-hme-course-thumbnail-course-title">
                            Catheters
                          </p>
                        </div>
                        <div className="ir-ws-hme-thumb-division-container">
                          {/* <img src={require('../../assets/images/hme-course-thumbail-1.png')} className="ir-ws-course-car-thumbnail-item" alt="thumb" /> */}
                          <p className="ir-color-white ir-ws-hme-course-thumbnail-course-title">
                            Balloons
                          </p>
                        </div>
                        <div className="ir-ws-hme-thumb-division-container">
                          {/* <img src={require('../../assets/images/hme-course-thumbail-1.png')} className="ir-ws-course-car-thumbnail-item" alt="thumb" /> */}
                          <p className="ir-color-white ir-ws-hme-course-thumbnail-course-title">
                            Inflation devices
                          </p>
                        </div>
                        <div className="ir-ws-hme-thumb-division-container">
                          {/* <img src={require('../../assets/images/hme-course-thumbail-1.png')} className="ir-ws-course-car-thumbnail-item" alt="thumb" /> */}
                          <p className="ir-color-white ir-ws-hme-course-thumbnail-course-title">
                            Stents
                          </p>
                        </div>
                        <div className="ir-ws-hme-thumb-division-container">
                          {/* <img src={require('../../assets/images/hme-course-thumbail-1.png')} className="ir-ws-course-car-thumbnail-item" alt="thumb" /> */}
                          <p className="ir-color-white ir-ws-hme-course-thumbnail-course-title">
                            Contrast
                          </p>
                        </div>
                        <div className="ir-ws-hme-thumb-division-container">
                          {/* <img src={require('../../assets/images/hme-course-thumbail-1.png')} className="ir-ws-course-car-thumbnail-item" alt="thumb" /> */}
                          <p className="ir-color-white ir-ws-hme-course-thumbnail-course-title">
                            Patient preparation
                          </p>
                        </div>
                        <div className="ir-ws-hme-thumb-division-container">
                          {/* <img src={require('../../assets/images/hme-course-thumbail-1.png')} className="ir-ws-course-car-thumbnail-item" alt="thumb" /> */}
                          <p className="ir-color-white ir-ws-hme-course-thumbnail-course-title">
                            Consent
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="ir-ws-hme-course-sub-text">
                  Interventional radiology (IR) is referred as surgical
                  radiology. It requires years of training to master the skills
                  needed to perform the wide breadth of minimally invasive
                  procedures. The training ranges from learning the basics to
                  latest advancements. This course is dedicated in exploring the
                  basic concepts in Interventional radiology.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="ir-ws-hme-course-carousel-main-container">
                <p className="ir-ws-hme-course-heading-text">
                  Women Interventions
                </p>
                <div className="ir-ws-hme-course-car-inner-container">
                  <div className="ir-ws-position-relative ir-ws-hme-img-container">
                    <img
                      className="ir-ws-hme-course-img"
                      src={require("../../assets/images/women-1.webp")}
                      alt="carousel"
                    />
                    <div className="ir-ws-position-absolute ir-ws-hme-img-thumbnail-container">
                      <h4 className="ir-color-white ir-ws-hme-img-thumbnail-container-title">
                        Women Interventions
                      </h4>
                      <p className="ir-color-white ir-ws-hme-img-thumbnail-container-para">
                        Over the past few decades, role of Interventional
                        radiology in womens health has accelerated.
                        Interventional radiology is fundamental in the treatment
                        of multiple conditions that affect women, including
                        uterine fibroids, infertility, chronic pelvic pain and
                        varicose veins. This course explores the various related
                        topics.
                      </p>
                      <div className="ir-ws-flex ir-ws-hme-thumb-container-inner">
                        <div className="ir-ws-hme-thumb-division-container">
                          {/* <img src={require('../../assets/images/hme-course-thumbail-1.png')} className="ir-ws-course-car-thumbnail-item" alt="thumb" /> */}
                          <p className="ir-color-white ir-ws-hme-course-thumbnail-course-title">
                            Infertility
                          </p>
                        </div>
                        <div className="ir-ws-hme-thumb-division-container">
                          {/* <img src={require('../../assets/images/hme-course-thumbail-1.png')} className="ir-ws-course-car-thumbnail-item" alt="thumb" /> */}
                          <p className="ir-color-white ir-ws-hme-course-thumbnail-course-title">
                            Postpartum bleeding
                          </p>
                        </div>
                        <div className="ir-ws-hme-thumb-division-container">
                          {/* <img src={require('../../assets/images/hme-course-thumbail-1.png')} className="ir-ws-course-car-thumbnail-item" alt="thumb" /> */}
                          <p className="ir-color-white ir-ws-hme-course-thumbnail-course-title">
                            Uterine fibroid embolization
                          </p>
                        </div>
                        <div className="ir-ws-hme-thumb-division-container">
                          {/* <img src={require('../../assets/images/hme-course-thumbail-1.png')} className="ir-ws-course-car-thumbnail-item" alt="thumb" /> */}
                          <p className="ir-color-white ir-ws-hme-course-thumbnail-course-title">
                            Pelvic venous disorders
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="ir-ws-hme-course-sub-text">
                  Over the past few decades, role of Interventional radiology in
                  womens health has accelerated. Interventional radiology is
                  fundamental in the treatment of multiple conditions that
                  affect women, including uterine fibroids, infertility, chronic
                  pelvic pain and varicose veins. This course explores the
                  various related topics.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="ir-ws-hme-course-carousel-main-container">
                <p className="ir-ws-hme-course-heading-text">
                  Oncologic Interventions
                </p>
                <div className="ir-ws-hme-course-car-inner-container">
                  <div className="ir-ws-position-relative ir-ws-hme-img-container">
                    <img
                      className="ir-ws-hme-course-img"
                      src={require("../../assets/images/oncologic-1.webp")}
                      alt="carousel"
                    />
                    <div className="ir-ws-position-absolute ir-ws-hme-img-thumbnail-container">
                      <h4 className="ir-color-white ir-ws-hme-img-thumbnail-container-title">
                        Oncologic Interventions
                      </h4>
                      <p className="ir-color-white ir-ws-hme-img-thumbnail-container-para">
                        In the present era of transplantation, Interventional
                        radiology plays a prominent role in the oncological
                        managements. IR input begins with establishing the
                        initial diagnosis of cancer, and involvement now extends
                        to minimally invasive treatment of malignancy, often in
                        combination with other modalities. This course explores
                        the various minimally invasive procedures relevant to
                        oncology.
                      </p>
                      <div className="ir-ws-flex ir-ws-hme-thumb-container-inner">
                        <div className="ir-ws-hme-thumb-division-container">
                          {/* <img src={require('../../assets/images/hme-course-thumbail-1.png')} className="ir-ws-course-car-thumbnail-item" alt="thumb" /> */}
                          <p className="ir-color-white ir-ws-hme-course-thumbnail-course-title">
                            Ablation
                          </p>
                        </div>
                        <div className="ir-ws-hme-thumb-division-container">
                          {/* <img src={require('../../assets/images/hme-course-thumbail-1.png')} className="ir-ws-course-car-thumbnail-item" alt="thumb" /> */}
                          <p className="ir-color-white ir-ws-hme-course-thumbnail-course-title">
                            Flow-directed
                          </p>
                        </div>
                        <div className="ir-ws-hme-thumb-division-container">
                          {/* <img src={require('../../assets/images/hme-course-thumbail-1.png')} className="ir-ws-course-car-thumbnail-item" alt="thumb" /> */}
                          <p className="ir-color-white ir-ws-hme-course-thumbnail-course-title">
                            Combined treatments
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="ir-ws-hme-course-sub-text">
                  In the present era of transplantation, Interventional
                  radiology plays a prominent role in the oncological
                  managements. IR input begins with establishing the initial
                  diagnosis of cancer, and involvement now extends to minimally
                  invasive treatment of malignancy, often in combination with
                  other modalities. This course explores the various minimally
                  invasive procedures relevant to oncology.
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default HomeCoursesFeatureContainer;
