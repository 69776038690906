import React from "react";

const DisclaimerInfo = ({ disclaimerInfo, setIsdisclaimerInfo }) => {
  return (
    <div className="ir-ws-position-fixed ir-ws-sign-popup-container ">
      <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container lg:!max-w-[400px] xl:!max-w-[500px] 3xl:!max-w-[600px]">
        <div className="ir-ws-signup-content-container">
          <div className="ir-ws-signup-content-inner-container px-[15px] xl:px-[25px] 3xl:px-[30px]">
            <div className="flex items-center justify-center">
              <img
                src={require("../../../assets/images/information.png")}
                alt="Info"
                className="w-[70px] "
              />
            </div>
            <h3 className="mt-[15px] xl:mt-[25px] 3xl:mt-[30px] ir-ws-text-center  ir-ws-heading-default-color px-[15px] !leading-normal lg:px-[15px] xl:px-[25px] 3xl:px-[30px] ir-ws-signup-content-title !text-[16px] lg:!text-[16px] xl:!text-[18px] 3xl:!text-[20px]">
              Disclaimer
            </h3>
            <p className="text-[10px] lg:text-[12px] xl:text-[14px] 3xl:text-[16px] text-center my-[15px] xl:my-[25px] 3xl:my-[30px]">
              These are educational cases with questions formatted in various
              forms. For certain format of questions (Free hand), your answers
              may not exactly correspond to the answers fed to the system. These
              questions are to enhance your knowledge and no marks are used to
              score.
            </p>
            <div
              className="ir-ws-text-center ir-ws-default-btn-container"
              style={{ width: "100%" }}
              onClick={() => setIsdisclaimerInfo(false)}
            >
              <button className=" !w-[120px] !p-[0px] lg:!h-[36px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px] xl:!h-[40px] 3xl:!h-[50px] ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button">
                <span>OK</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisclaimerInfo;
