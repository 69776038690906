import React from "react";

const NotificationsTab = ({ toggle, setToggle }) => {
  //   const handleToggle = () => {
  //     setToggle(false);
  //   };

  const handleSecondAdminNotifcationTab = () => {
    setToggle(1);
  };
  const handleProductReviewNotifcationTab = () => {
    setToggle(2);
  };
  return (
    <div className="ir-ws-webinar-admin-custom-tab">
      <p
        onClick={handleSecondAdminNotifcationTab}
        className={`ir-ws-custom-tab-button w-[160px] lg:w-[160px]  xl:w-[180px] 3xl:w-[190px] text-center  ${
          toggle === 1 ? "" : "ir-ws-custom-tab-active"
        }`}
      >
        Admin Notifications
      </p>
      <p
        className={`ir-ws-custom-tab-button w-[160px] lg:w-[160px]  xl:w-[180px] 3xl:w-[190px] text-center ${
          toggle === 2 ? "" : "ir-ws-custom-tab-active"
        }`}
        onClick={handleProductReviewNotifcationTab}
      >
        Product Notifications
      </p>
    </div>
  );
};

export default NotificationsTab;
