import { useContext, useEffect, useState } from "react";
import { useModalContext } from "../../components/WebinarAdminCreateWebinarTab/ModalContext";
import { RootStore } from "../../store/store";
import Swal from "sweetalert2";
import axios from "../../api/axios";
import { useNavigate } from "react-router-dom";

const UpdateUserModal = ({ updateUserModal, setUpdateUserModal }) => {
  // const { setUpdateUserModal } = useModalContext();
  const [profileData, setProfileData] = useState(null);
  const [updateProfileInputData, setUpdateProfileInputData] = useState({});
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [userId, setUserId] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [store, setStore] = useContext(RootStore);
  const { userStore } = store;
  const handleCloseUpdateUserModal = () => {
    setUpdateUserModal(false);
  };
  useEffect(() => {
    const token = localStorage.getItem("jwtTokenApi1");
    if (!token) {
      navigate("/signin?redirect=chat-room");
    } else {
      setToken(token);
      // getProducts() commented for the reason because of this line the chat is not loading in the begining of the page need to fix it
      if (Object.keys(userStore.profile).length <= 0) {
        getUserProfile();
      }
    }
  }, []);

  //   useEffect(() => {
  //     const userProfileDetails = async () => {
  //       try {
  //         const response = await axios.get(
  //           `https://backend.ir4u.info/api/v1/user/profile`,
  //           {
  //             headers: {
  //               Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
  //             },
  //           }
  //         );
  //         console.log("User profile from 1st api response", response);
  //         setUserId(response.data.id);
  //         setProfileData(response?.data)
  //         // if (response.data?.isEmailVerified) {
  //         //   setEmailVerified(true);
  //         // }
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     };
  //     userProfileDetails();
  //   }, []);

  //   const getProfile = async () => {
  //     const response = await axios({
  //       url: "user/profile",
  //       method: "get",
  //       withCredentials: false,
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
  //       },
  //     });
  //     console.log("User profile from 2nd api response", response);
  //     userStore.profile = response?.data?.data;
  //     setStore({ ...store, userStore });
  //   };

  const getUserProfile = async () => {
    try {
      const response = await axios.get(
        `https://backend.ir4u.info/api/v1/user/profile`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
          },
        }
      );
      console.log("User profile fetched", response);
      setUserId(response.data.id);
      setProfileData(response.data);
      userStore.profile = response.data;
      setStore({ ...store, userStore });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserProfile();
  }, [userId]);

  const updateProfileInputChange = (e) => {
    console.log(e.target.name, e.target.value);
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
    setUpdateProfileInputData({
      ...updateProfileInputData,
      [e.target.name]: e.target.value,
    });
  };

  const updateProfile = async () => {
    try {
      const userId = userStore.profile.id;

      // Prepare the JSON payload
      const updateProfileData = {
        firstname: updateProfileInputData.firstname,
        lastname: updateProfileInputData.lastname,
        email: updateProfileInputData.email,
        // password: updateProfileInputData.password || profileData.password,
      };

      // Add image file to payload only if one is selected
      if (selectedFile) {
        updateProfileData.image = selectedFile;
      }

      // Make the PUT request to update the profile
      const response = await axios.put(
        `https://backend.ir4u.info/api/v1/user/${userId}`,
        updateProfileData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
          },
        }
      );

      console.log("Updated user profile response", response);

      if (response.status === 200) {
        // Show success message
        Swal.fire({
          title: "Success!",
          text: "Your profile has been updated.",
          icon: "success",
        });

        // Update the profile in the store
        setStore({
          ...store,
          userStore: {
            ...userStore,
            profile: {
              ...userStore.profile,
              ...updateProfileInputData,
            },
          },
        });

        // Reset the input fields and close the modal
        setUpdateProfileInputData({});
        setUpdateUserModal(false);
      }
    } catch (error) {
      console.log("Error updating profile:", error);
      if (error.response?.status === 400) {
        Swal.fire("Error", "Email already exists", "error");
      }
    }
  };

  useEffect(() => {
    setProfileData(userStore.profile);
  }, [userStore.profile]);

  //   console.log("User Profile data", profileData);

  return (
    <div
      className="ir-ws-position-fixed ir-ws-sign-popup-container "
      // style={{ marginTop: "50px" }}
    >
      <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container !py-[10px] md:!py-[15px] lg:!py-[20px] xl:!py-[30px] !max-w-[400px] md:!max-w-[400px] xl:!max-w-[500px] 3xl:!max-w-[600px]">
        <div className="ir-ws-signup-content-container">
          <div
            className="ir-ws-position-absolute ir-ws-signup-close-container"
            onClick={handleCloseUpdateUserModal}
          >
            <span>
              <i class="bi bi-x-lg"></i>
            </span>
          </div>
          <div className="ir-ws-signup-content-inner-container">
            <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title !text-[20px]  lg:!text-[24px] xl:!text-[26px] 3xl:!text-[30px]">
              Update Profile
            </h3>
          </div>
          <form
            className="ir-ws-signup-form-container !px-[15px] md:!px-[20px] lg:!px-[30px] xl:!px-[40px] 3xl:!px-[50px] !mt-[15px] lg:!mt-[15px] xl:!mt-[25px] 3xl:!mt-[30px]"
            onSubmit={async (e) => {
              e.preventDefault();
              if (userId) {
                try {
                  updateProfile();
                } catch (error) {
                  console.error(error);
                }
              } else {
                console.error("userId is not available");
              }
            }}
          >
            <div className="ir-ws-signup-content-group !my-[15px] lg:!my-[25px] xl:!my-[30px] 3xl:!my-[35px]">
              <input
                className="ir-ws-signup-input-field !text-[12px] lg:!text-[12px] xl:!text-[14px] 3xl:!text-[16px]"
                type="text"
                name="firstname"
                value={profileData?.firstname || ""}
                onChange={updateProfileInputChange}
              />
              <span className="ir-ws-signup-highlight"></span>
              <span className="ir-ws-signup-bar"></span>
              <label className="ir-ws-signup-label !text-[10px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px]">
                Firstname
              </label>
            </div>
            <div className="ir-ws-signup-content-group !my-[15px] lg:!my-[25px] xl:!my-[30px] 3xl:!my-[35px]">
              <input
                className="ir-ws-signup-input-field !text-[12px] lg:!text-[12px] xl:!text-[14px] 3xl:!text-[16px]"
                type="text"
                name="lastname"
                value={profileData?.lastname || ""}
                onChange={updateProfileInputChange}
              />
              <span className="ir-ws-signup-highlight"></span>
              <span className="ir-ws-signup-bar"></span>
              <label className="ir-ws-signup-label !text-[10px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px]">
                Lastname
              </label>
            </div>
            <div className="ir-ws-signup-content-group !my-[15px] lg:!my-[25px] xl:!my-[30px] 3xl:!my-[35px]">
              <input
                className="ir-ws-signup-input-field !text-[12px] lg:!text-[12px] xl:!text-[14px] 3xl:!text-[16px]"
                type="text"
                name="email"
                value={profileData?.email || ""}
                onChange={updateProfileInputChange}
              />
              <span className="ir-ws-signup-highlight"></span>
              <span className="ir-ws-signup-bar"></span>
              <label className="ir-ws-signup-label !text-[10px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px]">
                Email
              </label>
            </div>
            {/* <div className="ir-ws-signup-content-group">
              <input
                className="ir-ws-signup-input-field"
                type="password"
                name="password"
                value={profileData?.password || "*******"}
                onChange={updateProfileInputChange}
              />
              <span className="ir-ws-signup-highlight"></span>
              <span className="ir-ws-signup-bar"></span>
              <label className="ir-ws-signup-label">Password</label>
            </div> */}

            <div className="ir-ws-text-center ir-ws-default-btn-container">
              <button
                className="!w-[200px] !p-[0px] !h-[36px] !text-[10px] lg:!h-[36px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px] xl:!h-[40px] 3xl:!h-[50px] ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                type="submit"
              >
                <span>Update Profile</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateUserModal;
