import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useModalContext } from "../../WebinarAdminCreateWebinarTab/ModalContext";
import Swal from "sweetalert2";

export const SecondAdminForm = () => {
  const navigate = useNavigate();
  const { userDetail } = useModalContext();

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    phone: "",
    designation: "",
    publish: "",
    description: "",
  });

  useEffect(() => {
    if (userDetail) {
      setFormData((prevData) => ({
        ...prevData,
        firstname: userDetail.firstname,
        lastname: userDetail.lastname,
        username: userDetail.username,
        email: userDetail.email,
      }));
    }
  }, [userDetail]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phone) => {
    // Allow phone numbers of varying lengths (no strict 10-digit rule)
    const re = /^\d+$/;
    return re.test(String(phone));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(formData.email)) {
      Swal.fire({
        icon: "warning",
        title: "Invalid Email",
        text: "Please enter a valid email address.",
      });
      return;
    }

    if (formData.phone && !validatePhone(formData.phone)) {
      Swal.fire({
        icon: "warning",
        title: "Invalid Phone Number",
        text: "Please enter a valid phone number (digits only).",
      });
      return;
    }

    if (
      !formData.firstname ||
      !formData.lastname ||
      !formData.email ||
      !formData.designation ||
      !formData.username ||
      !formData.publish ||
      !formData.description
    ) {
      Swal.fire({
        icon: "error",
        title: "Missing Fields",
        text: "Please fill out all required fields.",
      });
      return;
    }

    const phoneAsInteger = formData.phone ? parseInt(formData.phone, 10) : null;
    const userId = localStorage.getItem("userId");

    if (formData.phone && isNaN(phoneAsInteger)) {
      Swal.fire({
        icon: "warning",
        title: "Invalid Phone Number",
        text: "Please enter a valid phone number.",
      });
      return;
    }

    const payload = {
      firstname: formData.firstname,
      lastname: formData.lastname,
      email: formData.email,
      username: formData.username,
      id: userId,
      phone: phoneAsInteger,
      description: formData.description,
      reason: formData.publish,
    };

    try {
      const response = await fetch(
        "https://backend.ir4u.info/api/v1/user/requestion-publisher",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Form submitted successfully!",
        }).then(() => {
          navigate("/");
        });
      } else {
        const errorData = await response.json();
        // console.error("Error response:", errorData);
        Swal.fire({
          icon: "error",
          title: "Failed to Submit",
          text: errorData.message || "Unknown error",
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while submitting the form.",
      });
    }
  };

  return (
    <div className="ir-ws-sign-in-main-container">
      <div className="ir-ws-signin-inner-container ir-ws-sign-inner-container md:!max-w-[450px] xl:!max-w-[600px]  3xl:!max-w-[750px] ">
        <div className="ir-ws-sign-in-content-container">
          <div className="ir-ws-signin-logo-container">
            <div className="ir-ws-text-center flex items-center justify-center ir-ws-signin-logo-inner-container">
              <a href="/">
                <img
                  className="ir-ws-signin-logo-img w-[60px] lg:w-[60px] xl:w-[70px] 3xl:w-[100px]"
                  src={require("../../../assets/images/ir4u2.png")}
                  alt="logo"
                />
              </a>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="ir-ws-signup-content-inner-container">
              <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title !text-[20px]  lg:!text-[24px] xl:!text-[26px] 3xl:!text-[30px]">
                Collaborate with us
              </h3>
            </div>

            <div className="flex  flex-col md:flex-row  md:gap-[30px]">
              <div className="mb-[5px] 3xl:mb-[10px] w-[100%] md:w-[50%]">
                <label
                  htmlFor="firstname"
                  className="ir-se-admin-form-label text-[10px] lg:text-[10px] xl:text-[12px] font-[700] 3xl:text-[14px]"
                >
                  Firstname <span className="text-[#f03e3e]">*</span>
                </label>
                <input
                  type="text"
                  id="firstname"
                  name="firstname"
                  className="ir-se-admin-form-input"
                  required
                  value={formData.firstname}
                  onChange={handleChange}
                  readOnly
                />
              </div>
              <div className="mb-[5px] 3xl:mb-[20px] w-[100%] md:w-[50%]">
                <label
                  htmlFor="lastname"
                  className="ir-se-admin-form-label text-[10px] lg:text-[10px] xl:text-[12px] font-[700] 3xl:text-[14px]"
                >
                  Lastname <span className="text-[#f03e3e]">*</span>
                </label>
                <input
                  type="text"
                  id="lastname"
                  name="lastname"
                  className="ir-se-admin-form-input"
                  required
                  value={formData.lastname}
                  onChange={handleChange}
                  readOnly
                />
              </div>
            </div>
            <div className="flex  flex-col md:flex-row  md:gap-[30px]">
              <div className="mb-[5px] 3xl:mb-[20px] w-[100%] md:w-[50%]">
                <label
                  htmlFor="username"
                  className="ir-se-admin-form-label text-[10px] lg:text-[10px] xl:text-[12px] font-[700] 3xl:text-[14px]"
                >
                  Username <span className="text-[#f03e3e]">*</span>
                </label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  className="ir-se-admin-form-input"
                  required
                  value={formData.username}
                  onChange={handleChange}
                  readOnly
                />
              </div>
              <div className="mb-[5px] 3xl:mb-[20px] w-[100%] md:w-[50%]">
                <label
                  htmlFor="email"
                  className="ir-se-admin-form-label text-[10px] lg:text-[10px] xl:text-[12px] font-[700] 3xl:text-[14px]"
                >
                  Email <span className="text-[#f03e3e]">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="ir-se-admin-form-input"
                  required
                  value={formData.email}
                  onChange={handleChange}
                  readOnly
                />
              </div>
            </div>
            <div className="flex  flex-col md:flex-row  md:gap-[30px]">
              <div className="mb-[5px] 3xl:mb-[20px] w-[100%] md:w-[50%]">
                <label
                  htmlFor="phone"
                  className="ir-se-admin-form-label text-[10px] lg:text-[10px] xl:text-[12px] font-[700] 3xl:text-[14px]"
                >
                  Phone No.
                </label>
                <input
                  type="number"
                  id="phone"
                  name="phone"
                  className="ir-se-admin-form-input"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-[5px] 3xl:mb-[20px] w-[100%] md:w-[50%]">
                <label
                  htmlFor="designation"
                  className="ir-se-admin-form-label text-[10px] lg:text-[10px] xl:text-[12px] font-[700] 3xl:text-[14px]"
                >
                  Designation <span className="text-[#f03e3e]">*</span>
                </label>
                <input
                  type="text"
                  id="designation"
                  name="designation"
                  className="ir-se-admin-form-input"
                  required
                  value={formData.designation}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mb-[5px] 3xl:mb-[20px]">
              <label
                htmlFor="publish"
                className="ir-se-admin-form-label text-[10px] lg:text-[10px] xl:text-[12px] font-[700] 3xl:text-[14px]"
              >
                What you want to Publish?
                <span className="text-[#f03e3e]">*</span>
              </label>
              <textarea
                id="publish"
                name="publish"
                className="ir-se-admin-form-input resize-none"
                rows="4"
                value={formData.publish}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="mb-[5px] 3xl:mb-[20px]">
              <label
                htmlFor="description"
                className="ir-se-admin-form-label text-[10px] lg:text-[10px] xl:text-[12px] font-[700] 3xl:text-[14px]"
              >
                Description <span className="text-[#f03e3e]">*</span>
              </label>
              <textarea
                id="description"
                name="description"
                className="ir-se-admin-form-input resize-none"
                rows="4"
                value={formData.description}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="flex items-center justify-center">
              <div className="ir-ws-create-btn-container ir-ws-create-course-save-btn">
                <button
                  className="ir-ws-app-bg ir-color-white ir-ws-no-border ir-ws-banner-btn !w-[220px] !p-[0px] !text-[10px] !h-[36px] lg:!h-[36px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px] xl:!h-[40px] 3xl:!h-[50px]"
                  type="submit"
                >
                  <span>Submit</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SecondAdminForm;
