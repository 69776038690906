// import React, { useState } from "react";
// import Cropper from "react-easy-crop";

// const ProfilePicUpdate = ({
//   updateProfilePicCloseModalHandle,
//   upldateProfileSubmitFormHandle,
//   updateProfilePic,
//   setProfileThumbnail,
//   isLoading,
// }) => {
//   console.log(updateProfilePic);
//   const [crop, setCrop] = useState({ x: 0, y: 0 });
//   const [zoom, setZoom] = useState(1);
//   const onCropComplete = (croppedArea, croppedAreaPixels) => {
//     try {
//       const canvas = document.createElement("canvas");
//       const image = new Image();

//       image.src = updateProfilePic;
//       image.onload = () => {
//         const scaleX = image.naturalWidth / image.width;
//         const scaleY = image.naturalHeight / image.height;

//         canvas.width = 350;
//         canvas.height = 350;

//         const ctx = canvas.getContext("2d");

//         ctx.drawImage(
//           image,
//           croppedAreaPixels.x * scaleX,
//           croppedAreaPixels.y * scaleY,
//           croppedAreaPixels.width * scaleX,
//           croppedAreaPixels.height * scaleY,
//           0,
//           0,
//           canvas.width,
//           canvas.height
//         );

//         const base64Image = canvas.toDataURL("image/jpeg");
//         setProfileThumbnail(base64Image);
//       };
//     } catch (error) {
//       console.error("Error cropping image:", error);
//     }
//   };
//   return (
//     <div className="ir-ws-position-fixed ir-ws-sign-popup-container ">
//       <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
//         <div className="ir-ws-signup-content-container">
//           <div
//             className="ir-ws-position-absolute ir-ws-signup-close-container"
//             onClick={updateProfilePicCloseModalHandle}
//           >
//             <span>X</span>
//           </div>

//           <form
//             className="ir-ws-signup-form-container !m-[0px]"
//             onSubmit={upldateProfileSubmitFormHandle}
//           >
//             <div>
//               <div className="ir-ws-webinar-image-crop-container ">
//                 <Cropper
//                   image={updateProfilePic}
//                   crop={crop}
//                   zoom={zoom}
//                   aspect={350 / 350}
//                   cropSize={{ width: 350, height: 350 }}
//                   onCropChange={setCrop}
//                   onCropComplete={onCropComplete}
//                   onZoomChange={setZoom}
//                   minZoom={1}
//                 />
//               </div>

//               <div
//                 className="ir-ws-webinar-image-editor-btn-container "
//                 style={{ marginTop: "70px" }}
//               >
//                 <div className="ir-ws-webinar-image-controls">
//                   <div
//                     className="ir-ws-zoom-button"
//                     onClick={(e) => {
//                       e.preventDefault();
//                       setZoom(zoom - 0.03);
//                     }}
//                   >
//                     <img
//                       src={require("../../assets/images/chat-images/magnifying-glass (1).png")}
//                       className="ir-ws-zoom-button-images"
//                       alt="zoom button"
//                     />
//                   </div>
//                   <div
//                     className="ir-ws-zoom-button"
//                     onClick={(e) => {
//                       e.preventDefault();
//                       setZoom(zoom + 0.03);
//                     }}
//                   >
//                     <img
//                       src={require("../../assets/images/chat-images/magnifying-glass.png")}
//                       className="ir-ws-zoom-button-images"
//                       alt="zoom button"
//                     />
//                   </div>
//                 </div>

//                 <div className="ir-ws-text-center ir-ws-default-btn-container">
//                   <button
//                     className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
//                     style={{ width: "200px", height: "50px" }}
//                     type="submit"
//                   >
//                     <span>{isLoading ? "Uploading..." : "Upload Picture"}</span>
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProfilePicUpdate;

import React, { useState, useRef } from "react";
import Cropper from "react-cropper"; // Importing react-cropper
import "cropperjs/dist/cropper.css"; // Required CSS for react-cropper

const ProfilePicUpdate = ({
  updateProfilePicCloseModalHandle,
  upldateProfileSubmitFormHandle,
  updateProfilePic,
  setProfileThumbnail,
  isLoading,
}) => {
  const cropperRef = useRef(null); // Reference to the cropper instance

  const onCropComplete = () => {
    try {
      const cropper = cropperRef.current?.cropper;
      if (cropper) {
        const canvas = cropper.getCroppedCanvas({
          width: 350,
          height: 350,
        });

        const base64Image = canvas.toDataURL("image/jpeg");
        setProfileThumbnail(base64Image);
      }
    } catch (error) {
      console.error("Error cropping image:", error);
    }
  };

  return (
    <div className="ir-ws-position-fixed ir-ws-sign-popup-container ">
      <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container !p-[15px]">
        <div className="ir-ws-signup-content-container">
          <div
            className="ir-ws-position-absolute ir-ws-signup-close-container"
            onClick={updateProfilePicCloseModalHandle}
          >
            <i className="bi bi-x"></i>
          </div>

          <form
            className="ir-ws-signup-form-container !mt-[0px]"
            onSubmit={upldateProfileSubmitFormHandle}
          >
            <div>
              <div className="ir-ws-webinar-image-crop-container ">
                <Cropper
                  src={updateProfilePic}
                  style={{ height: "100%", width: "100%" }}
                  initialAspectRatio={1}
                  aspectRatio={1}
                  guides={false}
                  crop={onCropComplete} // Triggered when crop box is adjusted
                  ref={cropperRef}
                  scalable={false}
                  zoomable={true}
                  cropBoxResizable={false}
                  dragMode="move"
                  ready={onCropComplete}
                  // Prevents issues with some image orientations
                />
              </div>

              <div className="ir-ws-text-center ir-ws-default-btn-container">
                <button
                  className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button !w-[120px] !p-[0px] lg:!h-[36px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px] xl:!h-[40px] 3xl:!h-[50px]"
                  type="submit"
                >
                  <span>{isLoading ? "Uploading..." : "Upload Picture"}</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProfilePicUpdate;
