import { useEffect, useState } from "react";
import axios from "axios";

const useFetchPendingProducts = () => {
  const username = localStorage.getItem("loggedInUsername");

  const [pendingProducts, setPendingProducts] = useState({
    pendingCases: [],
    pendingWebinars: [],
    pendingCourses: [],
  });

  const [combinedPendingProducts, setCombinedPendingProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const token = localStorage.getItem("jwtTokenApi1");

  useEffect(() => {
    const fetchPendingData = async () => {
      try {
        setLoading(true);

        // Fetch all pending data in parallel
        const [casesRes, webinarsRes, coursesRes] = await Promise.all([
          axios
            .get("https://case.ir4u.info/api/v1/publisher/pending-case", {
              headers: { Authorization: `Bearer ${token}` },
            })
            .catch((err) => {
              // Handle 404 or other errors for cases
              if (
                err.response?.status === 404 ||
                err.response?.data?.message === "No cases found"
              ) {
                return { data: { cases: [] } };
              }
              throw err;
            }),
          axios
            .get(
              "https://webinar-staging.backend.ir4u.info/api/v1/sub-admin/pending-webinars",
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .catch((err) => {
              // Handle 404 or other errors for webinars
              if (
                err.response?.status === 404 ||
                err.response?.data?.message ===
                  "No pending webinars found for this user"
              ) {
                return { data: { pendingWebinars: [] } };
              }
              throw err;
            }),
          axios
            .get(
              `https://courses.ir4u.info/api/get-pending-courses/${username}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .catch((err) => {
              // Handle 404 or other errors for courses
              if (
                err.response?.status === 404 ||
                err.response?.data?.message === "No courses found"
              ) {
                return { data: { courses: [] } };
              }
              throw err;
            }),
        ]);

        // console.log("pending responses", casesRes, webinarsRes, coursesRes);

        // Extract data from responses
        const pendingCases = casesRes.data.cases || [];
        const pendingWebinars = webinarsRes.data.pendingWebinars || [];
        const pendingCourses = coursesRes.data || [];

        // Set pending data
        setPendingProducts({
          pendingCases,
          pendingWebinars,
          pendingCourses,
        });

        // Combine all pending products into a single array
        const combined = [
          ...pendingCases.map((item) => ({ ...item, type: "case" })),
          ...pendingWebinars.map((item) => ({ ...item, type: "webinar" })),
          ...pendingCourses.map((item) => ({ ...item, type: "course" })),
        ];

        setCombinedPendingProducts(combined);
      } catch (err) {
        setError(err.message);
        setLoading(false);
        // console.error("Error fetching pending data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchPendingData();
  }, [token, username]);

  return { pendingProducts, combinedPendingProducts, loading, error };
};

export default useFetchPendingProducts;
