import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAbISinH8Zc5pS-AM8xAHlQNyeMbLTVva4",
  authDomain: "ir4u-notifications.firebaseapp.com",
  projectId: "ir4u-notifications",
  storageBucket: "ir4u-notifications.appspot.com",
  messagingSenderId: "94798116312",
  appId: "1:94798116312:web:2a3abda6e346ed2127aead",
  measurementId: "G-T7KJLBW815",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

/**
 * Request notification permission and get FCM token
 */
export const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();

    if (permission !== "granted") {
      return null;
    }

    // Ensure Service Worker is ready
    const registration = await navigator.serviceWorker.ready;

    const vapidKey =
      "BBxTqZVSM40Q4BnOF4wKxBPtY1tNuQ1EMuBFWiryB3Egpc_-GI74N9rw5yns2tAIxl184gDv8RnaWWSzLga5gYU";
    const token = await getToken(messaging, {
      vapidKey,
      serviceWorkerRegistration: registration,
    });

    if (token) {
      return token;
    } else {
      // console.error(
      //   "Failed to get FCM Token. Check VAPID key, service worker, or browser settings."
      // );
      return null;
    }
  } catch (error) {
    // console.error("Error getting FCM token:", error);
    return null;
  }
};

// Listen for foreground messages
onMessage(messaging, (payload) => {
  // console.log("Received foreground message:", payload);
});
