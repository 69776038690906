import React, { useState, useEffect } from "react";
import { useModalContext } from "../../WebinarAdminCreateWebinarTab/ModalContext";
import Swal from "sweetalert2";
import {
  addSecondaryAdmin,
  removeSecondaryAdmin,
} from "../../../api/secondaryAdminApi";
import AdminConfirmModal from "../../Chat/AdminConfirmModal/AdminConfirmModal";
import axios from "axios";
import useFetchAllProducts from "../../../hooks/useFetchAllProducts";
import useFetchPendingProducts from "../../../hooks/useFetchPendingProducts";

const AdminDataComponent = ({ type, title, status, data, dataInfo }) => {
  const { webinarData } = useModalContext();
  const { allProducts, combinedProducts, loading, error } =
    useFetchAllProducts();

  const { pendingProducts, combinedPendingProducts } =
    useFetchPendingProducts();

  const [activeDropdown, setActiveDropdown] = useState(null);
  const [publishedData, setPublishedData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [userList, setUserList] = useState([]);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);

  function getFirstLetter(name) {
    if (typeof name !== "string" || name.length === 0) {
      return "Invalid input";
    }
    return name.charAt(0);
  }

  const getItemTitle = (item) => {
    switch (item.type) {
      case "case":
        return item.slides?.[0]?.name || "No Title";
      case "webinar":
        return item?.title || "No Title";
      case "chat":
        return item.name || "No Title";
      case "course":
        return item.formData?.title || "Untitled Course";
      default:
        return "Unknown";
    }
  };

  const statusColors = {
    approved: "!text-[#37b24d]", // Green
    pending: "!text-[#1c7ed6]", // Yellow
    declined: "!text-[#f59f00]", // Orange
    deleted: "!text-[#f03e3e]", // Red
  };

  // Fetch product status from the API
  const getProductStatus = async () => {
    try {
      const res = await fetch(
        "https://backend.ir4u.info/api/v1/user/product-status",
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
            "Content-Type": "application/json",
          },
        }
      );

      const data = await res.json();

      // Check if there are no products
      if (!data.products || data.products.length === 0) {
        setPublishedData([]);

        setIsLoading(false);
        return;
      } else {
        // Update state with filtered data
        setPublishedData(data?.products);
      }
    } catch (error) {
      setIsLoading(false);
      return;
      // console.error("Error fetching Product status:", error);
    } finally {
      setIsLoading(false); // Set loading to false after fetching
    }
  };

  useEffect(() => {
    getProductStatus();
  }, []);

  useEffect(() => {
    const handleGetAllUser = async () => {
      try {
        const response = await axios.get(
          "https://backend.ir4u.info/api/v1/admin/all/users",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
            },
          }
        );

        const filteredUsers = response.data.filter(
          (user) => user.username !== "admin"
        );
        setUserList(filteredUsers);
      } catch (error) {
        // console.log(error);
        return;
      }
    };
    handleGetAllUser();
  }, [selectedUserId, showConfirmModal]);

  // Make a user a secondary admin
  const handleAddAdmin = async (userId) => {
    try {
      const data = await addSecondaryAdmin(userId);

      // Refresh the user list or update the state
      setShowConfirmModal(false); // Close the modal
    } catch (error) {
      // console.error("Error:", error);
      setShowConfirmModal(false); // Close the modal
      return;
    }
  };

  // Remove a secondary admin
  const handleRemoveAdmin = async (userId) => {
    try {
      const data = await removeSecondaryAdmin(userId);

      // Refresh the user list or update the state
      setShowConfirmModal(false); // Close the modal
    } catch (error) {
      setShowConfirmModal(false); // Close the modal
      return;
    }
  };

  // Handle role change confirmation
  const handleConfirm = () => {
    if (selectedAction === "makeAdmin") {
      handleAddAdmin(selectedUserId);
    } else if (selectedAction === "removeAdmin") {
      handleRemoveAdmin(selectedUserId);
    }
  };

  // Handle role change cancellation
  const handleCancel = () => {
    setShowConfirmModal(false);
  };

  return (
    <div className="w-[80%] ph-box-shadow h-[100%]">
      <div className="bg-[#FFFFFF]  rounded-[8px] h-[100%] text-[#1C1D26]">
        <div className="lg:p-[8px] xl:p-[12px] 3xl:p-[20px]  h-[100%] flex flex-col">
          <div className="flex items-center justify-between">
            <div>
              <p className="lg:text-[12px] xl:text-[14px] text-[#0A0815] font-[700] 3xl:text-[16px]">
                {type}
              </p>
            </div>
            {/* <div className="text-[#646464] text-[24px] cursor-pointer ">
              <i className="bi bi-three-dots"></i>
            </div> */}
          </div>
          <div className="pt-[16px] lg:pt-[10px]  xl:pt-[12px]">
            <div className="w-[100%] rounded-[8px]  lg:text-[10px] xl:text-[12px] 3xl:text-[14px] font-[500] p-[4px] text-[#5D5B73] bg-[#F6F6F6] flex items-center justify-between">
              <div className="w-[33%] pl-[4px]  ">
                <p>{title}</p>
              </div>
              <div className="w-[33%] text-center">
                <p>{data}</p>
              </div>
              <div className="w-[33%] text-center pr-[4px]">
                <p>{status}</p>
              </div>
              {type !== "Under Review Product" && (
                <div className="w-[33%] text-center pr-[4px]">
                  <p>{dataInfo}</p>
                </div>
              )}
            </div>
          </div>

          {/* {type === "Live Products" && (
            <div className="ph-user-product-cont">
              {combinedProducts?.map((item, i) => (
                <div
                  className="pt-[8px] lg:pt-[4px] xl:pt-[4px] 3xl:pt-[8px]"
                  key={i}
                >
                  <div className="w-[100%] p-[4px] text-[#5D5B73] lg:text-[10px] xl:text-[12px] 3xl:text-[14px]  flex items-center justify-between">
                    <div className="w-[33%] pl-[4px]">
                      <div className="flex items-center gap-[8px]">
                        <div className="w-[30px] h-[30px] lg:w-[24px] lg:h-[24px] xl:w-[28px] xl:h-[28px] 3xl:w-[34px] 3xl:h-[34px]  bg-[#D9D9D9] rounded-[8px]"></div>
                        <p className="text-[#0A0815] max-w-[100px] xl:max-w-[130px] 3xl:max-w-[160px] text-ellipse">
                          {getItemTitle(item)}
                        </p>
                      </div>
                    </div>
                    <div className="w-[33%] text-center">
                      <p>{item?.type}</p>
                    </div>
                    <div className="w-[33%] text-center">
                      <p>{item?.status ? item.status : "Not available"}</p>
                    </div>
                    <div className="w-[33%] text-center">
                      <p>0</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )} */}
          {type === "Live Products" && (
            <div className="ph-user-product-cont">
              {combinedProducts?.length > 0 ? (
                combinedProducts.map((item, i) => (
                  <div
                    className="pt-[8px] lg:pt-[4px] xl:pt-[4px] 3xl:pt-[8px]"
                    key={i}
                  >
                    <div className="w-[100%] p-[4px] text-[#5D5B73] lg:text-[10px] xl:text-[12px] 3xl:text-[14px] flex items-center justify-between">
                      <div className="w-[33%] pl-[4px]">
                        <div className="flex items-center gap-[8px]">
                          <div className="w-[30px] h-[30px] lg:w-[24px] lg:h-[24px] xl:w-[28px] xl:h-[28px] 3xl:w-[34px] 3xl:h-[34px] bg-[#D9D9D9] rounded-[8px]"></div>
                          <p className="text-[#0A0815] max-w-[100px] xl:max-w-[130px] 3xl:max-w-[160px] text-ellipse">
                            {getItemTitle(item)}
                          </p>
                        </div>
                      </div>
                      <div className="w-[33%] text-center">
                        <p>{item?.type}</p>
                      </div>
                      <div className="w-[33%] text-center">
                        <p>{item?.status ? item.status : "active"}</p>
                      </div>
                      <div className="w-[33%] text-center">
                        <p>0</p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center mt-[30px] text-[#5D5B73] text-[10px] lg:text-[10px] xl:text-[12px] 3xl:text-[14px]">
                  No Live Products found
                </div>
              )}
            </div>
          )}

          {type === "Users" && (
            <div className="ph-user-product-cont">
              {userList
                ?.filter((u) => !u.role.includes("admin"))
                .map((item, index) => (
                  <div
                    className="pt-[8px] lg:pt-[4px] xl:pt-[4px] 3xl:pt-[8px]"
                    key={index}
                  >
                    <div className="w-[100%] p-[4px] text-[#5D5B73] text-[10px] lg:text-[10px] xl:text-[12px] 3xl:text-[14px] flex items-center justify-between">
                      <div className="w-[33%] pl-[4px]">
                        <div className="flex items-center gap-[8px]">
                          <div className="flex items-center justify-center w-[24px] h-[24px] lg:w-[24px] lg:h-[24px] xl:w-[28px] xl:h-[28px] 3xl:w-[34px] 3xl:h-[34px] bg-[#D9D9D9] rounded-[8px]">
                            {item?.userImage ? (
                              <img
                                src={item?.userImage}
                                alt="User"
                                className="w-[100%] h-[100%] object-cover rounded-[8px]"
                              />
                            ) : (
                              <p className="uppercase font-[600] lg:text-[8px]">
                                {getFirstLetter(item?.firstname)}
                              </p>
                            )}
                          </div>
                          <p
                            className="text-[#0A0815] max-w-[100px] xl:max-w-[130px] 3xl:max-w-[160px] text-ellipse"
                            title={item?.username}
                          >
                            {item?.username}
                          </p>
                        </div>
                      </div>
                      <div className="w-[33%] text-center text-ellipse">
                        <p>{item?.firstname}</p>
                      </div>
                      <div
                        className="w-[33%] text-center relative flex items-center cursor-pointer justify-center gap-[4px]"
                        // onClick={() =>
                        //   setActiveDropdown(
                        //     activeDropdown === index ? null : index
                        //   )
                        // }
                      >
                        {/* <p>
                          {item?.role.includes("admin")
                            ? "Admin"
                            : item?.role.includes("publisher")
                            ? "Publisher"
                            : "User"}
                        </p>
                        <i
                          className={`bi bi-chevron-${
                            activeDropdown === index ? "up" : "down"
                          } text-[#0A0815] xl:text-[12px] mt-[2px] 3xl:text-[14px]`}
                        ></i> */}
                        <div className="bg-[#CDBFFF] flex items-center  rounded-[8px] text-[#fff] text-[10px] lg:text-[8px] xl:text-[10px] 3xl:text-[12px]">
                          <p
                            className={`py-[6px] lg:w-[65px] xl:w-[80px] 3xl:w-[90px] text-center rounded-[8px] ${
                              item?.role.includes("publisher")
                                ? "bg-[#6842EF]"
                                : ""
                            }`}
                            onClick={() => {
                              if (item?.role.includes("publisher")) {
                                Swal.fire({
                                  icon: "info",
                                  title: "Already an Admin",
                                  text: "User is already an admin.",
                                });
                              } else {
                                setSelectedAction("makeAdmin");
                                setSelectedUserId(item.id);
                                setShowConfirmModal(true);
                              }
                            }}
                          >
                            2nd Admin
                          </p>

                          <p
                            className={`py-[6px] lg:w-[65px] xl:w-[80px] 3xl:w-[90px] text-center rounded-[8px] ${
                              item?.role.includes("user") &&
                              !item?.role.includes("publisher")
                                ? "bg-[#6842EF]"
                                : ""
                            }`}
                            onClick={() => {
                              if (
                                item?.role.length === 1 &&
                                item?.role.includes("user")
                              ) {
                                Swal.fire({
                                  icon: "info",
                                  title: "Already a User",
                                  text: "User is already a user.",
                                });
                              } else {
                                setSelectedAction("removeAdmin");
                                setSelectedUserId(item.id);
                                setShowConfirmModal(true);
                              }
                            }}
                          >
                            User
                          </p>
                        </div>
                        {/* {activeDropdown === index && (
                          <div className="absolute right-[25px] z-[10] lg:right-[3%] xl:right-[14%] 3xl:right-[14%] bottom-[-45px] xl:bottom-[-40px] bg-[#F2F6FE] border rounded">
                            <ul className="p-[8px] xl:p-[6px] 3xl:p-[8px]">
                              <li
                                className="text-[#1C1D26] cursor-pointer text-left xl:text-[10px] 3xl:text-[12px]"


                                onClick={() => {
                                  if (
                                    item?.role.length === 1 &&
                                    item?.role.includes("user")
                                  ) {
                                    Swal.fire({
                                      icon: "info",
                                      title: "Already a User",
                                      text: "User is already a user.",
                                    });
                                  } else {
                                    setSelectedAction("removeAdmin");
                                    setSelectedUserId(item.id);
                                    setShowConfirmModal(true);
                                  }
                                }}
                              >
                                User
                              </li>
                            </ul>
                            <div className="w-[100%] h-[1px] bg-[#e5e7eb]"></div>
                            <ul className="p-[8px] xl:p-[6px] 3xl:p-[8px]">
                              <li
                                className="text-[#1C1D26] cursor-pointer text-left xl:text-[10px] 3xl:text-[12px]"
                                onClick={() => {
                                  if (item?.role.includes("publisher")) {
                                    Swal.fire({
                                      icon: "info",
                                      title: "Already an Admin",
                                      text: "User is already an admin.",
                                    });
                                  } else {
                                    setSelectedAction("makeAdmin");
                                    setSelectedUserId(item.id);
                                    setShowConfirmModal(true);
                                  }
                                }}
                              >
                                Admin
                              </li>
                            </ul>
                          </div>
                        )} */}
                      </div>
                      <div
                        className="w-[33%] text-ellipse text-left"
                        title={item?.email}
                      >
                        <p className="text-ellipse">{item?.email}</p>
                      </div>
                    </div>
                  </div>
                ))}

              {/* Confirmation Modal */}
              {showConfirmModal && (
                <AdminConfirmModal
                  title={"Are you sure you want to change the role?"}
                  onConfirm={handleConfirm}
                  onCancel={handleCancel}
                />
              )}
            </div>
          )}

          {/* {type === "Under Review Product" && (
            <div className="ph-user-product-cont">
              {combinedPendingProducts?.map((item, index) => (
                <div
                  className="pt-[8px] lg:pt-[4px] xl:pt-[4px]3xl:pt-[8px]"
                  key={index}
                >
                  <div className="w-[100%] p-[4px] text-[#5D5B73] text-[10px] lg:text-[10px] xl:text-[12px] 3xl:text-[14px]  flex items-center justify-between">
                    <div className="w-[33%] pl-[4px]">
                      <div className="flex items-center gap-[8px]">
                        <div className="w-[24px] h-[24px] lg:w-[24px] lg:h-[24px] xl:w-[28px] xl:h-[28px] 3xl:w-[34px] 3xl:h-[34px]  bg-[#D9D9D9] rounded-[8px]"></div>
                        <p className="text-[#0A0815] max-w-[130px] xl:max-w-[160px] 3xl:max-w-[190px] text-ellipse">
                          {getItemTitle(item)}
                        </p>
                      </div>
                    </div>
                    <div className="w-[33%] text-center capitalize">
                      <p>{item?.type}</p>
                    </div>
                    <div className="w-[33%] text-center capitalize">
                      <p
                        className={` ${
                          statusColors[item?.status?.toLowerCase()] ||
                          "text-[#0A0815]"
                        } text-[#0A0815]`}
                      >
                        {item?.status ? item.status : "Not available"}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )} */}
          {type === "Under Review Product" && (
            <div className="ph-user-product-cont">
              {combinedPendingProducts?.length > 0 ? (
                combinedPendingProducts.map((item, index) => (
                  <div
                    className="pt-[8px] lg:pt-[4px] xl:pt-[4px] 3xl:pt-[8px]"
                    key={index}
                  >
                    <div className="w-[100%] p-[4px] text-[#5D5B73] text-[10px] lg:text-[10px] xl:text-[12px] 3xl:text-[14px] flex items-center justify-between">
                      <div className="w-[33%] pl-[4px]">
                        <div className="flex items-center gap-[8px]">
                          <div className="w-[24px] h-[24px] lg:w-[24px] lg:h-[24px] xl:w-[28px] xl:h-[28px] 3xl:w-[34px] 3xl:h-[34px] bg-[#D9D9D9] rounded-[8px]"></div>
                          <p className="text-[#5D5B73] max-w-[130px] xl:max-w-[160px] 3xl:max-w-[190px] text-ellipse">
                            {getItemTitle(item)}
                          </p>
                        </div>
                      </div>
                      <div className="w-[33%] text-center ">
                        <p>{item?.type}</p>
                      </div>
                      <div className="w-[33%] text-center ">
                        <p
                          className={`${
                            statusColors[item?.status?.toLowerCase()] ||
                            "text-[#5D5B73]"
                          } text-[#5D5B73]`}
                        >
                          {item?.status ? item.status : "pending"}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center mt-[30px] text-[#5D5B73] text-[10px] lg:text-[10px] xl:text-[12px] 3xl:text-[14px]">
                  No Under Review Product found
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default AdminDataComponent;
