import React, { useEffect, useState } from "react";
import Header from "../../../components/Header/Header";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Navigate, useNavigate } from "react-router-dom";
import { useModalContext } from "../../../components/WebinarAdminCreateWebinarTab/ModalContext";

const UploadCasePage = ({ uploadingCaseProgress }) => {
  const slides = useSelector((state) => state.case.slides);
  const cases = useSelector((state) => state.case);
  const [hasPublished, setHasPublished] = useState(false);
  const [caseId, setCaseId] = useState(null);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  const { caseUpdateId, handleDeleteDraftCase, updateDraftCaseId, userDetail } =
    useModalContext();

  const uploadBorderColors = {
    borderTopColor: uploadingCaseProgress >= 10 ? "#6842ef" : "#ffff",
    borderRightColor: uploadingCaseProgress >= 40 ? "#6842ef" : "#ffff",
    borderBottomColor: uploadingCaseProgress >= 70 ? "#6842ef" : "#ffff",
    borderLeftColor: uploadingCaseProgress >= 100 ? "#6842ef" : "#ffff",
    transform: "rotate(45deg)",
  };

  const isAdmin = localStorage.getItem("loggedInUsername") === "admin";
  const userProfile = JSON.parse(localStorage.getItem("userProfile") || "[]");
  const isSecondAdmin = userProfile.includes("publisher");
  const handlePublishCase = async () => {
    if (updateDraftCaseId) {
      handleDeleteDraftCase(updateDraftCaseId);
    }
    if (caseUpdateId) {
      try {
        let role = "admin";
        if (userDetail?.role?.includes("admin")) {
          role = "admin";
        } else if (userDetail?.role?.includes("publisher")) {
          role = "publisher";
        }

        // Determine API endpoint based on user role
        const updateUrl =
          role === "publisher"
            ? `https://case.ir4u.info/api/v1/publisher/update/${caseUpdateId}`
            : `https://case.ir4u.info/api/v1/admin/case/update/${caseUpdateId}`;

        // const updateUrl = `https://case.ir4u.info/api/v1/admin/case/update/${caseUpdateId}`;
        const response = await fetch(updateUrl, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwtTokenApi1")}`,
          },
          body: JSON.stringify(cases),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        // Swal.fire({
        //   icon: "success",
        //   title: "Case Updated",
        //   text: "Your case has been successfully updated!",
        // });
        // navigate(`/case/${caseUpdateId}`);
        Swal.fire({
          icon: "success",
          title: "Case Updated",
          text: "Your case has been successfully updated!",
          confirmButtonText: "OK",
        });
        // .then((result) => {
        //   if (result.isConfirmed) {
        //     navigate(`/case/${caseUpdateId}`);
        //   }
        // });
      } catch (error) {
        setIsError(true);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Something went wrong while updating the case.",
        });
      }
    } else {
      let role = "admin";
      if (userDetail?.role?.includes("admin")) {
        role = "admin";
      } else if (userDetail?.role?.includes("publisher")) {
        role = "publisher";
      }

      // Determine API endpoint based on user role
      const endpoint =
        role === "publisher"
          ? `https://case.ir4u.info/api/v1/publisher/request-create-case`
          : `https://case.ir4u.info/api/v1/admin/case/publish`;

      try {
        const response = await fetch(endpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwtTokenApi1")}`,
          },
          body: JSON.stringify({
            slides: cases.slides,
            casePrice: cases.casePrice,
            categorie: cases.categorie,
            name: cases.name,
            description: cases.description,
            thumbnail: cases.thumbnail,
            bundleTitle: cases.bundleTitle,
          }),
        });
        const data = await response.json();

        setCaseId(data?.id);

        if (role === "publisher") {
          Swal.fire({
            icon: "success",
            title: "Case Created",
            text: "Your case has been successfully created and is now under review!",
          });
          // navigate("/");
        } else {
          Swal.fire({
            icon: "success",
            title: "Case Published",
            text: "Your case has been successfully published!",
          });

          // navigate(`/case/${data?.id}`);
        }
      } catch (error) {
        setIsError(true);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Something went wrong while creating the case.",
        });
      }
    }
  };

  // useEffect(() => {
  //   if (uploadingCaseProgress === 100 && !hasPublished) {
  //     setHasPublished(true); // Ensure it runs only once
  //     // setTimeout(() => {
  //     handlePublishCase();

  //     // }, 500);
  //   }
  // }, [uploadingCaseProgress, hasPublished]);

  // Simplified useEffect to prevent duplicate calls
  // useEffect(() => {
  //   if (uploadingCaseProgress === 100) {
  //     const timer = setTimeout(() => {
  //       handlePublishCase();
  //     }, 500);

  //     return () => clearTimeout(timer);
  //   }
  // }, [uploadingCaseProgress]);

  useEffect(() => {
    let isMounted = true;

    if (uploadingCaseProgress === 100) {
      const timer = setTimeout(() => {
        if (isMounted) {
          handlePublishCase();
        }
      }, 500);

      return () => {
        isMounted = false;
        clearTimeout(timer);
      };
    }
  }, [uploadingCaseProgress]);

  return (
    <div>
      <Header />
      <section className="ir-course-preview-page-section ir-course-preview-loader-section">
        <div className="ir-course-preview-loader-container">
          <p className="ir-course-preview-loader-status-text">Status</p>
          {/* <p className="ir-course-preview-loader-converting-text">
            {uploadingCaseProgress !== 100 ? "Uploading" : "Uploaded"}
          </p> */}
          <p className="ir-course-preview-loader-converting-text">
            {isError
              ? "Failed"
              : uploadingCaseProgress !== 100
              ? "Uploading"
              : "Uploaded"}
          </p>
          <div
            className="ir-course-preview-loader-box"
            style={uploadBorderColors}
          >
            {/* <p
              className="ir-course-preview-upload-status-text"
              style={{ transform: "rotate(315deg)" }}
            >
              {uploadingCaseProgress}%
            </p> */}
            <p
              className="ir-course-preview-upload-status-text"
              style={{ transform: "rotate(315deg)" }}
            >
              {isError ? "Failed" : `${uploadingCaseProgress}%`}
            </p>
          </div>

          {isAdmin && !isError && (
            <p className="ir-course-preview-loader-time-remaining-text">
              {uploadingCaseProgress !== 100 ? "2 min more" : "Good to go"}
            </p>
          )}
          {isSecondAdmin && !isError && (
            <p className="ir-course-preview-loader-time-remaining-text">
              {uploadingCaseProgress !== 100 ? "2 min more" : "Sent for review"}
            </p>
          )}
          {isSecondAdmin && uploadingCaseProgress === 100 && (
            <p className="ir-course-preview-loader-time-remaining-text text-center w-[60%]">
              {isError
                ? `There was an error while ${
                    caseUpdateId ? "updating" : "creating"
                  } the case`
                : `To view the status of your product, please navigate to the dashboard 
         and click on 'Products Status'`}
            </p>
          )}
          {/* <div
            className="ir-ws-text-center ir-ws-default-btn-container"
            onClick={() => navigate(isAdmin ? `/case/${caseId}` : "/publisher")}
          >
            <button
              className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
              style={
                uploadingCaseProgress !== 100 ? { pointerEvents: "none" } : null
              }
              disabled={uploadingCaseProgress !== 100}
            >
              <span>{isAdmin ? "Go to cases" : "Dashboard"}</span>
            </button>
          </div> */}
          {/* <a
            href={isAdmin ? `/case/${caseUpdateId ?? caseId}` : "/publisher"}
            className="no-underline block"
          >
            <div className="ir-ws-text-center ir-ws-default-btn-container">
              <button
                className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button disabled:pointer-events-none"
                disabled={uploadingCaseProgress !== 100}
              >
                <span>{isAdmin ? "Go to cases" : "Dashboard"}</span>
              </button>
            </div>
          </a> */}
          {/* <a
            href={
              isError
                ? "/dashboard"
                : isAdmin
                ? `/case/${caseUpdateId ?? caseId}`
                : "/publisher"
            }
            className={`no-underline block ${
              uploadingCaseProgress !== 100
                ? "pointer-events-none opacity-50"
                : ""
            }`}
            onClick={(e) => {
              if (uploadingCaseProgress !== 100) {
                e.preventDefault();
              }
            }}
          >
            <div className="ir-ws-text-center ir-ws-default-btn-container">
              <button
                className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button disabled:pointer-events-none"
                disabled={uploadingCaseProgress !== 100}
              >
                <span>
                  {isError
                    ? "Dashboard"
                    : isAdmin
                    ? "Go to cases"
                    : "Dashboard"}
                </span>
              </button>
            </div>
          </a> */}
          <a
            href={
              isError
                ? isAdmin
                  ? "/admin"
                  : "/publisher"
                : isAdmin
                ? `/case/${caseUpdateId ?? caseId}`
                : "/publisher"
            }
            className={`no-underline block ${
              uploadingCaseProgress !== 100
                ? "pointer-events-none opacity-50"
                : ""
            }`}
            onClick={(e) => {
              if (uploadingCaseProgress !== 100) {
                e.preventDefault();
              }
            }}
          >
            <div className="ir-ws-text-center ir-ws-default-btn-container">
              <button
                className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button disabled:pointer-events-none"
                disabled={uploadingCaseProgress !== 100}
              >
                <span>
                  {isError
                    ? "Dashboard"
                    : isAdmin
                    ? "Go to cases"
                    : "Dashboard"}
                </span>
              </button>
            </div>
          </a>
        </div>
      </section>
    </div>
  );
};

export default UploadCasePage;
