import React from "react";
import AdminSideNavigation from "../AdminSideNavigation";
import { AdminPanelBar } from "../AdminPanelBar";

const SecondAdminDashboard = () => {
  return (
    <div className=" bg-[#F2F6FE] h-[100vh] p-[10px] lg:p-[8px] gap-[8px] lg:gap-[8px] flex xl:gap-[12px] 3xl:gap-[20px] xl:p-[12px] 3xl:p-[20px] overflow-hidden">
      <AdminSideNavigation />
      <AdminPanelBar />
    </div>
  );
};

export default SecondAdminDashboard;
