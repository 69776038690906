import React, { useState } from "react";
import axios from "../../api/axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const ForgotUsername = () => {
  const [email, setEmail] = useState("");
  const [checkMail, setCheckMail] = useState(false);
  const locationUrl = window.location.origin;
  // const navigate = useNavigate();
  const resetPassword = async (email) => {
    try {
      if (email) {
        const response = await axios.get(
          `https://backend.ir4u.info/api/v1/forgot-username?email=${email}`,
          {},
          {
            headers: {
              apphost: locationUrl,
            },
          }
        );
        if (response.status === 200) {
          // Swal.fire(
          //   "Success",
          //   "Password reset link send to your email",
          //   "success"
          // );
          setCheckMail(true);
        }
      } else {
        throw new Error("email Missing");
      }
    } catch (error) {
      // if (
      //   error.response.data.message === "Email address or username not valid."
      // )
      // {
      Swal.fire(
        "Invalid email",
        "email doesn't exists, please provide valid email",
        "warning"
      );
      // }
    }
  };
  return (
    <div className="ir-ws-sign-in-main-container">
      <div className="ir-ws-signin-inner-container ir-ws-sign-inner-container md:!max-w-[350px] lg:!max-w-[400px]  xl:!max-w-[500px] 3xl:!max-w-[600px]">
        {!checkMail && (
          <div className="ir-ws-sign-in-content-container">
            <div className="ir-ws-signin-logo-container flex items-center justify-center">
              <div className="ir-ws-text-center ir-ws-signin-logo-inner-container !m-[0px]">
                <a href="/">
                  <img
                    className="ir-ws-signin-logo-img"
                    src={require("../../assets/images/ir4u2.png")}
                    alt="logo"
                  />
                </a>
              </div>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                //   handleSubmit(username);
                resetPassword(email);
              }}
            >
              <div className="ir-ws-signup-content-inner-container">
                <h3 className="!my-[15px] lg:!my-[15px] xl:!my-[25px] 3xl:!my-[35px] !text-[16px] !leading-normal  lg:!text-[16px] xl:!text-[18px] 3xl:!text-[20px] ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                  Enter email to receive a reminder of your username in your
                  email
                </h3>
              </div>
              <div className="ir-ws-signup-form-container !mt-[25px]  xl:!mt-[25px] 3xl:!mt-[30px]">
                <div className="ir-ws-signup-content-group !my-[15px] lg:!my-[15px] xl:!my-[25px] 3xl:!my-[35px]">
                  <input
                    className="ir-ws-signup-input-field !text-[12px] lg:!text-[12px] xl:!text-[14px] 3xl:!text-[16px]"
                    type="text"
                    required="required"
                    autoComplete="off"
                    value={email}
                    name="email"
                    onChange={(e) => setEmail(e.target.value.toLowerCase())}
                  />
                  <label className="ir-ws-signup-label !text-[10px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px]">
                    Email
                  </label>
                  <span className="ir-ws-signup-highlight"></span>
                  <span className="ir-ws-signup-bar"></span>
                </div>
                <div className="ir-ws-text-center ir-ws-signup-form-submit-container">
                  <button className="!w-[170px] !p-[0px] !h-[36px] !text-[10px] lg:!h-[36px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px] xl:!h-[40px] 3xl:!h-[50px] ir-ws-app-bg ir-ws-no-border ir-color-white ir-ws-sign-submit-btn">
                    Submit
                  </button>
                </div>
              </div>
            </form>
            {/* <div className="ir-ws-signup-form-footer-container">
              <div className="ir-ws-sign-footer-lin-container">
                <p className="ir-default-color">
                  Not having an account? &nbsp;
                  <b>
                    <a href="/signup" className="ir-ws-app-color">
                      SignUp
                    </a>
                  </b>
                </p>
              </div>
            </div> */}
          </div>
        )}
        {checkMail && (
          <div className="ir-ws-sign-in-content-container">
            <div className="ir-ws-signin-logo-container">
              <div className="ir-ws-text-center ir-ws-signin-logo-inner-container flex items-center justify-center !m-[0px]">
                <a href="/">
                  <img
                    className="ir-ws-signin-logo-img"
                    src={require("../../assets/images/ir4u2.png")}
                    alt="logo"
                  />
                </a>
              </div>
            </div>
            <h3 className="!my-[15px] lg:!my-[15px] xl:!my-[25px] 3xl:!my-[35px] !text-[16px] !leading-normal  lg:!text-[16px] xl:!text-[18px] 3xl:!text-[20px] ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
              Check you email
            </h3>
            <p className="text-[10px] lg:text-[12px] xl:text-[14px] 3xl:text-[16px] text-center">
              Thanks! we have sent your username to the email registered with{" "}
              <b>{email}</b>
              <br />
              <br /> If you haven't received an email in 5 minutes, check your
              spam,&nbsp;
              <span
                className="ir-ws-app-color cursor-pointer"
                onClick={() => setCheckMail(false)}
              >
                <b>resend</b>
              </span>
            </p>

            <div className="ir-ws-signup-form-footer-container">
              <div className="ir-ws-sign-footer-lin-container">
                <p className="ir-default-color text-[10px] lg:!text-[12px] xl:!text-[14px] 3xl:!text-[16px]">
                  {/* Already having an account? &nbsp; */}
                  <b>
                    <a href="/signin" className="ir-ws-app-color ">
                      Signin
                    </a>
                  </b>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotUsername;
