import React, { useState, useEffect } from "react";
import ConfirmationModal from "../../../pages/Courses/AdminCreateCourseTab/ConfirmationModal";
import {
  addSecondaryAdmin,
  declineSecondaryAdminReq,
} from "../../../api/secondaryAdminApi";

const CollaborateNotificationList = () => {
  const [pendingUsers, setPendingUsers] = useState([]); // State to store pending users
  const [isContentVisible, setContentVisible] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null); // Track the selected user for approval
  const [declineModal, setDeclineModal] = useState(false);

  const toglgleDeclineModal = () => {
    setDeclineModal(!declineModal);
  };
  // console.log("pendingUsers :", pendingUsers);
  // Fetch pending users from the API
  useEffect(() => {
    const fetchPendingUsers = async () => {
      try {
        const response = await fetch(
          "https://backend.ir4u.info/api/v1/admin/get/pending-user",
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          // console.log("Response:", data);
          setPendingUsers(data); // Update state with fetched data
        } else {
          console.error("Failed to fetch pending users");
        }
      } catch (error) {
        console.error("Error fetching pending users:", error);
      }
    };

    fetchPendingUsers();
  }, [selectedUser, openConfirmationModal, declineModal]);

  // Toggle content visibility for a specific user
  const toggleContentVisibility = (userId) => {
    setContentVisible((prevState) => ({
      ...prevState,
      [userId]: !prevState[userId],
    }));
  };

  // Handle approve action
  const handleApprove = (user) => {
    setSelectedUser(user); // Set the selected user for approval
    setOpenConfirmationModal(true); // Open the confirmation modal
  };
  // Handle decline action
  const handleDecline = (user) => {
    setSelectedUser(user); // Set the selected user for approval
    setDeclineModal(true); // Open the confirmation modal
  };

  // Confirm approval
  const confirmApproval = async () => {
    try {
      // console.log("Approval id", selectedUser?.id);
      const data = await addSecondaryAdmin(selectedUser?.id);
      // console.log("User added as secondary admin:", data);
      setOpenConfirmationModal(false);
    } catch (error) {
      console.error("Error:", error);
      setOpenConfirmationModal(false);
    }
  };
  // Confirm approval
  const declineApproval = async () => {
    try {
      // console.log("Approval id", selectedUser?.id);
      const data = await declineSecondaryAdminReq(selectedUser?.id);
      // console.log("User added as secondary admin:", data);
      setDeclineModal(false);
    } catch (error) {
      console.error("Error:", error);
      setDeclineModal(false);
    }
  };

  return (
    <div className="ir-ws-admin-create-webinar-tab ir-ws-webinar-tb-main-container">
      {(pendingUsers?.users?.length === 0 || pendingUsers?.users === null) && (
        <div className="ir-ws-admin-webiner-list-container mt-[10px] text-center text-[10px] lg:text-[10px] xl:text-[12px] 3xl:text-[14px]">
          No Pending requests found
        </div>
      )}
      {pendingUsers?.users?.map((user) => (
        <div key={user.id} className="ir-ws-admin-webiner-list-container">
          <div
            className={`ir-ws-webinar-list-tab !mb-[0px] ${
              !isContentVisible[user.id] ? "active" : ""
            }`}
          >
            <div className="ir-ws-admin-webinar-content">
              <div className="ir-ws-admin-webinar-title-container">
                <p className="ir-ws-webinar-title-text">
                  {user.firstname} {user.lastname} wants to collaborate
                </p>
              </div>
              <div className="ir-ws-admin-list-button-container">
                <div className="flex items-center">
                  <button
                    className="ir-ws-webinar-transaction-button ir-ws-webinar-actions-button"
                    // onClick={toglgleDeclineModal}
                    onClick={() => handleDecline(user)}
                  >
                    Decline
                  </button>
                  <button
                    className="ir-ws-webinar-transaction-button ir-ws-webinar-actions-button"
                    onClick={() => handleApprove(user)}
                  >
                    Approve
                  </button>
                  <button
                    className={`ir-ws-webinar-transaction-button ir-ws-webinar-actions-button flex items-center gap-[4px] ${
                      isContentVisible[user.id]
                        ? "ir-ws-webinar-transaction-button-active"
                        : ""
                    }`}
                    onClick={() => toggleContentVisibility(user.id)}
                  >
                    Details
                    <i className="bi bi-chevron-down"></i>
                  </button>
                </div>
              </div>
            </div>

            {isContentVisible[user.id] && (
              <div className="p-[20px] rounded-b-[8px] bg-[#efefef] text-[10px] lg:text-[10px] xl:text-[12px] 3xl:text-[14px]">
                <div className="flex py-[10px]">
                  <div className="w-[10%]">
                    <p>Name</p>
                  </div>
                  <div className="w-[100%]">
                    <p>
                      {user.firstname} {user.lastname}
                    </p>
                  </div>
                </div>
                <div className="flex py-[10px]">
                  <div className="w-[10%]">
                    <p>Email</p>
                  </div>
                  <div className="w-[100%]">
                    <p>{user.email}</p>
                  </div>
                </div>
                <div className="flex py-[10px]">
                  <div className="w-[10%]">
                    <p>Username</p>
                  </div>
                  <div className="w-[100%]">
                    <p>{user.userName}</p>
                  </div>
                </div>
                <div className="flex py-[10px]">
                  <div className="w-[10%]">
                    <p>Phone no.</p>
                  </div>
                  <div className="w-[100%]">
                    <p>{user.phone}</p>
                  </div>
                </div>
                <div className="flex py-[10px]">
                  {/* <div className="w-[10%]">
                    <p>What you want to Publish</p>
                  </div> */}
                  <div className="w-[100%]">
                    <p>{user.reason}</p>
                  </div>
                </div>
                <div className="flex py-[10px]">
                  {/* <div className="w-[10%]">
                    <p>Description</p>
                  </div> */}
                  <div className="w-[100%]">
                    <p>{user.description}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}

      {openConfirmationModal && (
        <ConfirmationModal
          toggleModal={() => setOpenConfirmationModal(false)}
          confirmationFunc={confirmApproval}
          title="approve"
        />
      )}
      {declineModal && (
        <ConfirmationModal
          toggleModal={() => setDeclineModal(false)}
          confirmationFunc={declineApproval}
          title="decline"
        />
      )}
    </div>
  );
};

export default CollaborateNotificationList;
