import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const courseSlice = createSlice({
  name: "course",
  initialState: {
    sections: [
      {
        id: uuidv4(),
        sectionNumber: 0,
        content: "Introduction",
        lectures: [
          {
            id: uuidv4(),
            content: "Lecture Title",
            lectureNumber: 0,
            video: { videoName: "", videoUrl: "", file: null },
            document: { docName: "", docUrl: "", file: null },
          },
        ],
      },
    ],
    formData: {
      title: "",
      subtitle: "",
      description: "",
      basicInfo: {
        language: "",
        level: "",
        category: "",
      },
      taught: "",
      learnPointArray: [],
      courseIncludesArray: [],
      requirementsArray: [],
      completeDescription: "",
      instructorName: "",
      instructorProfile: "",
      selectedImageName: "",
      selectedVideoName: "",
      imagePreview: "",
      videoPreview: "",
      videoprevUrl: "",
      imageprevUrl: "",
      coursePrice: "",
      totalDuration: "",
    },
  },
  reducers: {
    resetFormData: (state) => {
      state.formData = {
        title: "",
        subtitle: "",
        description: "",
        basicInfo: {
          language: "",
          level: "",
          category: "",
        },
        taught: "",
        learnPointArray: [],
        courseIncludesArray: [],
        requirementsArray: [],
        completeDescription: "",
        instructorName: "",
        instructorProfile: "",
        selectedImageName: "",
        selectedVideoName: "",
        imagePreview: "",
        videoPreview: null,
        videoprevUrl: "",
        coursePrice: "",
      };
      state.sections = [
        {
          id: uuidv4(),
          sectionNumber: 0,
          content: "Introduction",
          lectures: [
            {
              id: uuidv4(),
              content: "Lecture title",
              lectureNumber: 0,
              video: { videoName: "", videoUrl: "", file: null, duration: "" },
              document: { docName: "", docUrl: "", file: null },
            },
          ],
        },
      ];
    },
    removeSection: (state) => {
      state.sections = [];
    },

    addAllSections: (state, action) => {
      const emptySectionIndex = state.sections.findIndex(
        (section) => section.lectures.length === 0
      );
      // if (emptySectionIndex === -1) {
      //   state.sections.splice(emptySectionIndex, 1);
      // }

      state.sections.push(action.payload);
    },
    addSection: (state, action) => {
      state.sections.push(action.payload);
    },
    addVideoPreview: (state, action) => {
      const { videoPreview } = action.payload;
      state.formData.videoPreview = videoPreview;
    },
    deleteSection: (state, action) => {
      const updatedSections = state.sections.filter(
        (section) => section.id !== action.payload
      );
      state.sections = updatedSections.map((section, index) => ({
        ...section,
        sectionNumber: index,
      }));
    },
    updateTotalDuration: (state, action) => {
      const { totalDuration } = action.payload;
      // console.log(totalDuration);
      state.formData.totalDuration = totalDuration;
    },
    addLecture: (state, action) => {
      const { sectionId, lecture } = action.payload;
      const section = state.sections.find(
        (section) => section.id === sectionId
      );
      if (section) {
        section.lectures.push(lecture);
      }
    },
    deleteLecture: (state, action) => {
      const { sectionId, lectureId } = action.payload;
      const sectionIndex = state.sections.findIndex(
        (section) => section.id === sectionId
      );
      if (sectionIndex !== -1) {
        state.sections[sectionIndex].lectures = state.sections[
          sectionIndex
        ].lectures?.filter((lecture) => lecture.id !== lectureId);
        state.sections[sectionIndex].lectures.forEach((lecture, index) => {
          lecture.lectureNumber = index;
        });
      }
    },
    updateVideoPreview: (state, action) => {
      const newVideoPreview = action.payload;
      // console.log("new url is setting ", newVideoPreview);
      state.formData.videoprevUrl = newVideoPreview;
    },
    updateImagePreview: (state, action) => {
      const newImagePreview = action.payload;
      state.formData.imageprevUrl = newImagePreview;
    },
    updateSectionContent: (state, action) => {
      const { sectionId, content } = action.payload;
      const sectionIndex = state.sections.findIndex(
        (section) => section.id === sectionId
      );

      if (sectionIndex !== -1) {
        state.sections[sectionIndex].content = content;
      }
    },
    updateLectureContent: (state, action) => {
      const { sectionId, lectureId, content } = action.payload;
      const sectionIndex = state.sections.findIndex(
        (section) => section.id === sectionId
      );

      if (sectionIndex !== -1) {
        const lectureIndex = state.sections[sectionIndex].lectures.findIndex(
          (lecture) => lecture.id === lectureId
        );

        if (lectureIndex !== -1) {
          state.sections[sectionIndex].lectures[lectureIndex].content = content;
        }
      }
    },

    addLectureVideo: (state, action) => {
      const { sectionId, lectureId, videoUrl, videoName, file } =
        action.payload;
      const sectionIndex = state.sections.findIndex(
        (section) => section.id === sectionId
      );
      if (sectionIndex !== -1) {
        const lectureIndex = state.sections[sectionIndex].lectures.findIndex(
          (lecture) => lecture.id === lectureId
        );
        if (lectureIndex !== -1) {
          state.sections[sectionIndex].lectures[lectureIndex].video.videoName =
            videoName;
          state.sections[sectionIndex].lectures[lectureIndex].video.videoUrl =
            videoUrl;
          state.sections[sectionIndex].lectures[lectureIndex].video.file = file;
        }
      }
    },
    updatelectureVideo: (state, action) => {
      const { sectionId, lectureId, videoUrl } = action.payload;
      const sectionIndex = state.sections.findIndex(
        (section) => section.id === sectionId
      );
      if (sectionIndex !== -1) {
        const lectureIndex = state.sections[sectionIndex].lectures.findIndex(
          (lecture) => lecture.id === lectureId
        );
        if (lectureIndex !== -1) {
          state.sections[sectionIndex].lectures[lectureIndex].video.videoUrl =
            videoUrl;
        }
      }
    },

    updateLectureVideoDuration: (state, action) => {
      const { sectionId, lectureId, duration } = action.payload;
      // console.log('this is calling', duration);
      const sectionIndex = state.sections.findIndex(
        (section) => section.id === sectionId
      );
      if (sectionIndex !== -1) {
        const lectureIndex = state.sections[sectionIndex].lectures.findIndex(
          (lecture) => lecture.id === lectureId
        );
        if (lectureIndex !== -1) {
          state.sections[sectionIndex].lectures[lectureIndex].video.duration =
            duration;
        }
      }
    },

    addLectureDoc: (state, action) => {
      const { sectionId, lectureId, docUrl, docName, file } = action.payload;
      const sectionIndex = state.sections.findIndex(
        (section) => section.id === sectionId
      );
      if (sectionIndex !== -1) {
        const lectureIndex = state.sections[sectionIndex].lectures.findIndex(
          (lecture) => lecture.id === lectureId
        );
        if (lectureIndex !== -1) {
          state.sections[sectionIndex].lectures[lectureIndex].document.docName =
            docName;
          state.sections[sectionIndex].lectures[lectureIndex].document.docUrl =
            docUrl;
          state.sections[sectionIndex].lectures[lectureIndex].document.file =
            file;
        }
      }
    },
    addFormData: (state, action) => {
      const newData = action.payload;
      state.formData = { ...state.formData, ...newData };
    },

    deleteSectionSelectedVideo: (state, action) => {
      const { sectionId, lectureId, videoName } = action.payload;
      // console.log(sectionId, lectureId, videoName);
      const sectionIndex = state.sections.findIndex(
        (section) => section.id === sectionId
      );
      // console.log(sectionIndex);
      if (sectionIndex !== -1) {
        const lectureIndex = state.sections[sectionIndex].lectures.findIndex(
          (lecture) => lecture.id === lectureId
        );
        // console.log(lectureIndex);
        if (lectureIndex !== -1) {
          const lecture = state.sections[sectionIndex].lectures[lectureIndex];

          // Check if lecture has a video and if its name matches
          if (lecture.video && lecture.video.videoName === videoName) {
            // Set video fields to empty strings
            lecture.video.videoName = "";
            lecture.video.url = "";

            // Alternatively, you can remove the video object completely
            // delete lecture.video;
          }
        }
      }
    },
    deleteSectionSelectedDoc: (state, action) => {
      const { sectionId, lectureId, docName } = action.payload;
      // console.log(sectionId, lectureId, docName);
      const sectionIndex = state.sections.findIndex(
        (section) => section.id === sectionId
      );
      if (sectionIndex !== -1) {
        const lectureIndex = state.sections[sectionIndex].lectures.findIndex(
          (lecture) => lecture.id === lectureId
        );
        if (lectureIndex !== -1) {
          const lecture = state.sections[sectionIndex].lectures[lectureIndex];

          if (lecture.document && lecture.document.docName === docName) {
            lecture.document.docName = "";
            lecture.document.url = "";
          }
        }
      }
    },
    updateLectureDocUrl: (state, action) => {
      const { sectionId, lectureId, docUrl } = action.payload;
      const sectionIndex = state.sections.findIndex(
        (section) => section.id === sectionId
      );
      if (sectionIndex !== -1) {
        const lectureIndex = state.sections[sectionIndex].lectures.findIndex(
          (lecture) => lecture.id === lectureId
        );
        if (lectureIndex !== -1) {
          state.sections[sectionIndex].lectures[lectureIndex].document.docUrl =
            docUrl;
        }
      }
    },

    // addDraftData: (state, action) => {
    //   const draftData = action.payload;
    //   state.formData = { ...state.formData, ...draftData.formData };
    //   state.sections = draftData.sections;
    // },
    addDraftData: (state, action) => {
      const merge = (target, source) => {
        for (const key in source) {
          if (source[key] && typeof source[key] === "object") {
            if (!target[key]) {
              target[key] = {};
            }
            merge(target[key], source[key]);
          } else {
            target[key] = source[key];
          }
        }
      };
      merge(state.formData, action.payload.formData);
    },
  },
});

export const {
  addSection,
  addAllSections,
  deleteSection,
  addLecture,
  deleteLecture,
  updateLectureContent,
  updateSectionContent,
  addLectureVideo,
  addLectureDoc,
  addFormData,
  deleteSectionSelectedVideo,
  deleteSectionSelectedDoc,
  updatelectureVideo,
  resetFormData,
  updateLectureDocUrl,
  updateVideoPreview,
  updateImagePreview,
  updateLectureVideoDuration,
  updateTotalDuration,
  removeSection,
  addDraftData,
  addVideoPreview,
} = courseSlice.actions;
export default courseSlice.reducer;
