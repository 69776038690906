import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
} from "react-router-dom";
import { MeetingProvider } from "amazon-chime-sdk-component-library-react";
import "./App.scss";
import Home from "./pages/Home/Home";
import Webinar from "./pages/Webinar/Webinar";
import "swiper/css";
import Cases from "./pages/Cases/Cases";
import Courses from "./pages/Courses/Courses";
import Aboutus from "./pages/Aboutus/Aboutus";
import SignIn from "./pages/SignIn/SignIn";
import Register from "./pages/Register/Register";
import Privacy from "./pages/Privacy/Privacy";
import Terms from "./pages/Privacy/Terms";
import Account from "./pages/Account/Account";
import ChatRoom from "./pages/ChatRoom/ChatRoom";
import { StoreProvider } from "./store/store";
import { PaymentProvider } from "./context/PaymentContext";
import { io } from "socket.io-client";
import { AuthProvider } from "./context/AuthProvider";
import Socket from "./socket";
import { BaseURL as ENDPOINT } from "./api/axios";
import MeetingTest from "./pages/MeetingTest/MeetingTest";
import WebinarAdmin from "./pages/Admin/Webinar/WebinarAdmin";
import { ModalProvider } from "./components/WebinarAdminCreateWebinarTab/ModalContext";
import UserMeetingTest from "./pages/MeetingTest/UserMeetingTest";
import ChatAdminDashboard from "./components/Chat/ChatAdmin/ChatAdminDashboard/ChatAdminDashboard";
import SuccessPayment from "./components/Chat/PaymentStatusResponses/SuccessPayment";
import EmailVerification from "./pages/Register/EmailVerification";
import ForgotPassword from "./pages/SignIn/ForgotPassword";
import { ResetPassword } from "./pages/SignIn/ResetPassword";
import WebinarPaymentSuccess from "./components/WebinarPaymentStatus/WebinarPaymentSuccess";
import WebinarPaymentFailure from "./components/WebinarPaymentStatus/WebinarPaymentFailure";
import WebinarPaymentProgress from "./components/WebinarPaymentStatus/WebinarPaymentProgress";
import MyProfile from "./pages/Account/MyProfile";
import { useSelector } from "react-redux";
import CoursePreviewPage from "./pages/Courses/CoursePreview/CoursePreviewPage";
import SingleCourse from "./pages/Courses/SingleCourse/SingleCourse";
import CasePreviewPage from "./pages/AdminCases/CasePreviewPage/CasePreviewPage";
import SearchedCourses from "./components/CoursesBanner/SearchedCourses";
import CategoryCourses from "./components/CoursesBanner/CategoryCourses";
import SingleCase from "./pages/Cases/SingleCase/SingleCase";
import CategoryCases from "./components/CategoryCases/CategoryCases";
import ForgotUsername from "./pages/SignIn/ForgotUsername";
import DeleteAccountPage from "./pages/Account/DeleteAccountPage ";
import { AdminDashboard } from "./components/AdminDashboard/AdminDashboard";
import PublisherDashboard from "./pages/PublisherDashboard/PublisherDashboard";
import SecondAdminForm from "./components/AdminDashboard/SecondAdmin/SecondAdminForm";
import SecondAdminDashboard from "./components/AdminDashboard/SecondAdmin/SecondAdminDashboard";
import SinglWebinarData from "./components/WebinarAdminWebinarListTab/SinglWebinarData";
import useFetchAllProducts from "./hooks/useFetchAllProducts";
import CollaboratePage from "./components/AdminDashboard/SecondAdmin/CollaboratePage";

const App = () => {
  // React.useEffect(() => {
  //   const handleContextMenu = (event) => {
  //     event.preventDefault();
  //   };

  //   const handleKeyDown = (event) => {
  //     if (
  //       event.keyCode === 123 ||
  //       (event.ctrlKey && event.shiftKey && event.keyCode === 73) ||
  //       (event.ctrlKey && event.shiftKey && event.keyCode === 74)
  //     ) {
  //       event.preventDefault();
  //     }
  //   };

  //   document.addEventListener('contextmenu', handleContextMenu);
  //   document.addEventListener('keydown', handleKeyDown);

  //   return () => {
  //     document.removeEventListener('contextmenu', handleContextMenu);
  //     document.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, []);
  //   const socketio = io(ENDPOINT, {
  //     autoConnect: false,
  //     reconnectionDelayMax: 10000,
  //     auth: {
  //       token: "Bearer " + localStorage.getItem("jwtTokenApi2"),
  //     },
  //     query: {
  //       "my-key": "my-value",
  //     },
  //   });

  const userProfile = JSON.parse(localStorage.getItem("userProfile"));
  const { allProducts, combinedProducts, loading, error } =
    useFetchAllProducts();

  let role;
  if (userProfile) {
    if (userProfile?.includes("admin")) {
      role = "admin";
    } else if (userProfile?.includes("publisher")) {
      role = "publisher";
    }
  }

  const isPublisher = role === "publisher";
  // const isPublisher = true;
  // const isAdmin =
  //   localStorage.getItem("loggedInUsername") === "admin" ||
  //   localStorage.getItem("loggedInEmail") === "admin@admin.com" ||
  //   role === "admin" ||
  //   "publisher";

  const isAdmin =
    localStorage.getItem("loggedInUsername") === "admin" ||
    localStorage.getItem("loggedInEmail") === "admin@admin.com";

  // const formData = useSelector((state) => state.course.formData);
  // // useEffect(() => {
  // //   console.log("the course form data is", formData);
  // // }, [formData]);
  // console.log("admin", isAdmin);

  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/firebase-messaging-sw.js")
      .then((registration) => {
        // console.log("Service Worker registered successfully:", registration);
      })
      .catch((error) => {
        // console.error("Service Worker registration failed:", error);
        return;
      });
  }

  const loggedIn = localStorage.getItem("isLoggedIn") === "true";
  return (
    <div className="ir-page">
      <Router>
        <MeetingProvider>
          <ModalProvider>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/forgot-username" element={<ForgotUsername />} />
              <Route
                path="/api/v1/reset/password/:resetLink"
                element={<ResetPassword />}
              />
              <Route path="/signup" element={<Register />} />
              <Route path="/webinar" element={<Webinar />} />
              <Route path="/verify" element={<EmailVerification />} />

              <Route
                path="/chat-room"
                element={
                  <StoreProvider>
                    <AuthProvider>
                      <PaymentProvider>
                        {/* <Socket /> */}
                        <ChatRoom />
                      </PaymentProvider>
                    </AuthProvider>
                  </StoreProvider>
                }
              />

              <Route
                path="/chat-admin"
                element={
                  <StoreProvider>
                    <AuthProvider>
                      <PaymentProvider>
                        {/* <ChatAdminDashboard /> */}
                        {/* <Socket  /> */}
                        <ChatAdminDashboard />
                      </PaymentProvider>
                    </AuthProvider>
                  </StoreProvider>
                }
              />
              <Route
                path="/chat-room/:id"
                element={
                  <StoreProvider>
                    <AuthProvider>
                      <PaymentProvider>
                        {/* <ChatRoom /> */}
                        <Socket />
                        <ChatRoom />
                      </PaymentProvider>
                    </AuthProvider>
                  </StoreProvider>
                }
              />

              <Route path="/cases" element={<Cases />} />
              <Route path="/courses" element={<Courses />} />
              <Route
                path="/courses/search/:query"
                element={<SearchedCourses />}
              />
              <Route
                path="/courses/category/:category"
                element={<CategoryCourses />}
              />
              <Route
                path="/cases/category/:category"
                element={<CategoryCases />}
              />
              <Route path="/aboutus" element={<Aboutus />} />
              <Route
                path="/myaccount"
                element={
                  <StoreProvider>
                    <AuthProvider>
                      <Account />
                    </AuthProvider>
                  </StoreProvider>
                }
              />

              <Route path="/collaborate" element={<SecondAdminForm />} />
              <Route path="/collaborate-policy" element={<CollaboratePage />} />

              {isPublisher ? (
                <Route
                  path="/publisher"
                  element={
                    <StoreProvider>
                      <AuthProvider>
                        {/* <PublisherDashboard /> */}
                        <SecondAdminDashboard />
                      </AuthProvider>
                    </StoreProvider>
                  }
                />
              ) : (
                <Route path="/publisher" element={<Navigate to="/" />} />
              )}
              {!isAdmin ? (
                <Route
                  path="/myprofile"
                  element={
                    <StoreProvider>
                      <AuthProvider>
                        <MyProfile />
                      </AuthProvider>
                    </StoreProvider>
                  }
                />
              ) : (
                <Route path="/myprofile" element={<Navigate to="/" />} />
              )}
              {!isAdmin ? (
                <Route path="/delete-account" element={<DeleteAccountPage />} />
              ) : (
                <Route path="/myprofile" element={<Navigate to="/" />} />
              )}

              <Route path="/privacy-policy" element={<Privacy />} />
              <Route path="/terms-conditions" element={<Terms />} />
              <Route
                path="/api/v1/webinar/join/:joinUrl"
                element={<MeetingTest />}
              />
              <Route
                path="/api/v1/webinar/attendee/join/:joinUrl"
                element={<UserMeetingTest />}
              />
              {/* <Route
                path="/dashboard"
                element={
                  <StoreProvider>
                    <AuthProvider>
                      <AdminDashboard />
                    </AuthProvider>
                  </StoreProvider>
                }
              /> */}

              {isAdmin ? (
                <Route
                  path="/admin"
                  element={
                    <StoreProvider>
                      <AuthProvider>
                        <AdminDashboard />
                      </AuthProvider>
                    </StoreProvider>
                  }
                />
              ) : (
                <Route
                  path="/admin"
                  element={<SignIn isAdminRedirect={true} />}
                />
              )}

              {/* <Route path="/secondAdmin" element={<SecondAdminDashboard />} /> */}
              {/* 
              {isAdmin ? (
                <Route
                  path="/admin"
                  element={
                    <StoreProvider>
                      <AuthProvider>
                        <WebinarAdmin />
                      </AuthProvider>
                    </StoreProvider>
                  }
                />
              ) : (
                <Route
                  path="/admin"
                  element={<SignIn isAdminRedirect={true} />}
                />
              )} */}
              <Route path="/meeting" element={<MeetingTest />} />
              <Route path="/course/:id" element={<SingleCourse />} />
              <Route path="/case/:caseId" element={<SingleCase />} />
              <Route path="/webinar/:id" element={<SinglWebinarData />} />
              <Route path="*" element={<Navigate to="/" />} />
              <Route exact path="/status" element={<SuccessPayment />}></Route>
              <Route path="/success" element={<WebinarPaymentSuccess />} />
              <Route path="/progress" element={<WebinarPaymentProgress />} />
              <Route path="/cancel" element={<WebinarPaymentFailure />} />
              <Route path="/preview" element={<CoursePreviewPage />} />
              <Route path="/case/preview" element={<CasePreviewPage />} />
            </Routes>
          </ModalProvider>
        </MeetingProvider>
      </Router>
    </div>
  );
};

export default App;
