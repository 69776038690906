import React, { useState } from "react";
import ConfirmationModal from "../../../pages/Courses/AdminCreateCourseTab/ConfirmationModal";

const SecondAdminList = () => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [visibleDetails, setVisibleDetails] = useState({});
  const [activeProductList, setActiveProductList] = useState("webinars");
  const userData = [
    {
      email: "john.doe@example.com",
      username: "johndoe",
      firstname: "John",
      lastname: "Doe",
    },
    {
      email: "jane.smith@example.com",
      username: "janesmith",
      firstname: "Jane",
      lastname: "Smith",
    },
    {
      email: "michael.brown@example.com",
      username: "michaelbrown",
      firstname: "Michael",
      lastname: "Brown",
    },
    {
      email: "emily.davis@example.com",
      username: "emilydavis",
      firstname: "Emily",
      lastname: "Davis",
    },
    {
      email: "david.wilson@example.com",
      username: "davidwilson",
      firstname: "David",
      lastname: "Wilson",
    },
    {
      email: "sarah.miller@example.com",
      username: "sarahmiller",
      firstname: "Sarah",
      lastname: "Miller",
    },
    {
      email: "chris.moore@example.com",
      username: "chrismoore",
      firstname: "Chris",
      lastname: "Moore",
    },
    {
      email: "jessica.lee@example.com",
      username: "jessicalee",
      firstname: "Jessica",
      lastname: "Lee",
    },
    {
      email: "daniel.anderson@example.com",
      username: "danielanderson",
      firstname: "Daniel",
      lastname: "Anderson",
    },
    {
      email: "olivia.thomas@example.com",
      username: "oliviathomas",
      firstname: "Olivia",
      lastname: "Thomas",
    },
  ];

  const toggleContentVisibility = (index) => {
    setVisibleDetails((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle only the clicked index
    }));
  };
  const accordianListButton = ["Email", "Username", "Firstname", "Lastname"];

  const webinars = [
    { title: "Webinar on AI Trends", status: "approved" },
    { title: "Cybersecurity Basics", status: "in Review" },
    { title: "Frontend Development with React", status: "declined" },
    { title: "Data Science for Beginners", status: "deleted" },
    { title: "Marketing Strategies 2025", status: "approved" },
    { title: "Blockchain and Cryptocurrency", status: "in Review" },
    { title: "Cloud Computing Essentials", status: "declined" },
    { title: "UI/UX Design Principles", status: "deleted" },
    { title: "Python for Machine Learning", status: "approved" },
    { title: "Leadership in Tech", status: "in Review" },
  ];

  const courses = [
    { title: "Full-Stack Web Development", status: "declined" },
    { title: "Introduction to Data Science", status: "deleted" },
    { title: "Mastering Python Programming", status: "declined" },
    { title: "Digital Marketing Fundamentals", status: "deleted" },
    { title: "Cloud Computing with AWS", status: "approved" },
    { title: "Cybersecurity Best Practices", status: "in Review" },
    { title: "Graphic Design with Photoshop", status: "approved" },
    { title: "Mobile App Development with Flutter", status: "in Review" },
    { title: "Machine Learning with TensorFlow", status: "approved" },
    { title: "Business Analytics with Excel", status: "in Review" },
  ];

  const cases = [
    { title: "Intellectual Property Rights", status: "in Review" },
    { title: "Cybercrime Investigation", status: "in Review" },
    { title: "Corporate Fraud Analysis", status: "declined" },
    { title: "Consumer Protection Case", status: "deleted" },
    { title: "Employment Law Dispute", status: "approved" },
    { title: "Real Estate Legal Issues", status: "approved" },
    { title: "Financial Fraud Examination", status: "declined" },
    { title: "Medical Malpractice Case", status: "deleted" },
    { title: "Contract Breach Litigation", status: "approved" },
    { title: "Tax Evasion Investigation", status: "deleted" },
  ];

  const handleTabClick = (product) => {
    setActiveProductList(product);
  };

  const getActiveList = () => {
    switch (activeProductList) {
      case "webinars":
        return webinars;
      case "cases":
        return cases;
      case "courses":
        return courses;
      default:
        return webinars;
    }
  };
  return (
    <div className="ir-ws-admin-create-webinar-tab ir-ws-webinar-tb-main-container ir-ws-admin-create-course-container ">
      {userData.map((item, index) => (
        <div className={`ir-ws-webinar-list-tab `} key={index}>
          <div className="ir-ws-admin-webinar-content">
            <div className="ir-ws-admin-webinar-title-container">
              <p className="ir-ws-webinar-title-text">{item.username}</p>
            </div>
            <div className="ir-ws-admin-list-button-container">
              <button
                className="ir-ws-webinar-actions-button"
                onClick={() => {
                  setShowConfirmationModal(true);
                }}
              >
                Remove
              </button>

              <button
                className={`ir-ws-webinar-transaction-button ir-ws-webinar-actions-button flex items-center gap-[4px] ${
                  visibleDetails[index]
                    ? "ir-ws-webinar-transaction-button-active"
                    : ""
                }`}
                onClick={() => toggleContentVisibility(index)}
              >
                User Details
                <i className="bi bi-chevron-down"></i>
              </button>
            </div>
          </div>
          {visibleDetails[index] && (
            <div>
              <ul className="ir-ws-webinar-list-accordian-tab ir-ws-webinar-admin-user-list">
                {accordianListButton.map((item, index) => (
                  <li className="ir-ws-list-item-box" key={index}>
                    {item}
                  </li>
                ))}
              </ul>
              <div className="ir-ws-webinar-admin-userlist-detail">
                <ul className="ir-ws-webinar-list-data " key={index}>
                  <li>{item.email}</li>
                  <li>{item.username}</li>
                  <li>{item.firstname}</li>
                  <li>{item.lastname}</li>
                </ul>
              </div>
              <div className="flex items-center justify-center text-[9px] h-[32px]  xl:text-[12px] 3xl:text-[14px] xl:h-[40px] 3xl:h-[50px]">
                <div className="w-[33%] ">
                  <p
                    className={`text-center font-[600] cursor-pointer  ${
                      activeProductList === "webinars" ? "text-[#6842ef]" : ""
                    }`}
                    onClick={() => handleTabClick("webinars")}
                  >
                    Webinar
                  </p>
                </div>
                <div className="w-[33%] ">
                  <p
                    className={`text-center font-[600] cursor-pointer ${
                      activeProductList === "cases" ? "text-[#6842ef]" : ""
                    }`}
                    onClick={() => handleTabClick("cases")}
                  >
                    Cases
                  </p>
                </div>
                <div className="w-[33%] ">
                  <p
                    className={`text-center font-[600] cursor-pointer ${
                      activeProductList === "courses" ? "text-[#6842ef]" : ""
                    }`}
                    onClick={() => handleTabClick("courses")}
                  >
                    Courses
                  </p>
                </div>
              </div>
              <div>
                <div className="flex items-center justify-center text-[9px] h-[32px] xl:text-[12px] 3xl:text-[14px] xl:h-[40px] 3xl:h-[50px] bg-[#bfbfbf]">
                  <div className="w-[50%]">
                    <p className="text-center ">Title</p>
                  </div>
                  <div className="w-[50%]">
                    <p className="text-center ">Link</p>
                  </div>
                </div>
                <div className="xl:max-h-[150px] lg:max-h-[120px] overflow-auto 3xl:max-h-[200px]">
                  {getActiveList().map((item, index) => (
                    <div className="flex py-[4px] items-center justify-center text-[8px] xl:text-[12px] 3xl:text-[14px] xl:h-[40px] 3xl:h-[50px] bg-[#efefef] ">
                      <div className="w-[50%]">
                        <p className="text-center ">{item.title}</p>
                      </div>
                      <div className="w-[50%]">
                        <p className="text-center cursor-pointer ">Preview</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
      {showConfirmationModal && (
        <ConfirmationModal
          title="remove"
          toggleModal={() => setShowConfirmationModal(false)}
        />
      )}
    </div>
  );
};

export default SecondAdminList;
