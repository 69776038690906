import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { v4 as uuidv4 } from "uuid";
import ConfirmationModal from "../../Courses/AdminCreateCourseTab/ConfirmationModal";
import { useDispatch } from "react-redux";
import { addRapidTime } from "../CaseSlice/Caseslice";

const RapidQuestion = ({
  onQuestionDataChange,
  rapidQuestionData,
  onDelete,
  delId,
  targetSlide,
  setRapidQuestions,
  rapidTimeData,
  slideNumber,
}) => {
  const [rapidTime, setRapidTime] = useState(rapidTimeData || null);
  const [timeInputError, setTimeInputError] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [questionId, setQuestionId] = useState(null);
  const [rapidQuestions, setRapidQuestionsData] = useState([
    { id: uuidv4(), content: "", answer: "" },
  ]);
  const modules = {
    toolbar: [["bold", "italic"]],
  };

  const dispatch = useDispatch();
  // useEffect(() => {
  //   setRapidQuestions(
  //     Array.isArray(rapidQuestionData.data) && rapidQuestionData.length > 0
  //       ? rapidQuestionData
  //       : [{ id: uuidv4(), content: "", answer: "" }]
  //   );
  // }, []);
  useEffect(() => {
    setRapidQuestionsData(
      rapidQuestionData?.data.length > 0
        ? rapidQuestionData.data
        : [{ id: uuidv4(), content: "", answer: "" }]
    );
  }, []);

  // const handleDeleteClick = (id) => {
  //   setRapidQuestions(rapidQuestions.filter((question) => question.id !== id));
  //   setQuestionId(null);
  //   toggleDeleteModal();
  // };
  useEffect(() => {
    dispatch(addRapidTime({ slideNumber: slideNumber, rapidTime: rapidTime }));
  }, [rapidTime]);

  const handleDeleteClick = (id) => {
    const updatedQuestions = rapidQuestions.filter(
      (question) => question.id !== id
    );

    setRapidQuestionsData(updatedQuestions);
    onQuestionDataChange(updatedQuestions);

    setShowDeleteModal(false);
  };

  // const handleAddQuestion = () => {
  //   const newQuestion = {
  //     id: uuidv4(),
  //     content: "",
  //     answer: "",
  //   };
  //   setRapidQuestions([...rapidQuestions, newQuestion]);
  // };
  const handleAddQuestion = () => {
    if (!rapidTime) {
      setTimeInputError(true);
      return;
    }
    const newQuestion = {
      id: uuidv4(),
      content: "",
      answer: "",
    };
    const updatedQuestions = [...rapidQuestions, newQuestion];
    setRapidQuestionsData(updatedQuestions);
    onQuestionDataChange(updatedQuestions);
  };

  // Function to handle data change for a single question
  const handleQuestionDataChange = (id, content, answer) => {
    const updatedQuestions = rapidQuestions.map((question) =>
      question.id === id ? { ...question, content, answer } : question
    );
    setRapidQuestionsData(updatedQuestions);
    onQuestionDataChange(updatedQuestions);
  };

  const handleChange = (e) => {
    let { value } = e.target;
    value = value.replace(/\D/g, "");
    if (value.length > 2) {
      value = value.slice(0, 2) + " : " + value.slice(2);
    }
    setRapidTime(value);
  };
  const toggleDeleteModal = (id) => {
    setShowDeleteModal(!showDeleteModal);
    setQuestionId(id);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace") {
      setRapidTime((prevValue) => {
        if (prevValue.slice(-1) === " ") {
          return prevValue.slice(0, -3);
        } else {
          return prevValue.slice(0, -1);
        }
      });
    }
  };

  return (
    <div className="ir-ws-multiple-choice-question-container">
      {rapidQuestions?.map((question, index) => (
        <>
          <div className="ir-ws-multiple-choice-question-box" key={question.id}>
            <ReactQuill
              placeholder="Type Rapid Questions"
              theme="snow"
              modules={modules}
              value={question.content}
              onChange={(content) =>
                handleQuestionDataChange(question.id, content, question.answer)
              }
            />

            <svg
              height="30"
              viewBox="0 0 48 48"
              width="30"
              xmlns="http://www.w3.org/2000/svg"
              className="ir-ws-creat-case-icon ir-ws-creat-case-icon-margin"
              onClick={() => toggleDeleteModal(question.id)}
            >
              <path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z" />
              <path d="M0 0h48v48H0z" fill="none" />
            </svg>
          </div>
          <div className="ir-ws-create-course-mcq-option-container">
            <input
              type="text"
              placeholder="Type correct answer"
              className="ir-ws-create-course-input-tag"
              value={question.answer}
              onChange={(e) =>
                handleQuestionDataChange(
                  question.id,
                  question.content,
                  e.target.value
                )
              }
            />
          </div>
          {index === rapidQuestions.length - 1 && (
            <div className="ir-ws-create-course-mcq-option-container ir-ws-rapid-question-time-add-btn-container ">
              <div className="ir-ws-flex ir-ws-align-center ir-ws-create-case-set-time-container">
                <input
                  placeholder="00 : 00"
                  // className="ir-ws-create-course-input-tag"
                  className={`${
                    timeInputError ? "ir-ws-mandatory-filed-border" : ""
                  } ir-ws-create-case-input`}
                  style={{ width: "42%" }}
                  value={rapidTime}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  maxLength={7}
                />
                <p>Set Time</p>
              </div>
              {/* <div
                className="ir-ws-add-question-button"
                onClick={handleAddQuestion}
              >
                <p className="ir-ws-add-question-rapid-btn">+ Add Question</p>
              </div> */}

              <div
                className="ir-ws-text-center ir-ws-default-btn-container ir-ws-create-case-add-option-container"
                onClick={handleAddQuestion}
              >
                <button className=" !p-[0px] lg:!h-[36px] lg:!text-[10px] xl:!text-[12px] 3xl:!text-[14px] xl:!h-[40px] 3xl:!h-[50px] ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button ir-ws-create-case-add-option-btn">
                  <span>+ Add Options</span>
                </button>
              </div>
            </div>
          )}
        </>
      ))}
      {showDeleteModal && (
        <ConfirmationModal
          toggleModal={toggleDeleteModal}
          confirmationFunc={() => handleDeleteClick(questionId)}
          title="delete"
        />
      )}
    </div>
  );
};

export default RapidQuestion;
