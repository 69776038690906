import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "../../../../api/axios"; // Ensure this path is correct

const AdvertiseChat = ({ handleAdTabModal, advertiseChatId }) => {
  const [advertiseData, setAdvertiseData] = useState([
    { id: 1, webImage: null, mobileImage: null, popupImage: null, title: "" },
    { id: 2, webImage: null, mobileImage: null, popupImage: null, title: "" },
    { id: 3, webImage: null, mobileImage: null, popupImage: null, title: "" },
    { id: 4, webImage: null, mobileImage: null, popupImage: null, title: "" },
    { id: 5, webImage: null, mobileImage: null, popupImage: null, title: "" },
  ]);

  const [showImage, setShowImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [currentImageType, setCurrentImageType] = useState(null);
  const [loading, setLoading] = useState(false);

  const baseurl = " https://staging-chats.ir4u.info/api/v1";

  const handleTextChange = (index, value) => {
    setAdvertiseData((prevData) =>
      prevData.map((ad, i) => (i === index ? { ...ad, title: value } : ad))
    );
  };

  const handleImageUpload = (index, type, event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setShowImage(reader.result);
        setCurrentIndex(index);
        setCurrentImageType(type);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = () => {
    setAdvertiseData((prevData) =>
      prevData.map((ad, index) =>
        index === currentIndex ? { ...ad, [currentImageType]: showImage } : ad
      )
    );
    setShowImage(null);
  };

  const handleDeleteImage = (index, type) => {
    setAdvertiseData((prevData) =>
      prevData.map((ad, i) => (i === index ? { ...ad, [type]: null } : ad))
    );
  };

  const uploadMediaFile = async (mediaFile) => {
    const fd = new FormData();
    const uniqueName = `image_${Date.now()}_${Math.floor(
      Math.random() * 1000000
    )}.jpg`;

    fd.append("uploadFile", mediaFile, uniqueName);
    fd.append("location", "home");

    try {
      const response = await axios({
        url: "uploadfile",
        method: "post",
        data: fd,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
        },
      });

      if (response.data.data && response.data.data.length > 0) {
        return response.data.data[0];
      }
    } catch (error) {
      console.error("Error uploading media file:", error);
    }

    return null;
  };

  // const handlePublishAll = async () => {
  //   // Filter ads that have at least one field filled
  //   const adsToPublish = advertiseData.filter(
  //     (ad) =>
  //       ad.webImage || ad.mobileImage || ad.popupImage || ad.title.trim() !== ""
  //   );

  //   // Check if all ads to publish have complete data
  //   const isValid = adsToPublish.every(
  //     (ad) =>
  //       ad.webImage && ad.mobileImage && ad.popupImage && ad.title.trim() !== ""
  //   );
  //   if (!isValid) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Incomplete Data",
  //       text: "Please ensure that a ad must have three images and a title.",
  //     });
  //     return; // Stop execution if validation fails
  //   }

  //   setLoading(true); // Start loading

  //   // Show "Please wait" SweetAlert2 notification
  //   Swal.fire({
  //     title: "Please wait",
  //     text: "Your ad is being created...",
  //     allowOutsideClick: false,
  //     didOpen: () => {
  //       Swal.showLoading();
  //     },
  //   });

  //   const adsPayload = [];

  //   for (const ad of advertiseData) {
  //     if ((ad.webImage || ad.mobileImage || ad.popupImage) && ad.title) {
  //       const webAdUrl = ad.webImage
  //         ? await uploadMediaFile(
  //             await fetch(ad.webImage).then((res) => res.blob())
  //           )
  //         : null;
  //       const appAdUrl = ad.mobileImage
  //         ? await uploadMediaFile(
  //             await fetch(ad.mobileImage).then((res) => res.blob())
  //           )
  //         : null;
  //       const popupAdUrl = ad.popupImage
  //         ? await uploadMediaFile(
  //             await fetch(ad.popupImage).then((res) => res.blob())
  //           )
  //         : null;

  //       if (webAdUrl || appAdUrl || popupAdUrl) {
  //         adsPayload.push({
  //           webAdUrl,
  //           appAdUrl,
  //           popupAdUrl,
  //           text: ad.title,
  //         });
  //       }
  //     }
  //   }

  //   if (adsPayload.length > 0) {
  //     try {
  //       const response = await fetch(`${baseurl}/ads/${advertiseChatId}`, {
  //         method: "PUT",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
  //         },
  //         body: JSON.stringify({ ads: adsPayload }),
  //       });

  //       if (response.status === 200) {
  //         Swal.fire("Success", "Advertisements published!", "success");
  //         handleAdTabModal();
  //       } else {
  //         Swal.fire("Error", "Failed to publish ads", "error");
  //       }
  //     } catch (error) {
  //       console.error("Error publishing ads:", error);
  //       Swal.fire("Error", "Failed to publish ads", "error");
  //     } finally {
  //       setLoading(false);
  //     }
  //   } else {
  //     Swal.fire("Warning", "No valid ads to publish", "warning");
  //   }
  // };

  const handlePublishAll = async () => {
    setLoading(true); // Start loading

    // Show "Please wait" SweetAlert2 notification
    Swal.fire({
      title: "Please wait",
      text: "Your ad is being created...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const adsPayload = [];

    for (const ad of advertiseData) {
      // Process only if at least one field (image or title) has data
      if (
        ad.webImage ||
        ad.mobileImage ||
        ad.popupImage ||
        ad.title.trim() !== ""
      ) {
        const webAdUrl = ad.webImage
          ? await uploadMediaFile(
              await fetch(ad.webImage).then((res) => res.blob())
            )
          : null;
        const appAdUrl = ad.mobileImage
          ? await uploadMediaFile(
              await fetch(ad.mobileImage).then((res) => res.blob())
            )
          : null;
        const popupAdUrl = ad.popupImage
          ? await uploadMediaFile(
              await fetch(ad.popupImage).then((res) => res.blob())
            )
          : null;

        // Create ad object dynamically, removing null fields
        const adObject = {};
        if (webAdUrl) adObject.webAdUrl = webAdUrl;
        if (appAdUrl) adObject.appAdUrl = appAdUrl;
        if (popupAdUrl) adObject.popupAdUrl = popupAdUrl;
        if (ad.title.trim() !== "") adObject.text = ad.title;

        adsPayload.push(adObject);
      }
    }

    if (adsPayload.length > 0) {
      try {
        const response = await fetch(`${baseurl}/ads/${advertiseChatId}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
          },
          body: JSON.stringify({ ads: adsPayload }),
        });

        if (response.status === 200) {
          Swal.fire("Success", "Advertisements published!", "success");
          handleAdTabModal();
        } else {
          Swal.fire("Error", "Failed to publish ads", "error");
        }
      } catch (error) {
        console.error("Error publishing ads:", error);
        Swal.fire("Error", "Failed to publish ads", "error");
      }
    } else {
      Swal.fire("Warning", "No valid ads to publish", "warning");
    }

    setLoading(false);
  };
  const renderImageInput = (index, type, image) => (
    <div className="relative">
      <div className="w-[60px] h-[60px] lg:w-[40px] lg:h-[40px] xl:w-[60px] xl:h-[60px] 3xl:w-[80px] 3xl:h-[80px] bg-[#D9D9D9] cursor-pointer flex items-center justify-center rounded-[8px]">
        {image ? (
          <div className="w-full h-full relative">
            <img
              src={image}
              alt="Preview"
              className="w-full h-full object-cover rounded-[8px]"
            />
            <i
              className="bi bi-x text-[18px] lg:text-[14px] lg:right-[-12px] xl:text-[16px] xl:right-[-15px] 3xl:text-[18px] 3xl:right-[-20px]  absolute rounded-[50%] top-[-6px] right-[-20px] cursor-pointer"
              onClick={() => handleDeleteImage(index, type)}
            ></i>
          </div>
        ) : (
          <label className="w-[100%] h-[100%] flex items-center justify-center cursor-pointer m-[0px]">
            <i className="bi bi-card-image text-[#5D5B73] text-[12px] lg:text-[12px] xl:text-[16px] 3xl:text-[20px]"></i>
            <input
              type="file"
              accept="image/*"
              className="hidden"
              onChange={(event) => handleImageUpload(index, type, event)}
            />
          </label>
        )}
      </div>
    </div>
  );

  return (
    <div className="ir-ws-position-fixed ir-ws-sign-popup-container">
      <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container lg:!max-w-[500px] xl:!max-w-[600px] lg:!py-[15px] xl:!py-[20px] 3xl:!py-[30px] !xl:w-[50%] 3xl:!w-[60%] 3xl:!max-w-[800px]">
        <div className="ir-ws-signup-content-container">
          <div
            className="ir-ws-position-absolute ir-ws-signup-close-container"
            onClick={handleAdTabModal}
          >
            <i className="bi bi-x"></i>
          </div>
          <div className="px-[30px] relative flex flex-col gap-[20px]">
            {showImage ? (
              <div>
                <div className="flex items-center justify-center bg-[#d9d9d9] rounded-[10px]">
                  <img src={showImage} alt="Preview" className="max-h-[50vh]" />
                </div>
                <div className="flex items-center justify-center gap-[20px] mt-[20px]">
                  <button
                    className="ir-ws-webinar-actions-button w-[100px] h-[30px] lg:h-[32px] text-[12px] lg:text-[10px] xl:h-[34px] 3xl:h-[40px] rounded-[8px] bg-[#d9d9d9] xl:text-[12px] 3xl:text-[14px]"
                    onClick={handleSave}
                  >
                    Done
                  </button>
                  <button
                    className="ir-ws-webinar-actions-button w-[100px] h-[30px] lg:h-[32px] text-[12px] lg:text-[10px] xl:h-[34px]  3xl:h-[40px] rounded-[8px] bg-[#d9d9d9] xl:text-[12px] 3xl:text-[14px]"
                    onClick={() => setShowImage(null)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <div className="text-[10px] 3xl:gap-[30px] font-[500] lg:text-[8px] xl:text-[12px] 3xl:text-[14px] flex items-center text-center gap-[20px] mb-[10px]">
                  <p className="w-[40px] lg:w-[40px]  xl:w-[60px]  3xl:w-[80px] ">
                    Web <br /> Image
                  </p>
                  <p className="w-[60px] lg:w-[40px]  xl:w-[60px]  3xl:w-[80px] ">
                    Mobile <br /> Image
                  </p>
                  <p className="w-[60px] lg:w-[40px]  xl:w-[60px]  3xl:w-[80px] ">
                    Popup <br /> Image
                  </p>
                </div>
                <div className="flex flex-col gap-[20px] ">
                  {advertiseData.map((ad, index) => (
                    <div
                      key={ad.id}
                      className="flex items-center gap-[20px] 3xl:gap-[30px] w-[100%]"
                    >
                      {renderImageInput(index, "webImage", ad.webImage)}
                      {renderImageInput(index, "mobileImage", ad.mobileImage)}
                      {renderImageInput(index, "popupImage", ad.popupImage)}
                      <input
                        type="text"
                        className="ir-se-admin-form-input h-[36px] lg:text-[10px] lg:h-[30px] xl:h-[40px]  3xl:h-[50px] xl:text-[12px] 3xl:text-[14px]"
                        placeholder={`Advertisement title ${ad.id}`}
                        value={ad.title}
                        onChange={(e) =>
                          handleTextChange(index, e.target.value)
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
            {!showImage && (
              <div className="flex justify-end">
                {/* <button
                  className="ir-ws-webinar-actions-button w-[150px] xl:h-[40px] lg:text-[10px] lg:h-[32px] 3xl:h-[50px] rounded-[8px] bg-[#007bff] text-white xl:text-[12px] 3xl:text-[14px]"
                  onClick={handlePublishAll}
                >
                  {loading ? <div className="spinner"></div> : "Publish"}
                </button> */}
                <button
                  className="ir-ws-webinar-actions-button w-[150px] xl:h-[40px] lg:text-[10px] lg:h-[32px] 3xl:h-[50px] rounded-[8px] bg-[#007bff] text-white xl:text-[12px] 3xl:text-[14px] flex items-center justify-center"
                  onClick={handlePublishAll}
                  disabled={loading}
                >
                  {loading ? (
                    <div className="flex items-center gap-2">
                      <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                      <span>Publishing...</span>
                    </div>
                  ) : (
                    "Publish All"
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvertiseChat;
